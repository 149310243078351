var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import NavigationHeader from "molecules/SessionHeader/NavigationHeader";
import styled from "styled-components";
import { Link } from "react-router-dom";
var Tile = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: 1px solid #e0e0e0;\n    display: flex;\n    justify-content: center;\n    padding: 2rem;\n    border-radius: 1rem;\n    font-size: 2rem;\n    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);\n    text-decoration: none;\n    color: #000000;\n"], ["\n    border: 1px solid #e0e0e0;\n    display: flex;\n    justify-content: center;\n    padding: 2rem;\n    border-radius: 1rem;\n    font-size: 2rem;\n    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);\n    text-decoration: none;\n    color: #000000;\n"])));
var StartPage = function (_a) {
    var theme = _a.theme, cart = _a.cart, onLogoutButtonClicked = _a.onLogoutButtonClicked, fetchSessions = _a.fetchSessions, sessionId = _a.sessionId, deleteCartItem = _a.deleteCartItem, clearCart = _a.clearCart, sessions = _a.sessions, getWatchlist = _a.getWatchlist;
    useEffect(function () {
        var session = localStorage.getItem("sessionuuid");
        if (session !== null && session !== "") {
            getWatchlist(session);
        }
        var interval = setInterval(function () {
            fetchSessions(0);
        }, 15000);
        return function () { return clearInterval(interval); };
    }, []);
    return (_jsxs("div", { children: [_jsx(NavigationHeader, { theme: theme, cart: cart, onLogoutButtonClicked: onLogoutButtonClicked, sessionId: sessionId, clearCart: clearCart, deleteCartItem: deleteCartItem }, void 0), _jsxs("div", __assign({ style: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "2rem",
                    padding: "1rem",
                } }, { children: [_jsxs(Tile, __assign({ to: "/sessions/open" }, { children: ["Neue Anfragen ", (sessions === null || sessions === void 0 ? void 0 : sessions.length) || "0"] }), void 0), _jsx(Tile, __assign({ to: "/sessions/" + localStorage.getItem("sessionuuid") + "/search" }, { children: "Sortiment" }), void 0), _jsx(Tile, __assign({ to: "/sessions/" + localStorage.getItem("sessionuuid") + "/ArticleSearch" }, { children: "Artikel suchen" }), void 0), _jsx(Tile, __assign({ to: "/sessions/" + localStorage.getItem("sessionuuid") + "/scan" }, { children: "Kamera Scanner" }), void 0)] }), void 0)] }, void 0));
};
export default StartPage;
var templateObject_1;
