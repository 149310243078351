import { handleActions } from "redux-actions";
import { fromJS, List, Map } from "immutable";
export var initialState = Map({
    data: List(),
    categoryId: 0,
    primaryCategoryId: 0,
    error: false,
    loaded: false,
    loadedAll: false,
    loading: false,
    loadingAll: false,
    page: 0,
    pageCount: 0,
    total: 0,
    articlesApiParams: "",
});
export var updateArticle = function (article) { return function (existingArticles) {
    var index = existingArticles.findIndex(function (existingArticle) { return existingArticle.get("id") === article.get("id"); });
    if (index === -1) {
        return existingArticles.push(article);
    }
    return existingArticles.set(index, article);
}; };
var updateOrSetArticles = function (articles, fetchType) { return function (existingArticles) {
    if (existingArticles.size === 0) {
        return articles;
    }
    if (fetchType === "filter" || fetchType === "update") {
        var newArticles_1 = articles;
        newArticles_1.forEach(function (article) {
            newArticles_1.update(updateArticle(article));
        });
        return newArticles_1;
    }
    else {
        var newArticles_2 = existingArticles;
        articles.forEach(function (article) {
            newArticles_2 = newArticles_2.update(updateArticle(article));
        });
        return newArticles_2;
    }
}; };
var reducer = handleActions({
    GET_ARTICLE: function (state) { return state.set("loading", true).set("error", false); },
    ARTICLE_NOT_FOUND: function (state) {
        return state.set("loading", false).set("loaded", true).set("error", true);
    },
    GET_ARTICLE_COMPLETE: {
        next: function (state, action) {
            return state
                .set("loading", false)
                .set("loaded", true)
                .update("data", updateArticle(action.payload));
        },
        throw: function (state) {
            return state.set("loading", false).set("loaded", false);
        },
    },
    SCAN_ARTICLE: function (state) {
        return state.set("loading", true).set("error", false);
    },
    SEARCH_ARTICLE: function (state) {
        return state.set("loading", true).set("error", false);
    },
    SEARCH_ARTICLE_COMPLETE: {
        next: function (state, action) {
            return state
                .set("loading", false)
                .set("loaded", true)
                .set("error", false)
                .update("data", updateArticle(action.payload));
        },
        throw: function (state) {
            return state
                .set("loading", false)
                .set("loaded", false)
                .set("error", true);
        },
    },
    GET_ARTICLES: function (state) { return state.set("loadingAll", true); },
    GET_ARTICLES_COMPLETE: {
        next: function (state, action) {
            return state
                .set("loadingAll", false)
                .set("loadedAll", true)
                .set("total", action.payload.totalElements)
                .set("page", action.payload.page)
                .set("pageCount", action.payload.totalPages)
                .update("data", updateOrSetArticles(fromJS(action.payload.content), action.meta.fetchType));
        },
        throw: function (state) {
            return state.set("loadingAll", false).set("loadedAll", false);
        },
    },
    RESET_ARTICLES: function (state) {
        return state
            .set("data", List())
            .set("page", 0)
            .set("total", 0)
            .set("primaryCategoryId", 0)
            .set("error", false);
    },
    FETCH_MORE_ARTICLES: function (state) { return state.set("loadingAll", true); },
    FETCH_MORE_ARTICLES_COMPLETE: {
        next: function (state, action) {
            return state
                .set("loadingAll", false)
                .set("loadedAll", true)
                .set("total", action.payload.totalElements)
                .set("page", action.payload.page)
                .set("pageCount", action.payload.totalPages)
                .update("data", updateOrSetArticles(fromJS(action.payload.content), action.meta.fetchType));
        },
        throw: function (state) {
            return state.set("loadingAll", false).set("loadedAll", false);
        },
    },
    RESET_FILTER_ARTICLES: function (state) { return state.set("loadingAll", true); },
    RESET_FILTER_ARTICLES_COMPLETE: {
        next: function (state, action) {
            return state
                .set("loadingAll", false)
                .set("loadedAll", true)
                .set("total", action.payload.totalElements)
                .set("page", action.payload.page)
                .set("pageCount", action.payload.totalPages)
                .set("data", fromJS(action.payload.content)); // Reset data
        },
        throw: function (state) {
            return state.set("loadingAll", false).set("loadedAll", false);
        },
    },
    UPDATE_ARTICLE: function (state) { return state.set("loading", true); },
    UPDATE_ARTICLE_COMPLETE: {
        next: function (state, action) {
            return state
                .set("loading", false)
                .set("loaded", true)
                .update("data", updateArticle(action.payload));
        },
        throw: function (state) {
            return state.set("loading", false).set("loaded", false);
        },
    },
    RESET_LOADING: function (state) {
        return state.set("loadingAll", false).set("loading", false).set("loaded", true);
    },
    RESET_ARTICLE_ERROR: function (state) { return state.set("error", false); },
    SET_ARTICLE_PARAMS: function (state, action) {
        return state.set("articlesApiParams", action.payload);
    },
}, initialState);
export default reducer;
