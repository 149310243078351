var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useState } from "react";
// utils
import { defineMessages, useIntl } from "react-intl";
// components
import SessionLayout from "templates/SessionLayout";
import SubMenu from "organisms/SubMenu";
import GoBack from "components/molecules/GoBack";
import { withTheme } from "styled-components";
import { withRouter } from "react-router";
import DropDownHandler from "./components/DropDownHandler";
var Filter = function (_a) {
    var assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, terminals = _a.terminals, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist, session = _a.session, theme = _a.theme;
    var intl = useIntl();
    var msg = defineMessages({
        filter: { defaultMessage: "Filter", id: "filter.menu.headline" },
    });
    var _b = useState(false), isArticleListScrolled = _b[0], setIsArticleListScrolled = _b[1];
    var handleArticleListScroll = function (scrolled) {
        setIsArticleListScrolled(scrolled);
    };
    return (_jsx(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, currentAssistantId: currentAssistantId, session: session, isArticleListScrolled: isArticleListScrolled, subMenu: _jsx(SubMenu, { title: intl.formatMessage(msg.filter), cancelIcon: _jsx(GoBack, { icon: "back", width: "20" }, void 0) }, void 0), terminals: terminals, unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, backgroundColor: theme.palette.white, watchlist: watchlist }, { children: _jsx(DropDownHandler, { ScrollingState: handleArticleListScroll }, void 0) }), void 0));
};
export var FilterComponent = Filter;
export default withRouter(withTheme(Filter));
