var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
// utils
import { fontWeight, palette } from "@shared/utils/styled";
import { getPriceString } from "@shared/utils/currency";
// components
import GoBack from "molecules/GoBack";
import SubMenu from "organisms/SubMenu";
import CancelButton from "@shared/molecules/CancelButton";
import CheckoutDelivery from "molecules/CheckoutDelivery";
import SessionLayout from "templates/SessionLayout";
import { processActionTypes } from "@shared/utils/constants";
var BottomMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  // margin-bottom: 52px;\n  height: 3.25rem;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n"], ["\n  // margin-bottom: 52px;\n  height: 3.25rem;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n"])));
var BottomMenuPrev = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1), 0px -1px 3px rgba(0, 0, 0, 0.1);\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  justify-content: center;\n  overflow: hidden;\n"], ["\n  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1), 0px -1px 3px rgba(0, 0, 0, 0.1);\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  justify-content: center;\n  overflow: hidden;\n"])), palette("white"), palette("linkText"), fontWeight("bold"));
var BottomMenuNext = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n  align-items: center;\n  background-color: ", ";\n  border: 0.125rem solid ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  justify-content: center;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n\n  & > span {\n    opacity: ", ";\n  }\n"], ["\n  cursor: pointer;\n  align-items: center;\n  background-color: ", ";\n  border: 0.125rem solid ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  justify-content: center;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n\n  & > span {\n    opacity: ", ";\n  }\n"])), palette("primaryHighlight"), palette("white"), palette("white"), fontWeight("bold"), function (props) {
    return props.disabled
        ? props.theme.palette.primaryHighlight
        : props.theme.palette.activePrimaryHighlight;
}, function (props) { return (props.disabled ? "0.3" : "1"); });
var PriceLabel = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight("bold"));
var CheckoutDeliveryComponent = function (_a) {
    var watchlist = _a.watchlist, match = _a.match, history = _a.history, session = _a.session, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, currentStoreId = _a.currentStoreId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, stores = _a.stores, terminals = _a.terminals, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, updateSession = _a.updateSession;
    var intl = useIntl();
    var getProccessActionType = function () {
        return match && match.params && match.params.type
            ? match.params.type
            : processActionTypes.CHECKOUT;
    };
    var cancelCheckout = function () {
        history.push("/sessions/" + session.uuid);
    };
    var getTotalPrice = function () {
        var total = watchlist.reduce(function (acc, item) {
            if (!item.selected) {
                return acc;
            }
            if (item.sizeId) {
                var si = item.article.sizes.find(function (size) { return size.sizeId === item.sizeId; });
                return si ? acc + si.price : acc;
            }
            else if (item.article.sizes.length === 0 && item.article.isAccessoire) {
                return item.article.prices.min + acc;
            }
            return acc;
        }, 0);
        return getPriceString(total);
    };
    var nextStepAvailable = function () {
        var filtered = watchlist.filter(function (item) { return item.selected; });
        if (!Boolean(filtered.length)) {
            return false;
        }
        return !Boolean(filtered.find(function (item) {
            return item.sizeId === null &&
                !(item.article.isAccessoire && item.article.sizes.length === 0);
        }));
    };
    var submit = function () {
        var _a;
        // { cancelable: true } required for Firefox
        // https://github.com/facebook/react/issues/12639#issuecomment-382519193
        (_a = document
            .getElementById("delivery-options-form")) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        var type = getProccessActionType();
        if (session.buyerId) {
            history.push({
                pathname: "/sessions/" + session.uuid + "/customer/finish/" + session.buyerId,
                search: "?ref=" + type,
            });
        }
        else {
            history.push({
                pathname: "/sessions/" + session.uuid + "/customer/add",
                search: "?ref=" + type,
            });
        }
    };
    var renderBottomMenu = function () {
        return (_jsxs(BottomMenu, { children: [_jsx(BottomMenuPrev, { children: _jsx(PriceLabel, { children: getTotalPrice() }, void 0) }, void 0), _jsx(BottomMenuNext, __assign({ disabled: !nextStepAvailable(), onClick: submit }, { children: _jsx(FormattedMessage, { defaultMessage: "Weiter", id: "checkout.bottomMenu.next" }, void 0) }), void 0)] }, void 0));
    };
    var type = getProccessActionType();
    var msgCollection = {
        checkout: defineMessages({
            customer: {
                defaultMessage: "Verkaufen",
                id: "checkout.delivery.menu.headline",
            },
        }),
        reserve: defineMessages({
            customer: {
                defaultMessage: "Reservieren",
                id: "reserve.delivery.menu.headline",
            },
        }),
        request: defineMessages({
            customer: {
                defaultMessage: "Anfordern",
                id: "request.delivery.menu.headline",
            },
        }),
        order: defineMessages({
            customer: {
                defaultMessage: "Bezahlen an der Kasse",
                id: "order.delivery.menu.headline",
            },
        }),
    };
    var msg = msgCollection[getProccessActionType()];
    return (_jsx(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, subMenu: _jsx(SubMenu, { cancelIcon: _jsx(GoBack, { icon: "iconBack", width: "15" }, void 0), iconRight: _jsx(CancelButton, { action: cancelCheckout }, void 0), title: intl.formatMessage(msg.customer) }, void 0), bottomMenu: renderBottomMenu(), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist }, { children: _jsx(CheckoutDelivery, { watchlist: watchlist, limitText: type !== processActionTypes.CHECKOUT, updateSession: function (data) {
                updateSession(data, session.uuid);
            }, stores: stores, currentStoreId: currentStoreId, type: type, session: session }, void 0) }), void 0));
};
export default withRouter(CheckoutDeliveryComponent);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
