var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import Icon from '@shared/atoms/Icon';
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-height: 4.125rem;\n  max-width: 4.125rem;\n  padding: 0.3125rem;\n  position: absolute;\n  top: 0;\n  z-index: 1;\n  left: 0;\n"], ["\n  max-height: 4.125rem;\n  max-width: 4.125rem;\n  padding: 0.3125rem;\n  position: absolute;\n  top: 0;\n  z-index: 1;\n  left: 0;\n"])));
var Badge = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: auto;\n  width: 100%;\n"], ["\n  height: auto;\n  width: 100%;\n"])));
var DiscountBadge = function () { return (_jsx(Wrapper, { children: _jsx(Badge, { icon: 'discountSmall', height: 25 }, void 0) }, void 0)); };
export default DiscountBadge;
var templateObject_1, templateObject_2;
