var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { getAuthHeader, getUUID } from "@shared/utils/auth";
import { ComboField, Error, Form, SearchIconContainer, StyledField, SuggestionItem, Suggestions, } from './styles';
import localStore from "store";
import { useSelector } from 'react-redux';
import { selectArticleError } from '@store/article/selectors';
import useDebounce from '../../../hooks/useDebounce';
import axios from 'axios';
var ScanFormComponent = function (_a) {
    var onSubmit = _a.onSubmit, scanResult = _a.scanResult;
    var deviceId = getUUID(window.location.search) || localStore.get("deviceId");
    var apiUrl = process.env.REACT_APP_API_URL;
    var headers = {
        Authorization: getAuthHeader(deviceId)
    };
    var _b = useState('Artikelnummer'), placeholder = _b[0], setPlaceholder = _b[1];
    var hasError = useSelector(selectArticleError);
    var _c = useState(''), searchInput = _c[0], setSearchInput = _c[1];
    var debouncedSearchInput = useDebounce(searchInput, 500);
    var _d = useState([]), suggestions = _d[0], setSuggestions = _d[1];
    var _e = useState(false), showSuggestions = _e[0], setShowSuggestions = _e[1];
    var _f = useState(-1), selectedSuggestionIndex = _f[0], setSelectedSuggestionIndex = _f[1];
    useEffect(function () {
        if (debouncedSearchInput.length >= 3) {
            fetchSuggestions(debouncedSearchInput).then(function (data) {
                setSuggestions(data);
                setSelectedSuggestionIndex(-1);
                setShowSuggestions(true);
            });
        }
        else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    }, [debouncedSearchInput]);
    var fetchSuggestions = function (query) { return __awaiter(void 0, void 0, Promise, function () {
        var api, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    api = apiUrl + "v2/article-match?query=" + query;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.get(api, { headers: headers })];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 3:
                    error_1 = _a.sent();
                    console.error("Error fetching suggestions:", error_1);
                    return [2 /*return*/, []];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onInputChange = function (e) {
        setSearchInput(e.target.value);
        setSelectedSuggestionIndex(-1);
    };
    var handleSuggestionSelection = function (suggestion) {
        setSearchInput(suggestion.custArtNumber);
        setShowSuggestions(false);
        setPlaceholder(suggestion.custArtNumber);
    };
    var handleKeyDown = function (e) {
        if (e.key === 'ArrowUp') {
            // up arrow
            setSelectedSuggestionIndex(function (prevIndex) {
                return prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1;
            });
        }
        else if (e.key === 'ArrowDown') {
            // down arrow
            setSelectedSuggestionIndex(function (prevIndex) {
                return prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0;
            });
        }
        else if (e.key === 'Enter' && selectedSuggestionIndex !== -1) {
            // enter key
            e.preventDefault();
            setSearchInput(suggestions[selectedSuggestionIndex].custArtNumber);
            setShowSuggestions(false);
        }
    };
    return (_jsx(FinalForm, { onSubmit: function (values) { return onSubmit(__assign(__assign({}, values), { code: searchInput })); }, initialValues: {
            type: 'number',
            code: scanResult,
        }, render: function (_a) {
            var _b;
            var handleSubmit = _a.handleSubmit, submitting = _a.submitting, pristine = _a.pristine;
            return (_jsxs(Form, __assign({ onSubmit: handleSubmit }, { children: [_jsxs(ComboField, { children: [_jsx(StyledField, { autoComplete: "off", component: "input", name: "code", placeholder: placeholder, type: "string", required: true, value: searchInput, onKeyDown: handleKeyDown, onChange: onInputChange }, void 0), _jsx(SearchIconContainer, __assign({ style: { cursor: "pointer" }, type: "submit", disabled: pristine || submitting }, { children: _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "55px", height: "55px", viewBox: "0 0 24 24", fill: "none" }, { children: _jsx("path", { d: "M14 4L17.5 4C20.5577 4 20.5 8 20.5 12C20.5 16 20.5577 20 17.5 20H14M15 12L3 12M15 12L11 16M15 12L11 8", stroke: "#000", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }, void 0) }), void 0) }), void 0)] }, void 0), _jsx(Error, { children: hasError && _jsx("span", { children: "Ihr Suchanfrage ergab leider keinen Treffer." }, void 0) }, void 0), showSuggestions && suggestions.length > 0 && (_jsx(Suggestions, { children: (_b = _.orderBy(suggestions, "custArtNumber", "asc")) === null || _b === void 0 ? void 0 : _b.map(function (suggestion, index) { return (_jsx(SuggestionItem, __assign({ onClick: function () { return handleSuggestionSelection(suggestion); }, className: index === selectedSuggestionIndex ? "selected" : "", role: "button", tabIndex: 0 }, { children: suggestion.custArtNumber }), suggestion.custArtNumber)); }) }, void 0))] }), void 0));
        } }, void 0));
};
export default ScanFormComponent;
