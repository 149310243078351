var _a;
import { createActions, createAction } from 'redux-actions';
export var createWatchlistItemComplete = (_a = createActions('CREATE_WATCHLIST_ITEM_COMPLETE', 'DELETE_WATCHLIST_ITEM_COMPLETE', 'GET_WATCHLIST_COMPLETE', 'GET_WATCHLIST', 'DELETE_WATCHLIST', 'GET_WATCHLIST_STATE', 'GET_WATCHLIST_STATE_COMPLETE', 'DELETE_WATCHLIST_COMPLETE', 'UPDATE_WATCHLIST_ITEM_COMPLETE', 'REQUEST_WATCHLIST', 'REQUEST_WATCHLIST_COMPLETE', 'POLL_WATCHLIST_STATE', 'POLL_WATCHLIST_STATE_COMPLETE', 'SET_WATCHLIST_STATUS', 'SET_WATCHLIST_HAS_DIFFERENT_TYPE', 'ADD_TO_CART_CLICKED'), _a.createWatchlistItemComplete), deleteWatchlistItemComplete = _a.deleteWatchlistItemComplete, getWatchlistComplete = _a.getWatchlistComplete, getWatchlist = _a.getWatchlist, deleteWatchlist = _a.deleteWatchlist, getWatchlistState = _a.getWatchlistState, getWatchlistStateComplete = _a.getWatchlistStateComplete, deleteWatchlistComplete = _a.deleteWatchlistComplete, updateWatchlistItemComplete = _a.updateWatchlistItemComplete, requestWatchlist = _a.requestWatchlist, requestWatchlistComplete = _a.requestWatchlistComplete, pollWatchlist = _a.pollWatchlist, pollWatchlistcomplete = _a.pollWatchlistcomplete, setWatchlistStatus = _a.setWatchlistStatus, setWatchlistHasDifferentType = _a.setWatchlistHasDifferentType, addToCartClickedcomplete = _a.addToCartClickedcomplete;
export var createWatchlistItem = createAction('CREATE_WATCHLIST_ITEM', function (payload) { return payload; }, function (payload, sessionId) { return ({ sessionId: sessionId }); });
export var deleteWatchlistItem = createAction('DELETE_WATCHLIST_ITEM', function (payload) { return payload; }, function (payload, sessionId) { return ({ sessionId: sessionId }); });
export var updateWatchlistItem = createAction('UPDATE_WATCHLIST_ITEM', function (payload) { return payload; }, function (payload, id, sessionId) { return ({ id: id, sessionId: sessionId }); });
export var addToCartClicked = function (item) { return ({
    type: "ADD_TO_CART_CLICKED",
    payload: item
}); };
