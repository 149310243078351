import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import CancelButton from '@shared/molecules/CancelButton';
var GoBack = function (_a) {
    var history = _a.history, icon = _a.icon, width = _a.width;
    return (_jsx(CancelButton, { customIcon: icon, action: history.goBack, width: width }, void 0));
};
export default withRouter(GoBack);
