import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
// eslint-disable-next-line
export var initialState = Map({
    data: List(),
    temp: List(),
});
export var addFilterItem = function (id, displayName) { return function (items) {
    return items.push(Map({ id: id, displayName: displayName }));
}; };
export var removeFilterItem = function (id) { return function (items) {
    return items.filter(function (item) { return item.get('id') !== id; });
}; };
export var operateOnFilterSelection = function (payload, operator) { return function (existingSelections) {
    var selectionIndex = existingSelections.findIndex(function (item) { return item.get('id') === payload.filterId; });
    if (selectionIndex !== -1) {
        var filterSelection = existingSelections.get(selectionIndex);
        filterSelection = filterSelection.update('items', operator(payload.filterOptionId, payload.filterOptionName));
        return existingSelections.set(selectionIndex, filterSelection);
    }
    return existingSelections;
}; };
export var initializeFilterSelection = function (filter) { return function (existingSelections) {
    var selectionIndex = existingSelections.findIndex(function (selection) { return selection.get('id') === filter.get('id'); });
    if (selectionIndex === -1) {
        return existingSelections.push(Map({ id: filter.get('id'), name: filter.get('name'), items: List() }));
    }
    return existingSelections;
}; };
export var initializeFilterSelections = function (filters) { return function (existingSelections) {
    if (existingSelections.size === 0) {
        return filters.reduce(function (acc, filter) {
            return acc.push(Map({
                id: filter.get('id'),
                name: filter.get('name'),
                items: List(),
            }));
        }, List());
    }
    return filters.reduce(function (acc, filter) { return initializeFilterSelection(filter)(acc); }, existingSelections);
}; };
export var resetFilterSelections = function () { return function (existing) {
    return existing.reduce(function (acc, item) {
        return acc.push(Map({ id: item.get('id'), name: item.get('name'), items: List() }));
    }, List());
}; };
var reducer = handleActions({
    APPLY_TEMP_FILTER_SELECTIONS: function (state) {
        return state.set('data', state.get('temp'));
    },
    RESET_TEMP_FILTER_SELECTIONS: function (state) {
        return state.set('temp', state.get('data'));
    },
    SELECT_FILTER_ITEM: function (state, action) {
        return state.update('temp', operateOnFilterSelection(action.payload, addFilterItem));
    },
    DESELECT_FILTER_ITEM: function (state, action) {
        return state.update('temp', operateOnFilterSelection(action.payload, removeFilterItem));
    },
    RESET_FILTER_SELECTIONS: function (state) {
        return state
            .update('data', resetFilterSelections())
            .update('temp', resetFilterSelections());
    },
    INITIALIZE_FILTER_SELECTIONS: function (state, action) {
        return state
            .update('data', initializeFilterSelections(action.payload))
            .update('temp', initializeFilterSelections(action.payload));
    },
    INITIALIZE_FILTER_SELECTION: function (state, action) {
        return state
            .update('data', initializeFilterSelection(action.payload))
            .update('temp', initializeFilterSelection(action.payload));
    },
}, initialState);
export default reducer;
