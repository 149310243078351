var _a;
import { createActions, createAction } from 'redux-actions';
import { metaCreator } from '@shared/utils/redux';
export var createCustomerComplete = (_a = createActions('CREATE_CUSTOMER_COMPLETE', 'GET_CUSTOMER', 'GET_CUSTOMER_COMPLETE', 'GET_CUSTOMERS', 'GET_CUSTOMERS_COMPLETE', 'SEARCH_CUSTOMER_COMPLETE', 'UPDATE_CUSTOMER_COMPLETE'), _a.createCustomerComplete), getCustomer = _a.getCustomer, getCustomerComplete = _a.getCustomerComplete, getCustomers = _a.getCustomers, getCustomersComplete = _a.getCustomersComplete, searchCustomerComplete = _a.searchCustomerComplete, updateCustomerComplete = _a.updateCustomerComplete;
export var createCustomer = createAction('CREATE_CUSTOMER', function (payload) { return payload; }, function (payload, sessionId, ref, resolve, reject) {
    return metaCreator({ sessionId: sessionId, ref: ref }, resolve, reject);
});
export var createCustomerLocal = createAction('CREATE_CUSTOMER_LOCAL', function (payload) { return payload; }, function (sessionId, ref) { return metaCreator({ sessionId: sessionId, ref: ref }); });
export var searchCustomer = createAction('SEARCH_CUSTOMER', function (payload) { return payload; }, function (payload, sessionId, requestType, ref) {
    return metaCreator({ requestType: requestType, sessionId: sessionId, ref: ref });
});
export var updateCustomer = createAction('UPDATE_CUSTOMER', function (payload) { return payload; }, function (payload, customerId, resolve, reject) {
    return metaCreator({ customerId: customerId }, resolve, reject);
});
