var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
// utils
import styled, { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { fontSize, palette } from '@shared/utils/styled';
import { defineMessages, useIntl } from 'react-intl';
// components
import AssistantProfile from 'organisms/AssistantProfile';
import CancelButton from '@shared/molecules/CancelButton';
import Spinner from '@shared/atoms/Spinner';
import HomeLayout from 'templates/HomeLayout';
import SubMenu from 'organisms/SubMenu';
var SubMenuWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border-top: 0.0625rem solid ", ";\n"], ["\n    border-top: 0.0625rem solid ", ";\n"])), palette('border'));
var LogoutButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    cursor: pointer;\n    align-items: center;\n    background-color: ", ";\n    color: ", ";\n    display: flex;\n    font-size: ", ";\n    height: fit-content;\n    justify-content: center;\n    position: absolute;\n    padding: 10px;\n    bottom: 0;\n    width: 100%;\n\n    &:active {\n        background-color: ", ";\n    }\n"], ["\n    cursor: pointer;\n    align-items: center;\n    background-color: ", ";\n    color: ", ";\n    display: flex;\n    font-size: ", ";\n    height: fit-content;\n    justify-content: center;\n    position: absolute;\n    padding: 10px;\n    bottom: 0;\n    width: 100%;\n\n    &:active {\n        background-color: ", ";\n    }\n"])), palette('black'), palette('white'), fontSize('large'), palette('activeSecondaryBackground'));
var Profile = function (_a) {
    var logout = _a.logout, currentAssistant = _a.currentAssistant, assistantLoading = _a.assistantLoading, createSession = _a.createSession, assignSession = _a.assignSession, history = _a.history, theme = _a.theme;
    var intl = useIntl();
    var msg = defineMessages({
        profile: { defaultMessage: 'Profil', id: 'profile.menu.headline' },
    });
    return (_jsxs(HomeLayout, __assign({ createSession: createSession, assignSession: assignSession, backgroundColor: theme.palette.white }, { children: [_jsx(SubMenuWrapper, { children: _jsx(SubMenu, { cancelIcon: _jsx(CancelButton, { action: function () { return history.goBack(); } }, void 0), title: intl.formatMessage(msg.profile) }, void 0) }, void 0), currentAssistant && !assistantLoading && (_jsx(AssistantProfile, { currentAssistant: currentAssistant }, void 0)), assistantLoading && _jsx(Spinner, {}, void 0), _jsx(LogoutButton, __assign({ onClick: logout }, { children: "Abmelden" }), void 0)] }), void 0));
};
export default withRouter(withTheme(Profile));
var templateObject_1, templateObject_2;
