var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { withRouter } from "react-router-dom";
// utils
import { fontWeight, palette } from "@shared/utils/styled";
import { deliveryModes, processActionTypes } from "@shared/utils/constants";
// components
import CheckoutCompleteContent from "molecules/CheckoutCompleteContent";
import SubMenu from "organisms/SubMenu";
import SessionLayout from "templates/SessionLayout";
import Spinner from "@shared/atoms/Spinner";
import { useSelector } from "react-redux";
import { isRequestingInProgress } from "@store/action/selectors";
var NoCustomerArtIdWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";\n    height: 100%;\n    padding: 2rem;\n    text-align: center;\n    overflow: auto;\n    margin-bottom: 2rem;\n    flex: 1;\n"], ["\n    background-color: ", ";\n    height: 100%;\n    padding: 2rem;\n    text-align: center;\n    overflow: auto;\n    margin-bottom: 2rem;\n    flex: 1;\n"])), palette("white"));
var BottomMenu = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    // margin-bottom: 50px;\n    height: 3.25rem;\n    position: relative;\n    display: flex;\n"], ["\n    // margin-bottom: 50px;\n    height: 3.25rem;\n    position: relative;\n    display: flex;\n"])));
var BottomMenuArchive = styled("button")(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border: none;\n    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1), 0px -1px 3px rgba(0, 0, 0, 0.1);\n    cursor: pointer;\n    align-items: center;\n    background-color: ", ";\n    color: ", ";\n    display: flex;\n        // font-weight: ", ";\n    height: 100%;\n    justify-content: center;\n    overflow: hidden;\n    text-align: center;\n    text-decoration: none;\n    transition: background-color 0.2s ease, opacity 0.2s ease;\n    width: 100%;\n\n    &:active {\n        background-color: #ACAAA9;\n    }\n\n    & > span {\n        opacity: ", ";\n    }\n"], ["\n    border: none;\n    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1), 0px -1px 3px rgba(0, 0, 0, 0.1);\n    cursor: pointer;\n    align-items: center;\n    background-color: ", ";\n    color: ", ";\n    display: flex;\n        // font-weight: ", ";\n    height: 100%;\n    justify-content: center;\n    overflow: hidden;\n    text-align: center;\n    text-decoration: none;\n    transition: background-color 0.2s ease, opacity 0.2s ease;\n    width: 100%;\n\n    &:active {\n        background-color: #ACAAA9;\n    }\n\n    & > span {\n        opacity: ", ";\n    }\n"])), palette("black"), palette("white"), fontWeight("bold"), function (props) { return (props.disabled ? "0.3" : "1"); });
var CheckoutComplete = function (_a) {
    var assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, checkingOut = _a.checkingOut, checkoutNumber = _a.checkoutNumber, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, orderNumber = _a.orderNumber, session = _a.session, terminals = _a.terminals, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist, resetOrderNumber = _a.resetOrderNumber, archiveSession = _a.archiveSession, customer = _a.customer, orderAction = _a.orderAction, match = _a.match, requestWatchlist = _a.requestWatchlist, checkout = _a.checkout;
    var intl = useIntl();
    var isRequestProcessing = useSelector(isRequestingInProgress);
    var onOrder = function () {
        var type = getProcessActionType();
        var deliveryMode = session.deliveryOption === "store"
            ? deliveryModes.MANUFACTURE_ORDER
            : deliveryModes.DROPSHIPPING;
        if (!orderNumber &&
            type === processActionTypes.ORDER &&
            customer &&
            customer.email) {
            orderAction(customer, session.uuid, deliveryMode);
        }
    };
    var getProcessActionType = function () {
        return match && match.params && match.params.type
            ? match.params.type
            : processActionTypes.CHECKOUT;
    };
    var _b = useState(false), noCustomerArtId = _b[0], setNoCustomerArtId = _b[1];
    var msgCollection = {
        checkout: defineMessages({
            customer: {
                defaultMessage: "Verkaufen",
                id: "checkout.complete.menu.headline",
            },
        }),
        order: defineMessages({
            customer: {
                defaultMessage: "Bezahlen an der Kasse",
                id: "order.complete.menu.headline",
            },
        }),
        reserve: defineMessages({
            customer: {
                defaultMessage: "Reservieren",
                id: "reserve.complete.menu.headline",
            },
        }),
        request: defineMessages({
            customer: {
                defaultMessage: "Anfordern",
                id: "request.complete.menu.headline",
            },
        }),
    };
    var type = getProcessActionType();
    var msg = msgCollection[type];
    var archive = function () {
        localStorage.removeItem("sessionuuid");
        resetOrderNumber();
        archiveSession(session.uuid);
    };
    var articles = watchlist
        .filter(function (item) { return item.selected; })
        .reduce(function (acc, item) {
        var article = {};
        article.sizeId = item.sizeId;
        article.articleId = item.articleId;
        article.customerArtId = item.article.customerArtId;
        acc.push(article);
        return acc;
    }, []);
    useEffect(function () {
        if (!checkingOut &&
            (type === processActionTypes.CHECKOUT ||
                type === processActionTypes.ORDER)) {
            if (type === processActionTypes.ORDER &&
                articles.length > 0 &&
                !articles[0].customerArtId) {
                setNoCustomerArtId(true);
                return;
            }
            checkout({ articles: articles, customerId: session.buyerId || null, type: type }, session.uuid);
        }
    }, []);
    var isCheckoutComplete = type === processActionTypes.REQUEST ||
        type === processActionTypes.RESERVE ||
        (type === processActionTypes.ORDER && orderNumber) ||
        (type === processActionTypes.CHECKOUT && checkoutNumber);
    var renderBottomMenu = function () {
        return (_jsxs(BottomMenu, { children: [_jsxs(BottomMenuArchive, __assign({ onClick: function () { return archive(); } }, { children: [isCheckoutComplete && (_jsx(FormattedMessage, { defaultMessage: "Vorgang abschlie\u00DFen", id: "checkoutComplete.bottomMenu.archive" }, void 0)), !isCheckoutComplete && (_jsx(FormattedMessage, { defaultMessage: "Vorgang abbrechen", id: "checkoutComplete.bottomMenu.cancel" }, void 0))] }), void 0), type === processActionTypes.ORDER &&
                    !orderNumber &&
                    watchlist.length > 0 && (_jsx(BottomMenuArchive, __assign({ onClick: function () { return onOrder(); } }, { children: _jsx(FormattedMessage, { defaultMessage: "Zahlung best\u00E4tigen", id: "checkout.complete.bottomMenu.confirmPayment" }, void 0) }), void 0))] }, void 0));
    };
    return (_jsxs(SessionLayout
    // hideTopMenu
    , __assign({ 
        // hideTopMenu
        assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, bottomMenu: renderBottomMenu(), currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, subMenu: _jsx(SubMenu, { title: intl.formatMessage(msg.customer), noBack: true }, void 0), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist, archiveSession: archiveSession, requestWatchlist: requestWatchlist }, { children: [noCustomerArtId && watchlist.length > 0 && (_jsx(NoCustomerArtIdWrapper, { children: _jsx(FormattedMessage, { id: "checkout.complete.noCustomerArtId", defaultMessage: "Artikel nicht im System, bitte \u00FCber Sammelwarengruppe verkaufen" }, void 0) }, void 0)), (checkingOut || isRequestProcessing) && _jsx(Spinner, {}, void 0), !checkingOut && watchlist.length > 0 && (_jsx(CheckoutCompleteContent, { checkoutNumber: checkoutNumber, hasNoCustomerArtId: noCustomerArtId, orderNumber: orderNumber, type: type }, void 0)), renderBottomMenu()] }), void 0));
};
export var CheckoutCompleteComponent = CheckoutComplete;
export default withRouter(CheckoutComplete);
var templateObject_1, templateObject_2, templateObject_3;
