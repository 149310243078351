var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { fontWeight, palette } from "@shared/utils/styled";
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n   color: ", ";\n   height: calc(100vh - 7rem);\n   overflow-y: auto;\n   flex: 1;\n\n   /* Custom scrollbar styles for WebKit browsers (Chrome, Safari) */\n   &::-webkit-scrollbar {\n      width: 6px; /* Set the width of the scrollbar */\n   }\n\n   &::-webkit-scrollbar-thumb {\n      background-color: lightgray; /* Thumb color */\n   }\n\n   &::-webkit-scrollbar-track {\n      background-color: gray; /* Track color */\n   }\n\n   /* Custom scrollbar styles for Firefox */\n   scrollbar-width: thin;\n   // scrollbar-color: ", " ", "; /* Thumb color and track color */\n"], ["\n   color: ", ";\n   height: calc(100vh - 7rem);\n   overflow-y: auto;\n   flex: 1;\n\n   /* Custom scrollbar styles for WebKit browsers (Chrome, Safari) */\n   &::-webkit-scrollbar {\n      width: 6px; /* Set the width of the scrollbar */\n   }\n\n   &::-webkit-scrollbar-thumb {\n      background-color: lightgray; /* Thumb color */\n   }\n\n   &::-webkit-scrollbar-track {\n      background-color: gray; /* Track color */\n   }\n\n   /* Custom scrollbar styles for Firefox */\n   scrollbar-width: thin;\n   // scrollbar-color: ", " ", "; /* Thumb color and track color */\n"])), palette("text"), palette("black"), palette("black"));
export var ArticleContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n   padding: 0 0.625rem 0.625rem;\n"], ["\n   padding: 0 0.625rem 0.625rem;\n"])));
export var AddToCartWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-radius: 0.5rem\n  border: 1px solid black;\n  padding: 1rem;\n  text-align: center;\n  color: black;\n  margin-top:10px\n"], ["\n  border-radius: 0.5rem\n  border: 1px solid black;\n  padding: 1rem;\n  text-align: center;\n  color: black;\n  margin-top:10px\n"])));
export var Main = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    background: ", ";\n    display: flex;\n    flex-direction: column;\n    /* grid-auto-rows: max-content;\n   grid-column-gap: 0.625rem;\n   grid-template-columns: 1fr 2fr; */\n"], ["\n    background: ", ";\n    display: flex;\n    flex-direction: column;\n    /* grid-auto-rows: max-content;\n   grid-column-gap: 0.625rem;\n   grid-template-columns: 1fr 2fr; */\n"])), palette("white"));
export var BrandImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n   width: 5rem;\n   margin-top: 1rem;\n"], ["\n   width: 5rem;\n   margin-top: 1rem;\n"])));
export var Light = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n   // font-weight: ", ";\n   font-size: 1rem;\n"], ["\n   // font-weight: ", ";\n   font-size: 1rem;\n"])), fontWeight("light"));
export var LightInfo = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\ndisplay: block;\n  max-width: 150px; \n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  // font-weight: ", ";\n   font-size: 1rem;\n"], ["\ndisplay: block;\n  max-width: 150px; \n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  // font-weight: ", ";\n   font-size: 1rem;\n"])), fontWeight("light"));
export var Name = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n   padding-left: 10px;\n   color: #000000;\n   font-size: 16px;\n"], ["\n   padding-left: 10px;\n   color: #000000;\n   font-size: 16px;\n"])));
export var Headline = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n   font-size: 1rem;\n   font-weight: ", ";\n   letter-spacing: 0.4px;\n"], ["\n   font-size: 1rem;\n   font-weight: ", ";\n   letter-spacing: 0.4px;\n"])), fontWeight("bold"));
export var BlockOne = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n   margin-bottom: 0rem;\n   flex-wrap: wrap;\n   display: flex;\n   flex-direction: column;\n"], ["\n   margin-bottom: 0rem;\n   flex-wrap: wrap;\n   display: flex;\n   flex-direction: column;\n"])));
export var Block = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n   margin-bottom: 0rem;\n   flex-wrap: wrap;\n   display: flex;\n   flex-direction: column;\n"], ["\n   margin-bottom: 0rem;\n   flex-wrap: wrap;\n   display: flex;\n   flex-direction: column;\n"])));
export var BlockTwo = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n   margin-bottom: 0rem;\n   flex-wrap: wrap;\n   display: flex;\n   flex-direction: column;\n   align-items: flex-start;\n   align-content: flex-end;\n"], ["\n   margin-bottom: 0rem;\n   flex-wrap: wrap;\n   display: flex;\n   flex-direction: column;\n   align-items: flex-start;\n   align-content: flex-end;\n"])));
export var Tag = styled.span(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n   color: ", ";\n   text-decoration: ", ";\n"], ["\n   color: ", ";\n   text-decoration: ", ";\n"])), function (props) { return (props.discount ? "#b52025" : "inherit"); }, function (props) { return (props.crossed ? "line-through" : "none"); });
export var SliderWrapper = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    width: 100%;\n    z-index: 0;\n\n    .swiper-button-next,\n    .swiper-button-prev {\n        color: ", ";\n    }\n\n    .swiper-button-next:after,\n    .swiper-button-prev:after {\n        font-size: xx-large;\n    }\n"], ["\n    width: 100%;\n    z-index: 0;\n\n    .swiper-button-next,\n    .swiper-button-prev {\n        color: ", ";\n    }\n\n    .swiper-button-next:after,\n    .swiper-button-prev:after {\n        font-size: xx-large;\n    }\n"])), palette("secondaryHighlight"));
export var SlideSmall = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject([""], [""])));
export var ColorSlider = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n   grid-column: 1 / 3;\n   margin-top: 0.75rem;\n   z-index: 0;\n   /* max-height: 12rem; */\n"], ["\n   grid-column: 1 / 3;\n   margin-top: 0.75rem;\n   z-index: 0;\n   /* max-height: 12rem; */\n"])));
export var WmsSize = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n   color: #b52025;\n   margin-top: 1rem;\n"], ["\n   color: #b52025;\n   margin-top: 1rem;\n"])));
export var Button = styled.button(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  //box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);\n  box-shadow: rgba(0, 0, 0, 0.2) 0px 0.125rem 0.25rem 2px;\n  background-color: black;\n  color: ", ";\n  border: 1px solid #a9a9a9;\n  font-size: 1rem;\n  padding: 0.6rem 1.2rem;\n  border-radius: 0.2rem;\n  text-align: center;\n  width: 100%;\n  cursor: pointer;\n  transition: background-color 0.2s, box-shadow 0.2s;\n  \n  &:disabled {\n    background-color: #ACAAA9;\n    cursor: not-allowed;\n  }\n"], ["\n  //box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);\n  box-shadow: rgba(0, 0, 0, 0.2) 0px 0.125rem 0.25rem 2px;\n  background-color: black;\n  color: ", ";\n  border: 1px solid #a9a9a9;\n  font-size: 1rem;\n  padding: 0.6rem 1.2rem;\n  border-radius: 0.2rem;\n  text-align: center;\n  width: 100%;\n  cursor: pointer;\n  transition: background-color 0.2s, box-shadow 0.2s;\n  \n  &:disabled {\n    background-color: #ACAAA9;\n    cursor: not-allowed;\n  }\n"])), palette('white'));
export var Fullscreen = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    background: ", ";\n    left: 0;\n    position: absolute;\n    top: 0;\n    z-index: 2000;\n    right: 0;\n    bottom: 0;\n    display: flex;\n"], ["\n    background: ", ";\n    left: 0;\n    position: absolute;\n    top: 0;\n    z-index: 2000;\n    right: 0;\n    bottom: 0;\n    display: flex;\n"])), palette("transparentBlack"));
export var ModalImage = styled.img(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n   max-width: 100%;\n"], ["\n   max-width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;
