var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
// utils
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
import { processActionTypes } from '@shared/utils/constants';
import InfoTextMessages from './InfoTextMessages';
import InfoHeadlinesMessages from './InfoHeadlinesMessages';
import { FormattedMessage } from 'react-intl';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  height: 100%;\n  padding: 2rem;\n  text-align: center;\n  overflow: auto;\n  flex: 1;\n  margin-bottom: 2rem;\n"], ["\n  background-color: ", ";\n  height: 100%;\n  padding: 2rem;\n  text-align: center;\n  overflow: auto;\n  flex: 1;\n  margin-bottom: 2rem;\n"])), palette('white'));
var Headline = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.4px;\n  line-height: 1.25rem;\n  margin: 1rem 0 2rem 0;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.4px;\n  line-height: 1.25rem;\n  margin: 1rem 0 2rem 0;\n"])), palette('secondaryHighlight'), fontSize('large'), fontWeight('bold'));
var InfoText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 2rem;\n"], ["\n  margin-bottom: 2rem;\n"])));
var CheckoutNumber = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 3rem;\n  font-weight: ", ";\n  line-height: 1;\n  margin-bottom: 3rem;\n"], ["\n  color: ", ";\n  font-size: 3rem;\n  font-weight: ", ";\n  line-height: 1;\n  margin-bottom: 3rem;\n"])), palette('secondaryHighlight'), fontWeight('bold'));
export var CheckoutCompleteContent = function (_a) {
    var checkoutNumber = _a.checkoutNumber, hasNoCustomerArtId = _a.hasNoCustomerArtId, orderNumber = _a.orderNumber, type = _a.type;
    var showCheckoutQrContent = (type === processActionTypes.CHECKOUT && !hasNoCustomerArtId);
    var showManufacturerMessage = ((type === processActionTypes.ORDER && !orderNumber && !hasNoCustomerArtId));
    return (_jsxs(Wrapper, { children: [_jsxs(_Fragment, { children: [_jsx(Headline, { children: _jsx(InfoHeadlinesMessages, { orderNumber: orderNumber, type: type }, void 0) }, void 0), _jsx(InfoText, { children: _jsx(InfoTextMessages, { orderNumber: orderNumber, type: type }, void 0) }, void 0)] }, void 0), showManufacturerMessage && _jsx(_Fragment, { children: _jsx(FormattedMessage, { id: 'checkout.complete.noCustomerArtId', defaultMessage: 'Artikel nicht im System, bitte \u00FCber Sammelwarengruppe verkaufen' }, void 0) }, void 0), showCheckoutQrContent && (_jsxs(React.Fragment, { children: [_jsx(CheckoutNumber, { children: checkoutNumber }, void 0), _jsx(QRCode, { "data-cy-id": 'qr-code', value: "*WK" + checkoutNumber, fgColor: '#000', size: 150 }, void 0)] }, void 0))] }, void 0));
};
export default CheckoutCompleteContent;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
