import { createSelector } from 'reselect';
var selectSessionState = function (state) { return state.get('session'); };
var selectSessionId = function (state, props) { return props.match.params.id; };
export var selectLoading = createSelector(selectSessionState, function (sessionState) { return sessionState.get('loading'); });
export var selectArchiving = createSelector(selectSessionState, function (sessionState) { return sessionState.get('archiving'); });
export var selectSharing = createSelector(selectSessionState, function (sessionState) { return sessionState.get('sharing'); });
export var selectLoadingAll = createSelector(selectSessionState, function (sessionState) { return sessionState.get('loadingAll'); });
export var selectLoadingArchive = createSelector(selectSessionState, function (sessionState) { return sessionState.get('loadingArchive'); });
export var selectLoadingAssigned = createSelector(selectSessionState, function (sessionState) { return sessionState.get('loadingAssigned'); });
export var selectLoadingUnassigned = createSelector(selectSessionState, function (sessionState) { return sessionState.get('loadingUnassigned'); });
export var selectLoaded = createSelector(selectSessionState, function (sessionState) {
    return sessionState.get('loaded');
});
export var selectAllPageCount = createSelector(selectSessionState, function (sessionState) { return sessionState.get('allPageCount'); });
export var selectCheckingAssigned = createSelector(selectSessionState, function (sessionState) { return sessionState.get('checkingAssigned'); });
export var selectCheckingUnassigned = createSelector(selectSessionState, function (sessionState) { return sessionState.get('checkingUnassigned'); });
export var selectArchivePageCount = createSelector(selectSessionState, function (sessionState) { return sessionState.get('archivePageCount'); });
export var selectAssignedPageCount = createSelector(selectSessionState, function (sessionState) { return sessionState.get('assignedPageCount'); });
export var selectUnassignedPageCount = createSelector(selectSessionState, function (sessionState) { return sessionState.get('unassignedPageCount'); });
export var selectAllPage = createSelector(selectSessionState, function (sessionState) { return sessionState.get('allPage'); });
export var selectArchivePage = createSelector(selectSessionState, function (sessionState) { return sessionState.get('archivePage'); });
export var selectAssignedPage = createSelector(selectSessionState, function (sessionState) { return sessionState.get('assignedPage'); });
export var selectUnassignedPage = createSelector(selectSessionState, function (sessionState) { return sessionState.get('unassignedPage'); });
export var selectLoadingAllComplete = createSelector(selectSessionState, function (sessionState) {
    return !sessionState.get('loadingAll') && sessionState.get('loadedAll');
});
export var selectLoadingArchiveComplete = createSelector(selectSessionState, function (sessionState) {
    return !sessionState.get('loadingArchive') && sessionState.get('loadedArchive');
});
export var selectLoadingAssignedComplete = createSelector(selectSessionState, function (sessionState) {
    return !sessionState.get('loadingAssigned') && sessionState.get('loadedAssigned');
});
export var selectLoadingUnassignedComplete = createSelector(selectSessionState, function (sessionState) {
    return !sessionState.get('loadingUnassigned') &&
        sessionState.get('loadedUnassigned');
});
export var selectSessions = createSelector(selectSessionState, function (sessionState) { return sessionState.get('data'); });
export var selectCheckingOut = createSelector(selectSessionState, function (sessionState) { return sessionState.get('checkingOut'); });
export var selectCheckoutNumber = createSelector(selectSessionState, function (sessionState) { return sessionState.get('checkoutNumber'); });
export var selectSessionsAll = createSelector(selectSessionState, function (sessionState) {
    return sessionState
        .get('all')
        .map(function (sessionId) {
        return sessionState
            .get('data')
            .find(function (session) { return session.get('uuid') === sessionId; });
    });
});
export var selectSessionsArchive = createSelector(selectSessionState, function (sessionState) {
    return sessionState
        .get('archive')
        .map(function (sessionId) {
        return sessionState
            .get('data')
            .find(function (session) { return session.get('uuid') === sessionId; });
    });
});
export var selectSessionsAssigned = createSelector(selectSessionState, function (sessionState) {
    return sessionState
        .get('assigned')
        .map(function (sessionId) {
        return sessionState
            .get('data')
            .find(function (session) { return session.get('uuid') === sessionId; });
    });
});
export var selectSessionsUnassigned = createSelector(selectSessionState, function (sessionState) {
    return sessionState
        .get('unassigned')
        .map(function (sessionId) {
        return sessionState
            .get('data')
            .find(function (session) { return session.get('uuid') === sessionId; });
    });
});
export var makeSelectSession = function () {
    return createSelector([selectSessions, selectSessionId], function (sessions, sessionId) {
        return sessions.find(function (session) { return session.get('uuid') === sessionId; });
    });
};
