var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BottomMenu, BottomMenuArchive } from './style';
var SessionArchiveBottomButton = function (_a) {
    var archiveSession = _a.archiveSession, archiving = _a.archiving;
    return (_jsx(BottomMenu, { children: _jsx(BottomMenuArchive, __assign({ onClick: archiveSession, "data-cy-id": 'session-archive' }, { children: archiving ? (_jsx(FormattedMessage, { defaultMessage: 'Archivieren...', id: 'checkoutComplete.bottomMenu.archiving' }, void 0)) : (_jsx(FormattedMessage, { defaultMessage: 'Warenkorb abschlie\u00DFen', id: 'checkoutComplete.bottomMenu.archive' }, void 0)) }), void 0) }, void 0));
};
export default SessionArchiveBottomButton;
