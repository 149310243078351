var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
import { stringify } from 'query-string';
import { getImageUrl } from '@shared/utils/images';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 0.625rem;\n  &:first-child > a > div,\n  &:first-child:active > a > div {\n    border-top: none;\n  }\n  &:active > a > div {\n    border-top: 0.0625rem solid transparent;\n  }\n  &:active + div > a > div {\n    border-top: 0.0625rem solid transparent;\n  }\n\n  ", ";\n"], ["\n  padding: 0 0.625rem;\n  &:first-child > a > div,\n  &:first-child:active > a > div {\n    border-top: none;\n  }\n  &:active > a > div {\n    border-top: 0.0625rem solid transparent;\n  }\n  &:active + div > a > div {\n    border-top: 0.0625rem solid transparent;\n  }\n\n  ", ";\n"])), function (props) {
    return props.active &&
        ">  a > div {\n      border-top: 0.0625rem solid transparent;\n    }\n    + div >  a > div {\n      border-top: 0.0625rem solid white;\n    }";
});
var InnerWrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  border-top: 0.0625rem solid ", ";\n  padding: 0.625rem;\n  background-color: ", ";\n  ", " &:active {\n    background-color: ", ";\n  }\n"], ["\n  align-items: center;\n  border-top: 0.0625rem solid ", ";\n  padding: 0.625rem;\n  background-color: ", ";\n  ", " &:active {\n    background-color: ", ";\n  }\n"])), palette('border'), function (props) {
    return props.active ? palette('selectedWhite') : 'transparent';
}, function (props) {
    return props.active &&
        "\n      border-top: 0.0625rem solid transparent;\n      border-radius: 0.4375rem;\n  ";
}, palette('activeWhite'));
var Name = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  flex: 7;\n  font-size: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  flex: 7;\n  font-size: ", ";\n  font-weight: ", ";\n"])), palette('secondaryHighlight'), fontSize('medium'), fontWeight('bold'));
var StyledLink = styled(Link)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-decoration: none;\n"], ["\n  text-decoration: none;\n"])));
var IconWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: center;\n  background-color: #d1dfe2; // Fixed for every theme\n  border-radius: 10%;\n  border: 0.0625rem solid ", ";\n  display: flex;\n  height: 3.75rem;\n  justify-content: center;\n  margin-right: 0.625rem;\n  width: 3.75rem;\n"], ["\n  align-items: center;\n  background-color: #d1dfe2; // Fixed for every theme\n  border-radius: 10%;\n  border: 0.0625rem solid ", ";\n  display: flex;\n  height: 3.75rem;\n  justify-content: center;\n  margin-right: 0.625rem;\n  width: 3.75rem;\n"])), palette('border'));
var Shortcut = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n"])), fontSize('extraLarge'), fontWeight('bold'), palette('white'));
var Image = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  max-width: 100%;\n  width: 100%;\n"], ["\n  max-width: 100%;\n  width: 100%;\n"])));
var DepartmentListItem = function (_a) {
    var params = _a.params, session = _a.session, department = _a.department, active = _a.active;
    var generateLink = function () {
        var basePath = "/sessions/" + session.uuid + "/inspiration";
        var newParams = __assign({}, params);
        newParams.department = department.id;
        return basePath + "?" + stringify(newParams, { arrayFormat: 'bracket' });
    };
    var target = generateLink();
    return (_jsx(Wrapper, __assign({ active: active }, { children: _jsx(StyledLink, __assign({ to: target }, { children: _jsxs(InnerWrapper, __assign({ active: active }, { children: [_jsx(IconWrapper, { children: department.image ? (_jsx(Image, { src: getImageUrl(department.image), alt: department === null || department === void 0 ? void 0 : department.name }, void 0)) : (_jsx(Shortcut, { children: (department === null || department === void 0 ? void 0 : department.name) && department.name.charAt(0) }, void 0)) }, void 0), _jsx(Name, { children: department === null || department === void 0 ? void 0 : department.name }, void 0)] }), void 0) }), void 0) }), void 0));
};
export default DepartmentListItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
