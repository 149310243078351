var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
import styled from 'styled-components';
import { useField } from 'react-final-form';
export var Form = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  font-size: ", ";\n  margin-bottom: 3.75rem;\n  margin-top: 1rem;\n  width: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  font-size: ", ";\n  margin-bottom: 3.75rem;\n  margin-top: 1rem;\n  width: 100%;\n"])), fontSize('large'));
var StyledInput = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-width: 0.0625rem ;\n  border-color: ", ";\n  border-style: solid;\n  height: 2.875rem;\n  padding: 0 0.675rem;\n  ::placeholder {\n    font-weight: ", ";\n  }\n  width: 100%;\n  text-align: center;\n"], ["\n  border-width: 0.0625rem ;\n  border-color: ", ";\n  border-style: solid;\n  height: 2.875rem;\n  padding: 0 0.675rem;\n  ::placeholder {\n    font-weight: ", ";\n  }\n  width: 100%;\n  text-align: center;\n"])), palette('border'), fontWeight('light'));
export function StyledField(props) {
    var input = useField(props.name).input;
    var inputProps = __assign(__assign({}, props), input);
    return _jsx(StyledInput, __assign({}, inputProps), void 0);
}
export var SubmitButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: black;\n  border: 0;\n  color: ", ";\n  height: 2.875rem;\n  padding: 0 1.25rem;\n  margin-top: 0.2rem;\n  width: 100%;\n"], ["\n  background-color: black;\n  border: 0;\n  color: ", ";\n  height: 2.875rem;\n  padding: 0 1.25rem;\n  margin-top: 0.2rem;\n  width: 100%;\n"])), palette('white'));
var templateObject_1, templateObject_2, templateObject_3;
