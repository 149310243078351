var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import CustomerFormEdit from 'molecules/CustomerFormEdit';
import GoBack from 'molecules/GoBack';
import SubMenu from 'organisms/SubMenu';
import SessionLayout from 'templates/SessionLayout';
import { fontWeight, palette } from '@shared/utils/styled';
// translations
import msg from 'pages/CustomerAdd/messages';
var BottomMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 3.25rem;\n  position: relative;\n"], ["\n  height: 3.25rem;\n  position: relative;\n"])));
var BottomMenuConfirm = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  height: 100%;\n  justify-content: center;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n  }\n\n  & > span {\n    opacity: ", ";\n  }\n"], ["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  height: 100%;\n  justify-content: center;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n  }\n\n  & > span {\n    opacity: ", ";\n  }\n"])), palette('primaryHighlight'), palette('white'), fontWeight('bold'), function (props) {
    return props.disabled
        ? props.theme.palette.primaryHighlight
        : props.theme.palette.activePrimaryHighlight;
}, function (props) { return (props.disabled ? '0.3' : '1'); });
var CustomerEdit = function (_a) {
    var updateCustomer = _a.updateCustomer, customer = _a.customer, isSubmitting = _a.isSubmitting, isValid = _a.isValid, session = _a.session, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, terminals = _a.terminals, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist;
    var _b = useState(true), isSubmitDisabled = _b[0], setIsSubmitDisabled = _b[1];
    var intl = useIntl();
    var onUpdateCustomerClick = function () {
        var _a;
        // { cancelable: true } required for Firefox
        // https://github.com/facebook/react/issues/12639#issuecomment-382519193
        (_a = document
            .getElementById('customer-edit')) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    };
    var updateSubmitState = function (value) {
        setIsSubmitDisabled(value);
    };
    var onCustomerFormEditSubmit = function (values) {
        return new Promise(function (resolve, reject) {
            return updateCustomer(values, customer.id, resolve, reject);
        });
    };
    var renderBottomMenu = function () {
        return (_jsx(BottomMenu, { children: _jsx(BottomMenuConfirm, __assign({ disabled: !isValid || isSubmitting || isSubmitDisabled, onClick: onUpdateCustomerClick }, { children: _jsx(FormattedMessage, { defaultMessage: 'OK', id: 'customerAdd.bottomMenu.ok' }, void 0) }), void 0) }, void 0));
    };
    return (_jsx(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, bottomMenu: renderBottomMenu(), currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, subMenu: _jsx(SubMenu, { noBack: true, iconRight: _jsx(GoBack, { icon: 'closex', width: '1.4375rem' }, void 0), title: intl.formatMessage(msg.customer) }, void 0), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist }, { children: _jsx(CustomerFormEdit, { session: session, customer: customer, onSubmit: onCustomerFormEditSubmit, updateSubmitState: updateSubmitState }, void 0) }), void 0));
};
export var CustomerEditComponent = CustomerEdit;
export default withRouter(CustomerEdit);
var templateObject_1, templateObject_2;
