var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { fontSize, fontWeight, palette } from '@shared/utils/styled';
export var Main = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  background-color: white;\n  z-index: 50;\n  bottom:50px;\n  height:fit-content;\n  max-height:500px;\n  width: 100%;\n  border-bottom: 1px solid ", ";\n  border-width:1px 0;\n"], ["\n  position: relative;\n  background-color: white;\n  z-index: 50;\n  bottom:50px;\n  height:fit-content;\n  max-height:500px;\n  width: 100%;\n  border-bottom: 1px solid ", ";\n  border-width:1px 0;\n"])), palette('border'));
export var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  padding:0.625rem;\n  border-bottom:1px solid;\n"], ["\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  padding:0.625rem;\n  border-bottom:1px solid;\n"])));
export var ItemBlock = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color : white;\n  margin:1.2rem 0.4rem;\n  \n"], ["\n  background-color : white;\n  margin:1.2rem 0.4rem;\n  \n"])));
export var ItemButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    text-align: center;\n    border: ", ";\n    border-radius: 5px;\n    height: 41px;\n    min-width: 5rem;\n    background-color: transparent;\n"], ["\n    text-align: center;\n    border: ", ";\n    border-radius: 5px;\n    height: 41px;\n    min-width: 5rem;\n    background-color: transparent;\n"])), palette('border'));
export var FilterButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  border: 0 solid #cad1d8;\n  border-width:0 0 1px 0;\n  margin: 3px 0;\n  color: #aaa;\n  display: flex;\n  flex: 7;\n  font-weight: ", ";\n  color: ", ";\n  justifyContent:center;\n  position: relative\n"], ["\n  width: 100%;\n  border: 0 solid #cad1d8;\n  border-width:0 0 1px 0;\n  margin: 3px 0;\n  color: #aaa;\n  display: flex;\n  flex: 7;\n  font-weight: ", ";\n  color: ", ";\n  justifyContent:center;\n  position: relative\n"])), fontWeight('bold'), palette('black'));
export var FilterConfirm = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.025rem;\n  justify-content: center;\n  overflow: hidden;\n  height: 3.125rem;\n  width: 75%;\n  margin: auto;\n  border-radius: 8px;\n  border: 0;\n  display: flex;\n  &:active {\n    outline: 0;\n  }\n  padding: 0 1.875rem;\n  margin: 0.3rem 0;\n  transition: background-color 0.2s ease;\n  \n"], ["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.025rem;\n  justify-content: center;\n  overflow: hidden;\n  height: 3.125rem;\n  width: 75%;\n  margin: auto;\n  border-radius: 8px;\n  border: 0;\n  display: flex;\n  &:active {\n    outline: 0;\n  }\n  padding: 0 1.875rem;\n  margin: 0.3rem 0;\n  transition: background-color 0.2s ease;\n  \n"])), palette('black'), palette('white'), fontSize('large'), fontWeight('bold'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
