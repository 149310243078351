var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
// utils
import { palette } from '@shared/utils/styled';
import { splitDate } from '@shared/utils/times';
// components
import CustomerFormFields from 'molecules/CustomerFormFields';
import VerticalFlex from 'atoms/VerticalFlex';
// translations
import msg from 'molecules/CustomerFormSearch/messages';
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background-color: white;\n  height: 100%;\n  overflow-y: auto;\n  flex: 1;\n  padding: 1.25rem;\n  width: 100%;\n"], ["\n  align-items: center;\n  background-color: white;\n  height: 100%;\n  overflow-y: auto;\n  flex: 1;\n  padding: 1.25rem;\n  width: 100%;\n"])));
var Hint = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  max-width: 17.5rem;\n"], ["\n  color: ", ";\n  max-width: 17.5rem;\n"])), palette('placeholderText'));
var CustomerFormEdit = function (_a) {
    var customer = _a.customer, session = _a.session, onSubmit = _a.onSubmit, updateSubmitState = _a.updateSubmitState;
    var birthday = customer.birthday;
    return (_jsx(Wrapper, { children: _jsx(FinalForm, { onSubmit: function (values) { return onSubmit(values); }, initialValues: __assign(__assign(__assign({}, customer), splitDate(birthday)), { birthday: undefined }), render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (_jsxs("form", __assign({ onSubmit: handleSubmit, id: 'customer-edit' }, { children: [_jsx(CustomerFormFields, { action: 'edit', session: session }, void 0), _jsx(Hint, { children: _jsx(FormattedMessage, __assign({}, msg.hint), void 0) }, void 0), _jsx(FormSpy, { subscription: {
                                valid: true,
                                submitting: true,
                                pristine: true,
                            }, onChange: function (_a) {
                                var valid = _a.valid, submitting = _a.submitting, pristine = _a.pristine;
                                if (valid) {
                                    updateSubmitState(false);
                                }
                                if (submitting || pristine) {
                                    updateSubmitState(true);
                                }
                            } }, void 0)] }), void 0));
            } }, void 0) }, void 0));
};
export default CustomerFormEdit;
var templateObject_1, templateObject_2;
