var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import localStore from 'store';
import store from 'store';
import errorHandler from 'services/connection';
import { getFurnitures } from '@store/furniture/actions';
import { selectWatchlist, selectWatchlistStatus } from './selectors';
import { createWatchlistItem as createWatchlistItemAction, createWatchlistItemComplete, deleteWatchlist as deleteWatchlistAction, deleteWatchlistComplete, deleteWatchlistItem as deleteWatchlistItemAction, deleteWatchlistItemComplete, getWatchlist as getWatchlistAction, getWatchlistComplete, pollWatchlist as pollWatchlistStateAction, pollWatchlistcomplete as pollWatchlistStateComplete, requestWatchlist as requestWatchlistAction, requestWatchlistComplete, setWatchlistStatus, updateWatchlistItem as updateWatchlistItemAction, updateWatchlistItemComplete, } from './actions';
var currency = store.get('currency') || 'EUR';
export function createWatchlistItem(api, action) {
    var payload, meta, body, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                payload = action.payload, meta = action.meta;
                return [4 /*yield*/, call([api, api.post], "sessions/" + meta.sessionId + "/watchlist", __assign(__assign({}, payload), { usage: 'remember', type: payload.status, requestState: payload.status }), { params: { currency: currency } })];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(createWatchlistItemComplete(fromJS(body)))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_1 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_1, createWatchlistItemComplete, createWatchlistItemAction, action.payload))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function deleteWatchlistItem(api, action) {
    var payload, meta, existing, index, body, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                payload = action.payload, meta = action.meta;
                return [4 /*yield*/, select(selectWatchlist)];
            case 1:
                existing = _a.sent();
                index = existing.findIndex(function (exitem) { return exitem.get('id') === action.payload; });
                return [4 /*yield*/, call([api, api.delete], "sessions/" + meta.sessionId + "/watchlist/" + payload)];
            case 2:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(deleteWatchlistItemComplete(fromJS(body)))];
            case 3:
                _a.sent();
                if (!((existing.count() === 1 && index >= 0) || 0 === existing.count)) return [3 /*break*/, 5];
                localStore.set('watchlistStatus', null);
                return [4 /*yield*/, put(setWatchlistStatus(null))];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_2 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_2, deleteWatchlistItemComplete, deleteWatchlistItemAction, action.payload))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function getWatchlist(api, action) {
    var payload, body, size, sizeId_1, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                payload = action.payload;
                return [4 /*yield*/, call([api, api.get], "sessions/" + payload + "/watchlist", {
                        params: { currency: currency },
                    })];
            case 1:
                body = (_a.sent()).body;
                size = null;
                sizeId_1 = body && body[0] && body[0].sizeId;
                if (sizeId_1) {
                    size = body[0].article.sizes.find(function (si) { return si.sizeId === sizeId_1; });
                }
                if (size) {
                    localStore.set('watchlistStatus', size.stock);
                }
                return [4 /*yield*/, put(getWatchlistComplete(fromJS(body)))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_3 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_3, getWatchlistComplete, getWatchlistAction, action.payload))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function deleteWatchlist(api, action) {
    var payload, body, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                payload = action.payload;
                return [4 /*yield*/, call([api, api.delete], "sessions/" + payload + "/watchlist")];
            case 1:
                body = (_a.sent()).body;
                localStore.set('watchlistStatus', null);
                return [4 /*yield*/, put(deleteWatchlistComplete(fromJS(body)))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_4 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_4, deleteWatchlistComplete, deleteWatchlistAction, action.payload))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
/**
 * Requests the selected items in watchlist to see if they are in stock.
 *
 * @param api - Api end point.
 * @param action - Appropriate action.
 * @returns {IterableIterator<*>} IterableIterator.
 */
export function requestWatchlist(api, action) {
    var payload, body, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                payload = action.payload;
                return [4 /*yield*/, call([api, api.post], "sessions/" + payload + "/watchlist/request")];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(requestWatchlistComplete(fromJS(body)))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_5 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_5, requestWatchlistComplete, requestWatchlistAction, action.payload))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
/**
 * Fetches the current state for the watchlistItem. This function is more lightweight than the normal GET.
 * !requestId = noch nicht abgerufen, Abruf möglich.
 * Wenn requestId:
 * requestStatus = "ok" - der Schuh kommt
 * requestStatus = "no" - der Schuh ist nicht da
 * sonst noch keine Antwort und Polling kann weiter laufen.
 *
 * @param api - Api end point.
 * @param action - Appropriate action.
 * @returns {IterableIterator<*>} IterableIterator.
 */
export function pollWatchlistState(api, action) {
    var payload, body, mustRefresh, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                payload = action.payload;
                return [4 /*yield*/, call([api, api.get], "sessions/" + payload + "/watchlist/request", {
                        params: { currency: currency },
                    })];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(pollWatchlistStateComplete(fromJS(body)))];
            case 2:
                _a.sent();
                mustRefresh = false;
                if (!mustRefresh) return [3 /*break*/, 4];
                return [4 /*yield*/, delay(pollWatchlistState(payload), 10000)];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                error_6 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_6, pollWatchlistStateComplete, pollWatchlistStateAction, action.payload))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function updateWatchlistItem(api, action) {
    var payload, meta, existing, index, updatedItemSizeId_1, updatedItemSize, updatedItemStatus, existWatchListStatus, body, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload, meta = action.meta;
                return [4 /*yield*/, select(selectWatchlist)];
            case 1:
                existing = _a.sent();
                index = existing.findIndex(function (exitem) { return exitem.get('id') === action.meta.id; });
                if (!(index > 0 && existing.count() > 1)) return [3 /*break*/, 3];
                updatedItemSizeId_1 = action.payload.sizeId;
                updatedItemSize = existing
                    .get(index)
                    .get('article')
                    .get('sizes')
                    .find(function (size) {
                    return size.get('sizeId') === updatedItemSizeId_1;
                });
                updatedItemStatus = updatedItemSize
                    ? updatedItemSize.get('stock')
                    : null;
                return [4 /*yield*/, select(selectWatchlistStatus)];
            case 2:
                existWatchListStatus = _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 7, , 9]);
                return [4 /*yield*/, call([api, api.put], "sessions/" + meta.sessionId + "/watchlist/" + meta.id, payload, { params: { currency: currency } })];
            case 4:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(updateWatchlistItemComplete(fromJS(body)))];
            case 5:
                _a.sent();
                return [4 /*yield*/, put(getFurnitures(meta.sessionId))];
            case 6:
                _a.sent();
                return [3 /*break*/, 9];
            case 7:
                error_7 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_7, updateWatchlistItemComplete, updateWatchlistItemAction, payload, meta.id, meta.sessionId))];
            case 8:
                _a.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function watchlistItemCreateWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('CREATE_WATCHLIST_ITEM', createWatchlistItem, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function watchlistItemDeleteWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('DELETE_WATCHLIST_ITEM', deleteWatchlistItem, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function watchlistGetWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('GET_WATCHLIST', getWatchlist, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function watchlistDeleteWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('DELETE_WATCHLIST', deleteWatchlist, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function watchlistUpdateWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('UPDATE_WATCHLIST_ITEM', updateWatchlistItem, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function requestWatchlistWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('REQUEST_WATCHLIST', requestWatchlist, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
