var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { fontWeight, palette } from "@shared/utils/styled";
import VerticalFlex from "components/atoms/VerticalFlex";
export var StyledActions = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  justify-content: flex-start;\n  align-items: center;\n  height: ", ";\n  transition: height 1s ease-in-out;\n"], ["\n  justify-content: flex-start;\n  align-items: center;\n  height: ", ";\n  transition: height 1s ease-in-out;\n"])), function (props) { return (props.visible ? "100%" : 0); });
export var StyledButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-shadow: -3px 4px 5px 2px rgba(0, 0, 0, 0.1),\n    0px 1px 3px rgba(0, 0, 0, 0.1);\n  align-items: center;\n  background-color: black;\n  color: ", ";\n  height: 3.125rem;\n  justify-content: center;\n  width: 75%;\n  margin: 0.5rem;\n  border-radius: 8px;\n  border: 0;\n  display: flex;\n  &:active {\n    outline: 0;\n  }\n"], ["\n  box-shadow: -3px 4px 5px 2px rgba(0, 0, 0, 0.1),\n    0px 1px 3px rgba(0, 0, 0, 0.1);\n  align-items: center;\n  background-color: black;\n  color: ", ";\n  height: 3.125rem;\n  justify-content: center;\n  width: 75%;\n  margin: 0.5rem;\n  border-radius: 8px;\n  border: 0;\n  display: flex;\n  &:active {\n    outline: 0;\n  }\n"])), palette("white"));
export var StyledButtonContent = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  font-weight: ", ";\n  justify-content: center;\n  opacity: ", ";\n"], ["\n  align-items: center;\n  display: flex;\n  font-weight: ", ";\n  justify-content: center;\n  opacity: ", ";\n"])), fontWeight("bold"), function (props) { return (props.disabled ? "0.7" : "1"); });
export var StyledWatchlistArea = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  height: calc(100% - 4.375rem);\n  left: 0;\n  overflow: hidden;\n  position: fixed;\n  top: 4.375rem;\n  transform-origin: 0 100%;\n  transform: ", ";\n  transition: transform 0.5s;\n  width: 100%;\n  z-index: 1;\n  display: flex;\n  flex-direction: column;\n"], ["\n  background-color: ", ";\n  height: calc(100% - 4.375rem);\n  left: 0;\n  overflow: hidden;\n  position: fixed;\n  top: 4.375rem;\n  transform-origin: 0 100%;\n  transform: ", ";\n  transition: transform 0.5s;\n  width: 100%;\n  z-index: 1;\n  display: flex;\n  flex-direction: column;\n"])), palette("white"), function (props) { return (props.visible ? "scaleY(1)" : "scaleY(0)"); });
export var DeleteIcon = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  opacity: ", ";\n  margin: 0.5rem;\n  position: absolute;\n  right: 0;\n  top: 3px;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  opacity: ", ";\n  margin: 0.5rem;\n  position: absolute;\n  right: 0;\n  top: 3px;\n  &:active {\n    background-color: ", ";\n  }\n"])), function (props) { return (props.deleting ? 0.5 : 1); }, palette("activeWhite"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
