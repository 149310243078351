var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toJS } from '@shared/utils/immutable';
import Page from 'pages/WatchlistSharePage';
// not mocking
import { selectAuthErrors } from '@store/authorize/selectors';
import SessionContainer from 'containers/SessionContainer';
import { selectArchiving as sessionSelectArchiving, selectSharing as sessionSelectSharing, } from '@store/session/selectors';
var WatchlistShare = /** @class */ (function (_super) {
    __extends(WatchlistShare, _super);
    function WatchlistShare() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WatchlistShare.prototype.render = function () {
        var _a = this.props, archiving = _a.archiving, sharing = _a.sharing;
        return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { archiving: archiving, sharing: sharing }), void 0)); } }, void 0));
    };
    return WatchlistShare;
}(Component));
var select = function () { return function (state) { return ({
    errors: selectAuthErrors(state),
    archiving: sessionSelectArchiving(state),
    sharing: sessionSelectSharing(state),
}); }; };
export default withRouter(connect(select, null)(toJS(WatchlistShare)));
