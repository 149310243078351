import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
var ValidateSessionWrapper = function (_a) {
    var socket = _a.socket, userId = _a.userId, logout = _a.logout, showAutoLogOffInformation = _a.showAutoLogOffInformation;
    useEffect(function () {
        socket.on('logout-user', function (data) {
            if ((data === null || data === void 0 ? void 0 : data.userId) !== userId) {
                return;
            }
            console.debug('logout-user', userId);
            logout({ assistantId: userId });
            showAutoLogOffInformation();
        });
        return function () {
            socket.off('logout-user');
        };
    });
    useEffect(function () {
        if (!userId) {
            return;
        }
        emitUserLogin();
    }, [userId]);
    var emitUserLogin = function () {
        console.debug("user-login [" + userId + "]");
        socket.emit('user-login', { userId: userId });
    };
    return (_jsx(_Fragment, {}, void 0));
};
export default ValidateSessionWrapper;
