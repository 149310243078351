var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import Image from '@shared/atoms/Image';
var LogoImage = styled(Image)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n"], ["\n  height: 100%;\n"])));
var Logo = function (props) { return _jsx(LogoImage, { src: props.logo, alt: 'Logo' }, void 0); };
/** @component */
export default Logo;
var templateObject_1;
