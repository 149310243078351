var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
// utils
import { palette, fontWeight } from '@shared/utils/styled';
// components
import Icon from '@shared/atoms/Icon';
import VerticalFlex from 'atoms/VerticalFlex';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import CenterText from '@shared/atoms/CenterText';
// translations
import msg from './messages';
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  padding: 1.25rem;\n  flex: 1;\n  overflow: auto;\n"], ["\n  background: ", ";\n  padding: 1.25rem;\n  flex: 1;\n  overflow: auto;\n"])), palette('white'));
var IconWrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  justify-content: center;\n  margin-bottom: 0.5rem;\n"], ["\n  justify-content: center;\n  margin-bottom: 0.5rem;\n"])));
var TextCenter = styled(CenterText)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: ", ";\n  margin-bottom: 1.25rem;\n  padding: 0 2.5rem;\n"], ["\n  font-weight: ", ";\n  margin-bottom: 1.25rem;\n  padding: 0 2.5rem;\n"])), fontWeight('bold'));
var TextGrid = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  grid-template-rows: 1fr;\n  grid-row-gap: 1.25rem;\n  ", ";\n"], ["\n  display: grid;\n  grid-template-rows: 1fr;\n  grid-row-gap: 1.25rem;\n  ", ";\n"])), function (props) {
    return props.type === 'customerExists' &&
        "background-color: " + props.theme.palette.selectedWhite + ";\n    border-radius: 0.25rem; padding: 1rem;";
});
var TextGridRow = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-column-gap: 1.25rem;\n  grid-template-columns: 2fr 3fr;\n"], ["\n  display: grid;\n  grid-column-gap: 1.25rem;\n  grid-template-columns: 2fr 3fr;\n"])));
var CustomerExistsTitle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
var CustomerInformation = function (_a) {
    var customer = _a.customer, type = _a.type;
    return (_jsxs(Wrapper, { children: [_jsxs(IconWrapper, { children: [type === 'customerExists' && (_jsx(Icon, { icon: 'client-exists', height: 120 }, void 0)), type === 'finish' && _jsx(Icon, { icon: 'client-info', height: 120 }, void 0), type !== 'finish' && type !== 'customerExists' && (_jsx(Icon, { icon: 'client-search', height: 120 }, void 0))] }, void 0), type !== 'customerExists' && (_jsxs(TextCenter, __assign({ size: 'large' }, { children: [customer.gender, " ", customer.firstName, " ", customer.lastName] }), void 0)), type === 'customerExists' && (_jsx(TextCenter, __assign({ size: 'large' }, { children: _jsx(FormattedMessage, __assign({}, msg.customerExists), void 0) }), void 0)), _jsxs(TextGrid, __assign({ type: type }, { children: [type === 'customerExists' && (_jsxs(CustomerExistsTitle, { children: [customer.gender, " ", customer.firstName, " ", customer.lastName] }, void 0)), _jsx(TextGridRow, { children: _jsxs("div", { children: [_jsxs("div", { children: [customer.street, " ", customer.streetNo] }, void 0), _jsxs("div", { children: [customer.zip, " ", customer.city] }, void 0)] }, void 0) }, void 0)] }), void 0)] }, void 0));
};
export default CustomerInformation;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
