import { createSelector } from 'reselect';
export var selectStoreState = function (state) { return state.get('store'); };
export var selectStores = createSelector(selectStoreState, function (state) {
    return state.get('data');
});
export var selectSortedStores = createSelector(selectStores, function (store) {
    return store.sortBy(function (st) { return st.get('sort'); });
});
export var selectShouldLoadAll = createSelector(selectStoreState, function (state) { return !state.get('loading') && !state.get('loaded'); });
export var selectCurrentStoreId = createSelector(selectStores, function (state) {
    return state.get(0) ? state.get(0).get('id') : null;
});
