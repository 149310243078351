var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import { palette } from '@shared/utils/styled';
import FilterOptionGridItem from 'molecules/FilterOptionGridItem';
import Spinner from '@shared/atoms/Spinner';
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  display: grid;\n  grid-areas: 'footer footer footer footer';\n  grid-gap: 0.8%;\n  grid-template-columns: 24.4% 24.4% 24.4% 24.4%;\n  overflow-y: scroll;\n  padding: 0.625rem;\n  flex: 1;\n"], ["\n  background-color: ", ";\n  display: grid;\n  grid-areas: 'footer footer footer footer';\n  grid-gap: 0.8%;\n  grid-template-columns: 24.4% 24.4% 24.4% 24.4%;\n  overflow-y: scroll;\n  padding: 0.625rem;\n  flex: 1;\n"])), palette('white'));
var FilterOptionGrid = function (_a) {
    var deselectFilterItem = _a.deselectFilterItem, filterId = _a.filterId, filterItems = _a.filterItems, filterLoading = _a.filterLoading, isOptionActive = _a.isOptionActive, selectFilterItem = _a.selectFilterItem;
    if (filterLoading) {
        return _jsx(Spinner, {}, void 0);
    }
    return (_jsx(Wrapper, { children: Boolean(filterItems) &&
            filterItems.map(function (filterOption) {
                var active = isOptionActive(filterOption.id);
                var actionData = {
                    filterId: filterId,
                    filterOptionId: filterOption.id,
                    filterOptionName: filterOption.displayName,
                };
                return (_jsx(FilterOptionGridItem, { active: active, handleClick: function () {
                        return active
                            ? deselectFilterItem(actionData)
                            : selectFilterItem(actionData);
                    }, filterOption: filterOption }, filterOption.id));
            }) }, void 0));
};
export default FilterOptionGrid;
var templateObject_1;
