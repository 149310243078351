var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { palette, fontWeight } from '@shared/utils/styled';
var Entry = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 0.0625rem solid ", ";\n  cursor: pointer;\n  text-decoration: none;\n  padding: 0.6rem 2rem;\n  font-weight: ", ";\n  align-items: center;\n  color: ", ";\n  display: flex;\n  padding: 0.5rem 2rem;\n  background-color: ", ";\n  ", "\n"], ["\n  border-bottom: 0.0625rem solid ", ";\n  cursor: pointer;\n  text-decoration: none;\n  padding: 0.6rem 2rem;\n  font-weight: ", ";\n  align-items: center;\n  color: ", ";\n  display: flex;\n  padding: 0.5rem 2rem;\n  background-color: ", ";\n  ", "\n"])), palette('border'), fontWeight('bold'), palette('black'), function (props) {
    return props.active ? palette('selectedWhite') : 'transparent';
}, function (props) {
    return props.active &&
        "\n      border-bottom: 0.0625rem solid " + palette('white') + ";\n  ";
});
var SortListItem = function (_a) {
    var sort = _a.sort;
    return (_jsx(Entry, __assign({ to: "" + sort.target, active: sort.active }, { children: sort.title }), void 0));
};
export default SortListItem;
var templateObject_1;
