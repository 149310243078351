var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { palette } from "@shared/utils/styled";
var ShadowBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);\n"], ["\n  background-color: ", ";\n  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);\n"])), palette("white"));
export var CurvedShadowBox = styled(ShadowBox)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 10px;\n"], ["\n  border-radius: 10px;\n"])));
/** @component */
export default ShadowBox;
var templateObject_1, templateObject_2;
