var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useEffect, useRef } from "react";
import { useLocation, withRouter } from "react-router-dom";
import CategoryGridItem from "molecules/CategoryGridItem";
import InfiniteScroll from "@shared/organisms/InfiniteScroll";
import localStorage from "store";
import { getUsSizeForGender } from "@shared/utils/matchAndFit";
import queryString from "query-string";
import styled from "styled-components";
var CategoryItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-top: 1rem;\n"], ["\n    margin-top: 1rem;\n"])));
var CategoriesWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 2rem;\n    padding: 1rem;\n"], ["\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 2rem;\n    padding: 1rem;\n"])));
var CategoryGrid = function (_a) {
    var categories = _a.categories, getCategories = _a.getCategories, currentPage = _a.currentPage, primaryCategoryId = _a.primaryCategoryId, basePath = _a.basePath, loading = _a.loading, pageCount = _a.pageCount, ScrollingState = _a.ScrollingState;
    var search = useLocation().search;
    var queryParams = queryString.parse(search);
    var wrapperRef = useRef(null);
    useEffect(function () {
        var handleScroll = function () {
            var _a;
            var scrolled = ((_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.scrollTop) > 0;
            ScrollingState(scrolled);
        };
        var currentWrapper = wrapperRef.current;
        if (currentWrapper) {
            currentWrapper.addEventListener("scroll", handleScroll);
        }
        return function () {
            if (currentWrapper) {
                currentWrapper.removeEventListener("scroll", handleScroll);
            }
        };
    }, [ScrollingState]);
    var fetch = function () {
        getCategories(__assign(__assign({ page: currentPage + 1, primaryCategory: primaryCategoryId }, buildFilterForMatchAndFit(primaryCategoryId)), queryParams));
    };
    useEffect(function () {
        if (!loading) {
            fetch();
        }
    }, [primaryCategoryId]);
    var buildFilterForMatchAndFit = function (primaryCategoryId) {
        var sizeEu = localStorage.get("sizeEu");
        var sizeUk = localStorage.get("sizeUk");
        var sizeUs = getUsSizeForGender(primaryCategoryId);
        var filter = { sizeEu: sizeEu, sizeUk: sizeUk, sizeUs: sizeUs };
        Object.keys(filter).forEach(function (key) { return (filter[key] === undefined ? delete filter[key] : {}); });
        return filter;
    };
    var renderItems = function (categories, basePath, primaryCategoryId) {
        return categories.map(function (category) { return (_jsx(CategoryGridItem, { basePath: basePath, primaryCategoryId: primaryCategoryId, category: category }, category.id)); });
    };
    var items = renderItems(categories, basePath, primaryCategoryId);
    return (_jsx(CategoryItem, { children: _jsx(InfiniteScroll, { currentPage: currentPage, customThreshold: 1, hasMore: currentPage < pageCount, items: items, loading: loading, loadMore: fetch, waypointAsElement: true, wrapper: CategoriesWrapper }, void 0) }, void 0));
};
export default withRouter(CategoryGrid);
var templateObject_1, templateObject_2;
