"use strict";
// @flow
var merge = require('lodash/merge');
var config = {
    all: {
        env: process.env.NODE_ENV || 'development',
        isDev: process.env.NODE_ENV === 'development',
        basename: process.env.PUBLIC_PATH,
        isBrowser: typeof window !== 'undefined',
        apiUrl: 'https://content-online.net/needle_test/api/',
        filterEnd: 'filters',
    },
    test: {},
    development: {},
    stage: {},
    debugbackend: { apiUrl: 'http://localhost:9033/needle/api/' },
    production: {
        apiUrl: 'https://content-online.net/needle_test/api/',
    },
};
module.exports = merge(config.all, config[config.all.env]);
