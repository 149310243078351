var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useCallback, useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import { fontSize, palette } from '@shared/utils/styled';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { getAuthHeader, getUUID } from '@shared/utils/auth';
import localStore from 'store';
var ProfileLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    text-decoration: none;\n    width: fit-content;\n    color: ", ";\n    font-size: ", ";\n    font-weight: 500;\n"], ["\n    text-decoration: none;\n    width: fit-content;\n    color: ", ";\n    font-size: ", ";\n    font-weight: 500;\n"])), palette('black'), fontSize("mediume"));
var ProfileIcon = function () {
    var _a = useState(null), currentAssistant = _a[0], setCurrentAssistant = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var fetchAssistant = useCallback(function () {
        if (loading || !isNil(currentAssistant)) {
            return;
        }
        var deviceId = getUUID(window.location.search) || localStore.get('deviceId');
        var currentUserId = localStore.get('currentUserId');
        var apiUrl = process.env.REACT_APP_API_URL;
        var requestUrl = apiUrl + "assistants/" + currentUserId;
        var headers = {
            'Authorization': getAuthHeader(deviceId),
        };
        // setLoading(true);
        axios
            .get(requestUrl, { headers: headers })
            .then(function (response) {
            setCurrentAssistant(response.data);
            return response;
        })
            .finally(function () {
            setLoading(false);
        })
            .catch(function (e) {
            console.error("cannot get assistant " + deviceId);
            throw e;
        });
    }, [currentAssistant, loading]);
    useEffect(function () {
        fetchAssistant();
    }, [fetchAssistant]);
    // if (loading) {
    //   return <Spinner />;
    // }
    return (_jsx(ProfileLink, __assign({ to: '/profile' }, { children: "Profil" }), void 0));
};
export default ProfileIcon;
var templateObject_1;
