var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import { palette } from '@shared/utils/styled';
var Wrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  border-radius: 10%;\n  color: ", ";\n  margin-bottom: 3%;\n  min-height: 3.5rem;\n  padding: 22%;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  border-radius: 10%;\n  color: ", ";\n  margin-bottom: 3%;\n  min-height: 3.5rem;\n  padding: 22%;\n"])), function (props) {
    return props.active ? palette('selectedWhite') : 'transparent';
}, palette('secondaryHighlight'));
var ThumbnailImage = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 0.06275rem solid\n    ", ";\n  width: 100%;\n"], ["\n  background-color: ", ";\n  border: 0.06275rem solid\n    ", ";\n  width: 100%;\n"])), palette('white'), function (props) { return (props.active ? palette('white') : palette('border')); });
var FilterOptionGridItem = function (_a) {
    var filterOption = _a.filterOption, handleClick = _a.handleClick, active = _a.active;
    return (_jsx(Wrapper, __assign({ onClick: handleClick, active: active }, { children: _jsx(ThumbnailImage, { active: active, src: filterOption.image && filterOption.image.medium
                ? filterOption.image.medium
                : undefined }, void 0) }), void 0));
};
export default FilterOptionGridItem;
var templateObject_1, templateObject_2;
