var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { makeSelectSession, selectLoaded as sessionSelectLoaded, selectLoading as sessionSelectLoading, } from '@store/session/selectors';
import { selectWatchlist } from '@store/watchlist/selectors';
import { selectAssistants, selectLoadingAllComplete as selectLoadingAllAssistantsComplete, } from '@store/assistant/selectors';
import { selectDevices, selectLoadingAllComplete as selectLoadingAllDevicesComplete, } from '@store/device/selectors';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { archiveSession, assignSession, assignTerminal, deleteSession, getSession, shareSession, unleashAssistant, unleashDevice, } from '@store/session/actions';
import { getAssistants } from '@store/assistant/actions';
import { deleteWatchlist, deleteWatchlistItem, getWatchlist, requestWatchlist, } from '@store/watchlist/actions';
import { resetOrderNumber } from '@store/action/actions';
import { getDevices } from '@store/device/actions';
import { toJS } from '@shared/utils/immutable';
import isEmpty from 'lodash/isEmpty';
import { selectCurrentUserId } from '@store/authorize/selectors';
var SessionContainer = /** @class */ (function (_super) {
    __extends(SessionContainer, _super);
    function SessionContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onAssignSession = function (session, assistantId, type, redirectTarget) {
            _this.props.assignSession({ assistantId: assistantId }, session.uuid, type, redirectTarget);
        };
        _this.onUnleashAssistant = function (session) {
            // TODO: Check if session has another assistant
            if (!Boolean(session.deviceId)) {
                // eslint-disable-next-line no-alert
                alert('Der Kundenvorgang muss zunächst einem Terminal oder einem anderen Mitarbeiter zugewiesen werden!');
                return;
            }
            _this.props.unleashAssistant(session.uuid);
        };
        _this.onUnleashTerminal = function (session) {
            _this.props.unleashDevice(session.uuid);
        };
        _this.onAssignTerminal = function (session, terminalId) {
            _this.props.assignTerminal({ deviceId: terminalId }, session.uuid);
        };
        _this.onRequestWatchlist = function (uuid) {
            _this.props.requestWatchlist(uuid);
        };
        return _this;
    }
    SessionContainer.prototype.componentDidMount = function () {
        var _a = this.props, assistantsLoaded = _a.assistantsLoaded, terminalsLoaded = _a.terminalsLoaded, loaded = _a.loaded, loading = _a.loading, match = _a.match;
        if (!loading && !loaded) {
            this.props.getSession({
                assistantId: this.props.currentAssistantId,
                sessionId: match.params.id,
            });
        }
        if (!assistantsLoaded) {
            this.props.getAssistants();
        }
        if (!terminalsLoaded) {
            this.props.getDevices();
        }
    };
    SessionContainer.prototype.render = function () {
        var _this = this;
        var _a = this.props, assistants = _a.assistants, children = _a.children, currentAssistantId = _a.currentAssistantId, terminals = _a.terminals, loaded = _a.loaded, loading = _a.loading, session = _a.session, watchlist = _a.watchlist;
        if ((loading || !loaded) && isEmpty(session)) {
            return _jsx("div", {}, void 0);
        }
        if (Boolean(session) && session) {
            return children({
                assignSession: this.onAssignSession,
                assignTerminal: this.onAssignTerminal,
                assistants: assistants,
                currentAssistantId: currentAssistantId,
                deleteSession: function () { return _this.props.deleteSession(session.uuid); },
                archiveSession: function () { return _this.props.archiveSession(session.uuid); },
                resetOrderNumber: function () { return _this.props.resetOrderNumber(); },
                shareSession: function (payload, resolve, reject) {
                    _this.props.shareSession(payload, session.uuid, resolve, reject);
                },
                session: session,
                terminals: terminals,
                unleashAssistant: this.onUnleashAssistant,
                unleashTerminal: this.onUnleashTerminal,
                watchlist: watchlist,
                requestWatchlist: this.onRequestWatchlist,
                deleteWatchlist: function (sessionId) {
                    return _this.props.deleteWatchlist(sessionId);
                },
                deleteWatchlistItem: function (articleId) {
                    _this.props.deleteWatchlistItem(articleId, session.uuid);
                },
            });
        }
        return _jsx(Redirect, { to: '/' }, void 0);
    };
    return SessionContainer;
}(Component));
var select = function () { return function (state, props) { return ({
    assistants: selectAssistants(state),
    assistantsLoaded: selectLoadingAllAssistantsComplete(state),
    currentAssistantId: selectCurrentUserId(state),
    terminals: selectDevices(state),
    terminalsLoaded: selectLoadingAllDevicesComplete(state),
    loaded: sessionSelectLoaded(state),
    loading: sessionSelectLoading(state),
    session: makeSelectSession()(state, props),
    watchlist: selectWatchlist(state),
    requestWatchlist: requestWatchlist(state),
}); }; };
export var Container = SessionContainer;
export default withRouter(connect(select, {
    assignSession: assignSession,
    assignTerminal: assignTerminal,
    deleteSession: deleteSession,
    deleteWatchlist: deleteWatchlist,
    deleteWatchlistItem: deleteWatchlistItem,
    getAssistants: getAssistants,
    getDevices: getDevices,
    getSession: getSession,
    getWatchlist: getWatchlist,
    unleashAssistant: unleashAssistant,
    unleashDevice: unleashDevice,
    requestWatchlist: requestWatchlist,
    archiveSession: archiveSession,
    shareSession: shareSession,
    resetOrderNumber: resetOrderNumber,
})(toJS(SessionContainer)));
