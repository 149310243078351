var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import { useField, Field } from 'react-final-form';
// utils
import { containsNoInteger, email, required, phone, composeValidators, } from '@shared/utils/validation';
// components
import Input, { StyledLabel, StyledSelect } from '@shared/atoms/Input';
// translations
import msg from 'molecules/CustomerFormSearch/messages';
import { countries } from '@store/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeliveryOptions } from '@store/session/types';
var InputWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n  width: 100%;\n"], ["\n  margin-bottom: 1rem;\n  width: 100%;\n"])));
export function StyledField(props) {
    var _a = useField(props.name, __assign({}, props)), input = _a.input, meta = _a.meta;
    var inputProps = __assign(__assign({}, props), { meta: meta });
    return _jsx(Input, __assign({}, inputProps, { input: input }), void 0);
}
var InputWrapperSmall = styled(InputWrapper)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var GenderWrapper = styled(InputWrapper)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n"], ["\n  display: flex;\n  width: 100%;\n"])));
var GenderFieldWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1 0 auto;\n  margin-right: 1rem;\n"], ["\n  flex: 1 0 auto;\n  margin-right: 1rem;\n"])));
var CustomerFormFields = function (_a) {
    var action = _a.action, session = _a.session;
    var isDelivery = session.deliveryOption === DeliveryOptions.dropshipping;
    var intl = useIntl();
    var deliveryBasedValidator = isDelivery
        ? [containsNoInteger, required]
        : [containsNoInteger];
    return (_jsxs("div", { children: [_jsx(InputWrapper, { children: _jsxs(GenderWrapper, { children: [_jsx(GenderFieldWrapper, { children: _jsx(StyledField, { component: 'input', name: 'gender', type: 'radio', placeholder: msg.placeholderDefault, label: msg.labelFemale, value: 'Frau' }, void 0) }, void 0), _jsx(GenderFieldWrapper, { children: _jsx(StyledField, { component: 'input', name: 'gender', type: 'radio', placeholder: msg.placeholderDefault, label: msg.labelMale, value: 'Herr' }, void 0) }, void 0)] }, void 0) }, void 0), _jsx(InputWrapper, { children: _jsx(StyledField, { component: 'input', name: 'email', type: 'email', placeholder: msg.placeholderDefault, label: msg.labelEmail, validate: composeValidators(required, email) }, void 0) }, void 0), _jsx(InputWrapper, { children: _jsx(StyledField, { component: 'input', name: 'firstName', type: 'text', placeholder: msg.placeholderDefault, label: msg.labelFirstName, validate: composeValidators(containsNoInteger, required) }, void 0) }, void 0), _jsx(InputWrapper, { children: _jsx(StyledField, { component: 'input', name: 'lastName', type: 'text', placeholder: msg.placeholderDefault, label: msg.labelLastName, validate: composeValidators(containsNoInteger, required) }, void 0) }, void 0), _jsx(InputWrapper, { children: _jsx(StyledField, { name: 'phone', component: 'input', type: 'text', placeholder: msg.placeholderPhone, label: msg.labelPhone, validate: phone }, void 0) }, void 0), _jsxs(InputWrapper, { children: [_jsx(StyledLabel, { children: _jsx(FormattedMessage, __assign({}, msg.labelCountry), void 0) }, void 0), _jsx(Field, __assign({ name: 'countryCode', component: 'select', validate: isDelivery ? required : undefined }, { children: function (_a) {
                            var input = _a.input, rest = __rest(_a, ["input"]);
                            return (_jsxs(StyledSelect, __assign({ name: input.name, onChange: function (value) { return input.onChange(value); }, error: rest.meta.error }, rest, { children: [_jsx("option", __assign({ value: '' }, { children: "W\u00E4hlen Sie ein Land" }), void 0), countries.map(function (country) { return (_jsx("option", __assign({ value: country.code }, { children: country.name[intl.locale] }), country.code)); })] }), void 0));
                        } }), void 0)] }, void 0), (action === 'add' || action === 'edit') && (_jsx(InputWrapper, { children: _jsx(StyledField, { component: 'input', name: 'street', type: 'text', placeholder: msg.placeholderDefault, label: msg.labelStreet, validate: composeValidators.apply(void 0, deliveryBasedValidator) }, void 0) }, void 0)), (action === 'add' || action === 'edit') && (_jsx(InputWrapperSmall, { children: _jsx(StyledField, { component: 'input', name: 'streetNo', type: 'text', placeholder: msg.placeholderDefault, label: msg.labelStreetNo, validate: isDelivery ? required : undefined }, void 0) }, void 0)), _jsx(InputWrapper, { children: _jsx(StyledField, { component: 'input', name: 'city', type: 'text', placeholder: msg.placeholderDefault, label: msg.labelCity, validate: composeValidators.apply(void 0, deliveryBasedValidator) }, void 0) }, void 0), (action === 'add' || action === 'edit') && (_jsx(InputWrapperSmall, { children: _jsx(StyledField, { component: 'input', name: 'zipCode', type: 'number', placeholder: msg.placeholderDefault, label: msg.labelZip, validate: isDelivery ? required : undefined }, void 0) }, void 0)), _jsx(InputWrapper, { children: _jsx(StyledField, { name: 'comment', component: 'input', type: 'text', placeholder: msg.placeholderComment, label: msg.labelComment }, void 0) }, void 0)] }, void 0));
};
CustomerFormFields.defaultProps = {
    action: 'search',
};
export default CustomerFormFields;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
