var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import { palette } from '@shared/utils/styled';
import styled from 'styled-components';
var Seprator = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n  margin: auto;\n  height: 0.0625rem;\n  background: ", ";\n"], ["\n  width: ", ";\n  margin: auto;\n  height: 0.0625rem;\n  background: ", ";\n"])), function (props) { return props.width; }, palette('border'));
var HorizontalSeparator = function (_a) {
    var width = _a.width;
    return _jsx(Seprator, { width: width }, void 0);
};
HorizontalSeparator.defaultProps = {
    width: '85%',
};
export default HorizontalSeparator;
var templateObject_1;
