import { defineMessages } from 'react-intl';
var msg = defineMessages({
    labelFirstName: {
        defaultMessage: "Vorname",
        id: 'customerForm.labels.firstName',
    },
    labelLastName: {
        defaultMessage: "Name",
        id: 'customerForm.labels.lastName',
    },
    labelCountry: {
        defaultMessage: "Land",
        id: 'customerForm.labels.country',
    },
    labelStreet: {
        defaultMessage: "Stra\u00DFe",
        id: 'customerForm.labels.street',
    },
    labelStreetNo: {
        defaultMessage: "Hausnr.",
        id: 'customerForm.labels.streetNo',
    },
    labelCity: {
        defaultMessage: "Ort",
        id: 'customerForm.labels.city',
    },
    labelZip: {
        defaultMessage: "PLZ",
        id: 'customerForm.labels.zip',
    },
    labelEmail: {
        defaultMessage: "E-Mail-Adresse",
        id: 'customerForm.labels.email',
    },
    labelPhone: {
        defaultMessage: "Telefon.",
        id: 'customerForm.labels.phone',
    },
    labelComment: {
        defaultMessage: "Kommentar",
        id: 'customerForm.labels.comment',
    },
    labelBirthday: {
        defaultMessage: "Geburtsdatum",
        id: 'customerForm.labels.birthday',
    },
    labelCustomerNo: {
        defaultMessage: "Kundennr.",
        id: 'customerForm.labels.customerNo',
    },
    labelMale: {
        defaultMessage: "Herr",
        id: 'customerForm.labels.male',
    },
    labelFemale: {
        defaultMessage: "Frau",
        id: 'customerForm.labels.female',
    },
    phone: {
        defaultMessage: 'Telefon',
        id: 'customerForm.labels.phone',
    },
    placeholderDay: {
        defaultMessage: "Tag",
        id: 'customerForm.placeholder.day',
    },
    placeholderMonth: {
        defaultMessage: "Monat",
        id: 'customerForm.placeholder.month',
    },
    placeholderYear: {
        defaultMessage: "Jahr",
        id: 'customerForm.placeholder.year',
    },
    placeholderPhone: {
        defaultMessage: "+49 (0) 123456789",
        id: 'customerForm.placeholder.phone',
    },
    placeholderComment: {
        defaultMessage: "Kommentar",
        id: 'customerForm.placeholder.comment',
    },
    placeholderDefault: {
        defaultMessage: "Bitte eintragen",
        id: 'customerForm.placeholder.default',
    },
    hint: {
        defaultMessage: "F\u00FCllen Sie alle Felder aus um einen neuen Kunden anzulegen.",
        id: 'customerForm.hint',
    },
});
export default msg;
