var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from "react";
import RModal from "react-modal";
import styled from "styled-components";
import Icon from "@shared/atoms/Icon";
var getThemedStyles = function (theme, margin, padding) { return ({
    overlay: {
        alignItems: "center",
        backgroundColor: theme.modalOverlayBg,
        display: "flex",
        justifyContent: "center",
        padding: "0.625rem",
        zIndex: 200,
    },
    content: {
        border: 0,
        borderRadius: 0,
        overflow: "hidden",
        bottom: "inherit",
        left: "inherit",
        margin: margin || 0,
        maxHeight: "40rem",
        maxWidth: "56.25rem",
        position: "relative",
        right: "inherit",
        top: "inherit",
        width: "100%",
        padding: padding || "3.125rem 3.375rem 1.875rem 3.375rem",
    },
}); };
var ModalCloseButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #5d5d5d; // Fixed color, for every theme\n  top: 2rem;\n  right: 2.375rem;\n  position: absolute;\n"], ["\n  color: #5d5d5d; // Fixed color, for every theme\n  top: 2rem;\n  right: 2.375rem;\n  position: absolute;\n"])));
var CloseIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: #5d5d5d; // Fixed color, for every theme\n"], ["\n  color: #5d5d5d; // Fixed color, for every theme\n"])));
var Modal = function (_a) {
    var children = _a.children, contentMargin = _a.contentMargin, contentPadding = _a.contentPadding, hideCloseIcon = _a.hideCloseIcon, label = _a.label, open = _a.open, theme = _a.theme, toggleAction = _a.toggleAction;
    return (_jsxs(RModal, __assign({ contentLabel: label, isOpen: open, onRequestClose: toggleAction, style: getThemedStyles(theme, contentMargin, contentPadding) }, { children: [!hideCloseIcon && (_jsx(ModalCloseButton, __assign({ onClick: toggleAction }, { children: _jsx(CloseIcon, { icon: "closex", width: 15 }, void 0) }), void 0)), children] }), void 0));
};
/** @component */
export default Modal;
var templateObject_1, templateObject_2;
