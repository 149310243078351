var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { palette, fontSize } from '@shared/utils/styled';
import FlexButton from '@shared/atoms/FlexButton';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import H2 from '@shared/atoms/H2';
import Modal from '@shared/molecules/Modal';
import { rem } from 'polished';
import P from '@shared/atoms/P';
import { FormattedMessage } from 'react-intl';
var Headline = styled(H2)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 0;\n"], ["\n  margin-top: 0;\n"])));
var Button = styled(FlexButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  font-size: ", ";\n  height: 2.875rem;\n  letter-spacing: ", ";\n  margin-top: 0.625rem;\n  padding: 0 0.625rem;\n"], ["\n  background-color: ", ";\n  color: ", ";\n  font-size: ", ";\n  height: 2.875rem;\n  letter-spacing: ", ";\n  margin-top: 0.625rem;\n  padding: 0 0.625rem;\n"])), palette('secondaryHighlight'), palette('white'), fontSize('large'), rem('0.1px'));
var Confirm = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  margin-left: 0.625rem;\n"], ["\n  background-color: ", ";\n  color: ", ";\n  margin-left: 0.625rem;\n"])), palette('primaryHighlight'), palette('white'));
var AssignModal = function (_a) {
    var goBackToSession = _a.goBackToSession, unleashAssistant = _a.unleashAssistant, open = _a.open, theme = _a.theme, toggleAction = _a.toggleAction, props = __rest(_a, ["goBackToSession", "unleashAssistant", "open", "theme", "toggleAction"]);
    return (_jsxs(Modal, __assign({ contentPadding: '1.25rem', hideCloseIcon: true, label: '\u00DCbertragung erfolgreich', open: open, theme: theme, toggleAction: toggleAction }, props, { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { id: 'assign.modal.headline', defaultMessage: '\u00DCbertragung erfolgreich' }, void 0) }, void 0), _jsx(P, { children: _jsx(FormattedMessage, { id: 'assign.modal.text', defaultMessage: 'Sie haben den Kundenvorgang erfolgreich an das Terminal \u00FCbertragen. Sie\n      k\u00F6nnen diesen nun auch von Ihrem Ger\u00E4t l\u00F6sen.' }, void 0) }, void 0), _jsxs(HorizontalFlex, { children: [_jsx(Button, __assign({ onClick: function () { return unleashAssistant(); } }, { children: _jsx(FormattedMessage, { id: 'assign.modal.unleash', defaultMessage: 'L\u00F6sen' }, void 0) }), void 0), _jsx(Confirm, __assign({ onClick: goBackToSession }, { children: _jsx(FormattedMessage, { id: 'assign.modal.next', defaultMessage: 'Weiter' }, void 0) }), void 0)] }, void 0)] }), void 0));
};
export default withTheme(withRouter(AssignModal));
var templateObject_1, templateObject_2, templateObject_3;
