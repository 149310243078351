var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled, { withTheme } from 'styled-components';
import LoginForm from 'molecules/LoginForm';
import BackgroundImage from '@shared/atoms/BackgroundImage';
import VerticalFlex from 'atoms/VerticalFlex';
var LogoWrapper = styled(BackgroundImage)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 3rem;\n  max-width: 100%;\n  margin-bottom: 1.875rem;\n"], ["\n  height: 3rem;\n  max-width: 100%;\n  margin-bottom: 1.875rem;\n"])));
var Wrapper = styled(VerticalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  justify-content: center;\n"], ["\n  align-items: center;\n  justify-content: center;\n"])));
var BlockWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-flow: column;\n  padding: 0 10%;\n  width: 100%;\n  min-height: 300px;\n"], ["\n  align-items: center;\n  display: flex;\n  flex-flow: column;\n  padding: 0 10%;\n  width: 100%;\n  min-height: 300px;\n"])));
var Login = function (_a) {
    var theme = _a.theme, login = _a.login, errors = _a.errors, reset = _a.reset;
    return (_jsx(Wrapper, { children: _jsxs(BlockWrapper, { children: [_jsx(LogoWrapper, { url: theme.logoUrl }, void 0), _jsx(LoginForm, { onSubmit: login, errors: errors, reset: reset }, void 0)] }, void 0) }, void 0));
};
export default withTheme(Login);
var templateObject_1, templateObject_2, templateObject_3;
