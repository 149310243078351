var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
// utils
import { palette, fontWeight, fontSize } from '@shared/utils/styled';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
// components
import { Link } from 'react-router-dom';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  margin-top: ", ";\n"], ["\n  position: relative;\n  margin-top: ", ";\n"])), function (props) { return (props.hasBanner ? '3rem' : 0); });
var Banner = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  left: 25%;\n  position: absolute;\n  top: -1.75rem;\n  width: 50%;\n"], ["\n  left: 25%;\n  position: absolute;\n  top: -1.75rem;\n  width: 50%;\n"])));
var Tile = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  line-height: 1.25rem;\n  text-align: center;\n  text-decoration: none;\n  width: 100%;\n  padding-bottom: 0.625rem;\n  display: block;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  line-height: 1.25rem;\n  text-align: center;\n  text-decoration: none;\n  width: 100%;\n  padding-bottom: 0.625rem;\n  display: block;\n"])), palette('text'), fontSize('default'));
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-height: 21.875rem;\n"], ["\n  max-height: 21.875rem;\n"])));
var TextWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 0 0.9375rem;\n"], ["\n  padding: 0 0.9375rem;\n"])));
var Descripction = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
var TilesSmall = function (_a) {
    var tiles = _a.tiles, bannerUrl = _a.bannerUrl, session = _a.session;
    return (_jsxs(Wrapper, __assign({ hasBanner: Boolean(bannerUrl) }, { children: [sortBy(tiles, 'sort').map(function (tile) { return (_jsxs(Tile, __assign({ to: "/sessions/" + session.uuid + tile.targetUrl }, { children: [_jsx(Image, { src: tile.imageUrl }, void 0), _jsxs(TextWrapper, { children: [tile.headline, _jsx(Descripction, { children: tile.description }, void 0)] }, void 0)] }), tile.sort)); }), bannerUrl && _jsx(Banner, { src: bannerUrl }, void 0)] }), void 0));
};
export default TilesSmall;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
