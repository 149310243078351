var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import GoBack from 'molecules/GoBack';
import SubMenu from 'organisms/SubMenu';
import SessionLayout from 'templates/SessionLayout';
import CustomerInformation from 'organisms/CustomerInformation';
import { fontWeight, palette } from '@shared/utils/styled';
// import type { Session, SessionProps } from '@store/session/types';
// import type { Device } from '@store/device/types';
// import type { WatchlistItem } from '@store/watchlist/types';
import { processActionTypes } from '@shared/utils/constants';
// translations
import msg from './messages';
var BottomMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 3.25rem;\n  position: relative;\n"], ["\n  height: 3.25rem;\n  position: relative;\n"])));
var BottomMenuButton = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  height: 100%;\n  justify-content: center;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  height: 100%;\n  justify-content: center;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('primaryHighlight'), palette('white'), fontWeight('bold'), palette('activePrimaryHighlight'));
var CustomerFinishButton = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  border-bottom: 0.125rem solid ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  height: calc(3.125rem + 0.125rem);\n  justify-content: center;\n  opacity: 1;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  width: 100%;\n\n  &:active {\n    background-color: ", ";\n  }\n\n  &:last-child {\n    border-bottom: none;\n  }\n"], ["\n  align-items: center;\n  background-color: ", ";\n  border-bottom: 0.125rem solid ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  height: calc(3.125rem + 0.125rem);\n  justify-content: center;\n  opacity: 1;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  width: 100%;\n\n  &:active {\n    background-color: ", ";\n  }\n\n  &:last-child {\n    border-bottom: none;\n  }\n"])), palette('secondaryBackground'), palette('primaryBackground'), palette('white'), fontWeight('bold'), palette('activeSecondaryBackground'));
var CustomerFinish = function (_a) {
    var uuid = _a.session.uuid, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, customer = _a.customer, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, location = _a.location, terminals = _a.terminals, session = _a.session, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist;
    var intl = useIntl();
    var renderBottomMenu = function () {
        var search = parse(location.search);
        return (_jsxs(BottomMenu, { children: [search.ref !== processActionTypes.CHECKOUT &&
                    search.ref !== processActionTypes.RESERVE && (_jsx(BottomMenuButton, __assign({ to: "/sessions/" + uuid }, { children: _jsx(FormattedMessage, { defaultMessage: 'Zum Dashboard', id: 'customerFinish.bottomMenu.toDashboard' }, void 0) }), void 0)), (search.ref === processActionTypes.CHECKOUT ||
                    search.ref === processActionTypes.RESERVE) && (_jsx(BottomMenuButton, __assign({ to: "/sessions/" + uuid + "/" + search.ref + "/complete" }, { children: _jsx(FormattedMessage, { defaultMessage: 'Weiter', id: 'customerFinish.bottomMenu.next' }, void 0) }), void 0))] }, void 0));
    };
    var search = parse(location.search);
    return (_jsxs(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, bottomMenu: renderBottomMenu(), currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, subMenu: _jsx(SubMenu, { cancelIcon: _jsx(GoBack, { icon: 'iconBack', width: '15' }, void 0), title: intl.formatMessage(msg.customer) }, void 0), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist }, { children: [_jsx(CustomerInformation, { customer: customer, type: 'finish' }, void 0), _jsxs("div", { children: [_jsx(CustomerFinishButton, __assign({ to: {
                            pathname: "/sessions/" + session.uuid + "/customer",
                            search: "?ref=" + search.ref,
                        } }, { children: _jsx(FormattedMessage, { defaultMessage: 'Anderen Kunden zuweisen', id: 'customerFinish.assignDifferentCustomer' }, void 0) }), void 0), _jsx(CustomerFinishButton, __assign({ to: "/sessions/" + session.uuid + "/customer/edit/" + customer.id }, { children: _jsx(FormattedMessage, { defaultMessage: 'Bearbeiten', id: 'customerFinish.edit' }, void 0) }), void 0)] }, void 0)] }), void 0));
};
export var CustomerFinishComponent = CustomerFinish;
export default withRouter(CustomerFinish);
var templateObject_1, templateObject_2, templateObject_3;
