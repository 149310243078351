var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { toJS } from '@shared/utils/immutable';
import Spinner from '@shared/atoms/Spinner';
import { getCustomer as getCustomerAction } from '@store/customer/actions';
import { updateSession as updateSessionAction } from '@store/session/actions';
import { selectCustomerById, selectLoading, selectLoaded, } from '@store/customer/selectors';
import SessionContainer from 'containers/SessionContainer';
import Page from 'pages/CustomerConfirm';
var CustomerConfirm = /** @class */ (function (_super) {
    __extends(CustomerConfirm, _super);
    function CustomerConfirm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomerConfirm.prototype.componentWillMount = function () {
        var _a = this.props, customer = _a.customer, getCustomer = _a.getCustomer, loading = _a.loading, params = _a.match.params;
        if (!customer && !loading) {
            getCustomer(params.customerId);
        }
    };
    CustomerConfirm.prototype.render = function () {
        var _a = this.props, customer = _a.customer, loading = _a.loading, updateSession = _a.updateSession;
        return (_jsx(SessionContainer, { children: function (sessionProps) {
                if (loading) {
                    return _jsx(Spinner, {}, void 0);
                }
                if (customer && !loading) {
                    return (_jsx(Page, __assign({}, sessionProps, { customer: customer, updateSession: updateSession }), void 0));
                }
                return (_jsx(Redirect, { push: true, to: "/sessions/" + sessionProps.session.uuid + "/customer" }, void 0));
            } }, void 0));
    };
    return CustomerConfirm;
}(Component));
export default withRouter(connect(function (state, props) { return ({
    customer: selectCustomerById()(state, props),
    loaded: selectLoaded(state),
    loading: selectLoading(state),
}); }, { getCustomer: getCustomerAction, updateSession: updateSessionAction })(toJS(CustomerConfirm)));
