import { jsx as _jsx } from "react/jsx-runtime";
import { processActionTypes } from '@shared/utils/constants';
import { FormattedMessage } from 'react-intl';
var InfoHeadlinesMessages = function (_a) {
    var _b;
    var orderNumber = _a.orderNumber, type = _a.type;
    var RESERVE = processActionTypes.RESERVE, REQUEST = processActionTypes.REQUEST, CHECKOUT = processActionTypes.CHECKOUT, ORDER = processActionTypes.ORDER;
    var InfoHeadlines = (_b = {},
        _b[RESERVE] = (_jsx(FormattedMessage, { id: 'checkout.reserve.complete.success', defaultMessage: 'Reservierung erfolgreich' }, void 0)),
        _b[REQUEST] = (_jsx(FormattedMessage, { id: 'checkout.request.complete.success', defaultMessage: 'Anforderung erfolgreich' }, void 0)),
        _b[ORDER] = !orderNumber ? null : (_jsx(FormattedMessage, { id: 'checkout.order.incomplete.missingPayment', defaultMessage: 'Lieferantenbestellung abschlie\u00DFen' }, void 0)),
        _b[CHECKOUT] = (_jsx(FormattedMessage, { id: 'checkout.buy.complete.success', defaultMessage: 'Kauf erfolgreich' }, void 0)),
        _b);
    return InfoHeadlines[type];
};
export default InfoHeadlinesMessages;
