var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import GoBack from 'molecules/GoBack';
import SubMenu from 'organisms/SubMenu';
import SessionLayout from 'templates/SessionLayout';
import CustomerListItem from 'molecules/CustomerListItem';
import { palette } from '@shared/utils/styled';
// translations
import msg from './messages';
var CustomerListContent = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  display: block;\n  height: 100%;\n  margin: 0;\n  overflow: auto;\n  flex: 1;\n  padding: 0.25rem 0.5rem 0 0.5rem;\n"], ["\n  background-color: ", ";\n  display: block;\n  height: 100%;\n  margin: 0;\n  overflow: auto;\n  flex: 1;\n  padding: 0.25rem 0.5rem 0 0.5rem;\n"])), palette('white'));
var CustomerList = function (_a) {
    var uuid = _a.session.uuid, history = _a.history, location = _a.location, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, terminals = _a.terminals, searchResults = _a.searchResults, session = _a.session, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist;
    var intl = useIntl();
    var onCustomerClick = function (customerId) {
        var search = parse(location.search);
        history.push({
            pathname: "/sessions/" + uuid + "/customer/confirm/" + customerId,
            search: "?ref=" + search.ref,
        });
    };
    useEffect(function () {
        var search = parse(location.search);
        if (!searchResults || searchResults.length === 0) {
            history.push({
                pathname: "/sessions/" + uuid + "/customer",
                search: "?ref=" + search.ref,
            });
        }
    }, [history, location.search, searchResults, uuid]);
    return (_jsx(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, hideBottomMenu: true, session: session, terminals: terminals, subMenu: _jsx(SubMenu, { cancelIcon: _jsx(GoBack, { icon: 'iconBack', width: '15' }, void 0), title: intl.formatMessage(msg.customer) }, void 0), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist }, { children: _jsx(CustomerListContent, { children: searchResults &&
                searchResults.map(function (customer) { return (_jsx(CustomerListItem, { customer: customer, onClick: function () { return onCustomerClick(customer.id); } }, customer.id)); }) }, void 0) }), void 0));
};
export var CustomerListComponent = CustomerList;
export default withRouter(CustomerList);
var templateObject_1;
