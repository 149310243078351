var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @flow
import React, { useEffect, useState } from 'react';
import { fontWeight, palette } from '@shared/utils/styled';
import styled from 'styled-components';
import GoBack from 'molecules/GoBack';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
var Wrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border-bottom: 1px solid #e0e0e0;\n    margin-top: 1px;\n    background-color: ", ";\n    color: ", ";\n    font-size: 1.5rem;\n    font-weight: ", ";\n    letter-spacing: 0.4px;\n    line-height: 1.25rem;\n    max-height: 3.75rem;\n    min-height: 3.75rem;\n    text-align: center;\n    position: absolute;\n    width: 100%;\n    top: 0;\n    z-index: 999;\n    padding: 0;\n    display: flex;\n    height: 100%;\n    align-items: center;\n"], ["\n    border-bottom: 1px solid #e0e0e0;\n    margin-top: 1px;\n    background-color: ", ";\n    color: ", ";\n    font-size: 1.5rem;\n    font-weight: ", ";\n    letter-spacing: 0.4px;\n    line-height: 1.25rem;\n    max-height: 3.75rem;\n    min-height: 3.75rem;\n    text-align: center;\n    position: absolute;\n    width: 100%;\n    top: 0;\n    z-index: 999;\n    padding: 0;\n    display: flex;\n    height: 100%;\n    align-items: center;\n"])), palette('white'), palette('primaryHighlight'), fontWeight('light'));
// const LineSpan = styled.span`
// border-bottom: 0.125rem solid ${palette('border')};
// position: absolute;
// display:flex;
// width:70%;
// bottom:0rem;
// left:15%
// `;
var Spacer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 4.375rem;\n"], ["\n    width: 4.375rem;\n"])));
var Count = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border: 2px solid #e0e0e0;\n    border-radius: 0.5rem;\n    padding: 0.175rem;\n    font-size: 1.25rem;\n"], ["\n    border: 2px solid #e0e0e0;\n    border-radius: 0.5rem;\n    padding: 0.175rem;\n    font-size: 1.25rem;\n"])));
var SubMenu = function (_a) {
    var cancelIcon = _a.cancelIcon, title = _a.title, iconRight = _a.iconRight, noBack = _a.noBack, count = _a.count;
    var _b = useState(false), isSticky = _b[0], setIsSticky = _b[1];
    var handleScroll = function () {
        // Define a threshold, e.g., 100 pixels
        var threshold = 100;
        setIsSticky(window.scrollY > threshold);
    };
    useEffect(function () {
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener
        return function () {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (_jsx(_Fragment, { children: _jsxs(Wrapper, __assign({ style: {
                position: isSticky ? 'absolute' : 'sticky',
                top: 0,
                zIndex: 2500,
                display: "grid",
                gridTemplateColumns: "25% 50% 25%",
                alignItems: 'center',
                padding: "0 1rem",
                width: "100%"
            } }, { children: [_jsx("div", __assign({ style: { display: "flex", justifyContent: "left", alignItems: 'center' } }, { children: noBack ? _jsx(Spacer, {}, void 0) : cancelIcon || _jsx(GoBack, {}, void 0) }), void 0), _jsxs("div", __assign({ style: {
                        display: 'flex',
                        flexDirection: "row",
                        gap: "0.25rem",
                        justifyContent: 'center',
                        alignItems: 'center',
                        lineHeight: "1.75rem"
                    } }, { children: [_jsx("div", __assign({ style: { fontSize: "1.5rem" } }, { children: title }), void 0), _jsx("div", { children: count && count !== 0 && _jsx(Count, { children: count }, void 0) }, void 0)] }), void 0), _jsx("div", __assign({ style: { display: 'flex', justifyContent: 'end', alignItems: 'center' } }, { children: iconRight || _jsx(Spacer, {}, void 0) }), void 0)] }), void 0) }, void 0));
};
export default SubMenu;
var templateObject_1, templateObject_2, templateObject_3;
