var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useEffect, useRef } from 'react';
import { Form as FinalForm, useField } from 'react-final-form';
import styled from 'styled-components';
import { fontSize, palette } from '@shared/utils/styled';
import Button from '@shared/atoms/Button';
var StyledInput = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: 0.0625rem solid ", ";\n    color: ", ";\n    font-size: ", ";\n    line-height: 3rem;\n    margin: 0.75rem 0 1.25rem 0;\n    padding-left: 1.5rem;\n    padding-right: 1.5rem;\n    width: 100%;\n    height: 2.875rem;\n\n    ::placeholder {\n        font-style: italic;\n    }\n"], ["\n    border: 0.0625rem solid ", ";\n    color: ", ";\n    font-size: ", ";\n    line-height: 3rem;\n    margin: 0.75rem 0 1.25rem 0;\n    padding-left: 1.5rem;\n    padding-right: 1.5rem;\n    width: 100%;\n    height: 2.875rem;\n\n    ::placeholder {\n        font-style: italic;\n    }\n"])), palette('border'), palette('text'), fontSize('large'));
export function StyledField(props) {
    var input = useField(props.name).input;
    var inputProps = __assign(__assign({}, props), input);
    return _jsx(StyledInput, __assign({}, inputProps), void 0);
}
var Confirm = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-color: ", ";\n    font-size: ", ";\n    height: 3rem;\n    width: 100%;\n"], ["\n    background-color: ", ";\n    font-size: ", ";\n    height: 3rem;\n    width: 100%;\n"])), palette('secondaryBackground'), fontSize('large'));
var Form = styled.form(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100%;\n    margin-bottom: 2.5rem;\n"], ["\n    width: 100%;\n    margin-bottom: 2.5rem;\n"])));
var Error = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    color: red;\n    font-size: ", ";\n"], ["\n    color: red;\n    font-size: ", ";\n"])), fontSize('small'));
var LoginFormComponent = function (_a) {
    var onSubmit = _a.onSubmit, errors = _a.errors, reset = _a.reset;
    var pinRef = useRef();
    var handleKeyPress = function (event) {
        switch (event.key) {
            //   case 42:
            //   case 125:
            case 'F14':
            case 'print':
                event.preventDefault();
                reset();
                break;
            default:
                break;
        }
    };
    useEffect(function () {
        document.addEventListener('keypress', function (e) { return handleKeyPress(e); });
        return function () {
            document.removeEventListener('keypress', function (e) { return handleKeyPress(e); });
        };
    }, []);
    return (_jsx(FinalForm, { onSubmit: function (values) { return onSubmit(values); }, render: function (_a) {
            var handleSubmit = _a.handleSubmit, submitting = _a.submitting, pristine = _a.pristine;
            return (_jsxs(Form, __assign({ onSubmit: handleSubmit }, { children: [_jsxs("div", { children: [Boolean(errors) && errors.length !== 0 && (_jsx(Error, { children: "Anmeldung fehlgeschlagen!" }, void 0)), _jsx("div", { children: _jsxs("div", { children: ["Filiale: ", _jsx("span", { children: localStorage.getItem("storeId") }, void 0)] }, void 0) }, void 0), _jsx(StyledField, { autoFocus: true, autoComplete: 'off', component: 'input', name: 'assistantId', placeholder: 'Mitarbeiternummer', innerRef: pinRef, onBlur: function () { var _a; return (_a = pinRef.current) === null || _a === void 0 ? void 0 : _a.getRenderedComponent().focus(); }, type: 'number', withRef: true }, void 0)] }, void 0), _jsx("div", { children: _jsx(Confirm, __assign({ type: 'submit', disabled: pristine || submitting }, { children: "Anmelden" }), void 0) }, void 0)] }), void 0));
        } }, void 0));
};
export default LoginFormComponent;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
