import { createSelector } from 'reselect';
var selectDeviceState = function (state) { return state.get('device'); };
export var selectLoadingAllComplete = createSelector(selectDeviceState, function (deviceState) {
    return !deviceState.get('loadingAll') && deviceState.get('loadedAll');
});
export var selectDevices = createSelector(selectDeviceState, function (deviceState) {
    return deviceState.get('data');
});
export var selectDevicePin = createSelector(selectDeviceState, function (deviceState) {
    var pin = deviceState.get('data').get(0).get('os').split(':')[1].trim();
    return pin;
});
