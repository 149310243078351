import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
export var initialState = Map({
    requesting: false,
    data: List(),
    loaded: false,
    loading: false,
    orderNumber: null,
});
var reducer = handleActions({
    REQUEST_ACTION: function (state) { return state.set('requesting', true); },
    ORDER_ACTION: function (state) { return state.set('requesting', true); },
    REQUEST_ACTION_COMPLETE: function (state) { return state.set('requesting', false); },
    ORDER_ACTION_COMPLETE: {
        next: function (state, action) {
            return state
                .set('orderNumber', action.payload.orderNumber)
                .set('requesting', false);
        },
    },
    RESET_ORDERNUMBER: {
        next: function (state) {
            return state.set('orderNumber', null);
        },
    },
}, initialState);
export default reducer;
