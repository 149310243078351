var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
var FlexButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #f5f5f5;\n  border: none;\n  color: ", ";\n  cursor: pointer;\n  flex: 1;\n  height: 100%;\n  outline: none;\n"], ["\n  background-color: #f5f5f5;\n  border: none;\n  color: ", ";\n  cursor: pointer;\n  flex: 1;\n  height: 100%;\n  outline: none;\n"])), palette('highlightText'));
/** @component */
export default FlexButton;
var templateObject_1;
