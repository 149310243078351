var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useState } from 'react';
// utils
import { fontSize, fontWeight, palette } from '@shared/utils/styled';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';
import { getPriceString, hasDiscounts } from '@shared/utils/currency';
// components
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import Icon from '@shared/atoms/Icon';
import PriceTag from '@shared/molecules/PriceTag';
import ShadowBox from '@shared/atoms/ShadowBox';
import DiscountBadgeSmall from 'molecules/DiscountBadgeSmall';
import ArticleImage from "../../../assistant/articleImage/components/ArticleImage";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-top: 0.625rem;\n    padding: 0 0.625rem;\n"], ["\n    margin-top: 0.625rem;\n    padding: 0 0.625rem;\n"])));
var ContentWrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    align-items: center;\n    color: ", ";\n    font-size: ", ";\n    justify-content: center;\n    min-height: 5.625rem;\n    opacity: ", ";\n    padding: 0.3125rem 0.5rem;\n    width: 100%;\n\n    &:active {\n        background-color: ", ";\n        box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);\n    }\n"], ["\n    align-items: center;\n    color: ", ";\n    font-size: ", ";\n    justify-content: center;\n    min-height: 5.625rem;\n    opacity: ", ";\n    padding: 0.3125rem 0.5rem;\n    width: 100%;\n\n    &:active {\n        background-color: ", ";\n        box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);\n    }\n"])), palette('black'), fontSize('default'), function (props) { return (props.deleting ? 0.5 : 1); }, palette('activeWhite'));
var RequestStatus = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin: 0.3rem;\n    position: absolute;\n    right: 0;\n    top: 2rem;\n    height: 1rem;\n    width: 1rem;\n    border: 1px solid gray;\n    border-radius: 0.5rem;\n    display: ", ";\n    background-color: ", ";\n"], ["\n    margin: 0.3rem;\n    position: absolute;\n    right: 0;\n    top: 2rem;\n    height: 1rem;\n    width: 1rem;\n    border: 1px solid gray;\n    border-radius: 0.5rem;\n    display: ", ";\n    background-color: ", ";\n"])), function (props) { return props.show; }, function (props) { return props.color; });
var DeleteIcon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    opacity: ", ";\n    margin: 0.5rem;\n    position: absolute;\n    right: 0;\n    top: 3px;\n\n    &:active {\n        background-color: ", ";\n    }\n"], ["\n    opacity: ", ";\n    margin: 0.5rem;\n    position: absolute;\n    right: 0;\n    top: 3px;\n\n    &:active {\n        background-color: ", ";\n    }\n"])), function (props) { return (props.deleting ? 0.5 : 1); }, palette('activeWhite'));
// const EditIcon = styled.div`
//   margin: 0.5rem;
//   position: absolute;
//   left: 0;
//   top: 3px;
//   &:active {
//     background-color: ${palette('activeWhite')};
//   }
// `;
var Image = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    align-items: center;\n    display: flex;\n    flex: 1;\n    font-size: 0;\n    justify-content: center;\n    margin-right: 0.625rem;\n    position: relative;\n\n    img {\n        max-width: 100%;\n        width: 100%;\n    }\n"], ["\n    align-items: center;\n    display: flex;\n    flex: 1;\n    font-size: 0;\n    justify-content: center;\n    margin-right: 0.625rem;\n    position: relative;\n\n    img {\n        max-width: 100%;\n        width: 100%;\n    }\n"])));
var Info = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    flex: 1;\n    font-weight: ", ";\n    padding-right: 1.125rem;\n"], ["\n    flex: 1;\n    font-weight: ", ";\n    padding-right: 1.125rem;\n"])), fontWeight('default'));
var Tag = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    color: ", ";\n    text-decoration: ", ";\n\n    &:nth-child(2) {\n        margin-left: 0.5rem;\n    }\n"], ["\n    color: ", ";\n    text-decoration: ", ";\n\n    &:nth-child(2) {\n        margin-left: 0.5rem;\n    }\n"])), function (props) { return (props.discount ? 'red' : 'inherit'); }, function (props) { return (props.crossed ? 'line-through' : 'none'); });
var StyledShadowBox = styled(ShadowBox)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    position: relative;\n    border-radius: 1.25rem;\n"], ["\n    position: relative;\n    border-radius: 1.25rem;\n"])));
var TopBanner = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    background-color: #f2f2f2;\n    color: ", ";\n    font-size: 0.875rem;\n    font-weight: bold;\n    padding: 0.3125rem;\n    text-align: center;\n"], ["\n    background-color: #f2f2f2;\n    color: ", ";\n    font-size: 0.875rem;\n    font-weight: bold;\n    padding: 0.3125rem;\n    text-align: center;\n"])), palette('text'));
var Lights = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    background-color: ", ";\n    border-radius: 1rem;\n    content: ' ';\n    height: 1rem;\n    width: 1rem;\n    display: inline-block;\n    margin-left: 0.5rem;\n"], ["\n    background-color: ", ";\n    border-radius: 1rem;\n    content: ' ';\n    height: 1rem;\n    width: 1rem;\n    display: inline-block;\n    margin-left: 0.5rem;\n"])), function (props) { return palette(props.availability); });
var WatchlistItem = function (_a) {
    var deleteWatchlistItem = _a.deleteWatchlistItem, history = _a.history, item = _a.item, sessionId = _a.sessionId, toggleWatchlist = _a.toggleWatchlist;
    var intl = useIntl();
    var _b = useState(false), deleting = _b[0], setDeleting = _b[1];
    var deleteItem = function () {
        setDeleting(!deleting);
        deleteWatchlistItem(item.id);
    };
    var getLabelSize = function () {
        var msg = defineMessages({
            size: { defaultMessage: 'Größe', id: 'watchlist.item.size' },
        });
        if (!Boolean(item.sizeId) || !Boolean(item.article)) {
            return _jsx("span", {}, void 0);
        }
        var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
        return (_jsxs("span", { children: [intl.formatMessage(msg.size) + ": " + (size ? size.labelSize : ''), _jsx(Lights, { availability: size.stock }, void 0), size ? _jsx("br", {}, void 0) : ''] }, void 0));
    };
    var openArticle = function () {
        history.push("/sessions/" + sessionId + "/articles/" + item.article.id);
        toggleWatchlist();
    };
    var getUsageText = function (usage) {
        var msg = defineMessages({
            tryOn: { defaultMessage: 'Anprobieren', id: 'watchlist.item.tryon' },
            reserve: { defaultMessage: 'Reservieren', id: 'watchlist.item.reserve' },
            checkout: { defaultMessage: 'Kaufen', id: 'watchlist.item.checkout' },
        });
        return msg[usage]
            ? intl.formatMessage(msg[usage])
            : '';
    };
    var getCurrentPrice = function () {
        var article = item.article;
        var prices = article.prices, targetPrices = article.targetPrices;
        if (item.sizeId) {
            var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
            if (size) {
                if (size.price === size.targetPrice) {
                    return _jsx(Tag, { children: getPriceString(size.price) }, void 0);
                }
                return (_jsxs("span", { children: [_jsx(Tag, __assign({ crossed: true }, { children: getPriceString(size.targetPrice) }), void 0), ' ', _jsx(Tag, __assign({ discount: true }, { children: getPriceString(size.price) }), void 0), ' '] }, void 0));
            }
        }
        return _jsx(PriceTag, { prices: prices, targetPrices: targetPrices }, void 0);
    };
    var isDiscounted = function () {
        if (!item.sizeId) {
            return hasDiscounts(item.article.prices, item.article.targetPrices);
        }
        var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
        if (size) {
            return size.price !== size.targetPrice;
        }
        return false;
    };
    var color = function (requestState) {
        var colors = {
            ok: '#0c0',
            no: '#f00',
        };
        return colors[requestState] || '#fff';
    };
    var article = item.article;
    return (_jsx(Wrapper, { children: _jsxs(StyledShadowBox, { children: [item.usage !== 'remember' && (_jsx(TopBanner, { children: getUsageText(item.usage) }, void 0)), _jsxs(ContentWrapper, __assign({ onClick: function () { return openArticle(); } }, { children: [_jsxs(Image, { children: [isDiscounted() && _jsx(DiscountBadgeSmall, {}, void 0), _jsx(ArticleImage, { article: article, size: "medium" }, void 0)] }, void 0), _jsxs(Info, { children: [article.brand && article.brand.name, _jsx("br", {}, void 0), article.displayName, _jsx("br", {}, void 0), article.color && " " + article.color, _jsx("br", {}, void 0), (item.sizeId && getLabelSize()) || '', "Fabart: ", article.fabart, " ", _jsx("br", {}, void 0), "ArtNr.: ", article.number, _jsx("br", {}, void 0), article.prices && getCurrentPrice()] }, void 0)] }), void 0), _jsx(DeleteIcon, __assign({ "data-cy-id": 'watchlist-item-delete', onClick: function () { return deleteItem(); }, deleting: deleting }, { children: _jsx(Icon, { icon: 'icon_trashcan-sm', width: 25, fill: 'black' }, void 0) }), void 0), _jsx(RequestStatus, { show: item.requestId ? 'block' : 'none', color: color(item.requestState || '') }, void 0)] }, void 0) }, void 0));
};
export default withRouter(WatchlistItem);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
