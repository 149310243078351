import { parse } from 'query-string';
import { createSelector } from 'reselect';
import { makeSelectSession } from '@store/session/selectors';
var selectState = function (state) { return state.get('inspiration'); };
var selectSearchParams = function (state, props) {
    return props.location.search ? parse(props.location.search) : null;
};
export var selectShouldLoadAll = createSelector(selectState, function (state) { return !state.get('loadingAll') && !state.get('loadedAll'); });
export var selectAll = createSelector(selectState, function (state) {
    return state.get('data');
});
export var selectLoadingAll = createSelector(selectState, function (state) {
    return state.get('loadingAll');
});
export var selectCurrent = function () {
    return createSelector([selectAll, selectSearchParams, makeSelectSession()], function (state, searchParams, session) {
        var paramsPriCatId = searchParams && searchParams.priCat
            ? parseInt(searchParams.priCat, 10)
            : null;
        // Inspiration page is already selected?
        if (paramsPriCatId) {
            return state.find(function (insp) { return insp.get('primaryCategoryId') === paramsPriCatId; });
        }
        // On inital visit
        // Is there a page connected to the session?
        if (session && session.get('lastInspirationId')) {
            return state.find(function (item) { return item.get('id') === session.get('lastInspirationId'); });
        }
        return null;
    });
};
