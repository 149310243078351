var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import H2 from "@shared/atoms/H2";
import breakpoint from "styled-components-breakpoint";
import { palette, fontSize } from "@shared/utils/styled";
export var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  animation: 0.6s ease-in 0s 1 fadeIn;\n  display: flex;\n  transition: height 0.15s ease-in;\n  flex-direction: column;\n  align-items: center;\n  ", ";\n"], ["\n  animation: 0.6s ease-in 0s 1 fadeIn;\n  display: flex;\n  transition: height 0.15s ease-in;\n  flex-direction: column;\n  align-items: center;\n  ", ";\n"])), breakpoint("tablet")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nflex-direction: row;\nalign-items: flex-start;\n"], ["\nflex-direction: row;\nalign-items: flex-start;\n"]))));
export var Headline = styled(H2)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 1.25rem;\n  margin-top: 1.125rem;\n  text-align: center;\n  ", ";\n"], ["\n  margin-bottom: 1.25rem;\n  margin-top: 1.125rem;\n  text-align: center;\n  ", ";\n"])), breakpoint("tablet")(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\ntext-align: left;\n"], ["\ntext-align: left;\n"]))));
export var MessageWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-left: 0;\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-left: 0;\n  ", ";\n"])), breakpoint("tablet")(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\nalign-items: flex-start;\n justify-content: flex-start;\n  margin-left: 1.75rem;\n"], ["\nalign-items: flex-start;\n justify-content: flex-start;\n  margin-left: 1.75rem;\n"]))));
export var SendIconWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n"])));
export var Text = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  text-align: center;\n  ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  text-align: center;\n  ", ";\n"])), palette("text"), fontSize("large"), breakpoint("tablet")(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\ntext-align: left;\n"], ["\ntext-align: left;\n"]))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
