// @flow
import { combineReducers } from 'redux-immutable';
import article from './article/reducer';
import assistant from './assistant/reducer';
import authorize from './authorize/reducer';
import category from './category/reducer';
import connection from './connection/reducer';
import customer from './customer/reducer';
import device from './device/reducer';
import filter from './filter/reducer';
import filterSelection from './filterSelection/reducer';
import filterReducer from './filterSelections/filterReducer';
import furniture from './furniture/reducer';
import identity from './identity/reducer';
import intl from './intl/reducer';
import primaryCategory from './primaryCategory/reducer';
import scan from './scan/reducer';
import session from './session/reducer';
import sessionMeta from './sessionMeta/reducer';
import store from './store/reducer';
import theme from './theme/reducer';
import watchlist from './watchlist/reducer';
import inspiration from './inspiration/reducer';
import department from './department/reducer';
import action from './action/reducer';
import error from './error/reducer';
import sort from './sort/reducer';
export default combineReducers({
    article: article,
    assistant: assistant,
    authorize: authorize,
    category: category,
    connection: connection,
    customer: customer,
    department: department,
    device: device,
    filter: filter,
    filterSelection: filterSelection,
    furniture: furniture,
    identity: identity,
    inspiration: inspiration,
    intl: intl,
    primaryCategory: primaryCategory,
    scan: scan,
    session: session,
    sessionMeta: sessionMeta,
    store: store,
    theme: theme,
    watchlist: watchlist,
    action: action,
    error: error,
    sort: sort,
    filterReducer: filterReducer,
});
