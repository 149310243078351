var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useEffect, useRef, useState } from "react";
import { palette } from "@shared/utils/styled";
import ArticleListItem from "molecules/ArticleListItem";
import TileInfiniteScroll from "@shared/organisms/TileInfiniteScroll";
import styled from "styled-components";
import HorizontalFlex from "@shared/atoms/HorizontalFlex";
import { selectLoadingAllComplete } from "@store/article/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { filterSelection } from "@store/filterSelections/filterSelectors";
import CancelButton from "@shared/molecules/CancelButton";
import { filterSelected } from "@store/filterSelections/filterActions";
var FilterDescription = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    align-items: center;\n    background-color: ", ";\n    color: ", ";\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    flex-direction: row;\n    margin-top: 1rem;\n    height: auto;\n    text-align: center;\n    padding: 0.5rem 0;\n"], ["\n    align-items: center;\n    background-color: ", ";\n    color: ", ";\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    flex-direction: row;\n    margin-top: 1rem;\n    height: auto;\n    text-align: center;\n    padding: 0.5rem 0;\n"])), palette("white"), palette("secondaryHighlight"));
var NoResultFound = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n"], ["\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n"])));
var Wrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1;\n    overflow: auto;\n"], ["\n    flex: 1;\n    overflow: auto;\n"])));
var FilterSelected = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #acaaa9;\n    padding: 0.5rem 2rem 0.5rem 0.5rem;\n    margin-right: 0.5rem;\n    border-radius: 4px;\n    position: relative;\n    margin-bottom: 0.5rem;\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #acaaa9;\n    padding: 0.5rem 2rem 0.5rem 0.5rem;\n    margin-right: 0.5rem;\n    border-radius: 4px;\n    position: relative;\n    margin-bottom: 0.5rem;\n"])));
var IconWrapper = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n    top: 50%;\n    right: 0.5rem;\n    transform: translateY(-50%);\n    background-color: transparent;\n    border-radius: 50%;\n    width: 20px;\n    height: 20px;\n    border: none;\n    cursor: pointer;\n\n    &:active {\n        background-color: rgba(0, 0, 0, 0.1);\n    }\n"], ["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n    top: 50%;\n    right: 0.5rem;\n    transform: translateY(-50%);\n    background-color: transparent;\n    border-radius: 50%;\n    width: 20px;\n    height: 20px;\n    border: none;\n    cursor: pointer;\n\n    &:active {\n        background-color: rgba(0, 0, 0, 0.1);\n    }\n"])));
function useQuery() {
    var search = useLocation().search;
    return React.useMemo(function () { return new URLSearchParams(search); }, [search]);
}
var ArticleList = function (_a) {
    var articles = _a.articles, basePath = _a.basePath, currentPage = _a.currentPage, filterDescription = _a.filterDescription, loading = _a.loading, pageCount = _a.pageCount, 
    // primaryCategory,
    fetchArticles = _a.fetchArticles, params = _a.params, ScrollingState = _a.ScrollingState, fetchType = _a.fetchType
    // cancelFilter
    ;
    var articlesLoaded = useSelector(selectLoadingAllComplete);
    var getSelectedFilter = useSelector(filterSelection);
    var id = useParams().id;
    var history = useHistory();
    var location = useLocation();
    var queryParams = useQuery();
    var primaryCategory = queryParams.get("primaryCategory");
    var category = queryParams.get("category");
    var wrapperRef = useRef(null);
    var dispatch = useDispatch();
    var _b = useState(getSelectedFilter), selectedItem = _b[0], setSelectedItem = _b[1];
    var onItemsClick = function () {
        dispatch(filterSelected(selectedItem));
    };
    var generateFilterQueryString = function (selectedItems) {
        var filterTypeMapping = {
            0: "brand",
            2: "style",
            3: "color",
            4: "advertising",
            6: "material",
            7: "size",
            8: "model",
        };
        var queryParameters = [];
        selectedItems.forEach(function (item) {
            var filterKey = filterTypeMapping[item.type];
            if (filterKey) {
                var encodedValue = encodeURIComponent(String(item.value).replace(/&/g, "%26")); // convert to string and replace & with %26
                queryParameters.push(filterKey + "=" + encodedValue);
            }
        });
        return queryParameters.join("&");
    };
    var cancelFilter = function (filterId) {
        setSelectedItem(function (prevSelectedItem) {
            var removeSelectedItem = prevSelectedItem.filter(function (item) { return item.name !== filterId; });
            var query = generateFilterQueryString(removeSelectedItem);
            // const filterType=query?'&fetchtype=filter':''
            history.push(process.env.PUBLIC_URL + "/sessions/" + id + "/results?category=" + category + "&primaryCategory=" + primaryCategory + "&" + query);
            fetchType = "filter";
            return removeSelectedItem;
        });
    };
    useEffect(function () {
        if (selectedItem) {
            dispatch(filterSelected(selectedItem));
        }
    }, [selectedItem, dispatch]);
    useEffect(function () {
        var fetch = function () {
            if (!loading) {
                var newParams = {
                    page: currentPage + 1,
                    filters: queryParams.toString()
                };
                fetchArticles(newParams);
            }
        };
        fetchType = "filter";
        fetch();
    }, [selectedItem]);
    useEffect(function () {
        var handleScroll = function () {
            var _a;
            var scrolled = ((_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.scrollTop) > 0;
            ScrollingState(scrolled);
        };
        var currentWrapper = wrapperRef.current;
        if (currentWrapper) {
            currentWrapper.addEventListener("scroll", handleScroll);
        }
        return function () {
            if (currentWrapper) {
                currentWrapper.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);
    useEffect(function () {
        var _a;
        var section = document.getElementById((_a = location.state) === null || _a === void 0 ? void 0 : _a.articleId);
        articlesLoaded &&
            section &&
            section.scrollIntoView({ behavior: "smooth", block: "start" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articlesLoaded]);
    var fetch = function () {
        if (!loading) {
            fetchArticles({ page: currentPage + 1 });
        }
    };
    var renderItems = function (articles, basePath) {
        return articles.map(function (article) {
            return (_jsx(ArticleListItem, { article: article, basePath: basePath, params: params }, article.id + "_" + article.color));
        });
    };
    var items = renderItems(articles, basePath);
    return (_jsxs(Wrapper, __assign({ ref: wrapperRef }, { children: [Boolean(getSelectedFilter) && (_jsx("div", { children: getSelectedFilter && (_jsx(FilterDescription, { children: selectedItem.map(function (item) { return (_jsxs(FilterSelected, { children: [item.name, _jsx(IconWrapper, __assign({ onClick: onItemsClick }, { children: _jsx(CancelButton, { customIcon: "closex", width: "10", fill: "black", action: function () { return cancelFilter(item.name); } }, void 0) }), void 0)] }, item.id)); }) }, void 0)) }, "filterDescription")), !loading && articles.length === 0 && (_jsx(NoResultFound, { children: "Keine Ergebnisse gefunden" }, "noResults")), _jsx(TileInfiniteScroll, { items: items, hasMore: currentPage < pageCount, currentPage: currentPage, loading: loading, loadMore: fetch }, void 0)] }), void 0));
};
export default ArticleList;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
