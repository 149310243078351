var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @flow
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getPriceString, hasDiscounts } from "@shared/utils/currency";
import { getImageUrl } from "@shared/utils/images";
import sortBy from "lodash/sortBy";
import { standardWmsSizes } from "@shared/utils/constants";
import SliderArticle from "molecules/SliderArticle";
import Spinner from "@shared/atoms/Spinner";
import Slider from "@shared/organisms/Slider";
import PriceTag from "@shared/molecules/PriceTag";
import DiscountBadgeMedium from "molecules/DiscountBadgeMedium";
import { selectLoading } from "@store/watchlist/selectors";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ArticleContainer, Block, BlockOne, BlockTwo, BrandImage, ColorSlider, Fullscreen, Headline, Light, LightInfo, Main, ModalImage, SliderWrapper, SlideSmall, Tag, WmsSize, Wrapper, } from "./style";
import NeedleSizeSelect from "molecules/SizeSelect/NeedleSizeSelect";
var InfoDetail = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    font-size: 1rem;\n    color: #000000;\n    margin: 0 10px 0 10px;\n"], ["\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    font-size: 1rem;\n    color: #000000;\n    margin: 0 10px 0 10px;\n"])));
var MainBlock = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n"], ["\n    display: flex;\n    flex-direction: column;\n"])));
var AttributeList = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n"], ["\n"])));
var ArticleView = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var article = _a.article, basePath = _a.basePath, choosingSize = _a.choosingSize, setChoosingSize = _a.setChoosingSize, currentSize = _a.currentSize, handleSizeSelect = _a.handleSizeSelect, params = _a.params, primaryCategoryId = _a.primaryCategoryId, createWatchlistItem = _a.createWatchlistItem, showingBranches = _a.showingBranches, setShowingBranches = _a.setShowingBranches, articleSizesWithAmounts = _a.articleSizesWithAmounts, watchlistItem = _a.watchlistItem, setSizeShowingBranches = _a.setSizeShowingBranches, ScrollingState = _a.ScrollingState, session = _a.session, requestWatchlist = _a.requestWatchlist, watchlist = _a.watchlist, theme = _a.theme, currentAssistant = _a.currentAssistant;
    var _m = useState(false), modalVisible = _m[0], setModalVisible = _m[1];
    var _o = useState(""), modalImage = _o[0], setModalImage = _o[1];
    var watchlistLoading = useSelector(selectLoading);
    var _p = useState(window.innerWidth), windowWidth = _p[0], setWindowWidth = _p[1];
    var _q = useState(""), errorMessage = _q[0], setErrorMessage = _q[1];
    useEffect(function () {
        var handleResize = function () { return setWindowWidth(window.innerWidth); };
        window.addEventListener("resize", handleResize);
        return function () { return window.removeEventListener("resize", handleResize); };
    }, []);
    var wrapperRef = useRef(null);
    var onActionsClick = function (articleId, sizeId, action, fromStoreId) {
        createWatchlistItem(articleId, sizeId, action, fromStoreId);
    };
    var toggleModal = function (image) {
        setModalImage(image);
        setModalVisible(!modalVisible);
    };
    var getColorVariants = function () {
        var variants = [];
        if (Boolean(article) &&
            Boolean(article.relatedArticles) &&
            article.relatedArticles.length > 0) {
            variants.push(article);
            variants = variants.concat(article.relatedArticles);
        }
        return variants;
    };
    var getDesiredPrices = function (article) {
        var firstAvailableSize = article.sizes.find(function (size) {
            return size.stock === "available";
        });
        if (firstAvailableSize) {
            return { min: firstAvailableSize.price, max: firstAvailableSize.price };
        }
        else {
            return article.prices;
        }
    };
    var getCurrentPrice = function () {
        if (currentSize) {
            var size = article.sizes.find(function (si) { return si.sizeId === currentSize.sizeId; });
            if (size) {
                if (size.price < size.targetPrice) {
                    return (_jsxs("span", { children: [_jsx(Tag, __assign({ crossed: true }, { children: getPriceString(size.targetPrice) }), void 0), " ", _jsx(Tag, __assign({ discount: true }, { children: getPriceString(size.price) }), void 0), " "] }, void 0));
                }
                return _jsx(Tag, { children: getPriceString(size.price) }, void 0);
            }
        }
        var desiredPrices = getDesiredPrices(article);
        return _jsx(PriceTag, { prices: desiredPrices, targetPrices: desiredPrices }, void 0);
    };
    var isDiscounted = function () {
        if (!article.prices && !article.targetPrices) {
            return false;
        }
        if (Boolean(currentSize) && currentSize) {
            return currentSize.price < currentSize.targetPrice;
        }
        return hasDiscounts(article.prices, article.targetPrices);
    };
    var FormattedMessageFixed = function (props) {
        return _jsx(FormattedMessage, __assign({}, props), void 0);
    };
    var colorVariants = getColorVariants();
    var articleWmsSize = "";
    var articleSizeTransId = "";
    try {
        if (undefined !== article.content) {
            var widthContent = article.content.filter(function (contentAttribute) { return contentAttribute.name === "width"; });
            articleWmsSize = widthContent.length
                ? standardWmsSizes[widthContent[0].value.toLowerCase()]
                : "";
            articleSizeTransId = "article.sizes." + articleWmsSize;
        }
    }
    catch (e) {
        articleWmsSize = "";
    }
    var mainImageSlide = article && article.images && (_jsx(SlideSmall, { children: _jsx("img", { style: {
                maxHeight: "6rem",
                width: "auto",
                cursor: "pointer",
            }, alt: "", src: article.images.large || ((_b = article.images.main) === null || _b === void 0 ? void 0 : _b.large), onClick: function () { var _a; return toggleModal(article.images.large || ((_a = article.images.main) === null || _a === void 0 ? void 0 : _a.large)); } }, void 0) }, getImageUrl(article.images.large || ((_c = article.images.main) === null || _c === void 0 ? void 0 : _c.large))));
    var ImageSlides = (_e = (_d = article === null || article === void 0 ? void 0 : article.images) === null || _d === void 0 ? void 0 : _d.detail) === null || _e === void 0 ? void 0 : _e.map(function (image) { return (_jsx(SlideSmall, __assign({ onClick: function () { return toggleModal(getImageUrl(image)); } }, { children: _jsx("img", { style: {
                maxHeight: "6rem",
                width: "auto",
                cursor: "pointer",
            }, alt: getImageUrl(image), src: image.thumbnail }, void 0) }), getImageUrl(image))); });
    var allSlides = [mainImageSlide].concat(ImageSlides);
    var articleName = article && ((_g = (_f = article.content) === null || _f === void 0 ? void 0 : _f.find(function (field) {
        return field.value && field.name === "articleName";
    })) === null || _g === void 0 ? void 0 : _g.value);
    if (!Boolean(article) || watchlistLoading) {
        return _jsx(Spinner, {}, void 0);
    }
    var branchSelectionEnabled = localStorage.getItem("branchSelectionEnabled") === "true";
    return (_jsx(Wrapper, __assign({ ref: wrapperRef }, { children: !choosingSize ? (_jsxs(ArticleContainer, { children: [_jsxs(Main, { children: [modalVisible && (_jsx(Fullscreen, __assign({ onClick: function () { return toggleModal(""); } }, { children: _jsx(ModalImage, { src: modalImage, alt: modalImage, style: {
                                    maxWidth: "100vw",
                                    margin: "auto",
                                    display: "flex",
                                    backgroundColor: "white",
                                    borderRadius: "5%",
                                    width: "90%",
                                } }, void 0) }), void 0)), _jsxs("div", __assign({ style: { position: "sticky", top: 0, backgroundColor: "#ffffff", zIndex: 1000 } }, { children: [((_h = article.v2) === null || _h === void 0 ? void 0 : _h.brandImage) &&
                                    _jsx(BrandImage, { src: ((_j = article.v2) === null || _j === void 0 ? void 0 : _j.brandImage) ? (_k = article.v2) === null || _k === void 0 ? void 0 : _k.brandImage : "" }, void 0), _jsx(SliderWrapper, { children: _jsx(Slider, __assign({ config: {
                                            slidesPerView: 3,
                                            navigation: false,
                                            centeredSlides: false,
                                            centerInsufficientSlides: true
                                        } }, { children: allSlides }), void 0) }, void 0), _jsxs(InfoDetail, { children: [isDiscounted() && _jsx(DiscountBadgeMedium, {}, void 0), _jsxs(MainBlock, __assign({ style: { width: "40%" } }, { children: [_jsx(Block, { children: article.brand && _jsx(Light, { children: article.brand.name }, void 0) }, void 0), _jsxs(BlockOne, { children: [articleName && _jsx(LightInfo, { children: articleName }, void 0), article.color && _jsxs(LightInfo, { children: [" ", article.color] }, void 0)] }, void 0), _jsx(Block, { children: Boolean(article.prices) && (_jsx("div", { children: _jsx(Headline, { children: getCurrentPrice() }, void 0) }, void 0)) }, void 0)] }), void 0), _jsx(MainBlock, __assign({ style: { width: "60%" } }, { children: _jsxs(BlockTwo, { children: [_jsxs(Light, { children: ["Fabart: ", article.fabart] }, void 0), _jsxs(Light, { children: ["ArtNr.: ", article.number] }, void 0)] }, void 0) }), void 0)] }, void 0), articleWmsSize && (_jsxs(WmsSize, { children: [_jsx(FormattedMessage, { id: "article.width", defaultMessage: "Weite" }, void 0), ":", " ", _jsx(FormattedMessageFixed, { id: articleSizeTransId, defaultMessage: articleWmsSize }, void 0)] }, void 0)), _jsx("div", __assign({ style: { marginTop: "1rem" } }, { children: _jsx(NeedleSizeSelect, { branchSelectionEnabled: branchSelectionEnabled, sizes: article === null || article === void 0 ? void 0 : article.sizes, stocksForSize: articleSizesWithAmounts, onSizeSelect: function (size) {
                                            handleSizeSelect(size);
                                        }, onRequestButtonClicked: function (size, store) { return __awaiter(void 0, void 0, void 0, function () {
                                            var existingSize;
                                            return __generator(this, function (_a) {
                                                if (watchlist.length > 0 && watchlist[0].requestState !== "inDifferentStore") {
                                                    setErrorMessage("Bitte erst Warenkorb abschliessen.");
                                                    return [2 /*return*/];
                                                }
                                                if (!store && branchSelectionEnabled) {
                                                    setErrorMessage("Bitte wählen Sie eine Filale aus!");
                                                    return [2 /*return*/];
                                                }
                                                existingSize = watchlist.find(function (item) { return item.sizeId === size.sizeId && item.articleId === article.articleId; });
                                                if (existingSize) {
                                                    setErrorMessage("Die Größe befindet sich bereits im Warenkorb!");
                                                    return [2 /*return*/];
                                                }
                                                onActionsClick(article.articleId, size.sizeId, "inDifferentStore", store === null || store === void 0 ? void 0 : store.id);
                                                handleSizeSelect(null);
                                                return [2 /*return*/];
                                            });
                                        }); }, onReserveButtonClicked: function (size) { return __awaiter(void 0, void 0, void 0, function () {
                                            var existingSize;
                                            return __generator(this, function (_a) {
                                                if (watchlist.length > 0 && watchlist[0].requestState !== "reserve-button") {
                                                    setErrorMessage("Bitte erst Warenkorb abschliessen.");
                                                    return [2 /*return*/];
                                                }
                                                existingSize = watchlist.find(function (item) { return item.sizeId === size.sizeId && item.articleId === article.articleId; });
                                                if (existingSize) {
                                                    setErrorMessage("Die Größe befindet sich bereits im Warenkorb!");
                                                    return [2 /*return*/];
                                                }
                                                if ((size === null || size === void 0 ? void 0 : size.stock) !== "available") {
                                                    setErrorMessage("Die Größe hat keinen Bestand in deiner Filiale!");
                                                    return [2 /*return*/];
                                                }
                                                onActionsClick(article.articleId, size.sizeId, "reserve-button");
                                                handleSizeSelect(null);
                                                return [2 /*return*/];
                                            });
                                        }); }, onBuyButtonClicked: function (size) { return __awaiter(void 0, void 0, void 0, function () {
                                            var existingSize;
                                            return __generator(this, function (_a) {
                                                if (watchlist.length > 0 && watchlist[0].requestState !== "checkout-button") {
                                                    setErrorMessage("Bitte erst Warenkorb abschliessen.");
                                                    return [2 /*return*/];
                                                }
                                                existingSize = watchlist.find(function (item) { return item.sizeId === size.sizeId && item.articleId === article.articleId; });
                                                if (existingSize) {
                                                    setErrorMessage("Die Größe befindet sich bereits im Warenkorb!");
                                                    return [2 /*return*/];
                                                }
                                                if ((size === null || size === void 0 ? void 0 : size.stock) !== "available") {
                                                    setErrorMessage("Die Größe hat keinen Bestand in deiner Filiale!");
                                                    return [2 /*return*/];
                                                }
                                                onActionsClick(article.articleId, size.sizeId, "checkout-button");
                                                handleSizeSelect(null);
                                                return [2 /*return*/];
                                            });
                                        }); } }, void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ hidden: !!currentSize, style: { position: "relative" } }, { children: [_jsx("div", __assign({ hidden: !!currentSize, style: { position: "relative" } }, { children: Boolean(colorVariants) && colorVariants.length > 0 && (_jsx(_Fragment, { children: _jsxs(ColorSlider, { children: [_jsx("div", __assign({ style: {
                                                        display: "flex",
                                                        fontSize: "1.25rem",
                                                        justifyContent: "start",
                                                        borderTop: "1px solid #e0e0e0",
                                                        padding: "0.75rem 0",
                                                        fontWeight: 500
                                                    } }, { children: "Varianten" }), void 0), _jsx(Slider, __assign({ customPaddingLeft: "1%", config: {
                                                        slidesPerView: windowWidth > 539 ? colorVariants.length : 3,
                                                        navigation: false,
                                                    } }, { children: sortBy(colorVariants, "id").map(function (colorVariant) { return (_jsx("div", __assign({ style: { width: "104px" }, onClick: function () {
                                                            handleSizeSelect(null);
                                                        } }, { children: _jsx(SlideSmall, __assign({ style: { position: "relative" } }, { children: _jsx(SliderArticle, { article: colorVariant, basePath: basePath, active: colorVariant.id === article.id, params: params }, void 0) }), "related" + colorVariant.id) }), colorVariant.id)); }) }), void 0)] }, void 0) }, void 0)) }), void 0), _jsx("div", __assign({ style: {
                                        marginTop: "1rem"
                                    } }, { children: _jsx("div", __assign({ style: {
                                            display: "flex",
                                            fontSize: "1.25rem",
                                            justifyContent: "start",
                                            borderTop: "1px solid #e0e0e0",
                                            padding: "0.75rem 0",
                                            fontWeight: 500
                                        } }, { children: "Produktbeschreibung" }), void 0) }), void 0), _jsx("div", { children: _jsx(AttributeList, { children: (_l = article === null || article === void 0 ? void 0 : article.content) === null || _l === void 0 ? void 0 : _l.map(function (c, index) {
                                            var localC = c;
                                            return (_jsxs("div", __assign({ style: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginBottom: "1rem"
                                                } }, { children: [_jsx("div", __assign({ style: { fontWeight: 500, fontSize: "0.75rem" } }, { children: _jsx(FormattedMessage, { id: "prop." + localC.name }, void 0) }), void 0), _jsx("div", __assign({ style: {} }, { children: localC.value }), void 0)] }), index));
                                        }) }, void 0) }, void 0)] }), void 0)] }, void 0), _jsx("div", __assign({ hidden: errorMessage === "" }, { children: _jsx("div", __assign({ style: {
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0,0,0,0.6)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 10000,
                        } }, { children: _jsxs("div", __assign({ style: {
                                backgroundColor: "#fff",
                                padding: "1rem",
                                borderRadius: "1rem",
                                width: "90%",
                                maxWidth: "500px",
                                boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.2)"
                            } }, { children: [_jsx("p", { children: errorMessage }, void 0), _jsx("button", __assign({ onClick: function () { return setErrorMessage(""); }, style: {
                                        background: "#737679",
                                        color: "#ffffff",
                                        border: "none",
                                        padding: "10px 20px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        marginTop: "15px",
                                    } }, { children: "Schlie\u00DFen" }), void 0)] }), void 0) }), void 0) }), void 0)] }, void 0)) : (_jsx(_Fragment, {}, void 0)) }), void 0));
};
export default ArticleView;
var templateObject_1, templateObject_2, templateObject_3;
