// @flow
import { handleActions } from 'redux-actions';
import { List, Map } from 'immutable';
import { closeNotification, notify } from 'services/worker';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    all: List(),
    allPage: 0,
    allPageCount: 0,
    archive: List(),
    archivePage: 0,
    archivePageCount: 0,
    assigned: List(),
    assignedNotificationTag: null,
    assignedCount: 0,
    assignedPage: 0,
    assignedPageCount: 0,
    checkingAssigned: false,
    checkingOut: false,
    checkingUnassigned: false,
    checkoutNumber: null,
    creating: false,
    data: List(),
    deleting: false,
    loaded: false,
    loadedAll: false,
    loadedArchive: false,
    loadedAssigned: false,
    loadedUnassigned: false,
    loading: false,
    loadingAll: false,
    loadingArchive: false,
    loadingAssigned: false,
    loadingUnassigned: false,
    unassigned: List(),
    unassignedCount: 0,
    unassignedNotificationTag: null,
    unassignedPage: 0,
    unassignedPageCount: 0,
    updating: false,
    archiving: false,
    sharing: false,
});
var updateSession = function (session) { return function (existingSessions) {
    // $FlowIgnoreNextLine
    var index = existingSessions.findIndex(function (existingSession) { return existingSession.get('uuid') === session.get('uuid'); });
    if (index === -1) {
        // $FlowIgnoreNextLine
        return existingSessions.push(session);
    }
    // $FlowIgnoreNextLine
    return existingSessions.set(index, session);
}; };
var updateSessions = function (sessions) { return function (existingSessions) {
    // $FlowIgnoreNextLine
    if (existingSessions.size === 0) {
        return sessions;
    }
    var newSessions = existingSessions;
    sessions.forEach(function (session) {
        // $FlowIgnoreNextLine
        newSessions = newSessions.update(updateSession(session));
    });
    return newSessions;
}; };
var updateIdCollection = function (sessions, samePage) {
    if (samePage === void 0) { samePage = false; }
    return function (existingIds) {
        var sessionIds = sessions.reduce(function (acc, session) { return acc.push(session.get('uuid')); }, List());
        // $FlowIgnoreNextLine
        if (existingIds.size === 0 || samePage) {
            return sessionIds;
        }
        // $FlowIgnoreNextLine
        return existingIds.toSet().union(sessionIds.toSet()).toList();
    };
};
var newRequestNotification = function (state, action) {
    var unassignedCount = parseInt(state.get('unassignedCount'), 10);
    var actualCount = parseInt(action.payload['x-pagination-total-count'], 10);
    var tag = state.get('unassignedNotificationTag');
    if (unassignedCount !== actualCount &&
        unassignedCount < actualCount &&
        action.meta.shouldSend) {
        if (tag !== null) {
            closeNotification(tag);
        }
        tag = "unassigned" + state.hashCode();
        notify('Neuer Service Request', {
            body: 'Es wurde ein neuer Service Request aufgegeben',
            tag: tag,
        });
    }
    if (tag !== null &&
        unassignedCount !== actualCount &&
        unassignedCount > actualCount) {
        closeNotification(tag);
        tag = null;
    }
    return state
        .set('unassignedCount', actualCount)
        .set('unassignedNotificationTag', tag);
};
var newAssignNotification = function (state, action) {
    var assignedCount = parseInt(state.get('assignedCount'), 10);
    var actualCount = parseInt(action.payload['x-pagination-total-count'], 10);
    var tag = state.get('assignedNotificationTag');
    if (assignedCount !== actualCount &&
        assignedCount < actualCount &&
        action.meta.shouldSend) {
        if (tag !== null) {
            closeNotification(tag);
        }
        tag = "assigned" + state.hashCode();
        notify('Kundenvorgang zugewiesen', {
            body: 'Ihnen wurde ein Kundenvorgang zugewiesen',
            tag: tag,
        });
    }
    if (tag !== null &&
        assignedCount !== actualCount &&
        assignedCount > actualCount) {
        closeNotification(tag);
        tag = null;
    }
    return state
        .set('assignedCount', actualCount)
        .set('assignedNotificationTag', tag);
};
var reducer = handleActions({
    AQUIRE_CALL: function (state) { return state.set('updating', true); },
    AQUIRE_CALL_COMPLETE: {
        next: function (state, action) {
            var sessionId = action.payload.get('uuid');
            return (state
                .set('updating', false)
                .update('unassigned', function (unassignedIds) {
                // $FlowIgnoreNextLine
                return unassignedIds.filterNot(function (id) { return id === sessionId; });
            })
                // $FlowIgnoreNextLine
                .update('assigned', function (assignedIds) { return assignedIds.push(sessionId); })
                .set('assignedCount', state.get('assignedCount') + 1)
                // $FlowIgnoreNextLine
                .set('unassignedCount', state.get('unassignedCount') - 1));
        },
        throw: function (state) {
            return state.set('updating', false);
        },
    },
    ASSIGN_SESSION: function (state) { return state.set('updating', true); },
    ASSIGN_SESSION_COMPLETE: {
        next: function (state, action) {
            var sessionType = action.meta.type;
            var sessionId = action.payload.get('uuid');
            return state
                .set('updating', false)
                .update('unassigned', function (unassignSessionIds) {
                // $FlowIgnoreNextLine
                return unassignSessionIds.filterNot(function (id) { return id === sessionId; });
            })
                .update('assigned', function (assignSessionIds) {
                // $FlowIgnoreNextLine
                return assignSessionIds.filterNot(function (id) { return id === sessionId; });
            })
                .update('all', function (allSessionIds) {
                // $FlowIgnoreNextLine
                return allSessionIds.filterNot(function (id) { return id === sessionId; });
            })
                .update('archive', function (archvieSessionIds) {
                // $FlowIgnoreNextLine
                return archvieSessionIds.filterNot(function (id) { return id === sessionId; });
            })
                .update(sessionType, function (sessionTypeIds) {
                return sessionTypeIds.push(sessionId);
            })
                .update('data', updateSession(action.payload));
        },
        throw: function (state) {
            return state.set('updating', false);
        },
    },
    ASSIGN_TERMINAL: function (state) { return state.set('updating', true); },
    ASSIGN_TERMINAL_COMPLETE: {
        next: function (state, action) {
            return state
                .set('updating', false)
                .update('data', updateSession(action.payload));
        },
        throw: function (state) {
            return state.set('updating', false);
        },
    },
    CHECK_ASSIGNED: function (state) { return state.set('checkingAssigned', true); },
    CHECK_ASSIGNED_COMPLETE: {
        next: function (state, action) {
            return newAssignNotification(state, action);
        },
        throw: function (state) {
            return state;
        },
    },
    CHECKOUT: function (state) { return state.set('checkingOut', true); },
    CHECKOUT_COMPLETE: {
        next: function (state, action) {
            return state
                .set('checkingOut', false)
                .set('checkoutNumber', action.payload.checkoutNumber);
        },
        throw: function (state) {
            return state.set('checkingOut', false);
        },
    },
    CHECK_UNASSIGNED: function (state) { return state.set('checkingUnassigned', true); },
    CHECK_UNASSIGNED_COMPLETE: {
        next: function (state, action) {
            return newRequestNotification(state, action);
        },
        throw: function (state) {
            return state;
        },
    },
    CREATE_SESSION: function (state) { return state.set('creating', true); },
    CREATE_SESSION_COMPLETE: {
        next: function (state, action) {
            return (state
                .set('creating', false)
                .set('assignedCount', state.get('assignedCount') + 1)
                //     // $FlowIgnoreNextLine
                .update('data', function (sessions) { return sessions.push(action.payload); }));
        },
        throw: function (state) {
            return state.set('creating', false);
        },
    },
    DELETE_SESSION: function (state) { return state.set('deleting', true); },
    DELETE_SESSION_COMPLETE: {
        next: function (state, action) {
            var sessionId = action.payload.get('uuid');
            return state
                .set('deleting', false)
                .update('unassigned', function (unassignSessionIds) {
                // $FlowIgnoreNextLine
                return unassignSessionIds.filterNot(function (id) { return id === sessionId; });
            })
                .update('assigned', function (assignSessionIds) {
                // $FlowIgnoreNextLine
                return assignSessionIds.filterNot(function (id) { return id === sessionId; });
            })
                .update('all', function (allSessionIds) {
                // $FlowIgnoreNextLine
                return allSessionIds.filterNot(function (id) { return id === sessionId; });
            })
                .update('archive', function (archvieSessionIds) {
                // $FlowIgnoreNextLine
                return archvieSessionIds.filterNot(function (id) { return id === sessionId; });
            })
                .update('archive', function (archiveSessionIds) {
                // $FlowIgnoreNextLine
                return archiveSessionIds.push(sessionId);
            })
                .update('data', updateSession(action.payload));
        },
        throw: function (state) {
            return state.set('deleting', false);
        },
    },
    GET_SESSION: function (state) { return state.set('loading', true); },
    GET_SESSION_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .update('data', updateSession(action.payload));
        },
        throw: function (state) {
            return state.set('loading', false).set('loaded', false);
        },
    },
    GET_SESSIONS_ALL: function (state) { return state.set('loadingAll', true); },
    GET_SESSIONS_ALL_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .set('allPage', parseInt(action.meta.headers['x-pagination-current-page'], 10))
                .set('allPageCount', parseInt(action.meta.headers['x-pagination-page-count'], 10))
                .update('all', updateIdCollection(action.payload))
                .update('data', updateSessions(action.payload));
        },
        throw: function (state) {
            return state.set('loadingAll', false).set('loadedAll', false);
        },
    },
    GET_SESSIONS_ARCHIVE: function (state) { return state.set('loadingArchive', true); },
    GET_SESSIONS_ARCHIVE_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingArchive', false)
                .set('loadedArchive', true)
                .set('archivePage', parseInt(action.meta.headers['x-pagination-current-page'], 10))
                .set('archivePageCount', parseInt(action.meta.headers['x-pagination-page-count'], 10))
                .update('archive', updateIdCollection(action.payload))
                .update('data', updateSessions(action.payload));
        },
        throw: function (state) {
            return state.set('loadingArchive', false).set('loadedArchive', true);
        },
    },
    GET_SESSIONS_ASSIGNED: function (state) { return state.set('loadingAssigned', true); },
    GET_SESSIONS_ASSIGNED_COMPLETE: {
        next: function (state, action) {
            var samePage = state.get('assignedPage') ===
                parseInt(action.meta.headers['x-pagination-current-page'], 10);
            return state
                .set('loadingAssigned', false)
                .set('loadedAssigned', true)
                .set('assignedPage', parseInt(action.meta.headers['x-pagination-current-page'], 10))
                .set('assignedPageCount', parseInt(action.meta.headers['x-pagination-page-count'], 10))
                .update('assigned', updateIdCollection(action.payload, samePage))
                .update('data', updateSessions(action.payload));
        },
        throw: function (state) {
            return state.set('loadingAssigned', false).set('loadedAssigned', false);
        },
    },
    GET_SESSIONS_UNASSIGNED: function (state) {
        return state.set('loadingUnassigned', true);
    },
    GET_SESSIONS_UNASSIGNED_COMPLETE: {
        next: function (state, action) {
            var samePage = state.get('unassignedPage') ===
                parseInt(action.meta.headers['x-pagination-current-page'], 10);
            return state
                .set('loadingUnassigned', false)
                .set('loadedUnassigned', true)
                .set('unassignedPage', parseInt(action.meta.headers['x-pagination-current-page'], 10))
                .set('unassignedPageCount', parseInt(action.meta.headers['x-pagination-page-count'], 10))
                .update('unassigned', updateIdCollection(action.payload, samePage))
                .update('data', updateSessions(action.payload));
        },
        throw: function (state) {
            return state
                .set('loadingUnassigned', false)
                .set('loadedUnassigned', false);
        },
    },
    UNLEASH_DEVICE: function (state) { return state.set('updating', true); },
    UNLEASH_DEVICE_COMPLETE: {
        next: function (state, action) {
            return state
                .set('updating', false)
                .update('data', updateSession(action.payload));
        },
        throw: function (state) {
            return state.set('updating', false);
        },
    },
    UPDATE_SESSION: function (state) { return state.set('updating', true); },
    UPDATE_SESSION_COMPLETE: {
        next: function (state, action) {
            return state
                .set('updating', false)
                .update('data', updateSession(action.payload));
        },
        throw: function (state) {
            return state.set('updating', false);
        },
    },
    UNLEASH_ASSISTANT: function (state) { return state.set('updating', true); },
    UNLEASH_ASSISTANT_COMPLETE: {
        next: function (state, action) {
            var sessionId = action.payload.get('uuid');
            return (state
                .set('updating', false)
                .update('data', updateSession(action.payload))
                .update('assigned', function (assignedIds) {
                // $FlowIgnoreNextLine
                return assignedIds.filterNot(function (id) { return id === sessionId; });
            })
                // $FlowIgnoreNextLine
                .update('all', function (allIds) { return allIds.push(sessionId); }));
        },
        throw: function (state) {
            return state.set('updating', false);
        },
    },
    ARCHIVE_SESSION: function (state) { return state.set('archiving', true); },
    ARCHIVE_SESSION_COMPLETE: function (state) { return state.set('archiving', false); },
    SHARE_SESSION: function (state) { return state.set('sharing', true); },
    SHARE_SESSION_COMPLETE: function (state) { return state.set('sharing', false); },
}, initialState);
export default reducer;
