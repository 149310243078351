var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @flow
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { fontSize, fontWeight, palette } from "@shared/utils/styled";
import styled, { withTheme } from "styled-components";
import HorizontalFlex from "@shared/atoms/HorizontalFlex";
import WatchlistInfo from "components/organisms/WatchlistInfo";
// Selectors
import { useSelector } from "react-redux";
import { selectCurrentUserId } from '@store/authorize/selectors';
import HeaderLogo from "../../../assistant/headerLogo/components/HeaderLogo";
import BurgerMenu from "atoms/BurgerMenu";
import SessionMenu from "molecules/SessionMenu";
import NavigationHeader from "molecules/SessionHeader/NavigationHeader";
var Wrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    justify-content: center;\n    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);\n    align-items: center;\n    background-color: ", ";\n    color: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n    height: 4.375rem;\n    letter-spacing: 0.025rem;\n    width: 100%;\n    padding: ", ";\n    transition: opacity 0.5s ease, max-height 0.5s ease, transform 0.5s ease;\n    opacity: ", ";\n    max-height: ", ";\n    overflow: hidden;\n    visibility: ", ";\n"], ["\n    display: flex;\n    justify-content: center;\n    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);\n    align-items: center;\n    background-color: ", ";\n    color: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n    height: 4.375rem;\n    letter-spacing: 0.025rem;\n    width: 100%;\n    padding: ", ";\n    transition: opacity 0.5s ease, max-height 0.5s ease, transform 0.5s ease;\n    opacity: ", ";\n    max-height: ", ";\n    overflow: hidden;\n    visibility: ", ";\n"])), palette("white"), palette("white"), fontSize("large"), fontWeight("bold"), function (_a) {
    var isVisible = _a.isVisible;
    return (isVisible ? "1rem" : "0 1rem");
}, function (_a) {
    var isVisible = _a.isVisible;
    return (isVisible ? 1 : 0);
}, function (_a) {
    var isVisible = _a.isVisible;
    return (isVisible ? "100px" : "0");
}, function (_a) {
    var isVisible = _a.isVisible;
    return (isVisible ? "visible" : "hidden");
});
var MenuIcons = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    justify-content: flex-end;\n    width: 100%;\n"], ["\n    display: flex;\n    justify-content: flex-end;\n    width: 100%;\n"])));
var SessionHeader = function (_a) {
    var _b, _c;
    var assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, terminals = _a.terminals, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, location = _a.location, hasFilters = _a.hasFilters, theme = _a.theme, watchlist = _a.watchlist, session = _a.session, deleteWatchlist = _a.deleteWatchlist, deleteWatchlistItem = _a.deleteWatchlistItem, requestWatchlist = _a.requestWatchlist, isOpen = _a.isOpen, istop = _a.istop, isArticleListScrolled = _a.isArticleListScrolled, showMenuIcons = _a.showMenuIcons, logout = _a.logout;
    var _d = useState(false), menuVisible = _d[0], setMenuVisible = _d[1];
    var getCurrentUserId = useSelector(selectCurrentUserId);
    var isOpenTag = (_c = (_b = (location.state && location.state.showModal)) !== null && _b !== void 0 ? _b : isOpen) !== null && _c !== void 0 ? _c : false;
    var toggleMenu = function () {
        setMenuVisible(!menuVisible);
    };
    var handleLogout = function () {
        logout(getCurrentUserId);
    };
    var renderWatchlist = function () {
        return (_jsx(WatchlistInfo, { deleteWatchlist: deleteWatchlist, deleteWatchlistItem: deleteWatchlistItem, isOpen: isOpenTag, session: session, watchlist: watchlist, requestWatchlist: requestWatchlist, theme: theme }, void 0));
    };
    var renderTopMenu = function () {
        return (_jsxs(_Fragment, { children: [_jsx(NavigationHeader, { theme: theme, cart: watchlist, sessionId: session.uuid || session, onLogoutButtonClicked: handleLogout, clearCart: deleteWatchlist, deleteCartItem: deleteWatchlistItem }, void 0), _jsx("div", __assign({ hidden: true }, { children: _jsxs(Wrapper, __assign({ isVisible: !isArticleListScrolled }, { children: [_jsx(HeaderLogo, {}, void 0), showMenuIcons && (_jsxs(MenuIcons, { children: [renderWatchlist(), _jsx(BurgerMenu, { menuVisible: menuVisible, toggleMenu: toggleMenu }, void 0)] }, void 0)), _jsx(SessionMenu, { assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, currentAssistantId: currentAssistantId, deleteSession: deleteSession, session: session, terminals: terminals, toggleMenu: toggleMenu, unleashAssistant: function () { return unleashAssistant(session); }, unleashTerminal: function () { return unleashTerminal(session); }, visible: menuVisible, logout: handleLogout }, void 0)] }), void 0) }), void 0)] }, void 0));
    };
    return _jsx(_Fragment, { children: renderTopMenu() }, void 0);
};
export default withTheme(withRouter(SessionHeader));
var templateObject_1, templateObject_2;
