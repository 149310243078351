var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import FilterOptionListItem from 'molecules/FilterOptionListItem';
import Spinner from '@shared/atoms/Spinner';
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-y: scroll;\n  flex: 1;\n  padding-bottom: 0.625rem;\n"], ["\n  overflow-y: scroll;\n  flex: 1;\n  padding-bottom: 0.625rem;\n"])));
var FilterOptionList = function (_a) {
    var deselectFilterItem = _a.deselectFilterItem, filterId = _a.filterId, filterItems = _a.filterItems, filterLoading = _a.filterLoading, isOptionActive = _a.isOptionActive, selectFilterItem = _a.selectFilterItem, filterName = _a.filterName;
    if (filterLoading) {
        return _jsx(Spinner, {}, void 0);
    }
    return (_jsx(Wrapper, { children: Boolean(filterItems) &&
            filterItems.map(function (filterOption) {
                var active = isOptionActive(filterOption.id);
                var actionData = {
                    filterId: filterId,
                    filterOptionId: filterOption.id,
                    filterOptionName: filterOption.displayName,
                };
                return (_jsx(FilterOptionListItem, { handleClick: function () {
                        return active
                            ? deselectFilterItem(actionData)
                            : selectFilterItem(actionData);
                    }, filterOption: filterOption, active: active, filterName: filterName }, filterOption.id));
            }) }, void 0));
};
export default FilterOptionList;
var templateObject_1;
