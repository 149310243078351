var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import errorHandler from 'services/connection';
import history from '@store/history';
import store from 'store';
import { articleNotFound, getArticle as getArticleAction, getArticleComplete, getArticles as getArticlesAction, getArticlesComplete, resetArticles, resetLoading, searchArticle as searchArticleAction, searchArticleComplete, setArticleParams, updateArticle as updateArticleAction, updateArticleComplete, } from './actions';
import { selectArticlePage, selectArticlesApiParams } from './selectors';
var currency = store.get('currency') || 'EUR';
export function getArticles(api, action) {
    var categoryId, primaryCategoryId, fetchArticle, page, previousPage, currentApiParams, prevApiParams, _a, body, headers, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                categoryId = action.payload.category;
                primaryCategoryId = action.payload.primaryCategory;
                fetchArticle = action.payload.fetch;
                page = action.payload.page;
                return [4 /*yield*/, select(selectArticlePage)];
            case 1:
                previousPage = _b.sent();
                if (page) {
                    delete action.payload.page;
                }
                currentApiParams = JSON.stringify(action.payload);
                return [4 /*yield*/, select(selectArticlesApiParams)];
            case 2:
                prevApiParams = _b.sent();
                if (!(currentApiParams === prevApiParams && page <= previousPage)) return [3 /*break*/, 4];
                return [4 /*yield*/, put(resetLoading())];
            case 3:
                _b.sent();
                return [2 /*return*/];
            case 4:
                if (!(page === 1)) return [3 /*break*/, 6];
                return [4 /*yield*/, put(resetArticles())];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6:
                _b.trys.push([6, 10, , 12]);
                return [4 /*yield*/, call([api, api.get], 'v2/article-search', {
                        params: __assign(__assign({ pageSize: 20 }, action.payload), { page: page, currency: currency }),
                    })];
            case 7:
                _a = _b.sent(), body = _a.body, headers = _a.headers;
                return [4 /*yield*/, put(getArticlesComplete(body, headers, categoryId, primaryCategoryId, fetchArticle))];
            case 8:
                _b.sent();
                return [4 /*yield*/, put(setArticleParams(JSON.stringify(action.payload)))];
            case 9:
                _b.sent();
                return [3 /*break*/, 12];
            case 10:
                error_1 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_1, getArticlesComplete, getArticlesAction, action.payload))];
            case 11:
                _b.sent();
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
export function getArticle(api, action) {
    var body, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call([api, api.get], "articles/" + action.payload, {
                        params: { currency: currency },
                    })];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(getArticleComplete(fromJS(__assign(__assign({}, body), { singleLoaded: true }))))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_2 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_2, getArticleComplete, getArticleAction, action.payload))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function searchArticle(api, action) {
    var body, error_3;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 6, , 8]);
                return [4 /*yield*/, call([api, api.get], "articles/search", {
                        params: (_a = { source: 'scan', currency: currency }, _a[action.meta.param] = action.payload, _a),
                    })];
            case 1:
                body = (_b.sent()).body;
                if (!((Array.isArray(body) && !body.length) || !body.id)) return [3 /*break*/, 3];
                return [4 /*yield*/, put(articleNotFound())];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, put(searchArticleComplete(fromJS(__assign(__assign({}, body), { singleLoaded: true }))))];
            case 4:
                _b.sent();
                history.push(action.meta.sessionPath + "articles/" + body.id + "?source=scan");
                _b.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_3 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_3, searchArticleComplete, searchArticleAction, action.payload))];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function scanArticle(api, action) {
    var body, error_4;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 7, , 9]);
                return [4 /*yield*/, call([api, api.get], "articles/search", {
                        params: (_a = {
                                source: 'scan',
                                currency: currency
                            },
                            _a[action.meta.param] = action.payload,
                            _a),
                    })];
            case 1:
                body = (_b.sent()).body;
                if (!(0 === body.length)) return [3 /*break*/, 3];
                return [4 /*yield*/, put(articleNotFound())];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3: return [4 /*yield*/, put(searchArticleComplete(fromJS(__assign(__assign({}, body), { singleLoaded: true }))))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(history.push, action.meta.sessionPath + "articles/" + body.id)];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [3 /*break*/, 9];
            case 7:
                error_4 = _b.sent();
                return [4 /*yield*/, put(articleNotFound())];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function updateArticle(api, action) {
    var payload, meta, body, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                payload = action.payload, meta = action.meta;
                return [4 /*yield*/, call([api, api.put], "articles/" + meta.articleId, payload, { params: { currency: currency } })];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(updateArticleComplete(fromJS(body)))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_5 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_5, updateArticleComplete, updateArticleAction, action.payload, action.meta.articleId))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function getArticlesWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('GET_ARTICLES', getArticles, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function getArticleWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('GET_ARTICLE', getArticle, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function searchArticleWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('SEARCH_ARTICLE', searchArticle, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function scanArticleWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('SCAN_ARTICLE', scanArticle, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function updateArticleWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('UPDATE_ARTICLE', updateArticle, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
