var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '@shared/utils/immutable';
// actions
import { updateWatchlistItem } from '@store/watchlist/actions';
import { updateSession } from '@store/session/actions';
import { getStores } from '@store/store/actions';
// selectors
import { selectSortedStores, selectShouldLoadAll as shouldLoadStores, selectCurrentStoreId, } from '@store/store/selectors';
// components
import SessionContainer from 'containers/SessionContainer';
import Page from 'pages/CheckoutDelivery';
var CheckoutDeliveryComp = /** @class */ (function (_super) {
    __extends(CheckoutDeliveryComp, _super);
    function CheckoutDeliveryComp() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CheckoutDeliveryComp.prototype.componentDidMount = function () {
        if (this.props.shouldLoadStores) {
            this.props.getStores();
        }
    };
    CheckoutDeliveryComp.prototype.render = function () {
        var _this = this;
        return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { updateWatchlistItem: function (payload, id) {
                    return _this.props.updateWatchlistItem(payload, id, sessionProps.session.uuid);
                }, updateSession: _this.props.updateSession, stores: _this.props.sortedStores, currentStoreId: _this.props.currentStoreId }), void 0)); } }, void 0));
    };
    return CheckoutDeliveryComp;
}(Component));
export { CheckoutDeliveryComp };
var select = function () { return function (state) { return ({
    currentStoreId: selectCurrentStoreId(state),
    shouldLoadStores: shouldLoadStores(state),
    sortedStores: selectSortedStores(state),
}); }; };
export default connect(select, {
    updateWatchlistItem: updateWatchlistItem,
    getStores: getStores,
    updateSession: updateSession,
})(toJS(CheckoutDeliveryComp));
