var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import NavigationHeader from "molecules/SessionHeader/NavigationHeader";
import { defineMessages, useIntl } from "react-intl";
import Icon from "@shared/atoms/Icon";
var OpenSessionsWrapper = function (_a) {
    var theme = _a.theme, cart = _a.cart, onLogoutButtonClicked = _a.onLogoutButtonClicked, sessions = _a.sessions, sessionId = _a.sessionId, fetchSessions = _a.fetchSessions, onAssignRequest = _a.onAssignRequest, deleteCartItem = _a.deleteCartItem, clearCart = _a.clearCart;
    var intl = useIntl();
    useEffect(function () {
        var interval = setInterval(function () {
            fetchSessions(0);
        }, 15000);
        return function () { return clearInterval(interval); };
    }, []);
    var messages = defineMessages({
        open: { defaultMessage: 'Übernehmen', id: 'session.open.open' },
        noResult: {
            defaultMessage: 'Keine neuen Vorgänge',
            id: 'session.open.noResult',
        },
    });
    return (_jsxs("div", { children: [_jsx(NavigationHeader, { theme: theme, cart: cart, onLogoutButtonClicked: onLogoutButtonClicked, sessionId: sessionId, clearCart: clearCart, deleteCartItem: deleteCartItem }, void 0), _jsxs("div", __assign({ style: { padding: '1rem' } }, { children: [_jsx("div", __assign({ hidden: (sessions === null || sessions === void 0 ? void 0 : sessions.length) > 0 }, { children: _jsxs("div", __assign({ style: {
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: 500,
                                fontSize: "1.5rem"
                            } }, { children: [_jsx(Icon, { icon: 'noRequests', width: 300 }, void 0), intl.formatMessage(messages.noResult)] }), void 0) }), void 0), _jsxs("div", __assign({ hidden: (sessions === null || sessions === void 0 ? void 0 : sessions.length) < 1 }, { children: [_jsx("div", __assign({ style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: 500,
                                    fontSize: "1.5rem"
                                } }, { children: "Beratung" }), void 0), _jsx("div", __assign({ style: { padding: "1rem", display: "flex", flexDirection: "column", gap: "1rem" } }, { children: sessions === null || sessions === void 0 ? void 0 : sessions.map(function (session) {
                                    return (_jsxs("div", __assign({ style: {
                                            display: "grid",
                                            gridTemplateColumns: "33% 33% 33%",
                                            padding: "1rem",
                                            borderRadius: "1rem",
                                            border: "1px solid #e0e0e0",
                                            boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.2)"
                                        } }, { children: [_jsxs("div", __assign({ style: {
                                                    fontSize: "1rem",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "start",
                                                    alignItems: "start"
                                                } }, { children: [_jsx("div", __assign({ style: { fontSize: "1rem", fontWeight: 500 } }, { children: "Ger\u00E4t" }), void 0), session.deviceName] }), void 0), _jsxs("div", __assign({ style: {
                                                    fontSize: "1rem",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "start",
                                                    alignItems: "center"
                                                } }, { children: [_jsx("div", __assign({ style: { fontSize: "1rem", fontWeight: 500 } }, { children: "Artikel" }), void 0), session.watchlistAmount] }), void 0), _jsxs("div", __assign({ style: {
                                                    fontSize: "1rem",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }, onClick: function () {
                                                    onAssignRequest(session);
                                                } }, { children: [_jsx(Icon, { icon: "checkmark" }, void 0), "\u00DCbernehmen"] }), void 0)] }), void 0));
                                }) }), void 0)] }), void 0)] }), void 0)] }, void 0));
};
export default OpenSessionsWrapper;
