var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
var Wrapper = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  font-size: 0;\n  position: relative;\n"], ["\n  display: block;\n  font-size: 0;\n  position: relative;\n"])));
var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  width: 100%;\n"], ["\n  display: block;\n  width: 100%;\n"])));
var Banner = function (_a) {
    var imageUrl = _a.imageUrl, targetUrl = _a.targetUrl;
    return (_jsx(Wrapper, __assign({ to: targetUrl }, { children: _jsx(Image, { src: imageUrl }, void 0) }), void 0));
};
export default Banner;
var templateObject_1, templateObject_2;
