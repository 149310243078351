var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { toJS } from '@shared/utils/immutable';
import history from '@store/history';
import { defineMessages, injectIntl } from 'react-intl';
import { login, logout, setAutoLogOff } from '@store/authorize/actions';
import { getDeviceTheme } from '@store/device/actions';
import { setIdentity } from '@store/identity/actions';
import { scanArticle } from '@store/article/actions';
import { checkAssigned, checkUnassigned, createSessionWithScan, getSessionsAll, getSessionsArchive, getSessionsAssigned, getSessionsUnassigned } from '@store/session/actions';
import { setWatchlistHasDifferentType } from '@store/watchlist/actions';
import { startScan, stopScan } from '@store/scan/actions';
import { selectError } from '@store/error/selectors';
import { addError, removeError } from '@store/error/actions';
import { selectError as identitySelectError, selectLoaded as identitySelectLoaded } from '@store/identity/selectors';
import { selectScanning, selectSearching } from '@store/scan/selectors';
import { selectAllPage, selectArchivePage, selectAssignedPage, selectCheckingAssigned, selectCheckingUnassigned, selectUnassignedPage } from '@store/session/selectors';
import { selectWatchListHasDifferentType, selectWatchlistStatus } from '@store/watchlist/selectors';
import { selectAuthorized, selectAutoLogOff, selectCurrentUserId } from '@store/authorize/selectors';
import { selectIsLoading } from '@store/selector/selectors';
import PageSpinner from '@shared/atoms/PageSpinner';
import ConnectionError from '@shared/molecules/ConnectionError';
import ModalText from '@shared/atoms/ModalText';
import CloseIcon from '@shared/atoms/CloseIcon';
import metaData from '@shared/utils/metadata.json';
import { io } from 'socket.io-client';
import store from 'store';
import ValidateSessionWrapper from 'containers/Session/ValidateSessionWrapper';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 100%;\n"], ["\n    height: 100%;\n"])));
/**
 * Hardware button defined keys used for scanning.
 * @type {{OPEN_SCAN_2: number, OPEN_SCAN_1: number, SCAN: number}}
 */
var KEYS = {
    OPEN_SCAN_1: 42,
    OPEN_SCAN_2: 15,
    SCAN: 13,
};
var watchlistTypeError = defineMessages({
    watchlistTypeError: {
        defaultMessage: "Im Warenkorb befindet sich bereits ein Artikel: {type} Bitte erst Warenkorb abschliessen.",
        id: 'watchlist.error.type',
    },
}).watchlistTypeError;
if (!process.env.REACT_APP_WEBSOCKET_URL) {
    console.error('websocket url not configured');
}
var socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
    transports: ['websocket'],
});
var NeedleProvider = /** @class */ (function (_super) {
    __extends(NeedleProvider, _super);
    function NeedleProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            scan: '',
        };
        _this.currentPage = function (location) {
            switch (location) {
                case '/sessions/all':
                    return _this.props.allCurrent;
                case '/sessions/archive':
                    return _this.props.archiveCurrent;
                case '/sessions/assigned':
                    return _this.props.assignedCurrent;
                default:
                    return _this.props.unassignedCurrent;
            }
        };
        _this.fetchSessions = function (location, page) {
            switch (location) {
                case '/sessions/all':
                    _this.props.getSessionsAll({ page: page });
                    break;
                case '/sessions/archive':
                    _this.props.getSessionsArchive({ page: page });
                    break;
                case '/sessions/assigned':
                    _this.props.getSessionsAssigned({ page: page });
                    break;
                default:
                    _this.props.getSessionsUnassigned({ page: page });
                    break;
            }
        };
        /**
         * Starts and actually proceeds with the scanning process.
         * Either scans an article when authorized or starts login with scanned pin.
         *
         * @param event - {Object} - Keystroke Event.
         */
        _this.onScan = function (event) {
            var sessionURLRegex = RegExp('/sessions/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}');
            var scan = _this.state.scan;
            var _a = _this.props, authorized = _a.authorized, scanning = _a.scanning;
            switch (event.which) {
                case KEYS.SCAN:
                    if (scanning) {
                        _this.props.stopScan();
                        if (authorized && sessionURLRegex.test(history.location.pathname)) {
                            var pathname = history.location.pathname.match(sessionURLRegex) + "/";
                            _this.props.scanArticle(scan, pathname, 'scan');
                        }
                        else if (localStorage.getItem("sessionuuid")) {
                            var pathname = "sessions/" + localStorage.getItem("sessionuuid") + "/";
                            _this.props.scanArticle(scan, pathname, 'scan');
                        }
                        else {
                            _this.props.login({ assistantId: scan });
                        }
                    }
                    break;
                case KEYS.OPEN_SCAN_1:
                case KEYS.OPEN_SCAN_2:
                    if (!scanning) {
                        _this.setState({ scan: event.key });
                        _this.props.startScan();
                    }
                    break;
                default:
                    if (scanning) {
                        _this.setState({
                            scan: "" + _this.state.scan + event.key,
                        });
                    }
                    break;
            }
        };
        _this.onErrorCloseClick = function () {
            if (_this.props.identityError) {
                return;
            }
            _this.props.removeError();
        };
        _this.closeDifferentTypeModal = function () {
            _this.props.setWatchlistHasDifferentType(false);
        };
        _this.closeAutoLogOffModal = function () {
            _this.props.setAutoLogOff(false);
        };
        _this.showAutoLogOffModal = function () {
            _this.props.setAutoLogOff(true);
        };
        return _this;
    }
    NeedleProvider.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, setIdentity = _a.setIdentity, getDeviceTheme = _a.getDeviceTheme, deviceId = _a.deviceId, currentUserId = _a.currentUserId;
        setIdentity(deviceId);
        getDeviceTheme(deviceId);
        var storeId = store.get('storeId');
        var customerId = store.get('customerId');
        var roomId = customerId + "-" + storeId;
        socket.on('connect', function () {
            socket.emit('join-room', roomId);
            if (customerId && storeId) {
                socket.on('check-sessions', function () {
                    _this.fetchSessions(history.location.pathname, _this.currentPage(history.location.pathname));
                    _this.props.checkAssigned(true);
                    _this.props.checkUnassigned(true);
                });
            }
        });
        socket.on('error', function (error) {
            console.error('Socket error:', error);
        });
        socket.on('disconnect', function () {
            console.log('Socket disconnected!');
        });
        window.needleVersion = metaData.buildMajor + "." + metaData.buildMinor + "." + metaData.buildRevision;
        console.debug("%cNeedle Assistant version " + window.needleVersion, 'background: #e7ebda; color: #d36e70');
        console.debug("This is a test. We're on branch " + process.env.REACT_APP_TEST_KEY);
        document.addEventListener('keypress', this.onScan);
        if (!Boolean(currentUserId)) {
            history.push('/login');
        }
    };
    NeedleProvider.prototype.render = function () {
        var _this = this;
        var _a = this.props, children = _a.children, loaded = _a.loaded, identityError = _a.identityError, error = _a.error, searching = _a.searching, isLoading = _a.isLoading, isWatchListHasDifferentType = _a.isWatchListHasDifferentType, isAutoLogOff = _a.isAutoLogOff, watchListStatus = _a.watchListStatus, intl = _a.intl;
        if ((!loaded || searching || isLoading) && !error.showError) {
            return _jsx(PageSpinner, {}, void 0);
        }
        var defaultMessagesForWatchlistTypes = {
            available: 'Kaufen',
            inDifferentStore: 'Anforderung aus anderer Filiale',
            orderable: 'beim Hersteller bestellen.',
        };
        return (_jsxs(Wrapper, { children: [children, ((error.hasError && error.showError) || identityError) && (_jsxs(ConnectionError, __assign({ onClick: this.onErrorCloseClick }, { children: [_jsx(ModalText, { children: error.message }, void 0), _jsx(CloseIcon, { icon: 'closex', width: 10 }, void 0)] }), void 0)), isWatchListHasDifferentType && (_jsxs(ConnectionError, __assign({ onClick: this.closeDifferentTypeModal }, { children: [_jsx(ModalText, { children: intl.formatMessage(watchlistTypeError, {
                                type: defaultMessagesForWatchlistTypes[watchListStatus],
                            }) }, void 0), _jsx(CloseIcon, { icon: 'closex', width: 10 }, void 0)] }), void 0)), isAutoLogOff && (_jsxs(ConnectionError, __assign({ onClick: this.closeAutoLogOffModal }, { children: [_jsx(ModalText, { children: "Sie wurden automatisch abgemeldet." }, void 0), _jsx(CloseIcon, { icon: 'closex', width: 10 }, void 0)] }), void 0)), _jsx(ValidateSessionWrapper, { socket: socket, userId: this.props.currentUserId, logout: function (value) { return _this.props.logout(value); }, showAutoLogOffInformation: function () { return _this.showAutoLogOffModal(); } }, void 0)] }, void 0));
    };
    return NeedleProvider;
}(Component));
var select = createStructuredSelector({
    authorized: selectAuthorized,
    checkingAssigned: selectCheckingAssigned,
    checkingUnassigned: selectCheckingUnassigned,
    currentUserId: selectCurrentUserId,
    identityError: identitySelectError,
    loaded: identitySelectLoaded,
    scanning: selectScanning,
    searching: selectSearching,
    isLoading: selectIsLoading,
    error: selectError,
    isWatchListHasDifferentType: selectWatchListHasDifferentType,
    isAutoLogOff: selectAutoLogOff,
    watchListStatus: selectWatchlistStatus,
    unassignedCurrent: selectUnassignedPage,
    assignedCurrent: selectAssignedPage,
    allCurrent: selectAllPage,
    archiveCurrent: selectArchivePage,
});
export default withRouter(injectIntl(connect(select, {
    addError: addError,
    createSessionWithScan: createSessionWithScan,
    checkAssigned: checkAssigned,
    checkUnassigned: checkUnassigned,
    getDeviceTheme: getDeviceTheme,
    login: login,
    logout: logout,
    scanArticle: scanArticle,
    setIdentity: setIdentity,
    startScan: startScan,
    stopScan: stopScan,
    removeError: removeError,
    setWatchlistHasDifferentType: setWatchlistHasDifferentType,
    setAutoLogOff: setAutoLogOff,
    getSessionsAll: getSessionsAll,
    getSessionsArchive: getSessionsArchive,
    getSessionsAssigned: getSessionsAssigned,
    getSessionsUnassigned: getSessionsUnassigned,
})(toJS(NeedleProvider))));
var templateObject_1;
