var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
// utils
import styled from 'styled-components';
// components
import DepartmentListItem from 'molecules/DepartmentListItem';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-y: scroll;\n  flex: 1;\n"], ["\n  overflow-y: scroll;\n  flex: 1;\n"])));
var DepartmentList = function (_a) {
    var departments = _a.departments, params = _a.params, session = _a.session;
    var isActive = function (departmentId) {
        if (params &&
            params.department &&
            parseInt(params.department, 10) === departmentId) {
            return true;
        }
        return false;
    };
    return (_jsx(Wrapper, { children: departments.map(function (department) { return (_jsx(DepartmentListItem, { department: department, params: params, session: session, active: isActive(department.id) }, department.id)); }) }, void 0));
};
export default DepartmentList;
var templateObject_1;
