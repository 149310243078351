var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
var Entry = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: 1px solid #e0e0e0;\n    display: flex;\n    justify-content: center;\n    padding: 2rem;\n    border-radius: 1rem;\n    font-size: 2rem;\n    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);\n    text-decoration: none;\n    color: #000000;\n"], ["\n    border: 1px solid #e0e0e0;\n    display: flex;\n    justify-content: center;\n    padding: 2rem;\n    border-radius: 1rem;\n    font-size: 2rem;\n    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);\n    text-decoration: none;\n    color: #000000;\n"])));
export var CategoryGridItem = function (_a) {
    var category = _a.category, basePath = _a.basePath, primaryCategoryId = _a.primaryCategoryId;
    var getTargetLink = function () {
        // Define the base URL for the search results page.
        var target = basePath + "/results";
        var queryParams = {
            category: category.id,
            primaryCategory: primaryCategoryId,
        };
        // Extract the current query parameters from the window's URL.
        var currentQueryParams = queryString.parse(window.location.search);
        // Combine the current query parameters with the new query parameters.
        var newQueryParams = __assign(__assign({}, currentQueryParams), queryParams);
        // Convert the combined query parameters into a string and append them to the base URL.
        var queryStringified = queryString.stringify(newQueryParams);
        return target + "?" + queryStringified;
    };
    return _jsx(Entry, __assign({ to: getTargetLink }, { children: category.displayName }), void 0);
};
export default CategoryGridItem;
var templateObject_1;
