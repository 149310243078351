var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
// utils
import styled from 'styled-components';
// components
import { FormattedMessage } from 'react-intl';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 1rem;\n    background-color: #f5f5f5; // Light grey background for contrast\n    border-radius: 8px; // Rounded corners for a modern look\n"], ["\n    padding: 1rem;\n    background-color: #f5f5f5; // Light grey background for contrast\n    border-radius: 8px; // Rounded corners for a modern look\n"])));
var InfoContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-color: white;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);\n    border-radius: 8px;\n    padding: 1rem;\n    margin-top: 1rem;\n    border: 1px solid #e0e0e0; // Light grey border for definition\n"], ["\n    background-color: white;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);\n    border-radius: 8px;\n    padding: 1rem;\n    margin-top: 1rem;\n    border: 1px solid #e0e0e0; // Light grey border for definition\n"])));
var InfoRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: grid;\n    grid-template-columns: 150px 1fr; // Fixed width for labels and flexible width for content\n    padding: 0.5rem 0;\n    border-bottom: 1px solid #f0f0f0; // Light grey line for separation\n\n    &:last-child {\n        border-bottom: none; // Remove border for the last item\n    }\n\n    div:first-child {\n        font-weight: bold;\n        color: #333333;\n    }\n\n    div:last-child {\n        padding-left: 10px;\n        color: #555555;\n    }\n"], ["\n    display: grid;\n    grid-template-columns: 150px 1fr; // Fixed width for labels and flexible width for content\n    padding: 0.5rem 0;\n    border-bottom: 1px solid #f0f0f0; // Light grey line for separation\n\n    &:last-child {\n        border-bottom: none; // Remove border for the last item\n    }\n\n    div:first-child {\n        font-weight: bold;\n        color: #333333;\n    }\n\n    div:last-child {\n        padding-left: 10px;\n        color: #555555;\n    }\n"])));
var DepartmentItem = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: block;\n    margin-bottom: 0.3rem; // Tighter spacing for department items\n    color: #555555;\n"], ["\n    display: block;\n    margin-bottom: 0.3rem; // Tighter spacing for department items\n    color: #555555;\n"])));
var AssistantProfile = function (_a) {
    var _b;
    var currentAssistant = _a.currentAssistant;
    return (_jsx(Wrapper, { children: _jsxs(InfoContainer, { children: [_jsxs(InfoRow, { children: [_jsxs("div", { children: [_jsx(FormattedMessage, { defaultMessage: 'Name', id: 'assistant.profile.name' }, void 0), ":"] }, void 0), _jsxs("div", { children: [" ", currentAssistant.firstName + " " + currentAssistant.lastName] }, void 0)] }, void 0), currentAssistant.role && (_jsxs(InfoRow, { children: [_jsxs("div", { children: [_jsx(FormattedMessage, { defaultMessage: 'Rolle', id: 'assistant.profile.role' }, void 0), ":"] }, void 0), _jsx("div", { children: "Verk\u00E4uferin" }, void 0)] }, void 0)), _jsxs(InfoRow, { children: [_jsxs("div", { children: [_jsx(FormattedMessage, { defaultMessage: 'Abteilung', id: 'assistant.profile.department' }, void 0), ":"] }, void 0), _jsx("div", { children: (_b = currentAssistant === null || currentAssistant === void 0 ? void 0 : currentAssistant.departments) === null || _b === void 0 ? void 0 : _b.map(function (department) { return (_jsx(DepartmentItem, { children: department }, department)); }) }, void 0)] }, void 0), _jsxs(InfoRow, { children: [_jsxs("div", { children: [_jsx(FormattedMessage, { defaultMessage: 'Filiale', id: 'assistant.profile.storeNumber' }, void 0), ":"] }, void 0), _jsx("div", { children: _jsx("span", { children: currentAssistant === null || currentAssistant === void 0 ? void 0 : currentAssistant.storeId }, void 0) }, void 0)] }, void 0)] }, void 0) }, void 0));
};
export default AssistantProfile;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
