var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled, { withTheme } from 'styled-components';
import { palette } from '@shared/utils/styled';
import { Link } from 'react-router-dom';
import Logo from '@shared/atoms/Logo';
var LogoArea = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 35pt;\n"], ["\n  height: 35pt;\n"])));
var HomeLink = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('activeSecondaryBackground'));
var HeaderLogo = function (_a) {
    var theme = _a.theme;
    if ((theme === null || theme === void 0 ? void 0 : theme.logoUrl) === null || (theme === null || theme === void 0 ? void 0 : theme.logoUrl) === "") {
        return _jsx("div", {}, void 0);
    }
    return (_jsx(LogoArea, { children: _jsx(HomeLink, __assign({ to: '/?main' }, { children: _jsx(Logo, { logo: theme.logoUrl }, void 0) }), void 0) }, void 0));
};
export default withTheme(HeaderLogo);
var templateObject_1, templateObject_2;
