import { createSelector } from 'reselect';
var selectState = function (state) { return state.get('scan'); };
export var selectScanning = createSelector(selectState, function (scan) {
    return scan.get('scanning');
});
export var selectSearching = createSelector(selectState, function (scan) {
    return scan.get('searching');
});
export var selectScanResult = createSelector(selectState, function (scan) {
    return scan.get('scanResult');
});
