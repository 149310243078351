var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import BarcodePicker from "@shared/atoms/BarcodePicker";
import { Center, Error, Headline, Main } from "./styles";
import Button from "@shared/atoms/Button";
import Spinner from "@shared/atoms/Spinner";
import addEanPrefix from "@shared/utils/ean";
var ScanSearch = function (_a) {
    var sessionId = _a.sessionId, scanArticle = _a.scanArticle, searchArticle = _a.searchArticle, loading = _a.loading, error = _a.error, resetArticleError = _a.resetArticleError;
    var _b = useState(false), scanErrors = _b[0], setScanErrors = _b[1];
    var _c = useState(true), cameraAccess = _c[0], setCameraAccess = _c[1];
    var _d = useState(""), scanResult = _d[0], setScanResult = _d[1];
    var scan = function (scannedData) {
        setScanResult(scannedData);
        var ean = addEanPrefix(scannedData);
        scanArticle("" + ean, "/sessions/" + sessionId + "/", "scan");
    };
    var handleCameraAccess = function (cameraAccess) {
        setCameraAccess(cameraAccess);
    };
    var showBarCodeComponent = 0 === scanResult.length && !loading;
    var showTryAgainMsg = error && scanResult.length > 0;
    var tryAgainMsg = (_jsxs("div", { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { defaultMessage: "Scan nicht erfolgreich", id: "scan.fallback.headline" }, void 0) }, void 0), _jsx(Center, { children: _jsx(FormattedMessage, { defaultMessage: "Scannen Sie den Barcode erneut oder benutzen Sie die manuelle Eingabe.", id: "scan.fallback.message" }, void 0) }, void 0), _jsx(Error, { children: scanResult }, void 0), _jsx(Center, { children: _jsx(Button, __assign({ onClick: function () {
                        setScanResult("");
                        setScanErrors(false);
                        setCameraAccess(true);
                        resetArticleError();
                    } }, { children: "Erneut Scannen" }), void 0) }, void 0)] }, void 0));
    var errorMsg = (_jsxs("div", { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { defaultMessage: "Scan nicht erfolgreich", id: "scan.fallback.headline" }, void 0) }, void 0), _jsx(Center, { children: _jsx(FormattedMessage, { defaultMessage: "Suchen Sie erneut oder nutzen Sie die manuelle Eingabe.", id: "scan.fallback.message" }, void 0) }, void 0)] }, void 0));
    return (_jsxs(Main, { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { defaultMessage: "Barcode scannen", id: "scan.headline" }, void 0) }, void 0), showBarCodeComponent && (_jsx(_Fragment, { children: cameraAccess ? (_jsx(BarcodePicker, { onScan: scan, handleCameraAccess: handleCameraAccess }, void 0)) : (_jsx(_Fragment, { children: _jsx("svg", __assign({ version: "1.0", xmlns: "http://www.w3.org/2000/svg", width: "80%", height: "20%", viewBox: "0 0 302.000000 170.000000", preserveAspectRatio: "xMidYMid meet" }, { children: _jsxs("g", __assign({ transform: "translate(0.000000,170.000000) scale(0.100000,-0.100000)", fill: "#000000", stroke: "none" }, { children: [_jsx("path", { d: "M32 1368 l3 -233 78 -3 77 -3 0 191 0 190 320 0 320 0 0 45 0 45\n              -400 0 -400 0 2 -232z" }, void 0), _jsx("path", { d: "M2100 1555 l0 -45 320 0 320 0 0 -190 0 -191 78 3 77 3 3 233 2 232\n              -400 0 -400 0 0 -45z" }, void 0), _jsx("path", { d: "M670 850 l0 -370 80 0 80 0 0 370 0 370 -80 0 -80 0 0 -370z" }, void 0), _jsx("path", { d: "M990 850 l0 -370 75 0 75 0 0 370 0 370 -75 0 -75 0 0 -370z" }, void 0), _jsx("path", { d: "M1310 850 l0 -370 75 0 75 0 0 370 0 370 -75 0 -75 0 0 -370z" }, void 0), _jsx("path", { d: "M1710 850 l0 -370 75 0 75 0 0 370 0 370 -75 0 -75 0 0 -370z" }, void 0), _jsx("path", { d: "M2100 850 l0 -370 80 0 80 0 0 370 0 370 -80 0 -80 0 0 -370z" }, void 0), _jsx("path", { d: "M37 563 c-4 -3 -7 -109 -7 -235 l0 -228 400 0 400 0 0 45 0 45 -320\n              0 -320 0 0 190 0 190 -73 0 c-41 0 -77 -3 -80 -7z" }, void 0), _jsx("path", { d: "M2740 380 l0 -190 -320 0 -320 0 0 -45 0 -45 400 0 400 0 -2 233 -3\n              232 -77 3 -78 3 0 -191z" }, void 0)] }), void 0) }), void 0) }, void 0)) }, void 0)), loading && _jsx(Spinner, {}, void 0), scanErrors && errorMsg, showTryAgainMsg && tryAgainMsg] }, void 0));
};
export default ScanSearch;
