var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import history from '@store/history';
import { pageSize } from '@shared/utils/constants';
import { selectCurrentUserId } from '@store/authorize/selectors';
import errorHandler from 'services/connection';
import { scanArticle } from '@store/article/actions';
import { getDevices } from '@store/device/actions';
import { resetFurniture } from '@store/furniture/actions';
import { resetOrderNumber } from '@store/action/actions';
import { aquireCall as aquireCallAction, aquireCallComplete, archiveSession as archiveSessionAction, archiveSessionComplete, assignSession as assignSessionAction, assignSessionComplete, assignTerminal as assignTerminalAction, assignTerminalComplete, checkAssigned as checkAssignedAction, checkAssignedComplete, checkout as checkoutAction, checkoutComplete, checkUnassigned as checkUnassignedAction, checkUnassignedComplete, createSession as createSessionAction, createSessionComplete, createSessionWithScan as createSessionWithScanAction, deleteSession as deleteSessionAction, deleteSessionComplete, getSession as getSessionAction, getSessionComplete, getSessionsAll as getSessionsAllAction, getSessionsAllComplete, getSessionsArchive as getSessionsArchiveAction, getSessionsArchiveComplete, getSessionsAssigned as getSessionsAssignedAction, getSessionsAssignedComplete, getSessionsUnassigned as getSessionsUnassignedAction, getSessionsUnassignedComplete, shareSession as shareSessionAction, shareSessionComplete, unleashAssistant as unleashAssistantAction, unleashAssistantComplete, unleashDevice as unleashDeviceAction, unleashDeviceComplete, updateSession as updateSessionAction, updateSessionComplete, } from './actions';
export function aquireCall(api, action) {
    var payload, meta, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                payload = action.payload, meta = action.meta;
                return [4 /*yield*/, call([api, api.put], "sessions/" + meta.id + "/aquireCall", payload)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, put(aquireCallComplete(fromJS(response.body)))];
            case 2:
                _a.sent();
                if (!(payload.watchlistCount > 0)) return [3 /*break*/, 4];
                return [4 /*yield*/, call(history.push, "/sessions/" + action.meta.id, { showModal: true })];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                error_1 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_1, aquireCallComplete, aquireCallAction, action.payload, action.meta.id))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function unleashAssistant(api, action) {
    var payload, response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                payload = action.payload;
                return [4 /*yield*/, call([api, api.put], "sessions/" + payload + "/unleashAssistant")];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, put(unleashAssistantComplete(fromJS(response.body)))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(resetFurniture())];
            case 3:
                _a.sent();
                return [4 /*yield*/, call(history.push, '/')];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                error_2 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_2, unleashAssistantComplete, unleashAssistantAction, action.payload))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function assignSession(api, action) {
    var payload, meta, response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                payload = action.payload, meta = action.meta;
                return [4 /*yield*/, call([api, api.put], "sessions/" + meta.id + "/assign", payload)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, put(assignSessionComplete(fromJS(response.body), meta.type))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(resetFurniture())];
            case 3:
                _a.sent();
                if (!Boolean(meta.redirectTarget)) return [3 /*break*/, 5];
                return [4 /*yield*/, call(history.push, meta.redirectTarget)];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_3 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_3, assignSessionComplete, assignSessionAction, action.payload, action.meta.id, action.meta.type, action.meta.redirectTarget))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function assignTerminal(api, action) {
    var payload, meta, response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                payload = action.payload, meta = action.meta;
                return [4 /*yield*/, call([api, api.put], "sessions/" + meta.id + "/assign", payload)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, put(assignTerminalComplete(fromJS(response.body), meta.type))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(resetFurniture())];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(getDevices())];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                error_4 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_4, assignTerminalComplete, assignTerminalAction, action.payload, action.meta.id))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function unleashDevice(api, action) {
    var payload, body, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                payload = action.payload;
                return [4 /*yield*/, call([api, api.put], "sessions/" + payload + "/unleashDevice")];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(unleashDeviceComplete(fromJS(body)))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(resetFurniture())];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(getDevices())];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                error_5 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_5, unleashDeviceComplete, unleashDeviceAction, action.payload))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function createSession(api, action) {
    var body, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 6]);
                return [4 /*yield*/, call([api, api.post], 'sessions', action.payload)];
            case 1:
                body = (_a.sent()).body;
                localStorage.setItem("sessionuuid", body.uuid);
                return [4 /*yield*/, put(createSessionComplete(fromJS(body)))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(resetFurniture())];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                error_6 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_6, createSessionComplete, createSessionAction, action.payload))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function createSessionWithScan(api, action) {
    var body, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                return [4 /*yield*/, call([api, api.post], 'sessions', action.payload)];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(createSessionComplete(fromJS(body)))];
            case 2:
                _a.sent();
                return [4 /*yield*/, call(history.push, "/sessions/" + body.uuid)];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(scanArticle(action.meta.scan, "/sessions/" + body.uuid + "/"))];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(resetFurniture())];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                error_7 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_7, createSessionComplete, createSessionWithScanAction, action.payload))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function deleteSession(api, action) {
    var body, error_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call([api, api.delete], "sessions/" + action.payload)];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(deleteSessionComplete(fromJS(body)))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(resetFurniture())];
            case 3:
                _a.sent();
                return [4 /*yield*/, call(history.push, '/')];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                error_8 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_8, deleteSessionComplete, deleteSessionAction, action.payload))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function getSession(api, action) {
    var _a, assistantId, sessionId, body, error_9;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, assistantId = _a.assistantId, sessionId = _a.sessionId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 9]);
                return [4 /*yield*/, call([api, api.get], "sessions/" + sessionId)];
            case 2:
                body = (_b.sent()).body;
                return [4 /*yield*/, put(getSessionComplete(fromJS(body)))];
            case 3:
                _b.sent();
                return [3 /*break*/, 9];
            case 4:
                error_9 = _b.sent();
                if (!(error_9.response && error_9.response.status === 404)) return [3 /*break*/, 7];
                return [4 /*yield*/, put(getSessionComplete(error_9))];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(createSessionAction({ assistantId: assistantId }))];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [4 /*yield*/, put(errorHandler(error_9, getSessionComplete, getSessionAction, action.payload))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function getSessionsAll(api, action) {
    var userId, _a, body, headers, error_10;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4 /*yield*/, select(selectCurrentUserId)];
            case 1:
                userId = _b.sent();
                return [4 /*yield*/, call([api, api.get], 'sessions', {
                        params: __assign({ status: 'all', assistantId: userId, pageSize: pageSize }, action.payload),
                    })];
            case 2:
                _a = _b.sent(), body = _a.body, headers = _a.headers;
                return [4 /*yield*/, put(getSessionsAllComplete(fromJS(body), headers))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_10 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_10, getSessionsAllComplete, getSessionsAllAction, action.payload))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function getSessionsArchive(api, action) {
    var userId, _a, body, headers, error_11;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4 /*yield*/, select(selectCurrentUserId)];
            case 1:
                userId = _b.sent();
                return [4 /*yield*/, call([api, api.get], 'sessions-archive', {
                        params: __assign({ pageSize: pageSize, assistantId: userId }, action.payload),
                    })];
            case 2:
                _a = _b.sent(), body = _a.body, headers = _a.headers;
                return [4 /*yield*/, put(getSessionsArchiveComplete(fromJS(body), headers))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_11 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_11, getSessionsArchiveComplete, getSessionsArchiveAction, action.payload))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function getSessionsAssigned(api, action) {
    var userId, _a, body, headers, error_12;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4 /*yield*/, select(selectCurrentUserId)];
            case 1:
                userId = _b.sent();
                return [4 /*yield*/, call([api, api.get], 'sessions', {
                        params: __assign({ status: 'assigned', pageSize: pageSize }, action.payload),
                    })];
            case 2:
                _a = _b.sent(), body = _a.body, headers = _a.headers;
                return [4 /*yield*/, put(getSessionsAssignedComplete(fromJS(body), headers))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_12 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_12, getSessionsAssignedComplete, getSessionsAssignedAction, action.payload))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function getSessionsUnassigned(api, action) {
    var _a, body, headers, error_13;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call([api, api.get], 'sessions', {
                        params: __assign({ status: 'unassigned', pageSize: pageSize }, action.payload),
                    })];
            case 1:
                _a = _b.sent(), body = _a.body, headers = _a.headers;
                return [4 /*yield*/, put(getSessionsUnassignedComplete(fromJS(body), headers))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                error_13 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_13, getSessionsUnassignedComplete, getSessionsUnassignedAction, action.payload))];
            case 4:
                _b.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function updateSession(api, action) {
    var payload, meta, response, error_14;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                payload = action.payload, meta = action.meta;
                return [4 /*yield*/, call([api, api.put], "sessions/" + meta.id, payload)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, put(updateSessionComplete(fromJS(response.body)))];
            case 2:
                _a.sent();
                if (!meta.redirectUrl) return [3 /*break*/, 4];
                return [4 /*yield*/, call(history.push, {
                        pathname: meta.redirectUrl,
                        search: "?ref=" + meta.ref,
                    })];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                error_14 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_14, updateSessionComplete, updateSessionAction, action.payload, action.meta.id))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function checkAssigned(api, action) {
    var userId, headers, error_15;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 6]);
                return [4 /*yield*/, select(selectCurrentUserId)];
            case 1:
                userId = _a.sent();
                return [4 /*yield*/, call([api, api.get], 'sessions', {
                        params: {
                            status: 'assigned',
                            pageSize: 1,
                            page: 1,
                        },
                    })];
            case 2:
                headers = (_a.sent()).headers;
                return [4 /*yield*/, put(checkAssignedComplete(headers, action.payload))];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                error_15 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_15, checkAssignedComplete, checkAssignedAction, action.payload))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function checkUnassigned(api, action) {
    var headers, error_16;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call([api, api.get], 'sessions', {
                        params: {
                            status: 'unassigned',
                            pageSize: 1,
                            page: 1,
                        },
                    })];
            case 1:
                headers = (_a.sent()).headers;
                return [4 /*yield*/, put(checkUnassignedComplete(headers, action.payload))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_16 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_16, checkUnassignedComplete, checkUnassignedAction, action.payload))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function checkout(api, action) {
    var body, error_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call([api, api.post], "sessions/" + action.meta.id + "/checkout", action.payload)];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(checkoutComplete(body))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_17 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_17, checkoutComplete, checkoutAction, action.payload, action.meta.id))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
/**
 * Archives the specified session.
 *  ArchivedAt is set to the current time.
 *
 * @param {object} api - Endpoint.
 * @param {action} action - Archive session action.
 */
export function archiveSession(api, action) {
    var body, error_18;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call([api, api.put], "sessions/" + action.payload + "/archive")];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(resetOrderNumber())];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(archiveSessionComplete(fromJS(body)))];
            case 3:
                _a.sent();
                return [4 /*yield*/, call(history.push, '/')];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                error_18 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_18, archiveSessionComplete, archiveSessionAction, action.payload))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
/**
 * Share session by email.
 *
 * @param {object} api - Endpoint.
 * @param {action} action - Archive session action.
 */
export function shareSession(api, action) {
    var payload, meta, sessionId, resolve, reject, body, error_19;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload, meta = action.meta;
                sessionId = meta.sessionId, resolve = meta.resolve, reject = meta.reject;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 8]);
                return [4 /*yield*/, call([api, api.post], "sessions/" + sessionId + "/share", payload)];
            case 2:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(shareSessionComplete(fromJS(body)))];
            case 3:
                _a.sent();
                return [4 /*yield*/, call(resolve)];
            case 4:
                _a.sent();
                return [3 /*break*/, 8];
            case 5:
                error_19 = _a.sent();
                return [4 /*yield*/, call(reject)];
            case 6:
                _a.sent();
                return [4 /*yield*/, put(errorHandler(error_19, shareSessionComplete, shareSessionAction, action.payload, action.meta.sessionId))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function sessionAquireCallWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('AQUIRE_CALL', aquireCall, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionAssignWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('ASSIGN_SESSION', assignSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionAssignTerminalWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('ASSIGN_TERMINAL', assignTerminal, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionCreateWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('CREATE_SESSION', createSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionDeleteWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('DELETE_SESSION', deleteSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionGetWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('GET_SESSION', getSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionsAllGetWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('GET_SESSIONS_ALL', getSessionsAll, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionsArchiveGetWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('GET_SESSIONS_ARCHIVE', getSessionsArchive, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionsAssignedGetWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('GET_SESSIONS_ASSIGNED', getSessionsAssigned, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionsUnassignedGetWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('GET_SESSIONS_UNASSIGNED', getSessionsUnassigned, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionUpdateWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('UPDATE_SESSION', updateSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function unleashDeviceWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('UNLEASH_DEVICE', unleashDevice, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionUnleashAssistantWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('UNLEASH_ASSISTANT', unleashAssistant, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionCreateWithScanWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('CREATE_SESSION_WITH_SCAN', createSessionWithScan, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionCheckAssignedWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('CHECK_ASSIGNED', checkAssigned, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionCheckUnassignedWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('CHECK_UNASSIGNED', checkUnassigned, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionCheckoutWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('CHECKOUT', checkout, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionArchiveWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('ARCHIVE_SESSION', archiveSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionShareWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('SHARE_SESSION', shareSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
