var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// @flow
import React, { Component } from "react";
// utils
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { toJS } from "@shared/utils/immutable";
// actions
import { aquireCall, assignSession, createSession, getSession, getSessionsAll, getSessionsArchive, getSessionsAssigned, getSessionsUnassigned, } from "@store/session/actions";
import { addToCartClickedcomplete, deleteWatchlist, deleteWatchlistItem, getWatchlist } from "@store/watchlist/actions";
// selectors
import { selectAllPage, selectAllPageCount, selectArchivePage, selectArchivePageCount, selectAssignedPage, selectAssignedPageCount, selectLoaded as sessionSelectLoaded, selectLoading as sessionSelectLoading, selectLoadingAll as sessionSelectLoadingAll, selectLoadingAllComplete as sessionSelectLoadingAllComplete, selectLoadingArchive as sessionSelectLoadingArchive, selectLoadingArchiveComplete as sessionSelectLoadingArchiveComplete, selectLoadingAssigned as sessionSelectLoadingAssigned, selectLoadingAssignedComplete as sessionSelectLoadingAssignedComplete, selectLoadingUnassigned as sessionSelectLoadingUnassigned, selectLoadingUnassignedComplete as sessionSelectLoadingUnassignedComplete, selectSessionsAll, selectSessionsArchive, selectSessionsAssigned, selectSessionsUnassigned, selectUnassignedPage, selectUnassignedPageCount, } from "@store/session/selectors";
import { selectTheme } from "@store/theme/selectors";
import { selectCurrentUserId } from "@store/authorize/selectors";
// components
import StartPage from "containers/HomePage/StartPage";
import { selectWatchlist } from "@store/watchlist/selectors";
import { logout } from "@store/authorize/actions";
import isEmpty from "lodash/isEmpty";
var HomePage = /** @class */ (function (_super) {
    __extends(HomePage, _super);
    function HomePage(props) {
        var _this = _super.call(this, props) || this;
        _this.fetchUnassignedSessions = function (page) {
            _this.props.getSessionsUnassigned({ page: page });
        };
        _this.fetchSessions = function (location, page) {
            switch (location) {
                case "/sessions/all":
                    _this.props.getSessionsAll({ page: page });
                    break;
                case "/sessions/archive":
                    _this.props.getSessionsArchive({ page: page });
                    break;
                case "/sessions/assigned":
                    _this.props.getSessionsAssigned({ page: page });
                    break;
                default:
                    _this.props.getSessionsUnassigned({ page: page });
                    break;
            }
        };
        _this.isLoadingComplete = function (location) {
            switch (location) {
                case "/sessions/all":
                    return _this.props.loadingAllComplete;
                case "/sessions/archive":
                    return _this.props.loadingArchiveComplete;
                case "/sessions/assigned":
                    return _this.props.loadingAssignedComplete;
                default:
                    return _this.props.loadingUnassignedComplete;
            }
        };
        _this.isLoading = function (location) {
            switch (location) {
                case "/sessions/all":
                    return _this.props.loadingAll;
                case "/sessions/archive":
                    return _this.props.loadingArchive;
                case "/sessions/assigned":
                    return _this.props.loadingAssigned;
                default:
                    return _this.props.loadingUnassigned;
            }
        };
        _this.currentPage = function (location) {
            switch (location) {
                case "/sessions/all":
                    return _this.props.allCurrent;
                case "/sessions/archive":
                    return _this.props.archiveCurrent;
                case "/sessions/assigned":
                    return _this.props.assignedCurrent;
                default:
                    return _this.props.unassignedCurrent;
            }
        };
        _this.pageCount = function (location) {
            switch (location) {
                case "/sessions/all":
                    return _this.props.allCount;
                case "/sessions/archive":
                    return _this.props.archiveCount;
                case "/sessions/assigned":
                    return _this.props.assignedCount;
                default:
                    return _this.props.unassignedCount;
            }
        };
        _this.onAquireCall = function (session) {
            var currentAssistantId = _this.props.currentAssistantId;
            _this.props.aquireCall({ assistantId: currentAssistantId }, session.uuid, session.watchlistAmount);
        };
        _this.onAssignSession = function (session, type) {
            var currentAssistantId = _this.props.currentAssistantId;
            _this.props.assignSession({ assistantId: currentAssistantId }, session.uuid, type, "/sessions/" + session.uuid);
        };
        _this.onCreateSession = function () {
            _this.props.createSession({ assistantId: _this.props.currentAssistantId });
        };
        _this.performLogout = function () {
            localStorage.removeItem("sessionuuid");
            _this.props.logout({ assistantId: _this.props.currentAssistantId });
        };
        return _this;
    }
    HomePage.prototype.componentDidMount = function () {
        var _a = this.props, loaded = _a.loaded, loading = _a.loading, match = _a.match;
        var sessionId = match.params.id || localStorage.getItem("sessionuuid");
        if (sessionId === null || sessionId === "") {
            this.props.createSession({ assistantId: this.props.currentAssistantId });
        }
        if (sessionId !== null && sessionId !== "") {
            if (!loading && !loaded) {
                this.props.getSession({
                    assistantId: this.props.currentAssistantId,
                    sessionId: sessionId,
                });
            }
        }
    };
    HomePage.prototype.render = function () {
        var _this = this;
        var _a = this.props, loading = _a.loading, loaded = _a.loaded, session = _a.session, sessionsUnassigned = _a.sessionsUnassigned, theme = _a.theme, watchlist = _a.watchlist;
        if ((loading || !loaded) && isEmpty(session)) {
            return _jsx("div", {}, void 0);
        }
        return (_jsx(_Fragment, { children: _jsx(StartPage, { getWatchlist: this.props.getWatchlist, theme: theme, cart: watchlist, onLogoutButtonClicked: function () { return _this.performLogout(); }, sessions: sessionsUnassigned, sessionId: (session === null || session === void 0 ? void 0 : session.id) || session, fetchSessions: function () {
                    _this.fetchUnassignedSessions(0);
                }, clearCart: function () {
                    _this.props.deleteWatchlist(localStorage.getItem("sessionuuid"));
                }, deleteCartItem: function (articleId) {
                    _this.props.deleteWatchlistItem(articleId, localStorage.getItem("sessionuuid"));
                } }, void 0) }, void 0));
    };
    return HomePage;
}(Component));
var select = createStructuredSelector({
    allCount: selectAllPageCount,
    allCurrent: selectAllPage,
    archiveCount: selectArchivePageCount,
    archiveCurrent: selectArchivePage,
    assignedCount: selectAssignedPageCount,
    assignedCurrent: selectAssignedPage,
    currentAssistantId: selectCurrentUserId,
    loaded: sessionSelectLoaded,
    loading: sessionSelectLoading,
    loadingAll: sessionSelectLoadingAll,
    loadingAllComplete: sessionSelectLoadingAllComplete,
    loadingArchive: sessionSelectLoadingArchive,
    loadingArchiveComplete: sessionSelectLoadingArchiveComplete,
    loadingAssigned: sessionSelectLoadingAssigned,
    loadingAssignedComplete: sessionSelectLoadingAssignedComplete,
    loadingUnassigned: sessionSelectLoadingUnassigned,
    loadingUnassignedComplete: sessionSelectLoadingUnassignedComplete,
    sessionsAll: selectSessionsAll,
    sessionsArchive: selectSessionsArchive,
    sessionsAssigned: selectSessionsAssigned,
    sessionsUnassigned: selectSessionsUnassigned,
    theme: selectTheme,
    unassignedCount: selectUnassignedPageCount,
    unassignedCurrent: selectUnassignedPage,
    watchlist: selectWatchlist,
});
export var Container = HomePage;
export default connect(select, {
    aquireCall: aquireCall,
    assignSession: assignSession,
    createSession: createSession,
    getSession: getSession,
    getSessionsAll: getSessionsAll,
    getSessionsArchive: getSessionsArchive,
    getSessionsAssigned: getSessionsAssigned,
    getSessionsUnassigned: getSessionsUnassigned,
    getWatchlist: getWatchlist,
    addToCartClickedcomplete: addToCartClickedcomplete,
    logout: logout,
    deleteWatchlist: deleteWatchlist,
    deleteWatchlistItem: deleteWatchlistItem,
})(toJS(Container));
