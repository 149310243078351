var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createAction, createActions } from 'redux-actions';
import { metaCreator } from '@shared/utils/redux';
export var assignTerminalComplete = (_a = createActions('ASSIGN_TERMINAL_COMPLETE', 'AQUIRE_CALL_COMPLETE', 'CHECK_ASSIGNED', 'CHECK_UNASSIGNED', 'CHECKOUT_COMPLETE', 'CREATE_SESSION', 'CREATE_SESSION_COMPLETE', 'DELETE_SESSION', 'DELETE_SESSION_COMPLETE', 'GET_SESSION', 'GET_SESSION_COMPLETE', 'SHARE_SESSION_COMPLETE', 'GET_SESSIONS_ALL', 'GET_SESSIONS_ARCHIVE', 'GET_SESSIONS_ASSIGNED', 'GET_SESSIONS_UNASSIGNED', 'UNLEASH_ASSISTANT', 'UNLEASH_ASSISTANT_COMPLETE', 'UNLEASH_DEVICE', 'UNLEASH_DEVICE_COMPLETE', 'UPDATE_SESSION_COMPLETE', 'ARCHIVE_SESSION', 'ARCHIVE_SESSION_COMPLETE'), _a.assignTerminalComplete), aquireCallComplete = _a.aquireCallComplete, checkAssigned = _a.checkAssigned, checkUnassigned = _a.checkUnassigned, checkoutComplete = _a.checkoutComplete, createSession = _a.createSession, createSessionComplete = _a.createSessionComplete, deleteSession = _a.deleteSession, deleteSessionComplete = _a.deleteSessionComplete, getSession = _a.getSession, getSessionComplete = _a.getSessionComplete, shareSessionComplete = _a.shareSessionComplete, getSessionsAll = _a.getSessionsAll, getSessionsArchive = _a.getSessionsArchive, getSessionsAssigned = _a.getSessionsAssigned, getSessionsUnassigned = _a.getSessionsUnassigned, unleashAssistant = _a.unleashAssistant, unleashAssistantComplete = _a.unleashAssistantComplete, unleashDevice = _a.unleashDevice, unleashDeviceComplete = _a.unleashDeviceComplete, updateSessionComplete = _a.updateSessionComplete, archiveSession = _a.archiveSession, archiveSessionComplete = _a.archiveSessionComplete;
export var checkAssignedComplete = createAction('CHECK_ASSIGNED_COMPLETE', function (payload) { return payload; }, function (payload, shouldSend) { return ({ shouldSend: shouldSend }); });
export var checkUnassignedComplete = createAction('CHECK_UNASSIGNED_COMPLETE', function (payload) { return payload; }, function (payload, shouldSend) { return ({ shouldSend: shouldSend }); });
export var aquireCall = createAction('AQUIRE_CALL', function (payload, session, watchlistCount) {
    return __assign(__assign({}, payload), { session: session, watchlistCount: watchlistCount });
}, function (payload, id) {
    return { id: id };
});
export var assignSession = createAction('ASSIGN_SESSION', function (payload) { return payload; }, function (payload, id, type, redirectTarget) { return ({
    id: id,
    type: type,
    redirectTarget: redirectTarget,
}); });
export var assignTerminal = createAction('ASSIGN_TERMINAL', function (payload) { return payload; }, function (payload, id) { return ({
    id: id,
}); });
export var getSessionsAllComplete = createAction('GET_SESSIONS_ALL_COMPLETE', function (payload) { return payload; }, function (payload, headers) { return ({ headers: headers }); });
export var getSessionsAssignedComplete = createAction('GET_SESSIONS_ASSIGNED_COMPLETE', function (payload) { return payload; }, function (payload, headers) { return ({ headers: headers }); });
export var getSessionsArchiveComplete = createAction('GET_SESSIONS_ARCHIVE_COMPLETE', function (payload) { return payload; }, function (payload, headers) { return ({ headers: headers }); });
export var getSessionsUnassignedComplete = createAction('GET_SESSIONS_UNASSIGNED_COMPLETE', function (payload) { return payload; }, function (payload, headers) { return ({ headers: headers }); });
export var updateSession = createAction('UPDATE_SESSION', function (payload) { return payload; }, function (payload, id, redirectUrl, ref) { return ({
    id: id,
    redirectUrl: redirectUrl,
    ref: ref,
}); });
export var checkout = createAction('CHECKOUT', function (payload) { return payload; }, function (payload, id) { return ({
    id: id,
}); });
export var assignSessionComplete = createAction('ASSIGN_SESSION_COMPLETE', function (payload) { return payload; }, function (payload, type) { return ({
    type: type,
}); });
export var createSessionWithScan = createAction('CREATE_SESSION_WITH_SCAN', function (payload) { return payload; }, function (payload, scan) { return ({
    scan: scan,
}); });
export var shareSession = createAction('SHARE_SESSION', function (payload) { return payload; }, function (payload, sessionId, resolve, reject) {
    return metaCreator({
        sessionId: sessionId,
    }, resolve, reject);
});
