import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { getAuthHeader, getUUID } from "@shared/utils/auth";
import localStore from "store";
var ArticleImage = function (_a) {
    var article = _a.article, size = _a.size;
    var deviceId = getUUID(window.location.search) || localStore.get("deviceId");
    var apiUrl = process.env.REACT_APP_API_URL;
    var requestUrl = apiUrl + "v2/article-image/?id=" + article.id + "&eccId=" + article.eccId + "&customerArtId=" + article.customerArtId + "&size=" + size + "&auth=" + getAuthHeader(deviceId);
    return (_jsx(_Fragment, { children: _jsx("img", { src: requestUrl, alt: "article", style: { maxHeight: "auto", width: "100%" } }, void 0) }, void 0));
};
export default ArticleImage;
