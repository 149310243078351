import { createSelector } from 'reselect';
var selectArticlesState = function (state) { return state.get('article'); };
export var selectArticleId = function (state, props) {
    return parseInt(props.match.params.articleId, 10);
};
export var selectLoading = createSelector(selectArticlesState, function (articleState) { return articleState.get('loading'); });
export var selectArticlesApiParams = createSelector(selectArticlesState, function (articleState) { return articleState.get('articlesApiParams'); });
export var selectLoadingAll = createSelector(selectArticlesState, function (articleState) { return articleState.get('loadingAll'); });
export var selectPage = createSelector(selectArticlesState, function (articleState) {
    return articleState.get('page');
});
export var selectCategoryId = createSelector(selectArticlesState, function (articleState) { return articleState.get('categoryId'); });
export var selectLoadingAllComplete = createSelector(selectArticlesState, function (articleState) {
    return !articleState.get('loadingAll') && articleState.get('loadedAll');
});
export var selectTotal = createSelector(selectArticlesState, function (articleState) {
    return articleState.get('total');
});
export var selectArticles = createSelector(selectArticlesState, function (articleState) { return articleState.get('data'); });
export var selectArticlePage = createSelector(selectArticlesState, function (articleState) { return articleState.get('page'); });
export var selectArticlePageCount = createSelector(selectArticlesState, function (articleState) { return articleState.get('pageCount'); });
export var selectArticleError = createSelector(selectArticlesState, function (articleState) { return articleState.get('error'); });
export var makeSelectArticle = function () {
    return createSelector([selectArticles, selectArticleId], function (articles, articleId) {
        return articles.find(function (article) { return article.get('id') === articleId; });
    });
};
export var loadSingleArticle = function () {
    return createSelector([selectArticles, selectArticleId, selectLoading], function (articles, id, loading) {
        var a = articles.find(function (article) { return article.get('id') === id; });
        return !Boolean(a) || (!loading && !a.get('singleLoaded'));
    });
};
