var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { palette, fontWeight } from '@shared/utils/styled';
import styled from 'styled-components';
import HorizontalSeparator from 'atoms/HorizontalSeprator';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
var Wrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  letter-spacing: 0.025rem;\n  font-weight: ", ";\n  background-color: ", ";\n"], ["\n  letter-spacing: 0.025rem;\n  font-weight: ", ";\n  background-color: ", ";\n"])), fontWeight('light'), palette('white'));
var Tab = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n    background-color: ", ";\n  display: flex;\n  flex: 1 1 auto;\n  height: 3.125rem;\n  outline: 0;\n  position: relative;\n  text-decoration: none;\n"], ["\n  align-items: center;\n  color: ", ";\n    background-color: ", ";\n  display: flex;\n  flex: 1 1 auto;\n  height: 3.125rem;\n  outline: 0;\n  position: relative;\n  text-decoration: none;\n"])), function (props) {
    return props.isActive ? palette('white') : palette('gray');
}, function (props) {
    return props.isActive ? palette('primaryHighlight') : palette('white');
});
var StyledLink = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  color: inherit;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  text-decoration: none;\n  width: 100%;\n"], ["\n  align-items: center;\n  color: inherit;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  text-decoration: none;\n  width: 100%;\n"])));
var TabMenu = function (_a) {
    var tabs = _a.tabs, checkIfTabIsActive = _a.checkIfTabIsActive;
    return (_jsxs("div", { children: [_jsx(Wrapper, { children: tabs.map(function (tab) { return (_jsx(Tab, __assign({ isActive: checkIfTabIsActive(tab.target) }, { children: _jsx(StyledLink, __assign({ "data-cy-id": 'tab-menu', to: tab.target }, { children: tab.title }), void 0) }), tab.title)); }) }, void 0), _jsx(HorizontalSeparator, {}, void 0)] }, void 0));
};
export default TabMenu;
var templateObject_1, templateObject_2, templateObject_3;
