var _a;
import { createActions, createAction } from 'redux-actions';
export var articleNotFound = (_a = createActions('ARTICLE_NOT_FOUND', 'GET_ARTICLE', 'GET_ARTICLE_COMPLETE', 'GET_ARTICLES', 'RESET_ARTICLES', 'SEARCH_ARTICLE_COMPLETE', 'RESET_LOADING', 'UPDATE_ARTICLE_COMPLETE', 'RESET_ARTICLE_ERROR', 'SET_ARTICLE_PARAMS'), _a.articleNotFound), getArticle = _a.getArticle, getArticleComplete = _a.getArticleComplete, getArticles = _a.getArticles, resetArticles = _a.resetArticles, searchArticleComplete = _a.searchArticleComplete, resetLoading = _a.resetLoading, updateArticleComplete = _a.updateArticleComplete, resetArticleError = _a.resetArticleError, setArticleParams = _a.setArticleParams;
export var getArticlesComplete = createAction('GET_ARTICLES_COMPLETE', function (payload) { return payload; }, function (payload, headers, categoryId, primaryCategory, fetchType) { return ({
    headers: headers,
    categoryId: categoryId,
    primaryCategory: primaryCategory,
    fetchType: fetchType
}); });
export var searchArticle = createAction('SEARCH_ARTICLE', function (payload) { return payload; }, function (payload, sessionPath, param) { return ({ sessionPath: sessionPath, param: param }); });
export var scanArticle = createAction('SCAN_ARTICLE', function (payload) { return payload; }, function (payload, sessionPath, param) { return ({ sessionPath: sessionPath, param: param }); });
export var updateArticle = createAction('UPDATE_ARTICLE', function (payload) { return payload; }, function (payload, articleId) { return ({ articleId: articleId }); });
