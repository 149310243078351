var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import Item from './item';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-y: auto;\n  flex: 1;\n"], ["\n  overflow-y: auto;\n  flex: 1;\n"])));
var FurnitureList = function (_a) {
    var watchlist = _a.watchlist, furnitures = _a.furnitures, addAction = _a.addAction, removeAction = _a.removeAction;
    return (_jsx(Wrapper, { children: furnitures.map(function (item) { return (_jsx(Item, { item: item, watchlist: watchlist, addAction: addAction, removeAction: removeAction }, item.size.sizeId + " " + item.id)); }) }, void 0));
};
export default FurnitureList;
var templateObject_1;
