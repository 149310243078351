var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import localStore from "store";
import { processActionTypes } from "@shared/utils/constants";
import MiddleCircleCrossLine from "@shared/atoms/MiddleCircleCrossLine";
import CancelButton from "@shared/molecules/CancelButton";
import SubMenu from "organisms/SubMenu";
import { Watchlist } from "organisms/Watchlist";
import { StyledActions, StyledButton, StyledButtonContent, StyledWatchlistArea, } from "./style";
import Icon from "@shared/atoms/Icon";
var headline = defineMessages({
    headline: {
        defaultMessage: "Warenkorb ({total, number})",
        id: "watchlist.menu.headline",
    },
}).headline;
var ActionTypeEnum = {
    "request-button": "customer/add?ref=request",
    "reserve-button": "customer/add?ref=reserve",
    "order-button": "order/delivery",
    "checkout-button": "checkout/complete",
    "request-stock-button": function (sessionId) { return sessionId; },
    share: "/watchlist/share",
};
var WatchlistArea = function (_a) {
    var _b, _c;
    var deleteWatchlist = _a.deleteWatchlist, deleteWatchlistItem = _a.deleteWatchlistItem, requestWatchlist = _a.requestWatchlist, sessionId = _a.sessionId, theme = _a.theme, toggleWatchlist = _a.toggleWatchlist, visible = _a.visible, watchlist = _a.watchlist, history = _a.history;
    var intl = useIntl();
    var _d = useState(true), hideButtons = _d[0], setHideButtons = _d[1];
    var whichAction = localStore.get("addToCartClicked");
    var handleActionRequest = useCallback(function (type) {
        if (type === "share") {
            var endpoint_1 = "/watchlist/share";
            history.push("/sessions/" + sessionId + endpoint_1);
            return;
        }
        if (type === "order/delivery") {
            var endpoint_2 = "/order/delivery";
            history.push("/sessions/" + sessionId + endpoint_2);
            return;
        }
        if (type === "customer/add?ref=request") {
            var endpoint_3 = "/customer/add?ref=request";
            history.push("/sessions/" + sessionId + endpoint_3);
            return;
        }
        var actionKey = whichAction === null || whichAction === void 0 ? void 0 : whichAction.toLowerCase();
        var checktype = ActionTypeEnum[actionKey];
        var endpoint = checktype === processActionTypes.SHARE
            ? "/watchlist/share"
            : "/" + checktype;
        history.push("/sessions/" + sessionId + endpoint);
    }, [history, sessionId, whichAction]);
    var toggleButtons = function () {
        setHideButtons(!hideButtons);
    };
    var watchListStatus = localStore.get("watchlistStatus");
    var canShare = theme.switches.share || false;
    var manufacturerItem = watchListStatus === "orderable";
    var prolongedCounterAvailable = manufacturerItem &&
        watchlist &&
        ((_c = (_b = watchlist[0]) === null || _b === void 0 ? void 0 : _b.article.deliveryMode) === null || _c === void 0 ? void 0 : _c.length) > 0;
    var switches = (theme && theme.switches) || {};
    var checkoutDisabled = !watchlist || !watchlist.length || watchListStatus !== "available";
    var reserveDisabled = watchListStatus !== "available";
    var watchlistItemType = (watchListStatus === "available" ||
        watchListStatus === "inDifferentStore") &&
        whichAction !== "reserve-button";
    var checkVerkaufen = whichAction === "checkout-button";
    var renderButton = useCallback(function (key, dataCyId, action, message, disabled) {
        if (disabled === void 0) { disabled = false; }
        return (_jsx(StyledButton, __assign({ "data-cy-id": dataCyId, onClick: action, disabled: disabled }, { children: _jsx(StyledButtonContent, __assign({ disabled: disabled }, { children: _jsx(FormattedMessage, { id: "watchlist." + message, defaultMessage: message }, void 0) }), void 0) }), key));
    }, []);
    var buttons;
    if (manufacturerItem && prolongedCounterAvailable) {
        buttons = [
            renderButton("order-button", "order-button", function () { return handleActionRequest(processActionTypes.ORDER_DELIVERY); }, "Bezahlen an der Kasse"),
            renderButton("share-button", "share-button", function () { return handleActionRequest(processActionTypes.SHARE); }, "Bezahlen über Paypal"),
            canShare &&
                renderButton("share-button", "share-button", function () { return handleActionRequest(processActionTypes.SHARE); }, "Teilen", checkoutDisabled),
        ];
    }
    else {
        buttons = [
            switches.request &&
                watchlistItemType && !checkVerkaufen &&
                renderButton("request-button", "request-button", function () { return handleActionRequest(processActionTypes.REQUEST_CUSTOMER_ADD); }, "Anfordern"),
            switches.reserve &&
                !checkoutDisabled &&
                !watchlistItemType &&
                renderButton("reserve-button", "reserve-button", function () { return handleActionRequest(processActionTypes.RESERVE_CUSTOMER_ADD); }, "Reservieren", checkoutDisabled || reserveDisabled),
            switches.buy &&
                !checkoutDisabled &&
                checkVerkaufen &&
                renderButton("checkout-button", "checkout-button", function () { return handleActionRequest(processActionTypes.CHECKOUT_COMPLETE); }, "Verkaufen", checkoutDisabled),
            switches.buy &&
                canShare &&
                renderButton("share-button", "share-button", function () { return handleActionRequest(processActionTypes.SHARE); }, "Teilen"),
        ];
    }
    return (_jsxs(StyledWatchlistArea, __assign({ visible: visible }, { children: [_jsx(SubMenu, { title: intl.formatMessage(headline, {
                    total: Boolean(watchlist) ? watchlist.length : 0,
                }), cancelIcon: _jsx(CancelButton, { action: toggleWatchlist }, void 0), iconRight: _jsx(Icon, { height: "30", icon: "icon_trashcan-sm", fill: "black", onClick: function () { return deleteWatchlist(sessionId); } }, void 0) }, void 0), _jsx(Watchlist, { toggleWatchlist: toggleWatchlist, deleteWatchlistItem: deleteWatchlistItem, sessionId: sessionId, watchlist: watchlist, theme: theme, canShare: canShare }, void 0), _jsx(MiddleCircleCrossLine, { up: hideButtons, fill: "" + theme.palette.secondaryHighlight, onClick: toggleButtons }, void 0), _jsx(StyledActions, __assign({ visible: !hideButtons }, { children: buttons }), void 0)] }), void 0));
};
export default withRouter(WatchlistArea);
