// @flow
import { handleActions } from 'redux-actions';
import { fromJS, List, Map } from 'immutable';
export var initialState = Map({
    currentPage: 0,
    data: List(),
    loaded: false,
    loadedAll: false,
    loading: false,
    loadingAll: false,
    pageCount: 0,
});
var updateEntry = function (entry) { return function (existing) {
    var index = existing.findIndex(function (existingEntry) { return existingEntry.get('id') === entry.get('id'); });
    return index === -1 ? existing.push(entry) : existing.set(index, entry);
}; };
var updateEntries = function (entries) { return function (existing) {
    if (existing.size === 0) {
        return entries;
    }
    var newEntries = existing;
    entries.forEach(function (device) {
        newEntries = newEntries.update(updateEntry(device));
    });
    return entries;
}; };
var reducer = handleActions({
    GET_CATEGORY: function (state) { return state.set('loading', true); },
    GET_CATEGORY_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .update('data', updateEntry(action.payload));
        },
        throw: function (state) {
            return state.set('loading', false).set('loaded', false);
        },
    },
    GET_CATEGORIES: function (state) { return state.set('loadingAll', true); },
    GET_CATEGORIES_COMPLETE: {
        next: function (state, action) {
            var _a = action.payload.toJS(), page = _a.page, totalPages = _a.totalPages, content = _a.content;
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .set('currentPage', page)
                .set('pageCount', totalPages)
                .update('data', updateEntries(fromJS(content)));
        },
        throw: function (state) {
            return state.set('loadingAll', false).set('loadedAll', false);
        },
    },
    RESET_CATEGORIES: function (state) {
        return state.set('data', List()).set('currentPage', 0);
    },
    NO_PRIMARY_CATEGORY_ID_PROVIDED: function (state) {
        return state.set('loadingAll', false).set('loadedAll', true);
    },
}, initialState);
export default reducer;
