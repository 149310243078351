var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var initWorker = function () {
    if (navigator.serviceWorker) {
        navigator.serviceWorker.register('/worker.js');
    }
};
export var notify = function (title, options) {
    if (options === void 0) { options = {}; }
    if (window.Notification) {
        Notification.requestPermission(function (result) {
            if (result === 'granted') {
                navigator.serviceWorker.ready.then(function (registration) {
                    registration.showNotification(title, __assign({ actions: [
                            {
                                action: 'notificationclick',
                                title: 'Ansehen',
                                icon: 'appicon-notifications.png',
                            },
                        ], vibrate: [500, 300, 2000], icon: 'appicon-notifications.png', badge: 'appicon-notifications.png' }, options));
                });
            }
        });
    }
};
export var closeNotification = function (tag) {
    if (window.Notification) {
        Notification.requestPermission(function (result) {
            if (result === 'granted') {
                navigator.serviceWorker.ready.then(function (registration) {
                    registration.getNotifications({ tag: tag }).then(function (notifications) {
                        notifications.forEach(function (notification) {
                            notification.close();
                        });
                    });
                });
            }
        });
    }
};
