var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import BurgerButton from "atoms/BurgerMenu/BurgerButton";
import CartButton from "organisms/WatchlistInfo/CartButton";
import styled from "styled-components";
import Icon from "@shared/atoms/Icon";
import SubMenu from "organisms/SubMenu";
import CancelButton from "@shared/molecules/CancelButton";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import ArticleImage from "../../../assistant/articleImage/components/ArticleImage";
import { palette } from "@shared/utils/styled";
import QRCode from "qrcode.react";
var MenuWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: fixed;\n    height: 100%;\n    width: 100%;\n    right: 0;\n    z-index: 5000;\n    background-color: #ffffff;\n"], ["\n    position: fixed;\n    height: 100%;\n    width: 100%;\n    right: 0;\n    z-index: 5000;\n    background-color: #ffffff;\n"])));
var MenuEntry = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    justify-content: start;\n    align-items: center;\n    border-bottom: 1px solid #e0e0e0;\n    width: 100%;\n    padding: 1rem;\n    font-weight: 500;\n    font-size: 1.5rem;\n\n    a {\n        text-decoration: none;\n        color: #000000;\n    }\n"], ["\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    justify-content: start;\n    align-items: center;\n    border-bottom: 1px solid #e0e0e0;\n    width: 100%;\n    padding: 1rem;\n    font-weight: 500;\n    font-size: 1.5rem;\n\n    a {\n        text-decoration: none;\n        color: #000000;\n    }\n"])));
export var Button = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    box-shadow: rgba(0, 0, 0, 0.2) 0px 0.125rem 0.25rem 2px;\n    background-color: black;\n    color: ", ";\n    border: 1px solid #a9a9a9;\n    font-size: 1rem;\n    padding: 0.6rem 1.2rem;\n    border-radius: 0.2rem;\n    text-align: center;\n    width: 100%;\n    cursor: pointer;\n    transition: background-color 0.2s, box-shadow 0.2s;\n\n    &:disabled {\n        background-color: #ACAAA9;\n        cursor: not-allowed;\n    }\n"], ["\n    box-shadow: rgba(0, 0, 0, 0.2) 0px 0.125rem 0.25rem 2px;\n    background-color: black;\n    color: ", ";\n    border: 1px solid #a9a9a9;\n    font-size: 1rem;\n    padding: 0.6rem 1.2rem;\n    border-radius: 0.2rem;\n    text-align: center;\n    width: 100%;\n    cursor: pointer;\n    transition: background-color 0.2s, box-shadow 0.2s;\n\n    &:disabled {\n        background-color: #ACAAA9;\n        cursor: not-allowed;\n    }\n"])), palette('white'));
var NavigationHeader = function (_a) {
    var theme = _a.theme, cart = _a.cart, sessionId = _a.sessionId, onLogoutButtonClicked = _a.onLogoutButtonClicked, clearCart = _a.clearCart, deleteCartItem = _a.deleteCartItem;
    var _b = useState(false), showMenu = _b[0], setShowMenu = _b[1];
    var _c = useState(false), showCart = _c[0], setShowCart = _c[1];
    var intl = useIntl();
    var history = useHistory();
    var messages = defineMessages({
        cartHeadline: {
            defaultMessage: "Warenkorb ({total, number})",
            id: "watchlist.menu.headline",
        },
        noResult: {
            defaultMessage: 'Keine Einträge vorhanden.',
            id: 'cart.empty',
        },
    });
    var getLabelSize = function (item) {
        var msg = defineMessages({
            size: { defaultMessage: 'Größe', id: 'watchlist.item.size' },
        });
        if (!Boolean(item.sizeId) || !Boolean(item.article)) {
            return _jsx("span", {}, void 0);
        }
        var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
        return (_jsx("div", __assign({ style: {
                display: 'flex',
                flexDirection: 'row',
                gap: "0.25rem",
                alignItems: "center",
                justifyContent: 'center'
            } }, { children: intl.formatMessage(msg.size) + ": " + (size ? size.labelSize : '') }), void 0));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ style: {
                    display: "grid",
                    gridTemplateColumns: "50% 50%",
                    padding: "1rem",
                    position: "relative",
                    borderBottom: "1px solid #e0e0e0"
                } }, { children: [_jsx("div", __assign({ hidden: !(theme === null || theme === void 0 ? void 0 : theme.logoUrl) }, { children: _jsx(Link, __assign({ to: '/?main' }, { children: _jsx("img", { src: theme.logoUrl, alt: "logo", style: { maxHeight: '2rem', width: 'auto' } }, void 0) }), void 0) }), void 0), _jsxs("div", __assign({ style: { display: "flex", gap: "2rem", justifyContent: "end", alignItems: "center" } }, { children: [_jsx(CartButton, { cart: cart, onClick: function () {
                                    setShowMenu(false);
                                    setShowCart(!showCart);
                                } }, void 0), _jsx(BurgerButton, { showMenu: showMenu, onClick: function () {
                                    setShowCart(false);
                                    setShowMenu(!showMenu);
                                } }, void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ hidden: !showMenu }, { children: _jsx(MenuWrapper, { children: _jsxs("div", __assign({ style: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "end",
                            alignItems: "center"
                        } }, { children: [_jsx(MenuEntry, { children: _jsxs(Link, __assign({ to: "/profile", style: {
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "1rem",
                                        justifyContent: "center"
                                    } }, { children: [_jsx(Icon, { icon: "customer", width: 20 }, void 0), " Profil"] }), void 0) }, void 0), _jsxs(MenuEntry, __assign({ onClick: function () {
                                    setShowMenu(!showMenu);
                                    onLogoutButtonClicked();
                                } }, { children: [_jsx(Icon, { icon: "closex", width: 20 }, void 0), " Abmelden"] }), void 0)] }), void 0) }, void 0) }), void 0), _jsx("div", __assign({ hidden: !showCart }, { children: _jsx(MenuWrapper, { children: _jsxs("div", __assign({ style: {
                            display: "flex",
                            flexDirection: "column",
                            height: "100vh"
                        } }, { children: [_jsx("div", { children: _jsx(SubMenu, { title: intl.formatMessage(messages.cartHeadline, {
                                        total: cart.length,
                                    }), cancelIcon: _jsx(CancelButton, { action: function () {
                                            setShowCart(!showCart);
                                        } }, void 0), iconRight: _jsx(Icon, { height: "30", icon: "icon_trashcan-sm", fill: "black", onClick: function () {
                                            clearCart(sessionId);
                                        } }, void 0) }, void 0) }, void 0), _jsxs("div", __assign({ style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "2rem",
                                    padding: "1rem",
                                    paddingBottom: "12rem",
                                    overflowY: "scroll",
                                } }, { children: [_jsx("div", __assign({ hidden: (cart === null || cart === void 0 ? void 0 : cart.length) > 0 }, { children: _jsxs("div", __assign({ style: {
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            } }, { children: [_jsx(Icon, { icon: 'noRequests', width: 300 }, void 0), intl.formatMessage(messages.noResult)] }), void 0) }), void 0), _jsx("div", __assign({ hidden: (cart === null || cart === void 0 ? void 0 : cart.length) < 1 }, { children: _jsxs("div", __assign({ style: { display: "flex", flexDirection: "column", gap: "1rem" } }, { children: [_jsx("div", __assign({ style: { display: "flex", flexDirection: "column", gap: "1rem" } }, { children: cart === null || cart === void 0 ? void 0 : cart.map(function (item) {
                                                        var _a, _b;
                                                        var article = item.article;
                                                        return (_jsxs("div", __assign({ style: {
                                                                padding: "1rem",
                                                                borderRadius: "1rem",
                                                                border: "1px solid #e0e0e0",
                                                                boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.2)"
                                                            } }, { children: [_jsx("div", __assign({ style: {
                                                                        display: "flex",
                                                                        justifyContent: "end",
                                                                    }, onClick: function () {
                                                                        deleteCartItem(item.id);
                                                                    } }, { children: _jsx(Icon, { icon: "icon_trashcan-sm", width: 20 }, void 0) }), void 0), _jsxs("div", __assign({ style: {
                                                                        display: "grid",
                                                                        gridTemplateColumns: "40% 60%",
                                                                    } }, { children: [_jsx("div", __assign({ style: {
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                maxWidth: "100px"
                                                                            } }, { children: _jsx(ArticleImage, { article: article, size: "medium" }, void 0) }), void 0), _jsxs("div", __assign({ style: {
                                                                                position: "relative",
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                justifyContent: "center",
                                                                                alignItems: "start"
                                                                            } }, { children: [_jsx("div", { children: article.displayName }, void 0), _jsx("div", { children: (_a = article.v2) === null || _a === void 0 ? void 0 : _a.brand }, void 0), _jsx("div", { children: (_b = article.v2) === null || _b === void 0 ? void 0 : _b.color }, void 0), _jsx("div", { children: getLabelSize(item) }, void 0), _jsxs("div", { children: ["Fabart: ", article.fabart] }, void 0), _jsxs("div", { children: ["ArtNr.: ", article.number] }, void 0), _jsx("div", {}, void 0)] }), void 0)] }), void 0)] }), item.id));
                                                    }) }), void 0), _jsx("div", __assign({ hidden: (cart === null || cart === void 0 ? void 0 : cart.length) > 0 && cart[0].requestState === "inDifferentStore" }, { children: _jsxs("div", __assign({ style: {
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "1rem",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        } }, { children: [_jsx("div", __assign({ style: {
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    fontWeight: 500,
                                                                    textAlign: "center"
                                                                } }, { children: _jsx(FormattedMessage, { id: "watchlist.qrCode.description", defaultMessage: "\u00DCbertrage den Warenkorb zur mobilen Bezahlung oder zum weiterst\u00F6bern an deinen Kunden." }, void 0) }), void 0), _jsx(QRCode, { value: theme.customerAppUrl + "/" + sessionId + "?" + theme.customerAppId + "&p=1", fgColor: "#000", size: 150 }, void 0)] }), void 0) }), void 0), _jsxs("div", __assign({ style: {
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "1rem",
                                                        justifyContent: "center"
                                                    } }, { children: [_jsx(Button, __assign({ hidden: (cart === null || cart === void 0 ? void 0 : cart.length) > 0 && cart[0].requestState !== "inDifferentStore", onClick: function () {
                                                                var action = "/customer/add?ref=request";
                                                                history.push("/sessions/" + sessionId + action);
                                                            } }, { children: "Anfordern" }), void 0), _jsx(Button, __assign({ hidden: (cart === null || cart === void 0 ? void 0 : cart.length) > 0 && cart[0].requestState !== "checkout-button", onClick: function () {
                                                                var action = "/checkout/complete";
                                                                history.push("/sessions/" + sessionId + action);
                                                            } }, { children: "Verkaufen" }), void 0), _jsx(Button, __assign({ hidden: (cart === null || cart === void 0 ? void 0 : cart.length) > 0 && cart[0].requestState !== "reserve-button", onClick: function () {
                                                                var action = "/customer/add?ref=reserve";
                                                                history.push("/sessions/" + sessionId + action);
                                                            } }, { children: "Reservieren" }), void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0)] }), void 0) }, void 0) }), void 0)] }, void 0));
};
export default NavigationHeader;
var templateObject_1, templateObject_2, templateObject_3;
