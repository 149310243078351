var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @flow
import React from 'react';
// utils
import { fontWeight, palette } from '@shared/utils/styled';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { parse, stringify } from 'query-string';
// components
import SortList from 'molecules/SortList';
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import GoBack from 'components/molecules/GoBack';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import styled, { withTheme } from 'styled-components';
import { withRouter } from 'react-router';
var StyledButton = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    align-items: center;\n    background-color: #000;\n    color: ", ";\n    font-size: 1.25rem;\n    font-weight: ", ";\n    letter-spacing: 0.025rem;\n    justify-content: center;\n    overflow: hidden;\n    height: 3.125rem;\n    width: 75%;\n    margin: auto;\n    margin-bottom: 1rem;\n    border-radius: 8px;\n    border: 0;\n    display: flex;\n\n    &:active {\n        outline: 0;\n    }\n\n    opacity: ", ";\n    cursor: pointer;\n"], ["\n    align-items: center;\n    background-color: #000;\n    color: ", ";\n    font-size: 1.25rem;\n    font-weight: ", ";\n    letter-spacing: 0.025rem;\n    justify-content: center;\n    overflow: hidden;\n    height: 3.125rem;\n    width: 75%;\n    margin: auto;\n    margin-bottom: 1rem;\n    border-radius: 8px;\n    border: 0;\n    display: flex;\n\n    &:active {\n        outline: 0;\n    }\n\n    opacity: ", ";\n    cursor: pointer;\n"])), palette('white'), fontWeight('bold'), function (props) { return (props.disabled ? '0.7' : '1'); });
var Sort = function (_a) {
    var loading = _a.loading, currentSortId = _a.currentSortId, sorts = _a.sorts, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, session = _a.session, terminals = _a.terminals, currentAssistantId = _a.currentAssistantId, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist, location = _a.location, history = _a.history, theme = _a.theme;
    var intl = useIntl();
    var resetSorts = function () {
        var baseParams = parse(location.search, { arrayFormat: 'bracket' });
        delete baseParams.sort;
        history.push("/sessions/" + session.uuid + "/results?" + stringify(baseParams, {
            arrayFormat: 'bracket',
        }));
    };
    var hasSorting = function () {
        var baseParams = parse(location.search, { arrayFormat: 'bracket' });
        return Boolean(baseParams.sort);
    };
    var msg = defineMessages({
        filter: { defaultMessage: 'Sortieren', id: 'sort.menu.headline' },
    });
    return (_jsx(_Fragment, { children: _jsxs(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist, subMenu: _jsx(SubMenu, { title: intl.formatMessage(msg.filter), cancelIcon: _jsx(GoBack, { icon: 'back', width: '20' }, void 0) }, void 0), bottomMenu: _jsx(StyledButton, __assign({ onClick: function () { return resetSorts(); }, disabled: false === hasSorting() }, { children: _jsx(FormattedMessage, { id: 'sort.tab.cancel', defaultMessage: 'ABBRECHEN' }, void 0) }), void 0), backgroundColor: theme.palette.white, currentAssistantId: currentAssistantId }, { children: [_jsx(SortList, { loading: loading, params: location.search, session: session, currentSortId: currentSortId, sorts: sorts }, void 0), _jsx(StyledButton, __assign({ onClick: function () { return resetSorts(); }, disabled: false === hasSorting() }, { children: _jsx(FormattedMessage, { id: 'sort.tab.cancel', defaultMessage: 'ABBRECHEN' }, void 0) }), void 0)] }), void 0) }, void 0));
};
export var SortComponent = Sort;
export default withRouter(withTheme(Sort));
var templateObject_1;
