var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import Icon from '@shared/atoms/Icon';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
var Wrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  align-self: stretch;\n  justify-content: center;\n  min-width: 4.375rem;\n"], ["\n  align-items: center;\n  align-self: stretch;\n  justify-content: center;\n  min-width: 4.375rem;\n"])));
var InnerWrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 15%;\n  padding: 0.3125rem;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  border-radius: 15%;\n  padding: 0.3125rem;\n  &:active {\n    background-color: ", ";\n  }\n"])), function (props) {
    return props.hasSelections ? palette('activeWhite') : 'transparent';
}, palette('activeWhite'));
var DepartmentIcon = function (_a) {
    var action = _a.action;
    return (_jsx(Wrapper, __assign({ onClick: action }, { children: _jsx(InnerWrapper, { children: _jsx(Icon, { icon: 'drilldown' }, void 0) }, void 0) }), void 0));
};
export default DepartmentIcon;
var templateObject_1, templateObject_2;
