import { defineMessages } from 'react-intl';
var msg = defineMessages({
    customerExists: {
        defaultMessage: 'Der Kunde ist bereits vorhanden.',
        id: 'customerInformation.customerExists',
    },
    labelBirthday: {
        defaultMessage: 'Geburtsdatum',
        id: 'customerInformation.labels.birthday',
    },
    labelcustomerNo: {
        defaultMessage: 'Kundennr.',
        id: 'customerInformation.labels.customerNo',
    },
    labelEmail: {
        defaultMessage: 'E-Mail-Adresse',
        id: 'customerInformation.labels.email',
    },
    labelPhone: {
        defaultMessage: 'Telefonnr.',
        id: 'customerInformation.labels.phone',
    },
});
export default msg;
