var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { Map, List, fromJS } from 'immutable';
import localStore from 'store';
import configureStore from '@store/configure';
import history from '@store/history';
import api from 'services/api';
import App from 'components/App';
import NeedleProvider from 'providers/NeedleProvider';
import ConnectedIntlProvider from 'providers/ConnectedIntlProvider';
import { getUUID, getAuthHeader } from '@shared/utils/auth';
import { initWorker } from 'services/worker';
var deviceId = getUUID(window.location.search) || localStore.get('deviceId');
var currentUser = localStore.get('currentUserId');
var filterSelections = localStore.get('filterSelections');
var lang = localStore.get('currentLanguage');
var store = configureStore(Map({
    authorize: Map({
        currentUserId: currentUser || null,
        authorized: Boolean(currentUser),
        error: null,
    }),
    filterSelection: Map({
        data: fromJS(filterSelections) || List(),
        temp: fromJS(filterSelections) || List(),
    }),
    intl: Map({
        currentLanguage: lang || 'de',
    }),
}), {
    api: api.create({ authToken: getAuthHeader(deviceId) }),
});
localStore.remove('sizeEu');
localStore.remove('sizeUsMen');
localStore.remove('sizeUsWomen');
localStore.remove('sizeUsKids');
localStore.remove('sizeUk');
localStore.remove('mafId');
localStore.remove('sizeWms');
initWorker();
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(ConnectedIntlProvider, { children: _jsx(Router, __assign({ history: history }, { children: _jsx(NeedleProvider, __assign({ deviceId: deviceId }, { children: _jsx(App, {}, void 0) }), void 0) }), void 0) }, void 0) }), void 0) }, void 0), document.getElementById('app'));
