var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import Icon from '@shared/atoms/Icon';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Headline, MessageWrapper, SendIconWrapper, Text, } from './style';
var WatchlistShareSuccess = function () { return (_jsxs(Wrapper, __assign({ "data-cy-id": 'watchlist-share-success' }, { children: [_jsx(SendIconWrapper, { children: _jsx(Icon, { icon: 'send', width: '140' }, void 0) }, void 0), _jsxs(MessageWrapper, { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { id: 'modal.shareSuccess.headline', defaultMessage: 'Ihr Warenkorb wurde \u00FCbertragen' }, void 0) }, void 0), _jsx(Text, { children: _jsx(FormattedMessage, { id: 'modal.shareSuccess.description', defaultMessage: 'Ihr Warenkorb wurde erfolgreich an die angegebene E-Mail-Adresse verschickt' }, void 0) }, void 0)] }, void 0)] }), void 0)); };
export default WatchlistShareSuccess;
