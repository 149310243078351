// @flow
import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
export var initialState = Map({
    dashViews: 0,
});
var reducer = handleActions({
    INC_DASH_VIEWS: function (state) {
        return state.set('dashViews', state.get('dashViews') + 1);
    },
    RESET_DASH_VIEWS: function (state) { return state.set('dashViews', 0); },
}, initialState);
export default reducer;
