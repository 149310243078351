var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '@shared/utils/immutable';
import { withRouter } from 'react-router-dom';
import SessionContainer from 'containers/SessionContainer';
import Page from 'pages/CustomerAdd';
import { processActionTypes } from '@shared/utils/constants';
import { requestAction } from '@store/action/actions';
import { createCustomerLocal, createCustomer } from '@store/customer/actions';
var CustomerAdd = /** @class */ (function (_super) {
    __extends(CustomerAdd, _super);
    function CustomerAdd() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onAddCustomer = function (values, sessionId, ref, resolve, reject) {
            if (ref === processActionTypes.CHECKOUT) {
                // this.props.createCustomer(values, sessionId, resolve, reject);
                _this.props.history.push("/sessions/" + sessionId + "/checkout/complete");
            }
            else if (ref === processActionTypes.REQUEST ||
                ref === processActionTypes.RESERVE) {
                _this.props.requestAction(values, sessionId, ref, resolve, reject);
            }
            else if (ref === processActionTypes.ORDER) {
                _this.props.createCustomerLocal(values, sessionId, resolve, reject);
                _this.props.history.push("/sessions/" + sessionId + "/order/complete");
            }
            else {
                console.error('error', 'No action type defined');
            }
        };
        return _this;
    }
    CustomerAdd.prototype.render = function () {
        var _this = this;
        return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { createCustomer: _this.onAddCustomer }), void 0)); } }, void 0));
    };
    return CustomerAdd;
}(Component));
export var CustomerAddContainer = CustomerAdd;
export default withRouter(connect(null, {
    createCustomer: createCustomer,
    createCustomerLocal: createCustomerLocal,
    requestAction: requestAction,
})(toJS(CustomerAdd)));
