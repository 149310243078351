import moment from "moment";
import { idleTime } from "@shared/utils/constants";
export var getTimeDiff = function (date) {
    if (!date) {
        return "k. A.";
    }
    var diff = moment().diff(date, "minutes", true);
    var hours = Math.floor(diff / 60).toString().padStart(2, "0");
    var minutes = Math.floor(diff % 60).toString().padStart(2, "0");
    return hours + ":" + minutes;
};
export var formattedDate = function () { return moment().subtract(idleTime).format(); };
export var now = moment().format();
export var formatDuration = function (secs) {
    var minutes = Math.ceil(secs / 60);
    return minutes;
};
export var getISODate = function (day, month, year) {
    return new Date(Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10), 0, 0, 0, 0)).toISOString();
};
export var splitDate = function (date) {
    var currentDate = new Date(date);
    return {
        day: String(currentDate.getDate()),
        month: String(currentDate.getMonth() + 1),
        year: String(currentDate.getFullYear()),
    };
};
