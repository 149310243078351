var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { parse } from "query-string";
import { selectArticlePageCount, selectArticles, selectLoadingAll as selectLoadingAllArticles, } from "@store/article/selectors";
import { getArticles } from "@store/article/actions";
import { resetTempFilterSelections } from "@store/filterSelection/actions";
import { getPrimaryCategories } from "@store/primaryCategory/actions";
import { getCategory } from "@store/category/actions";
import { toJS } from "@shared/utils/immutable";
import { selectFilterDescription } from "@store/filterSelection/selectors";
import { selectCategory, selectLoading as selectLoadingCat, } from "@store/category/selectors";
import { selectPrimaryCategory, selectShouldLoad as selectShouldLoadPriCats, } from "@store/primaryCategory/selectors";
import SessionContainer from "containers/SessionContainer";
import Page from "pages/Result";
function Result(props) {
    var _a = useState(0), currentPage = _a[0], setCurrentPage = _a[1];
    var _b = useState(""), prevLocationSearch = _b[0], setPrevLocationSearch = _b[1];
    var getTitle = useCallback(function () {
        var primaryCategory = props.primaryCategory, category = props.category, location = props.location;
        var params = parse(location.search, { arrayFormat: "bracket" });
        var searchTitle = params.search ? "Suche: '" + params.search + "'" : "Suche";
        var singleTitle = !primaryCategory && category ? category.displayName : searchTitle;
        var title = primaryCategory && category
            ? primaryCategory.displayName + ": " + category.displayName
            : singleTitle;
        return title;
    }, [props.primaryCategory, props.category, props.location]);
    var fetch = useCallback(function (params, fetchType) {
        if (fetchType === void 0) { fetchType = "default"; }
        setCurrentPage(function (current) { return current + 1; });
        var pageParams = parse(props.location.search, {
            arrayFormat: "bracket",
        });
        if (!props.articlesLoading) {
            pageParams = __assign(__assign({}, pageParams), { fetch: fetchType });
            props.getArticles(__assign(__assign({}, pageParams), params));
        }
    }, [props.articlesLoading, props.getArticles, props.location.search]);
    useEffect(function () {
        var location = props.location, shouldLoadPriCats = props.shouldLoadPriCats, isLoadingCategory = props.isLoadingCategory;
        props.resetTempFilterSelections();
        var params = parse(location.search, { arrayFormat: "bracket" });
        fetch(params, "initial");
        if (params.primaryCategory && shouldLoadPriCats) {
            props.getPrimaryCategories();
        }
        if (params.category && !isLoadingCategory) {
            props.getCategory(params.category);
        }
    }, []);
    var handleDataFetching = useCallback(function () {
        var params = parse(window.location.search, {
            parseNumbers: true,
            parseBooleans: true,
            arrayFormat: "comma",
        });
        fetch(params, "update");
    }, [fetch]);
    useEffect(function () {
        var currentSearch = window.location.search;
        if (prevLocationSearch !== currentSearch) {
            handleDataFetching();
            setPrevLocationSearch(currentSearch);
        }
    }, [prevLocationSearch, handleDataFetching]);
    var articlePageCount = props.articlePageCount, articles = props.articles, articlesLoading = props.articlesLoading, filterDescription = props.filterDescription, location = props.location, primaryCategory = props.primaryCategory;
    return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { title: getTitle(), articlePage: currentPage, articlePageCount: articlePageCount, articles: articles, articlesLoading: articlesLoading, fetch: function (params) { return fetch(params, "default"); }, filterDescription: filterDescription, params: location.search, primaryCategory: primaryCategory }), void 0)); } }, void 0));
}
var select = function () { return function (state, props) { return ({
    articlePageCount: selectArticlePageCount(state),
    articles: selectArticles(state),
    articlesLoading: selectLoadingAllArticles(state),
    category: selectCategory()(state, props),
    filterDescription: selectFilterDescription(state),
    isLoadingCategory: selectLoadingCat(state),
    primaryCategory: selectPrimaryCategory()(state, props),
    shouldLoadPriCats: selectShouldLoadPriCats(state),
}); }; };
export var ResultContainer = Result;
export default withRouter(connect(select, {
    getArticles: getArticles,
    getCategory: getCategory,
    getPrimaryCategories: getPrimaryCategories,
    resetTempFilterSelections: resetTempFilterSelections,
})(toJS(Result)));
