var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import Autocomplete from 'react-autocomplete';
import Icon from '@shared/atoms/Icon';
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 1.25rem;\n"], ["\n  padding: 1.25rem;\n"])));
var InnerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  justify-content: center;\n  position: relative;\n  > div {\n    width: 100%;\n  }\n  .filterInput {\n    border: 0.0625rem solid ", ";\n    color: #5d5d5d;\n    font-size: ", ";\n    font-weight: ", ";\n    height: 2.875rem;\n    line-height: 3rem;\n    padding: 0 3rem 0 0.625rem;\n    width: 100%;\n    &:focus {\n      outline: none;\n    }\n    ::placeholder {\n      font-style: italic;\n      font-weight: ", ";\n    }\n  }\n"], ["\n  justify-content: center;\n  position: relative;\n  > div {\n    width: 100%;\n  }\n  .filterInput {\n    border: 0.0625rem solid ", ";\n    color: #5d5d5d;\n    font-size: ", ";\n    font-weight: ", ";\n    height: 2.875rem;\n    line-height: 3rem;\n    padding: 0 3rem 0 0.625rem;\n    width: 100%;\n    &:focus {\n      outline: none;\n    }\n    ::placeholder {\n      font-style: italic;\n      font-weight: ", ";\n    }\n  }\n"])), palette('border'), fontSize('large'), fontWeight('bold'), fontWeight('light'));
var StyledFilterItem = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: #ebebeb;\n  border-bottom: 0.0625rem solid white;\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  padding: 0.9375rem;\n  &:last-child {\n    border-bottom: 0;\n  }\n"], ["\n  background-color: #ebebeb;\n  border-bottom: 0.0625rem solid white;\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  padding: 0.9375rem;\n  &:last-child {\n    border-bottom: 0;\n  }\n"])), palette('secondaryHighlight'), fontSize('default'), fontWeight('bold'));
var DropDownIcon = styled(Icon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  right: 0.9375rem;\n  top: 0.875rem;\n"], ["\n  position: absolute;\n  right: 0.9375rem;\n  top: 0.875rem;\n"])));
var FilterAutocomplete = function (_a) {
    var filterItems = _a.filterItems, filterId = _a.filterId, selectItem = _a.selectItem;
    var _b = useState(''), value = _b[0], setValue = _b[1];
    var selectFilterItem = function (itemName) {
        var filterItem = filterItems.find(function (item) { return item.displayName === itemName; });
        var actionData = {
            filterId: filterId,
            // $FlowIgnoreNextLine
            filterOptionId: filterItem === null || filterItem === void 0 ? void 0 : filterItem.id,
            // $FlowIgnoreNextLine
            filterOptionName: filterItem === null || filterItem === void 0 ? void 0 : filterItem.displayName,
        };
        setValue('');
        selectItem(actionData);
    };
    var renderItem = function (item) { return (_jsx(StyledFilterItem, { children: item.displayName }, void 0)); };
    var shouldItemRender = function (item, val) {
        return item.displayName
            ? item.displayName.toLowerCase().indexOf(val.toLowerCase()) > -1
            : false;
    };
    var renderInput = function (inputProps) { return (_jsx("input", __assign({}, inputProps, { className: 'filterInput', placeholder: 'Marke' }), void 0)); };
    var dropdownStyle = {
        maxHeight: 'calc(100% - 15rem)',
        maxWidth: '100%',
        overflow: 'scroll',
        position: 'fixed',
        width: 'calc(100% - 2.5rem)',
        zIndex: 1,
        border: '0.0625rem solid white',
    };
    return (_jsx(Wrapper, { children: _jsxs(InnerWrapper, { children: [_jsx(Autocomplete, { getItemValue: function (item) { return item.displayName || ''; }, items: filterItems, menuStyle: dropdownStyle, renderInput: function (props) { return renderInput(props); }, onChange: function (event) { return setValue(event.target.value); }, onSelect: function (val) { return selectFilterItem(val); }, renderItem: function (item) { return renderItem(item); }, shouldItemRender: function (item, val) { return shouldItemRender(item, val); }, value: value }, void 0), _jsx(DropDownIcon, { icon: 'drilldown' }, void 0)] }, void 0) }, void 0));
};
export default FilterAutocomplete;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
