import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
export var initialState = Map({
    creating: false,
    data: List(),
    loaded: false,
    loading: false,
    searchResults: List(),
});
export var updateItem = function (item) { return function (existing) {
    // $FlowIgnoreNextLine
    var index = existing.findIndex(function (exitem) { return exitem.get('id') === item.get('id'); });
    if (index === -1) {
        // $FlowIgnoreNextLine
        return existing.push(item);
    }
    // $FlowIgnoreNextLine
    return existing.set(index, item);
}; };
var reducer = handleActions({
    CREATE_CUSTOMER: function (state) { return state.set('creating', true); },
    CREATE_CUSTOMER_COMPLETE: {
        next: function (state, action) {
            return state.set('creating', false).update('data', function (customers) {
                // $FlowIgnoreNextLine
                return customers.push(action.payload);
            });
        },
        throw: function (state) {
            return state.set('creating', false);
        },
    },
    CREATE_CUSTOMER_LOCAL: {
        next: function (state, action) {
            return state.set('data', action.payload);
        },
    },
    GET_CUSTOMERS: function (state) { return state.set('loading', true); },
    GET_CUSTOMERS_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .set('data', action.payload);
        },
        throw: function (state) {
            return state.set('loading', false).set('loaded', false);
        },
    },
    GET_CUSTOMER: function (state) { return state; },
    GET_CUSTOMER_COMPLETE: {
        next: function (state, action) {
            return state.update('data', updateItem(action.payload));
        },
        throw: function (state) {
            return state;
        },
    },
    UPDATE_CUSTOMER: function (state) { return state; },
    UPDATE_CUSTOMER_COMPLETE: {
        next: function (state, action) {
            return state.update('data', updateItem(action.payload));
        },
        throw: function (state) {
            return state;
        },
    },
    SEARCH_CUSTOMER: function (state) { return state.set('loading', true); },
    SEARCH_CUSTOMER_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .set('searchResults', action.payload)
                .set('data', state.get('data').merge(action.payload));
        },
        throw: function (state) {
            return state.set('loading', false).update('searchResults', List());
        },
    },
}, initialState);
export default reducer;
