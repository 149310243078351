import { createSelector } from 'reselect';
import { selectCurrentUserId } from '@store/authorize/selectors';
var selectState = function (state) { return state.get('assistant'); };
export var selectLoadingAllComplete = createSelector(selectState, function (assistantsState) {
    return !assistantsState.get('loadingAll') && assistantsState.get('loadedAll');
});
export var selectLoading = createSelector(selectState, function (assistantsState) {
    return assistantsState.get('loading');
});
export var selectAssistants = createSelector(selectState, function (assistantsState) {
    return assistantsState.get('data');
});
export var selectCurrentAssistant = createSelector([selectAssistants, selectCurrentUserId], function (assistants, assistantId) {
    return assistants.find(function (assistant) { return assistant.get('id') === assistantId; });
});
export var selectShouldLoadAssistant = createSelector([selectState, selectCurrentAssistant], function (state, currentAssistant) { return !state.loading && !Boolean(currentAssistant); });
