import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    data: List(),
    loadingAll: false,
    loadedAll: false,
    error: false,
});
export var updateAssistant = function (assistant) { return function (existingAssistants) {
    var index = existingAssistants.findIndex(function (existingAssistant) { return existingAssistant.get('id') === assistant.get('id'); });
    if (index === -1) {
        return existingAssistants.push(assistant);
    }
    return existingAssistants.set(index, assistant);
}; };
var updateAssistants = function (assistants) { return function (existingAssistants) {
    if (existingAssistants.size === 0) {
        return assistants;
    }
    var newAssistants = existingAssistants;
    assistants.forEach(function (assistant) {
        newAssistants = newAssistants.update(updateAssistant(assistant));
    });
    return newAssistants;
}; };
var reducer = handleActions({
    GET_ASSISTANT: function (state) {
        return state.set('loading', true).set('error', false);
    },
    GET_ASSISTANT_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .update('data', updateAssistant(action.payload));
        },
        throw: function (state) {
            return state.set('loading', false).set('loaded', false);
        },
    },
    GET_ASSISTANTS: function (state) { return state.set('loadingAll', true); },
    GET_ASSISTANTS_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .update('data', updateAssistants(action.payload));
        },
        throw: function (state) {
            return state.set('loadingAll', false).set('loadedAll', false);
        },
    },
}, initialState);
export default reducer;
