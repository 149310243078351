var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
// utils & libs
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { stringify, parse } from 'query-string';
import { toJS } from '@shared/utils/immutable';
// selectors
import { selectFilters, selectLoading as selectLoadingFilter, } from '@store/filter/selectors';
import { selectTempFilterSelections } from '@store/filterSelection/selectors';
// actions
import { getFilter } from '@store/filter/actions';
import { selectFilterItem, deselectFilterItem, applyTempFilterSelections, } from '@store/filterSelection/actions';
// components & container
import SessionContainer from 'containers/SessionContainer';
import Page from 'pages/FilterOptions';
var FilterOptions = /** @class */ (function (_super) {
    __extends(FilterOptions, _super);
    function FilterOptions() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getTitle = function () {
            var filter = _this.props.filters.find(function (item) { return item.id === parseInt(_this.props.match.params.filterId, 10); });
            return filter ? "Filter: " + filter.displayName : 'Filteroptionen';
        };
        _this.applyFilterSelection = function (sessionUuid) {
            var _a = _this.props, tempFilterSelections = _a.tempFilterSelections, location = _a.location;
            _this.props.applyTempFilterSelections();
            var baseParams = parse(location.search, { arrayFormat: 'bracket' });
            var filterParams = tempFilterSelections.reduce(function (acc, filterSelection) {
                if (!Boolean(filterSelection.items))
                    return acc;
                acc[filterSelection.name] = filterSelection.items.reduce(function (flat, item) {
                    flat.push(item.id);
                    return flat;
                }, []);
                return acc;
            }, baseParams);
            _this.props.history.push({
                pathname: "/sessions/" + sessionUuid + "/results",
                search: "?" + stringify(filterParams, { arrayFormat: 'bracket' }),
            });
        };
        return _this;
    }
    FilterOptions.prototype.componentDidMount = function () {
        var params = parse(this.props.location.search, {
            arrayFormat: 'bracket',
        });
        if (!this.props.filterLoading) {
            this.props.getFilter(this.props.match.params.filterId, __assign({}, params));
        }
    };
    FilterOptions.prototype.render = function () {
        var _this = this;
        var _a = this.props, filterLoading = _a.filterLoading, filters = _a.filters, location = _a.location, match = _a.match, tempFilterSelections = _a.tempFilterSelections;
        var filter = filters.find(function (item) { return item.id === parseInt(match.params.filterId, 10); });
        return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { applyFilterSelection: function () {
                    return _this.applyFilterSelection(sessionProps.session.uuid);
                }, deselectFilterItem: _this.props.deselectFilterItem, filter: filter, filterLoading: filterLoading, filterSelections: tempFilterSelections, params: location.search, selectFilterItem: _this.props.selectFilterItem, title: _this.getTitle() }), void 0)); } }, void 0));
    };
    return FilterOptions;
}(Component));
var select = function () { return function (state) { return ({
    filters: selectFilters(state),
    filterLoading: selectLoadingFilter(state),
    tempFilterSelections: selectTempFilterSelections(state),
}); }; };
export default withRouter(connect(select, {
    getFilter: getFilter,
    selectFilterItem: selectFilterItem,
    deselectFilterItem: deselectFilterItem,
    applyTempFilterSelections: applyTempFilterSelections,
})(toJS(FilterOptions)));
