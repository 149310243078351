var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
// utils
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toJS } from '@shared/utils/immutable';
// actions
import { logout } from '@store/authorize/actions';
import { getAssistant } from '@store/assistant/actions';
// selectors
import { selectCurrentUserId } from '@store/authorize/selectors';
import { selectCurrentAssistant, selectLoading as selectAssistantLoading, selectShouldLoadAssistant, } from '@store/assistant/selectors';
// components
import ProfilePage from 'pages/Profile';
var Profile = /** @class */ (function (_super) {
    __extends(Profile, _super);
    function Profile(props) {
        var _this = _super.call(this, props) || this;
        _this.performLogout = function () {
            _this.props.logout({ assistantId: _this.props.currentUserId });
            localStorage.removeItem("sessionuuid");
        };
        return _this;
    }
    Profile.prototype.componentDidMount = function () {
        if (this.props.shouldLoadAssistant) {
            this.props.getAssistant(this.props.currentUserId);
        }
    };
    Profile.prototype.render = function () {
        var _a = this.props, currentAssistant = _a.currentAssistant, assistantLoading = _a.assistantLoading;
        return (_jsx(ProfilePage, { assistantLoading: assistantLoading, currentAssistant: currentAssistant, logout: this.performLogout }, void 0));
    };
    return Profile;
}(Component));
var select = function () { return function (state) { return ({
    assistantLoading: selectAssistantLoading(state),
    currentAssistant: selectCurrentAssistant(state),
    currentUserId: selectCurrentUserId(state),
    shouldLoadAssistant: selectShouldLoadAssistant(state),
}); }; };
export var ProfileContainer = Profile;
export default withRouter(connect(select, { logout: logout, getAssistant: getAssistant })(toJS(Profile)));
