var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
// utils
import { palette, fontWeight } from '@shared/utils/styled';
import { hasDiscounts } from '@shared/utils/currency';
import { getImageUrl } from '@shared/utils/images';
// components
import PriceTag from '@shared/molecules/PriceTag';
import DiscountBadgeCheckout from 'molecules/DiscountBadgeCheckout';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import VerticalFlex from 'atoms/VerticalFlex';
var Wrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  justify-content: flex-start;\n"], ["\n  justify-content: flex-start;\n"])));
var ThumbnailImage = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  font-size: 0;\n  justify-content: center;\n  margin-right: 0.625rem;\n  padding: 0.625rem 0;\n  position: relative;\n  width: 5.1875rem;\n  img {\n    border: 0.0625rem solid ", ";\n    max-width: 100%;\n    width: 100%;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  font-size: 0;\n  justify-content: center;\n  margin-right: 0.625rem;\n  padding: 0.625rem 0;\n  position: relative;\n  width: 5.1875rem;\n  img {\n    border: 0.0625rem solid ", ";\n    max-width: 100%;\n    width: 100%;\n  }\n"])), palette('border'));
var Info = styled(VerticalFlex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  flex: 7;\n  line-height: 1.25rem;\n  margin-top: 0.625rem;\n  max-width: 78%; // Needed for inner text-overflow\n  padding: 0.625rem 0;\n"], ["\n  color: ", ";\n  flex: 7;\n  line-height: 1.25rem;\n  margin-top: 0.625rem;\n  max-width: 78%; // Needed for inner text-overflow\n  padding: 0.625rem 0;\n"])), palette('text'));
var Headline = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: ", ";\n  margin: 0;\n  overflow: hidden;\n  padding-right: 2rem;\n  text-overflow: ellipsis;\n  text-transform: uppercase;\n  white-space: nowrap;\n"], ["\n  color: ", ";\n  font-weight: ", ";\n  margin: 0;\n  overflow: hidden;\n  padding-right: 2rem;\n  text-overflow: ellipsis;\n  text-transform: uppercase;\n  white-space: nowrap;\n"])), palette('text'), fontWeight('bold'));
var Description = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"], ["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"])));
export var CheckoutArticleInfo = function (_a) {
    var article = _a.article, children = _a.children;
    var brand = article.brand, displayName = article.displayName, images = article.images, prices = article.prices, targetPrices = article.targetPrices;
    return (_jsxs(Wrapper, { children: [_jsxs(ThumbnailImage, { children: [_jsx("img", { src: getImageUrl(images.main), alt: '' }, void 0), hasDiscounts(prices, targetPrices) && _jsx(DiscountBadgeCheckout, {}, void 0)] }, void 0), _jsxs(Info, { children: [_jsxs(Headline, { children: [brand && brand.name && brand.name + ", ", displayName] }, void 0), _jsx(Description, { children: article.color }, void 0), _jsx("span", { children: prices && (_jsx(PriceTag, { prices: prices, targetPrices: targetPrices, fromAssistant: true }, void 0)) }, void 0), children] }, void 0)] }, void 0));
};
export default CheckoutArticleInfo;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
