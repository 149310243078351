var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from "styled-components";
import { palette } from "@shared/utils/styled";
var InnerWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 2.5rem;\n    margin-top: 0.0635rem;\n    opacity: 0.5;\n    position: relative;\n    transform: rotate(0deg);\n    transition: 0.5s ease-in-out;\n    width: 1.375rem;\n"], ["\n    height: 2.5rem;\n    margin-top: 0.0635rem;\n    opacity: 0.5;\n    position: relative;\n    transform: rotate(0deg);\n    transition: 0.5s ease-in-out;\n    width: 1.375rem;\n"])));
var Line = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-color: ", ";\n    border-radius: 0.375rem;\n    display: block;\n    height: 0.125rem;\n    left: 0;\n    opacity: 1;\n    position: absolute;\n    transform: rotate(0deg);\n    transition: 0.25s ease-in-out;\n    user-select: none;\n    width: 100%;\n\n    &:nth-child(1) {\n        left: ", ";\n        top: ", ";\n        width: ", ";\n    }\n\n    &:nth-child(2) {\n        top: 1.125rem;\n        transform: ", ";\n    }\n\n    &:nth-child(3) {\n        top: 1.125rem;\n        transform: ", ";\n    }\n\n    &:nth-child(4) {\n        left: ", ";\n        top: ", ";\n        width: ", ";\n    }\n"], ["\n    background-color: ", ";\n    border-radius: 0.375rem;\n    display: block;\n    height: 0.125rem;\n    left: 0;\n    opacity: 1;\n    position: absolute;\n    transform: rotate(0deg);\n    transition: 0.25s ease-in-out;\n    user-select: none;\n    width: 100%;\n\n    &:nth-child(1) {\n        left: ", ";\n        top: ", ";\n        width: ", ";\n    }\n\n    &:nth-child(2) {\n        top: 1.125rem;\n        transform: ", ";\n    }\n\n    &:nth-child(3) {\n        top: 1.125rem;\n        transform: ", ";\n    }\n\n    &:nth-child(4) {\n        left: ", ";\n        top: ", ";\n        width: ", ";\n    }\n"])), palette('black'), function (props) { return (props.menuVisible ? '50%' : '0'); }, function (props) { return (props.menuVisible ? '0.875rem' : '0.625rem'); }, function (props) { return (props.menuVisible ? '0%' : '100%'); }, function (props) { return (props.menuVisible ? 'rotate(45deg)' : 'none'); }, function (props) { return (props.menuVisible ? 'rotate(-45deg)' : 'none'); }, function (props) { return (props.menuVisible ? '50%' : '0'); }, function (props) { return (props.menuVisible ? '1rem' : '1.625rem'); }, function (props) { return (props.menuVisible ? '0' : '100%'); });
var BurgerButton = function (_a) {
    var showMenu = _a.showMenu, onClick = _a.onClick;
    return (_jsx("div", { children: _jsxs(InnerWrapper, __assign({ menuVisible: showMenu, onClick: onClick }, { children: [_jsx(Line, { menuVisible: showMenu }, void 0), _jsx(Line, { menuVisible: showMenu }, void 0), _jsx(Line, { menuVisible: showMenu }, void 0), _jsx(Line, { menuVisible: showMenu }, void 0)] }), void 0) }, void 0));
};
export default BurgerButton;
var templateObject_1, templateObject_2;
