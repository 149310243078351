import { defineMessages } from 'react-intl';
export var SizeSelectMessageHeaders;
(function (SizeSelectMessageHeaders) {
    SizeSelectMessageHeaders["headline"] = "headline";
    SizeSelectMessageHeaders["available"] = "available";
    SizeSelectMessageHeaders["inDifferentStore"] = "inDifferentStore";
    SizeSelectMessageHeaders["inStock"] = "inStock";
    SizeSelectMessageHeaders["orderable"] = "orderable";
    SizeSelectMessageHeaders["scheduled"] = "scheduled";
})(SizeSelectMessageHeaders || (SizeSelectMessageHeaders = {}));
var msg = defineMessages({
    headline: {
        defaultMessage: "Gr\u00F6\u00DFe w\u00E4hlen",
        id: 'checkoutSizeSelect.menu.headline',
    },
    available: {
        defaultMessage: 'verfügbar',
        id: 'checkoutSizeSelect.sizeLabels.available',
    },
    inDifferentStore: {
        defaultMessage: 'in anderer Filiale',
        id: 'checkoutSizeSelect.sizeLabels.inDifferentStore',
    },
    inStock: {
        defaultMessage: 'lieferbar aus Zentrallager',
        id: 'checkoutSizeSelect.sizeLabels.inStock',
    },
    orderable: {
        defaultMessage: 'lieferbar über Lieferant',
        id: 'checkoutSizeSelect.sizeLabels.orderable',
    },
    scheduled: {
        defaultMessage: 'disponiert',
        id: 'checkoutSizeSelect.sizeLabels.scheduled',
    },
});
export default msg;
