var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
// utils
import styled from 'styled-components';
import { key, palette, fontWeight } from '@shared/utils/styled';
// mockdata
// components
import ControlledSlider from '@shared/molecules/ControlledSlider';
import SliderArticleExtended from 'molecules/SliderArticleExtended';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 1.25rem;\n"], ["\n  margin-bottom: 1.25rem;\n"])));
var Headline = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 1.25rem;\n  font-weight: ", ";\n  margin-bottom: 1.25rem;\n  padding: 1.25rem 0.9375rem;\n  text-align: center;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    font-size: 1.5rem;\n  }\n"], ["\n  color: ", ";\n  font-size: 1.25rem;\n  font-weight: ", ";\n  margin-bottom: 1.25rem;\n  padding: 1.25rem 0.9375rem;\n  text-align: center;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    font-size: 1.5rem;\n  }\n"])), palette('text'), fontWeight('bold'), key('breakpoints.medium'));
var Slide = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 60% !important;\n  padding-left: 0.9375rem;\n"], ["\n  width: 60% !important;\n  padding-left: 0.9375rem;\n"])));
var SimilarWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  grid-column: 1 / 3;\n  min-height: 0;\n  min-width: 0;\n"], ["\n  grid-column: 1 / 3;\n  min-height: 0;\n  min-width: 0;\n"])));
var ArticleSlider = function (_a) {
    var articles = _a.articles, headline = _a.headline, session = _a.session;
    return (_jsx(Wrapper, { children: _jsxs(SimilarWrapper, { children: [Boolean(headline) && _jsx(Headline, { children: headline }, void 0), _jsx(ControlledSlider, __assign({ config: { slidesPerView: 'auto', spaceBetween: 0 } }, { children: articles.map(function (article) {
                        return (_jsx(Slide, { children: _jsx(SliderArticleExtended, { session: session, article: article }, void 0) }, article.id));
                    }) }), void 0)] }, void 0) }, void 0));
};
export default ArticleSlider;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
