var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from 'reselect';
import theme from '@shared/themes/default';
import { reversePalette } from 'styled-theme/composer';
import { darken } from 'polished';
import { fromJS } from 'immutable';
var buildPalette = function (colors) {
    var pureColors = colors.toJS();
    var activePrimaryHighlight = darken(0.07, colors.get('primaryHighlight')) ||
        theme.palette.activePrimaryHighlight;
    var activeSecondaryBackground = darken(0.07, colors.get('secondaryBackground')) ||
        theme.palette.activeSecondaryBackground;
    var activeSecondaryHighlight = darken(0.07, colors.get('secondaryHighlight')) ||
        theme.palette.activeSecondaryHighlight;
    return __assign(__assign(__assign({}, theme.palette), pureColors), { activePrimaryHighlight: activePrimaryHighlight, activeSecondaryBackground: activeSecondaryBackground, activeSecondaryHighlight: activeSecondaryHighlight });
};
var buildIcons = function (icons) { return (__assign(__assign({}, theme.icons), icons.toJS())); };
export var selectThemeState = function (state) { return state.get('theme'); };
export var selectTheme = createSelector(selectThemeState, function (themeState) {
    return themeState.get('data');
});
export var selectFullTheme = createSelector(selectTheme, function (themeData) {
    if (themeData.size > 0) {
        return fromJS(__assign(__assign({}, theme), { palette: buildPalette(themeData.get('colors')), reversePalette: reversePalette(buildPalette(themeData.get('colors'))), icons: buildIcons(themeData.get('icons')), logoUrl: themeData.get('logoUrl'), switches: themeData.get('switches') || {
                buy: true,
                request: true,
                reserve: true,
            }, customerAppUrl: themeData.get('customerAppUrl'), customerAppId: themeData.get('customerAppId'), customerId: themeData.get('customerId'), breakpoints: {
                xs: 0,
                phone: 400,
                tablet: 768,
                lg: 992,
                xl: 1200,
            } }));
    }
    return fromJS(theme);
});
