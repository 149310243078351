var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { palette } from 'styled-tools';
import Icon from '../Icon';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  position: relative;\n  width: 100%;\n  border-top: 1px solid ", ";\n  margin: 2rem 0;\n"], ["\n  cursor: pointer;\n  position: relative;\n  width: 100%;\n  border-top: 1px solid ", ";\n  margin: 2rem 0;\n"])), palette('border'));
var HalfCircle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  width: 50px;\n  height: 25px;\n  border: 1px solid ", ";\n  background-color: white;\n  right: calc(50% - 25px);\n\n  ", "\n\n  ", "\n"], ["\n  position: absolute;\n  width: 50px;\n  height: 25px;\n  border: 1px solid ", ";\n  background-color: white;\n  right: calc(50% - 25px);\n\n  ", "\n\n  ", "\n"])), palette('border'), function (_a) {
    var up = _a.up;
    return !up &&
        "\n  border-bottom-left-radius: 27px;\n  border-bottom-right-radius: 27px;\n  border-top: 0;\n  top: -1px;\n";
}, function (_a) {
    var up = _a.up;
    return up &&
        "\n  border-top-left-radius: 27px;\n  border-top-right-radius: 27px;\n  border-bottom: 0;\n  bottom: 0px;\n";
});
var CircleIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  font-size: 25pt;\n  ", "\n\n  ", "\n"], ["\n  position: absolute;\n  font-size: 25pt;\n  ", "\n\n  ", "\n"])), function (_a) {
    var up = _a.up;
    return !up &&
        "\n    left: 9px;\n    bottom: -2px;  \n";
}, function (_a) {
    var up = _a.up;
    return up &&
        "\nright: 6px;\n";
});
var MiddleCircleCrossLine = function (_a) {
    var up = _a.up, fill = _a.fill, onClick = _a.onClick;
    return (_jsx(Wrapper, { children: _jsx(HalfCircle, __assign({ up: up, onClick: function () { return onClick(); } }, { children: _jsx(CircleIcon, { icon: up ? 'icon_arrow_up' : 'icon_arrow_down', fill: fill, up: up }, void 0) }), void 0) }, void 0));
};
export default MiddleCircleCrossLine;
var templateObject_1, templateObject_2, templateObject_3;
