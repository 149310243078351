var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
// utils & libs
import styled, { withTheme } from 'styled-components';
import sortBy from 'lodash/sortBy';
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
// components
import { FormattedMessage } from 'react-intl';
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import GoBack from 'molecules/GoBack';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import FilterOptionGrid from 'molecules/FilterOptionGrid';
import FilterOptionSearch from 'molecules/FilterOptionSearch';
import FilterOptionList from 'molecules/FilterOptionList';
var ConfirmButton = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.025rem;\n  justify-content: center;\n  overflow: hidden;\n  height: 3.125rem;\n  width: 75%;\n  margin: auto;\n  border-radius: 8px;\n  border: 0;\n  display: flex;\n  &:active {\n    outline: 0;\n  }\n  opacity: ", ";\n  cursor: pointer;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.025rem;\n  justify-content: center;\n  overflow: hidden;\n  height: 3.125rem;\n  width: 75%;\n  margin: auto;\n  border-radius: 8px;\n  border: 0;\n  display: flex;\n  &:active {\n    outline: 0;\n  }\n  opacity: ", ";\n  cursor: pointer;\n"])), palette('primaryHighlight'), palette('white'), fontSize('large'), fontWeight('bold'), function (props) { return (props.disabled ? '0.7' : '1'); });
var FilterOptions = function (_a) {
    var applyFilterSelection = _a.applyFilterSelection, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, deleteSession = _a.deleteSession, deselectFilterItem = _a.deselectFilterItem, filter = _a.filter, filterLoading = _a.filterLoading, filterSelections = _a.filterSelections, selectFilterItem = _a.selectFilterItem, session = _a.session, terminals = _a.terminals, title = _a.title, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, theme = _a.theme, currentAssistantId = _a.currentAssistantId, deleteWatchlistItem = _a.deleteWatchlistItem, watchlist = _a.watchlist;
    var isOptionActive = function (filterOptionId) {
        var filterSelection = filterSelections.find(function (item) { return item.id === filter.id; });
        // $FlowIgnoreNextLine
        if (!Boolean(filterSelection) || !Boolean(filterSelection === null || filterSelection === void 0 ? void 0 : filterSelection.items)) {
            return false;
        }
        return Boolean(filterSelection === null || filterSelection === void 0 ? void 0 : filterSelection.items.find(function (item) { return item.id === filterOptionId; }));
    };
    var sortedItems = filter && filter.items ? sortBy(filter.items, 'displayName') : null;
    return (_jsxs(SessionLayout, __assign({ currentAssistantId: currentAssistantId, deleteWatchlistItem: deleteWatchlistItem, backgroundColor: theme.palette.white, assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, bottomMenu: _jsx(ConfirmButton, __assign({ onClick: function () { return applyFilterSelection(); } }, { children: _jsx(FormattedMessage, { defaultMessage: 'Filter anwenden', id: 'filter.options.action' }, void 0) }), void 0), deleteSession: deleteSession, session: session, subMenu: _jsx(SubMenu, { title: title, cancelIcon: _jsx(GoBack, { icon: 'back', width: '20' }, void 0) }, void 0), terminals: terminals, unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist }, { children: [sortedItems && filter.name === 'easColor' && (_jsx(FilterOptionGrid, { deselectFilterItem: deselectFilterItem, filterId: filter.id, filterItems: sortedItems, filterLoading: filterLoading, isOptionActive: isOptionActive, selectFilterItem: selectFilterItem }, void 0)), sortedItems && filter.name === 'brand' && (_jsx(FilterOptionSearch, { deselectFilterItem: deselectFilterItem, filterId: filter.id, filterItems: sortedItems, filterLoading: filterLoading, filterSelections: filterSelections, isOptionActive: isOptionActive, selectFilterItem: selectFilterItem }, void 0)), sortedItems &&
                filter.type !== 'search' &&
                filter.name !== 'easColor' &&
                filter.name !== 'brand' && (_jsx(FilterOptionList, { deselectFilterItem: deselectFilterItem, filterId: filter.id, filterItems: sortedItems, filterLoading: filterLoading, isOptionActive: isOptionActive, selectFilterItem: selectFilterItem, filterName: filter.name }, void 0))] }), void 0));
};
export default withTheme(FilterOptions);
var templateObject_1;
