var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useState } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { parse } from "query-string";
import CustomerFormAdd from "molecules/CustomerFormAdd";
import GoBack from "molecules/GoBack";
import SubMenu from "organisms/SubMenu";
import SessionLayout from "templates/SessionLayout";
import { fontWeight, palette } from "@shared/utils/styled";
import { processActionTypes } from "@shared/utils/constants";
// translations
import msg from "./messages";
var BottomMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  // margin-bottom: 50px;\n  height: 3.25rem;\n  position: relative;\n"], ["\n  // margin-bottom: 50px;\n  height: 3.25rem;\n  position: relative;\n"])));
var BottomMenuConfirm = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: none;\n  cursor: pointer;\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  height: 100%;\n  justify-content: center;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  width: 100%;\n  & > span {\n    opacity: ", ";\n  }\n"], ["\n  border: none;\n  cursor: pointer;\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  height: 100%;\n  justify-content: center;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  width: 100%;\n  & > span {\n    opacity: ", ";\n  }\n"])), palette("black"), palette("white"), fontWeight("bold"), function (props) { return (props.disabled ? "0.3" : "1"); });
var CustomerAdd = function (_a) {
    var location = _a.location, session = _a.session, createCustomer = _a.createCustomer, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, terminals = _a.terminals, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist;
    var intl = useIntl();
    var _b = useState(true), isSubmitDisabled = _b[0], setIsSubmitDisabled = _b[1];
    var updateSubmitState = function (value) {
        setIsSubmitDisabled(value);
    };
    var onCustomerFormAddSubmit = function (values) {
        var search = parse(location.search);
        return new Promise(function (resolve, reject) {
            return createCustomer(values, session.uuid, search.ref, resolve, reject);
        });
    };
    var renderBottomMenu = function () {
        var _a;
        var search = parse(location.search);
        return (_jsx(BottomMenu, { children: _jsx(BottomMenuConfirm, __assign({ "data-cy-id": "add-customer", disabled: isSubmitDisabled, type: "submit", form: "customer-add" }, { children: (_a = {},
                    _a[processActionTypes.RESERVE] = (_jsx(FormattedMessage, { defaultMessage: "Reservieren", id: "customerAdd.bottomMenu.reserve" }, void 0)),
                    _a[processActionTypes.REQUEST] = (_jsx(FormattedMessage, { defaultMessage: "Anfordern", id: "customerAdd.bottomMenu.request" }, void 0)),
                    _a[processActionTypes.CHECKOUT] = (_jsx(FormattedMessage, { defaultMessage: "Verkaufen", id: "customerAdd.bottomMenu.checkout" }, void 0)),
                    _a[processActionTypes.ORDER] = (_jsx(FormattedMessage, { defaultMessage: "Bezahlen an der Kasse", id: "customerAdd.bottomMenu.order" }, void 0)),
                    _a)[search.ref] }), void 0) }, void 0));
    };
    return (_jsxs(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, bottomMenu: renderBottomMenu(), currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, subMenu: _jsx(SubMenu, { noBack: true, iconRight: _jsx(GoBack, { icon: "closex", width: "1.4375rem" }, void 0), title: intl.formatMessage(msg.customer) }, void 0), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist }, { children: [_jsx(CustomerFormAdd, { onSubmit: onCustomerFormAddSubmit, updateSubmitState: updateSubmitState, session: session }, void 0), renderBottomMenu()] }), void 0));
};
export var CustomerAddComponent = CustomerAdd;
export default withRouter(CustomerAdd);
var templateObject_1, templateObject_2;
