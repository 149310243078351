var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
// utils
import { fontSize, fontWeight, palette } from '@shared/utils/styled';
import { getPriceString } from '@shared/utils/currency';
import { rare, processActionTypes } from '@shared/utils/constants';
// components
import GoBack from 'molecules/GoBack';
import SubMenu from 'organisms/SubMenu';
import CancelButton from '@shared/molecules/CancelButton';
import HighlightButton from '@shared/atoms/HighlightButton';
import CheckoutAvailableGroups from 'molecules/CheckoutAvailableGroups';
import SessionLayout from 'templates/SessionLayout';
var BottomMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 3.25rem;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n"], ["\n  height: 3.25rem;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n"])));
var BottomMenuPrev = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  font-weight: ", ";\n  display: flex;\n  justify-content: center;\n  overflow: hidden;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  font-weight: ", ";\n  display: flex;\n  justify-content: center;\n  overflow: hidden;\n"])), palette('white'), palette('linkText'), fontWeight('bold'));
var BottomMenuNext = styled(HighlightButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  border: 0.125rempx solid ", ";\n  color: ", ";\n  display: flex;\n  font-size: ", ";\n  font-weight: ", ";\n  justify-content: center;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n\n  & > span {\n    opacity: ", ";\n  }\n"], ["\n  align-items: center;\n  background-color: ", ";\n  border: 0.125rempx solid ", ";\n  color: ", ";\n  display: flex;\n  font-size: ", ";\n  font-weight: ", ";\n  justify-content: center;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n\n  & > span {\n    opacity: ", ";\n  }\n"])), palette('primaryHighlight'), palette('white'), palette('white'), fontSize('default'), fontWeight('bold'), function (props) {
    return props.disabled
        ? props.theme.palette.primaryHighlight
        : props.theme.palette.activePrimaryHighlight;
}, function (props) { return (props.disabled ? '0.3' : '1'); });
var PriceLabel = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
var CheckoutAvailability = function (_a) {
    var assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, session = _a.session, terminals = _a.terminals, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, updateWatchlistItem = _a.updateWatchlistItem, watchlist = _a.watchlist, match = _a.match, history = _a.history, furnitures = _a.furnitures, requestWatchlist = _a.requestWatchlist, furnituresReady = _a.furnituresReady, getFurnitures = _a.getFurnitures, archiveSession = _a.archiveSession;
    var intl = useIntl();
    var getProccessActionType = function () {
        return match && match.params && match.params.type
            ? match.params.type
            : processActionTypes.CHECKOUT;
    };
    var cancelCheckout = function () {
        history.push("/sessions/" + session.uuid);
    };
    var getTotalPrice = function () {
        var total = watchlist.reduce(function (acc, item) {
            if (!item.selected) {
                return acc;
            }
            if (item.sizeId) {
                var si = item.article.sizes.find(function (size) { return size.sizeId === item.sizeId; });
                return si ? acc + si.price : acc;
            }
            else if (item.article.sizes.length === 0 && item.article.isAccessoire) {
                return item.article.prices.min + acc;
            }
            return acc;
        }, 0);
        return getPriceString(total);
    };
    var nextStepAvailable = function () {
        var filtered = watchlist.filter(function (item) { return item.selected; });
        if (!Boolean(filtered.length)) {
            return false;
        }
        return !Boolean(filtered.find(function (item) {
            return item.sizeId === null &&
                !(item.article.isAccessoire && item.article.sizes.length === 0);
        }));
    };
    var getNextTarget = function () {
        var type = getProccessActionType();
        var deliverables = rare.reduce(function (acc, item) {
            var items = watchlist.filter(function (wItem) {
                if (!wItem.selected) {
                    return false;
                }
                var size = wItem.article.sizes.find(function (si) { return si.sizeId === wItem.sizeId; });
                if (!Boolean(size)) {
                    return false;
                }
                return size && item === size.stock;
            });
            if (items.length > 0) {
                acc.push(item);
            }
            return acc;
        }, []);
        if (furnitures.length && type === processActionTypes.CHECKOUT) {
            return "/sessions/" + session.uuid + "/" + type + "/furniture";
        }
        if (deliverables.length > 0) {
            return "/sessions/" + session.uuid + "/" + type + "/delivery";
        }
        return "/sessions/" + session.uuid + "/" + type + "/complete";
    };
    useEffect(function () {
        if (!furnituresReady) {
            getFurnitures();
        }
    }, [furnituresReady, getFurnitures]);
    var renderBottomMenu = function () {
        return (_jsxs(BottomMenu, { children: [_jsx(BottomMenuPrev, { children: _jsx(PriceLabel, { children: getTotalPrice() }, void 0) }, void 0), _jsx(BottomMenuNext, __assign({ disabled: !nextStepAvailable(), onClick: function () { return history.push(getNextTarget()); } }, { children: _jsx(FormattedMessage, { defaultMessage: 'Weiter', id: 'checkout.bottomMenu.next' }, void 0) }), void 0)] }, void 0));
    };
    var msgCollection = {
        checkout: defineMessages({
            customer: {
                defaultMessage: 'Verkaufen',
                id: 'checkout.availabilty.menu.headline',
            },
        }),
        reserve: defineMessages({
            customer: {
                defaultMessage: 'Reservieren',
                id: 'reserve.availabilty.menu.headline',
            },
        }),
        request: defineMessages({
            customer: {
                defaultMessage: 'Anfordern',
                id: 'request.availabilty.menu.headline',
            },
        }),
        order: defineMessages({
            customer: {
                defaultMessage: 'Bezahlen an der Kasse',
                id: 'order.availability.menu.headline',
            },
        }),
    };
    var type = getProccessActionType();
    var msg = msgCollection[type];
    return (_jsx(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, subMenu: _jsx(SubMenu, { cancelIcon: _jsx(GoBack, { icon: 'iconBack', width: '15' }, void 0), iconRight: _jsx(CancelButton, { action: cancelCheckout }, void 0), title: intl.formatMessage(msg.customer) }, void 0), requestWatchlist: requestWatchlist, bottomMenu: renderBottomMenu(), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist, archiveSession: archiveSession }, { children: _jsx(CheckoutAvailableGroups, { watchlist: watchlist, updateWatchlistItem: updateWatchlistItem, type: type }, void 0) }), void 0));
};
export var CheckoutAvailabilityComponent = CheckoutAvailability;
export default withRouter(CheckoutAvailability);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
