var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import Icon from "@shared/atoms/Icon";
var CartButton = function (_a) {
    var cart = _a.cart, onClick = _a.onClick;
    var _b = useState(""), cartStatus = _b[0], setCartStatus = _b[1];
    useEffect(function () {
        if ((cart === null || cart === void 0 ? void 0 : cart.length) < 1) {
            setCartStatus("");
            return;
        }
        if (cart[0].requestState === "checkout-button") {
            setCartStatus("V");
            return;
        }
        if (cart[0].requestState === "request-button") {
            setCartStatus("A");
            return;
        }
        if (cart[0].requestState === "reserve-button") {
            setCartStatus("R");
            return;
        }
        if (cart[0].requestState === "inDifferentStore") {
            setCartStatus("A");
            return;
        }
        if (cart[0].requestState === "orderable") {
            setCartStatus("B");
            return;
        }
    }, [cart]);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ onClick: onClick, style: { position: "relative", display: "flex", alignItems: "center", justifyContent: "center" } }, { children: [_jsx("div", { children: _jsx(Icon, { icon: "warenkorb", width: "35", height: "35" }, void 0) }, void 0), _jsx("div", __assign({ style: { position: "absolute", fontWeight: 500, left: "0.75rem", top: "0.75rem" } }, { children: cartStatus }), void 0), _jsx("div", __assign({ hidden: (cart === null || cart === void 0 ? void 0 : cart.length) < 1, style: {
                        position: "absolute",
                        top: "-0.5rem",
                        left: "1.25rem",
                        backgroundColor: "#000000",
                        color: "#ffffff",
                        borderRadius: "1rem",
                        padding: "0.25rem",
                        minWidth: "1.5rem",
                        display: "flex",
                        justifyContent: "center"
                    } }, { children: cart === null || cart === void 0 ? void 0 : cart.length }), void 0)] }), void 0) }, void 0));
};
export default CartButton;
