var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled, { withTheme } from 'styled-components';
// utils
import { palette, fontWeight, fontSize } from '@shared/utils/styled';
// singlton
import history from '@store/history';
// components
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import CancelButton from '@shared/molecules/CancelButton';
import FlexButton from '@shared/atoms/FlexButton';
import Icon from '@shared/atoms/Icon';
import VerticalFlex from 'atoms/VerticalFlex';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
var BottomMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n"], ["\n  display: flex;\n  height: 100%;\n"])));
var StyledButton = styled(FlexButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  font-weight: ", ";\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  font-weight: ", ";\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('primaryHighlight'), palette('white'), fontWeight('bold'), palette('activePrimaryHighlight'));
var IconWrapper = styled(HorizontalFlex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  justify-content: center;\n  padding: 2rem 0 0;\n"], ["\n  align-items: center;\n  color: ", ";\n  justify-content: center;\n  padding: 2rem 0 0;\n"])), palette('selectedWhite'));
var Wrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.4px;\n  line-height: 1.25rem;\n  padding: 0 3rem;\n  text-align: center;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.4px;\n  line-height: 1.25rem;\n  padding: 0 3rem;\n  text-align: center;\n"])), palette('secondaryHighlight'), fontSize('large'), fontWeight('bold'));
var MainWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  overflow: auto;\n  flex: 1;\n"], ["\n  overflow: auto;\n  flex: 1;\n"])));
var NotFound = function (_a) {
    var assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, buttonText = _a.buttonText, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, message = _a.message, session = _a.session, terminals = _a.terminals, title = _a.title, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist, theme = _a.theme;
    return (_jsx(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, backgroundColor: theme.palette.white, bottomMenu: _jsx(BottomMenu, { children: _jsx(StyledButton, __assign({ onClick: function () { return history.goBack(); } }, { children: buttonText }), void 0) }, void 0), currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, subMenu: _jsx(SubMenu, { title: title, cancelIcon: _jsx(CancelButton, { customIcon: 'back', width: '2rem', action: function () { return history.goBack(); } }, void 0) }, void 0), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist }, { children: _jsx(MainWrapper, { children: _jsxs(VerticalFlex, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { icon: 'search', height: 220 }, void 0) }, void 0), _jsx(Wrapper, { children: message }, void 0)] }, void 0) }, void 0) }), void 0));
};
export default withTheme(NotFound);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
