var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
// utils & libs
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getSearchParams } from '@shared/utils/params';
import { toJS } from '@shared/utils/immutable';
import sortBy from 'lodash/sortBy';
// selectors
import { selectFilters, selectLoadingAll as selectLoadingAllFilters, } from '@store/filter/selectors';
import { selectTempFilterSelections, selectFilterDescription, } from '@store/filterSelection/selectors';
// actions
import { getFilters } from '@store/filter/actions';
import { resetFilterSelections } from '@store/filterSelection/actions';
// components & container
import SessionContainer from 'containers/SessionContainer';
import Page from 'pages/Filter';
var Filter = /** @class */ (function (_super) {
    __extends(Filter, _super);
    function Filter(props) {
        var _this = _super.call(this, props) || this;
        _this.fetch = function () {
            var pageParams = getSearchParams(_this.props.location.search);
            if (!_this.props.filtersLoading) {
                _this.props.getFilters(__assign({}, pageParams));
            }
        };
        return _this;
    }
    Filter.prototype.render = function () {
        var _this = this;
        var _a = this.props, filterPage = _a.filterPage, filterPageCount = _a.filterPageCount, filters = _a.filters, filtersLoading = _a.filtersLoading, location = _a.location, tempFilterSelections = _a.tempFilterSelections, filtersAsText = _a.filtersAsText, resetFilterSelections = _a.resetFilterSelections;
        var sortedFilters = sortBy(filters, 'displayName');
        return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { fetch: function () { return _this.fetch(); }, filterPage: filterPage, filterPageCount: filterPageCount, filters: sortedFilters, filterSelections: tempFilterSelections, filtersLoading: filtersLoading, params: location.search, filtersAsText: filtersAsText, resetFilterSelections: resetFilterSelections }), void 0)); } }, void 0));
    };
    return Filter;
}(Component));
var select = function () { return function (state) { return ({
    filters: selectFilters(state),
    filtersLoading: selectLoadingAllFilters(state),
    tempFilterSelections: selectTempFilterSelections(state),
    filtersAsText: selectFilterDescription(state),
}); }; };
export default withRouter(connect(select, { getFilters: getFilters, resetFilterSelections: resetFilterSelections })(toJS(Filter)));
