var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
// components
import DeliveryOptionsForm from 'molecules/DeliveryOptionsForm';
import { DeliveryOptions } from '@store/session/types';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 0.125rem;\n  overflow-y: auto;\n  flex:1;\n"], ["\n  margin-top: 0.125rem;\n  overflow-y: auto;\n  flex:1;\n"])));
var CheckoutDelivery = function (_a) {
    var _b, _c, _d;
    var stores = _a.stores, currentStoreId = _a.currentStoreId, limitText = _a.limitText, updateSession = _a.updateSession, session = _a.session, type = _a.type, watchlist = _a.watchlist;
    var deliveryModes = type === 'order' ? (_c = (_b = watchlist[0]) === null || _b === void 0 ? void 0 : _b.article) === null || _c === void 0 ? void 0 : _c.deliveryMode : [];
    //   let preferedDeliveryMode: any = ;
    var _e = useState(DeliveryOptions[(_d = watchlist[0]) === null || _d === void 0 ? void 0 : _d.article.preferredDeliveryMode] || DeliveryOptions.order), deliveryMode = _e[0], setDeliveryMode = _e[1];
    return (_jsx(Wrapper, { children: stores.length > 0 && (_jsx(DeliveryOptionsForm, { limitText: limitText, stores: stores, onSubmit: updateSession, currentStoreId: currentStoreId, type: type, deliveryModes: deliveryModes, deliveryMode: deliveryMode, setDeliveryMode: setDeliveryMode, session: session }, void 0)) }, void 0));
};
export default CheckoutDelivery;
var templateObject_1;
