import { defineMessages } from 'react-intl';
var messages = defineMessages({
    title: { defaultMessage: 'Kunden suchen', id: 'customer.notFound.title' },
    message: {
        defaultMessage: 'Es konnten leider keine Kunden gefunden werden',
        id: 'customer.notFound.message',
    },
    buttonText: {
        defaultMessage: 'Erneut suchen',
        id: 'customer.notFound.buttonText',
    },
});
export default messages;
