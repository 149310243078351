var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
// utils
import { palette, fontWeight, fontSize } from '@shared/utils/styled';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
// components
import { Link } from 'react-router-dom';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var Tile = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  display: block;\n  font-size: ", ";\n  margin-bottom: 0.9375rem;\n  text-decoration: none;\n  width: 100%;\n"], ["\n  color: ", ";\n  display: block;\n  font-size: ", ";\n  margin-bottom: 0.9375rem;\n  text-decoration: none;\n  width: 100%;\n"])), palette('text'), fontSize('default'));
var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var Headline = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: ", ";\n  margin-bottom: 0.625rem;\n"], ["\n  font-weight: ", ";\n  margin-bottom: 0.625rem;\n"])), fontWeight('bold'));
var TextWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 0 0.9375rem;\n"], ["\n  padding: 0 0.9375rem;\n"])));
var TilesBig = function (_a) {
    var tiles = _a.tiles, session = _a.session;
    return (_jsx(Wrapper, { children: sortBy(tiles, 'sort').map(function (tile) { return (_jsxs(Tile, __assign({ to: "/sessions/" + session.uuid + tile.targetUrl }, { children: [_jsx(Image, { src: tile.imageUrl }, void 0), _jsxs(TextWrapper, { children: [_jsx(Headline, { children: tile.headline }, void 0), tile.description] }, void 0)] }), tile.sort)); }) }, void 0));
};
export default TilesBig;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
