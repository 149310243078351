var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, defineMessages } from 'react-intl';
// utils
import { palette, fontWeight } from '@shared/utils/styled';
// components
import CheckButton from '@shared/molecules/CheckButton';
import CheckoutArticleInfo from 'molecules/CheckoutArticleInfo';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import VerticalFlex from 'atoms/VerticalFlex';
import Icon from '@shared/atoms/Icon';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-left: 0.25rem solid transparent;\n  border-right: 0.25rem solid transparent;\n  height: ", ";\n  margin-bottom: 0.25rem;\n  opacity: ", ";\n  overflow: hidden;\n  position: relative;\n  width: 100%;\n\n  &:first-child {\n    margin-top: 0.25rem;\n  }\n\n  &::before,\n  &::after {\n    content: '';\n    height: 0.25rem;\n    left: -0.25rem;\n    position: absolute;\n    width: calc(100% + 0.5rem);\n  }\n\n  &::before {\n    bottom: 100%;\n  }\n\n  &::after {\n    top: 100%;\n  }\n"], ["\n  border-left: 0.25rem solid transparent;\n  border-right: 0.25rem solid transparent;\n  height: ", ";\n  margin-bottom: 0.25rem;\n  opacity: ", ";\n  overflow: hidden;\n  position: relative;\n  width: 100%;\n\n  &:first-child {\n    margin-top: 0.25rem;\n  }\n\n  &::before,\n  &::after {\n    content: '';\n    height: 0.25rem;\n    left: -0.25rem;\n    position: absolute;\n    width: calc(100% + 0.5rem);\n  }\n\n  &::before {\n    bottom: 100%;\n  }\n\n  &::after {\n    top: 100%;\n  }\n"])), function (props) { return (props.hasSizeSelection ? '7.375rem' : '5.5rem'); }, function (props) { return (!props.selected ? 0.5 : 1); });
var InnerWrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  height: 100%;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  height: 100%;\n"])), palette('white'));
var InfoWrapper = styled(VerticalFlex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: center;\n  width: 100%;\n"])));
var CheckButtonWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  border-right: 0.0625rem solid ", ";\n  display: flex;\n  height: 100%;\n  margin-right: 0.625rem;\n  padding: 0 0.625rem;\n"], ["\n  align-items: center;\n  border-right: 0.0625rem solid ", ";\n  display: flex;\n  height: 100%;\n  margin-right: 0.625rem;\n  padding: 0 0.625rem;\n"])), palette('border'));
var DropdownIcon = styled(Icon)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-left: 0.3125rem;\n\n  & > svg {\n    color: ", ";\n\n    & > path {\n      fill: currentcolor;\n    }\n  }\n"], ["\n  margin-left: 0.3125rem;\n\n  & > svg {\n    color: ", ";\n\n    & > path {\n      fill: currentcolor;\n    }\n  }\n"])), function (props) {
    return !props.isSizeSelected ? palette('primaryHighlight') : 'inherit';
});
var Lights = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 1rem;\n  content: ' ';\n  display: inline-block;\n  height: 0.5rem;\n  width: 0.5rem;\n"], ["\n  background-color: ", ";\n  border-radius: 1rem;\n  content: ' ';\n  display: inline-block;\n  height: 0.5rem;\n  width: 0.5rem;\n"])), function (props) { return palette(props.availability); });
var SizeToggle = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-bottom: 0.625rem;\n"], ["\n  margin-bottom: 0.625rem;\n"])));
var SizeToggleLabel = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
var TextWrapper = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), palette('primaryHighlight'));
var CheckoutOverviewListItem = function (_a) {
    var item = _a.item, openSizeSelect = _a.openSizeSelect, updateWatchlistItem = _a.updateWatchlistItem;
    var getLabelSize = function () {
        if (!Boolean(item.sizeId) || !Boolean(item.article)) {
            return null;
        }
        var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
        return size;
    };
    var msg = defineMessages({
        size: {
            defaultMessage: "Gr\u00F6\u00DFe",
            id: 'checkoutOverviewListItem.size',
        },
        choose: {
            defaultMessage: 'wählen',
            id: 'checkoutOverviewListItem.choose',
        },
    });
    var toggleSelection = function () {
        updateWatchlistItem({ selected: !item.selected });
    };
    var article = item.article;
    var size = getLabelSize();
    var hasSizeSelection = !(item.article.sizes.length === 0 && item.article.isAccessoire);
    return (_jsx(Wrapper, __assign({ selected: item.selected, hasSizeSelection: hasSizeSelection }, { children: _jsxs(InnerWrapper, { children: [_jsx(CheckButtonWrapper, __assign({ onClick: toggleSelection }, { children: _jsx(CheckButton, { checked: item.selected, onChange: toggleSelection }, void 0) }), void 0), _jsx(InfoWrapper, __assign({ onClick: openSizeSelect }, { children: _jsx(CheckoutArticleInfo, __assign({ article: article }, { children: hasSizeSelection && (_jsxs(SizeToggle, { children: [_jsxs(SizeToggleLabel, { children: [_jsx(FormattedMessage, __assign({}, msg.size), void 0), ":", ' '] }, void 0), item && item.sizeId && size ? (_jsxs("span", { children: [_jsx(Lights, { availability: size.stock }, void 0), ' ', _jsx(SizeToggleLabel, { children: size.labelSize }, void 0)] }, void 0)) : (_jsx(TextWrapper, { children: _jsx(FormattedMessage, __assign({}, msg.choose), void 0) }, void 0)), _jsx(DropdownIcon, { icon: 'dropdown', width: 10, isSizeSelected: item && item.sizeId && size }, void 0)] }, void 0)) }), void 0) }), void 0)] }, void 0) }), void 0));
};
export default CheckoutOverviewListItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
