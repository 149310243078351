var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import ArticleView from "organisms/ArticleView";
import SessionLayout from "templates/SessionLayout";
import SubMenu from "organisms/SubMenu";
import Icon from "@shared/atoms/Icon";
import { withTheme } from "styled-components";
var sizesWithAmounts = function (article, currentAssistant) {
    var _a;
    var sizesWithAmounts = { total: 0 };
    (_a = article === null || article === void 0 ? void 0 : article.sizes) === null || _a === void 0 ? void 0 : _a.forEach(function (size) {
        sizesWithAmounts[size.labelSize] =
            size.stock === "available" &&
                size.stores.some(function (item) { return item.id === (currentAssistant === null || currentAssistant === void 0 ? void 0 : currentAssistant.storeId); })
                ? size.stores
                    .filter(function (items) { return items.id === (currentAssistant === null || currentAssistant === void 0 ? void 0 : currentAssistant.storeId); })
                    .map(function (items) { return items.amount; })
                : size.stores.reduce(function (accumulator, currentValue) {
                    return accumulator + parseInt(currentValue.amount);
                }, 0);
    });
    return sizesWithAmounts;
};
// For subMenu
var sizeTotalAmount = function (article) {
    var _a;
    var result = { total: 0 };
    (_a = article === null || article === void 0 ? void 0 : article.sizes) === null || _a === void 0 ? void 0 : _a.forEach(function (size) {
        var sizeTotalAmount = size.stores.reduce(function (accumulator, currentValue) { return accumulator + parseInt(currentValue.amount, 10); }, 0);
        if (sizeTotalAmount > 0) {
            result[size.labelSize] = sizeTotalAmount;
            result.total += sizeTotalAmount;
        }
    });
    result.total = result.total > 0 ? result.total : undefined;
    return result;
};
// End
var Article = function (_a) {
    var article = _a.article, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, primaryCategoryId = _a.primaryCategoryId, session = _a.session, source = _a.source, terminals = _a.terminals, currentAssistantId = _a.currentAssistantId, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist, theme = _a.theme, requestWatchlist = _a.requestWatchlist, createWatchlistItemProp = _a.createWatchlistItem, currentAssistant = _a.currentAssistant, showMenuIcons = _a.showMenuIcons;
    var _b = useState(null), currentSize = _b[0], setCurrentSize = _b[1];
    var _c = useState(false), choosingSize = _c[0], setChoosingSize = _c[1];
    var _d = useState(false), showingBranches = _d[0], setShowingBranches = _d[1];
    var intl = useIntl();
    var history = useHistory();
    var params = useLocation().search;
    var articleSizesWithAmounts = sizesWithAmounts(article, currentAssistant);
    var articleSizeTotalAmount = sizeTotalAmount(article);
    var currentSizeId = currentSize ? currentSize.sizeId : null;
    var _e = useState(false), isArticleListScrolled = _e[0], setIsArticleListScrolled = _e[1];
    var handleArticleListScroll = function (scrolled) {
        setIsArticleListScrolled(scrolled);
    };
    var handleSizeSelect = function (size) {
        setCurrentSize(size);
    };
    var handleSubMenuToggle = function () {
        if (showingBranches) {
            setShowingBranches(false);
            return;
        }
        if (choosingSize) {
            setChoosingSize(false);
        }
        else {
            history.goBack();
        }
    };
    var msg = defineMessages({
        article: { defaultMessage: "Artikel", id: "article.page.headline" },
    });
    var watchlistItem = Boolean(article)
        ? watchlist.find(function (item) { return item.articleId === article.id && item.sizeId === currentSizeId; })
        : null;
    var renderCancelButton = function () {
        if (choosingSize || source !== "scan") {
            return _jsx(Icon, { icon: "back", onClick: function () { return handleSubMenuToggle(); } }, void 0);
        }
        return _jsx(Icon, { icon: "back", onClick: function () { return history.goBack(); } }, void 0);
    };
    return (_jsx(SessionLayout, __assign({ showMenuIcons: !showMenuIcons, isArticleListScrolled: isArticleListScrolled, currentAssistantId: currentAssistantId, assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, deleteSession: deleteSession, requestWatchlist: requestWatchlist, session: session, terminals: terminals, theme: theme, backgroundColor: theme.palette.white, subMenu: _jsx(SubMenu, { cancelIcon: renderCancelButton(), count: articleSizeTotalAmount.total, title: intl.formatMessage(msg.article) }, void 0), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist, deleteWatchlistItem: deleteWatchlistItem }, { children: _jsx(ArticleView, { ScrollingState: handleArticleListScroll, article: article, choosingSize: choosingSize, setChoosingSize: setChoosingSize, handleSizeSelect: handleSizeSelect, showingBranches: showingBranches, setShowingBranches: setShowingBranches, basePath: "/sessions/" + session.uuid + "/articles/", currentSize: currentSize, articleSizesWithAmounts: articleSizesWithAmounts, primaryCategoryId: primaryCategoryId, params: params, requestWatchlist: requestWatchlist, session: session, createWatchlistItem: function (articleId, sizeId, status, fromStoreId) {
                return currentSizeId &&
                    createWatchlistItemProp({
                        articleId: articleId,
                        sizeId: sizeId,
                        status: status,
                        fromStoreId: fromStoreId
                    });
            }, watchlistItem: watchlistItem, watchlist: watchlist, currentAssistant: currentAssistant, theme: theme }, void 0) }), void 0));
};
export default withTheme(Article);
