var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { stringify, parse } from 'query-string';
import InspirationSections from 'organisms/InspirationSections';
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
import TabMenu from 'molecules/TabMenu';
import CancelButton from '@shared/molecules/CancelButton';
import Spinner from '@shared/atoms/Spinner';
import DepartmentIcon from 'molecules/DepartmentIcon';
import styled, { withTheme } from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: auto;\n  flex: 1;\n"], ["\n  overflow: auto;\n  flex: 1;\n"])));
var SpinnerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"])));
var Inspiration = function (_a) {
    var inspiration = _a.inspiration, history = _a.history, primaryCategories = _a.primaryCategories, session = _a.session, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, hasDepartments = _a.hasDepartments, inspirationsLoading = _a.inspirationsLoading, terminals = _a.terminals, title = _a.title, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist, theme = _a.theme;
    var checkIfTabIsActive = function (tabTarget) {
        var params = parse(history.location.search);
        var currentPath = '';
        if (Boolean(inspiration)) {
            // $FlowIgnoreNextLine
            params.priCat = inspiration.primaryCategoryId;
            // $FlowIgnoreNextLine
            if (Boolean(inspiration.departmentId)) {
                params.department = inspiration.departmentId;
            }
        }
        currentPath = history.location.pathname + "?" + stringify(params, {
            arrayFormat: 'bracket',
        });
        return currentPath === tabTarget;
    };
    var getTabs = function () {
        return primaryCategories.reduce(function (acc, item) {
            var params = parse(history.location.search);
            params.priCat = item.id;
            // $FlowIgnoreNextLine
            if (Boolean(inspiration) && Boolean(inspiration.departmentId)) {
                params.department = inspiration.departmentId;
            }
            acc.push({
                title: item.displayName,
                target: "/sessions/" + session.uuid + "/inspiration?" + stringify(params, {
                    arrayFormat: 'bracket',
                }),
            });
            return acc;
        }, []);
    };
    var getDepermentLink = function () {
        var params = parse(history.location.search);
        if (Boolean(inspiration)) {
            // $FlowIgnoreNextLine
            params.priCat = inspiration.primaryCategoryId;
            // $FlowIgnoreNextLine
            if (Boolean(inspiration.departmentId)) {
                params.department = inspiration.departmentId;
            }
        }
        return "/sessions/" + session.uuid + "/departments?" + stringify(params, {
            arrayFormat: 'bracket',
        });
    };
    var tabs = getTabs();
    return (_jsx(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, subMenu: _jsx(SubMenu, { title: title, cancelIcon: _jsx(CancelButton, { action: function () { return history.push("/sessions/" + session.uuid); }, width: '2rem', customIcon: 'back' }, void 0), iconRight: hasDepartments ? (_jsx(DepartmentIcon, { action: function () { return history.push(getDepermentLink()); } }, void 0)) : null }, void 0), terminals: terminals, unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist, backgroundColor: theme.palette.white }, { children: _jsxs(Wrapper, { children: [_jsx("div", { children: _jsx(TabMenu, { tabs: tabs, checkIfTabIsActive: checkIfTabIsActive }, void 0) }, void 0), !inspiration && inspirationsLoading && (_jsx(SpinnerWrapper, { children: _jsx(Spinner, {}, void 0) }, void 0)), inspiration && inspiration.sections && !inspirationsLoading && (_jsx(InspirationSections, { sections: inspiration.sections, session: session, watchlist: watchlist }, inspiration.id))] }, void 0) }), void 0));
};
export default withRouter(withTheme(Inspiration));
var templateObject_1, templateObject_2;
