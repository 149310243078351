var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
// utils
import { fontWeight, palette } from '@shared/utils/styled';
var Customer = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 0.0625rem solid ", ";\n  display: block;\n  line-height: 1.3;\n  padding: 1rem 1.25rem;\n\n  &:last-child {\n    border: none;\n  }\n"], ["\n  border-bottom: 0.0625rem solid ", ";\n  display: block;\n  line-height: 1.3;\n  padding: 1rem 1.25rem;\n\n  &:last-child {\n    border: none;\n  }\n"])), palette('border'));
var BoldRow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
var CustomerListItem = function (_a) {
    var customer = _a.customer, onClick = _a.onClick;
    return (_jsxs(Customer, __assign({ onClick: onClick }, { children: [_jsxs(BoldRow, { children: [customer.gender, " ", customer.firstName, " ", customer.lastName] }, void 0), _jsxs("div", { children: [customer.street && customer.street + " ,", customer.city] }, void 0), _jsxs("div", { children: [_jsx(FormattedMessage, { id: 'customerList.birthdayLabel', defaultMessage: 'Geb.' }, void 0), ' '] }, void 0), _jsx("div", { children: customer.email }, void 0)] }), void 0));
};
export default CustomerListItem;
var templateObject_1, templateObject_2;
