import { createSelector } from 'reselect';
var selectState = function (state) { return state.get('customer'); };
var selectCustomerIdFromParams = function (state, props) {
    return parseInt(props.match.params.customerId, 10);
};
export var selectLoading = createSelector(selectState, function (customerState) {
    return customerState.get('loading');
});
export var selectLoaded = createSelector(selectState, function (customerState) {
    return customerState.get('loaded');
});
export var selectCustomers = createSelector(selectState, function (customerState) {
    return customerState.get('data');
});
export var selectCustomerById = function () {
    return createSelector([selectCustomers, selectCustomerIdFromParams], function (customerState, customerId) {
        return customerState.find(function (customer) { return customer.get('id') === customerId; });
    });
};
export var selectSearchResults = createSelector(selectState, function (customerState) { return customerState.get('searchResults'); });
export var selectCustomerFromSearchResults = function () {
    return createSelector([selectSearchResults, selectCustomerIdFromParams], function (state, customerId) {
        return state.find(function (result) { return result.get('id') === customerId; });
    });
};
