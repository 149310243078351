var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
// utils
import { defineMessages, useIntl } from 'react-intl';
import { parse, stringify } from 'query-string';
// components
import CancelButton from '@shared/molecules/CancelButton';
import DepartmentList from 'molecules/DepartmentList';
import history from '@store/history';
import SessionLayout from 'templates/SessionLayout';
import styled, { withTheme } from 'styled-components';
import SubMenu from 'organisms/SubMenu';
var Spacer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 4.3575rem;\n"], ["\n  width: 4.3575rem;\n"])));
var Department = function (_a) {
    var currentAssistantId = _a.currentAssistantId, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, departments = _a.departments, departmentsLoading = _a.departmentsLoading, session = _a.session, terminals = _a.terminals, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist, location = _a.location, theme = _a.theme;
    var intl = useIntl();
    var params = parse(location === null || location === void 0 ? void 0 : location.search, {
        arrayFormat: 'bracket',
    });
    var msg = defineMessages({
        departments: {
            defaultMessage: 'Abteilungen',
            id: 'departments.menu.headline',
        },
    });
    return (_jsx(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, subMenu: _jsx(SubMenu, { title: intl.formatMessage(msg.departments), cancelIcon: _jsx(Spacer, {}, void 0), iconRight: _jsx(CancelButton, { customIcon: 'back', width: '2rem', action: function () {
                    return history.push("/sessions/" + session.uuid + "/inspiration?" + (params
                        ? stringify(params, {
                            arrayFormat: 'bracket',
                        })
                        : ''));
                } }, void 0) }, void 0), terminals: terminals, unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, backgroundColor: theme.palette.white, watchlist: watchlist, currentAssistantId: currentAssistantId }, { children: Boolean(departments) && (_jsx(DepartmentList, { departments: departments, loading: departmentsLoading, params: params, session: session }, void 0)) }), void 0));
};
export var DepartmentComponent = Department;
export default withTheme(Department);
var templateObject_1;
