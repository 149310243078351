var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { put, call, takeLatest } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { stringify } from 'query-string';
// utils
import history from '@store/history';
import { getISODate } from '@shared/utils/times';
import errorHandler from 'services/connection';
// actions
import { updateSession } from '@store/session/actions';
import { createCustomer as createCustomerAction, createCustomerComplete, getCustomer as getCustomerAction, getCustomerComplete, searchCustomer as searchCustomerAction, searchCustomerComplete, updateCustomer as updateCustomerAction, updateCustomerComplete, } from './actions';
export function createCustomer(api, action) {
    var _a, ref, resolve, reject, payload, body, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.meta, ref = _a.ref, resolve = _a.resolve, reject = _a.reject;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 12]);
                payload = action.payload.toJS();
                return [4 /*yield*/, call([api, api.post], 'customers', payload)];
            case 2:
                body = (_b.sent()).body;
                return [4 /*yield*/, call(resolve, fromJS(body))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(createCustomerComplete(fromJS(body)))];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(updateSession({ buyerId: body.id }, action.meta.sessionId, "/sessions/" + action.meta.sessionId + "/customer/finish/" + body.id, ref))];
            case 5:
                _b.sent();
                return [3 /*break*/, 12];
            case 6:
                error_1 = _b.sent();
                if (!!Boolean(error_1.response)) return [3 /*break*/, 9];
                return [4 /*yield*/, call(reject, error_1)];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(errorHandler(error_1, createCustomerComplete, createCustomerAction, action.payload, action.meta))];
            case 8:
                _b.sent();
                return [3 /*break*/, 11];
            case 9: return [4 /*yield*/, put(createCustomerComplete())];
            case 10:
                _b.sent();
                _b.label = 11;
            case 11: return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
export function searchCustomer(api, action) {
    var _a, resolve, reject, sessionId, ref, requestType, payload, birthday, body, immutableBody, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.meta, resolve = _a.resolve, reject = _a.reject, sessionId = _a.sessionId, ref = _a.ref, requestType = _a.requestType;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 15, , 21]);
                payload = action.payload.toJS();
                birthday = payload.day &&
                    payload.month &&
                    payload.year &&
                    getISODate(payload.day, payload.month, payload.year);
                return [4 /*yield*/, call([api, api.get], 'customers', {
                        params: __assign(__assign({}, payload), { birthday: birthday || null, day: undefined, month: undefined, year: undefined }),
                    })];
            case 2:
                body = (_b.sent()).body;
                immutableBody = fromJS(body);
                return [4 /*yield*/, call(resolve, immutableBody)];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(searchCustomerComplete(immutableBody))];
            case 4:
                _b.sent();
                if (!(requestType === 'addCustomer' && body.length === 1)) return [3 /*break*/, 6];
                return [4 /*yield*/, call(history.push, {
                        pathname: "/sessions/" + sessionId + "/customer/confirm/" + body[0].id,
                        search: stringify({ type: 'customerExists', ref: ref }),
                    })];
            case 5:
                _b.sent();
                return [3 /*break*/, 14];
            case 6:
                if (!(body.length === 1)) return [3 /*break*/, 8];
                return [4 /*yield*/, call(history.push, {
                        pathname: "/sessions/" + sessionId + "/customer/confirm/" + body[0].id,
                        search: stringify({ ref: ref }),
                    })];
            case 7:
                _b.sent();
                return [3 /*break*/, 14];
            case 8:
                if (!(body.length > 1)) return [3 /*break*/, 10];
                return [4 /*yield*/, call(history.push, {
                        pathname: "/sessions/" + sessionId + "/customer/list",
                        search: stringify({ ref: ref }),
                    })];
            case 9:
                _b.sent();
                return [3 /*break*/, 14];
            case 10:
                if (!(requestType === 'addCustomer')) return [3 /*break*/, 12];
                return [4 /*yield*/, call(history.push, {
                        pathname: "/sessions/" + sessionId + "/customer/add",
                        search: stringify(__assign(__assign({}, payload), { ref: ref })),
                    })];
            case 11:
                _b.sent();
                return [3 /*break*/, 14];
            case 12: return [4 /*yield*/, call(history.push, {
                    pathname: "/sessions/" + sessionId + "/customer/notfound",
                    search: stringify({ ref: ref }),
                })];
            case 13:
                _b.sent();
                _b.label = 14;
            case 14: return [3 /*break*/, 21];
            case 15:
                error_2 = _b.sent();
                if (!!Boolean(error_2.response)) return [3 /*break*/, 18];
                return [4 /*yield*/, call(reject, error_2)];
            case 16:
                _b.sent();
                return [4 /*yield*/, put(errorHandler(error_2, searchCustomerComplete, searchCustomerAction, action.payload, action.meta))];
            case 17:
                _b.sent();
                return [3 /*break*/, 20];
            case 18: return [4 /*yield*/, put(searchCustomerComplete())];
            case 19:
                _b.sent();
                _b.label = 20;
            case 20: return [3 /*break*/, 21];
            case 21: return [2 /*return*/];
        }
    });
}
export function getCustomer(api, action) {
    var payload, body, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                payload = action.payload;
                return [4 /*yield*/, call([api, api.get], "customers/" + payload)];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(getCustomerComplete(fromJS(body)))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_3 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_3, getCustomerComplete, getCustomerAction, action.payload))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function updateCustomer(api, action) {
    var _a, resolve, reject, payload, body, error_4;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.meta, resolve = _a.resolve, reject = _a.reject;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 12]);
                payload = action.payload.toJS();
                return [4 /*yield*/, call([api, api.put], "customers/" + action.meta.customerId, __assign(__assign({}, payload), { birthday: getISODate(payload.day, payload.month, payload.year), day: undefined, month: undefined, year: undefined }))];
            case 2:
                body = (_b.sent()).body;
                return [4 /*yield*/, call(resolve, fromJS(body))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(updateCustomerComplete(fromJS(body)))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(history.goBack)];
            case 5:
                _b.sent();
                return [3 /*break*/, 12];
            case 6:
                error_4 = _b.sent();
                if (!!Boolean(error_4.response)) return [3 /*break*/, 9];
                return [4 /*yield*/, call(reject, error_4)];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(errorHandler(error_4, updateCustomerComplete, updateCustomerAction, action.payload, action.meta))];
            case 8:
                _b.sent();
                return [3 /*break*/, 11];
            case 9: return [4 /*yield*/, put(updateCustomerComplete())];
            case 10:
                _b.sent();
                _b.label = 11;
            case 11: return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
export function customerCreateWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('CREATE_CUSTOMER', createCustomer, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function customerGetWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('GET_CUSTOMER', getCustomer, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function customerUpdateWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('UPDATE_CUSTOMER', updateCustomer, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function customerSearchWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('SEARCH_CUSTOMER', searchCustomer, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
