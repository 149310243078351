import { createSelector } from 'reselect';
var selectFiltersState = function (state) { return state.get('filter'); };
var selectFilterId = function (state, props) { return props.match.params.id; };
export var selectLoading = createSelector(selectFiltersState, function (filterState) {
    return filterState.get('loading');
});
export var selectLoadingAll = createSelector(selectFiltersState, function (filterState) { return filterState.get('loadingAll'); });
export var selectLoadingAllComplete = createSelector(selectFiltersState, function (filterState) {
    return !filterState.get('loadingAll') && filterState.get('loadedAll');
});
export var selectFilters = createSelector(selectFiltersState, function (filterState) {
    return filterState.get('data');
});
export var makeSelectFilter = function () {
    return createSelector([selectFilters, selectFilterId], function (filters, filterId) {
        return filters.find(function (filter) { return filter.get('id') === filterId; });
    });
};
