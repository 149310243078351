import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { processActionTypes } from '@shared/utils/constants';
import { FormattedMessage } from 'react-intl';
var InfoTextMessages = function (_a) {
    var _b;
    var orderNumber = _a.orderNumber, type = _a.type;
    var RESERVE = processActionTypes.RESERVE, REQUEST = processActionTypes.REQUEST, CHECKOUT = processActionTypes.CHECKOUT, ORDER = processActionTypes.ORDER;
    var getInfoTextMessages = (_b = {},
        _b[RESERVE] = (_jsx(FormattedMessage, { id: 'checkout.reserve.complete.infoText', defaultMessage: 'Ihre Artikel wurden erfolgreich reserviert' }, void 0)),
        _b[REQUEST] = (_jsx(FormattedMessage, { id: 'checkout.request.complete.infoText', defaultMessage: 'Ihre Artikel wurden erfolgreich angefordert' }, void 0)),
        _b[CHECKOUT] = (_jsx(FormattedMessage, { id: 'checkout.buy.complete.infoText', defaultMessage: 'Bitte gehen Sie zur Kasse und nennen Sie Ihren Code:' }, void 0)),
        _b[ORDER] = orderNumber ? (_jsx(FormattedMessage, { id: 'checkout.order.complete.infoText', defaultMessage: 'Ihre Artikel wurden beim Lieferanten bestellt.' }, void 0)) : (_jsx(_Fragment, {}, void 0)),
        _b);
    return getInfoTextMessages[type];
};
export default InfoTextMessages;
