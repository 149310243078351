var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _this = this;
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import { Form, useField } from 'react-final-form';
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
import { defineMessages, useIntl } from 'react-intl';
var TextAreaStyles = styled.textarea(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  height: 100%;\n  line-height: 1.5rem;\n  padding: 1.25rem;\n  width: 100%;\n  ::placeholder {\n    font-style: italic;\n  }\n  &:focus {\n    outline: none;\n  }\n"], ["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  height: 100%;\n  line-height: 1.5rem;\n  padding: 1.25rem;\n  width: 100%;\n  ::placeholder {\n    font-style: italic;\n  }\n  &:focus {\n    outline: none;\n  }\n"])), palette('white'), palette('text'));
export function StyledTextArea(props) {
    var input = useField(props.name).input;
    var inputProps = __assign(__assign({}, props), input);
    return _jsx(TextAreaStyles, __assign({}, inputProps), void 0);
}
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  overflow: auto;\n"], ["\n  flex: 1;\n  overflow: auto;\n"])));
var StyledForm = styled.form(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: calc(100% - 3.75rem);\n  width: 100%;\n"], ["\n  height: calc(100% - 3.75rem);\n  width: 100%;\n"])));
var ArticleInfoFormComponent = function (_a) {
    var editMode = _a.editMode, currentAssistantNote = _a.currentAssistantNote, updateArticle = _a.updateArticle;
    var placeholder = defineMessages({
        placeholder: {
            id: 'article.infoForm.placeholder',
            defaultMessage: 'Hinterlegen Sie hier Notizen zu diesem Artikel.',
        },
    });
    var intl = useIntl();
    return (_jsx(Wrapper, { children: _jsx(Form, { onSubmit: updateArticle, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (_jsx(StyledForm, __assign({ onSubmit: handleSubmit, id: 'article-infor-form' }, { children: _jsx(StyledTextArea, { autoComplete: 'off', component: 'textarea', disabled: !editMode, innerRef: function (input) {
                            _this.input = input;
                        }, name: 'assistantNote', placeholder: intl.formatMessage(placeholder.placeholder), value: currentAssistantNote, withRef: true }, void 0) }), void 0));
            } }, void 0) }, void 0));
};
export default ArticleInfoFormComponent;
var templateObject_1, templateObject_2, templateObject_3;
