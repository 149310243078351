var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import { withTheme } from 'styled-components';
import WatchlistShare from 'organisms/WatchlistShare';
import SubMenu from 'organisms/SubMenu';
import GoBack from 'molecules/GoBack';
import SessionLayout from 'templates/SessionLayout';
import SessionArchiveBottomButton from 'molecules/SessionArchiveBottomButton';
import PageSpinner from '@shared/atoms/PageSpinner';
var WatchlistSharePage = function (_a) {
    var assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, terminals = _a.terminals, session = _a.session, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist, shareSession = _a.shareSession, archiveSession = _a.archiveSession, archiving = _a.archiving, theme = _a.theme;
    return (_jsxs(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, bottomMenu: _jsx(SessionArchiveBottomButton, { archiveSession: archiveSession, archiving: archiving }, void 0), subMenu: _jsx(SubMenu, { cancelIcon: _jsx(GoBack, { icon: 'iconBack', width: '15' }, void 0), title: 'Teilen' }, void 0), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist }, { children: [archiving && _jsx(PageSpinner, {}, void 0), !archiving && (_jsx(WatchlistShare, { sessionId: session.uuid, shareSession: shareSession, theme: theme }, void 0))] }), void 0));
};
export default withTheme(WatchlistSharePage);
