var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';
// utils
import { palette } from '@shared/utils/styled';
import { availablities, processActionTypes } from '@shared/utils/constants';
// components
import CheckoutOverviewList from 'molecules/CheckoutOverviewList';
// translations
import sizeSelectMsg from 'organisms/CheckoutSizeSelect/messages';
import reserveMsg from 'molecules/CheckoutAvailableGroups/messages';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-y: auto;\n  flex: 1;\n"], ["\n  overflow-y: auto;\n  flex: 1;\n"])));
var Group = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &:first-child {\n    margin-top: 0.125rem;\n  }\n"], ["\n  &:first-child {\n    margin-top: 0.125rem;\n  }\n"])));
var GroupHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  padding: 0.5rem 0.6875rem;\n"], ["\n  background-color: ", ";\n  color: ", ";\n  padding: 0.5rem 0.6875rem;\n"])), palette('secondaryHighlight'), palette('white'));
var Lights = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 1rem;\n  content: ' ';\n  display: inline-block;\n  height: 0.5rem;\n  margin-left: 0.25rem;\n  width: 0.5rem;\n"], ["\n  background-color: ", ";\n  border-radius: 1rem;\n  content: ' ';\n  display: inline-block;\n  height: 0.5rem;\n  margin-left: 0.25rem;\n  width: 0.5rem;\n"])), function (props) { return palette(props.availability); });
export var CheckoutAvailableGroups = function (_a) {
    var updateWatchlistItem = _a.updateWatchlistItem, watchlist = _a.watchlist, type = _a.type;
    //   const [sizeSelectItem, setSizeSelectItem] = useState(null);
    //   const toggleSizeSelect = (sizeSelectItem) => {
    //     setSizeSelectItem({ sizeSelectItem });
    //   };
    //   const selectSize = (watchlistItemId, sizeId) => {
    //     toggleSizeSelect(null);
    //     updateWatchlistItem({ sizeId }, watchlistItemId);
    //   };
    var getArticlesByAvailability = function (stock) {
        if (stock === void 0) { stock = 'available'; }
        return watchlist.filter(function (item) {
            var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
            if (!Boolean(size)) {
                return false;
            }
            return size && stock === size.stock;
        });
    };
    var getGroups = function () {
        return availablities.reduce(function (acc, item) {
            var group = getArticlesByAvailability(item);
            if (group.length > 0) {
                acc[item] = group;
            }
            return acc;
        }, {});
    };
    var groups = getGroups();
    return (_jsx(Wrapper, { children: map(groups, function (group, groupName) { return (_jsxs(Group, { children: [_jsxs(GroupHeader, { children: [_jsx(Lights, { availability: groupName }, void 0), ' ', type === processActionTypes.CHECKOUT ? (_jsx(FormattedMessage, __assign({}, sizeSelectMsg[groupName]), void 0)) : (_jsx(FormattedMessage, __assign({}, reserveMsg[groupName]), void 0))] }, void 0), _jsx(CheckoutOverviewList, { watchlist: group, updateWatchlistItem: updateWatchlistItem }, void 0)] }, groupName)); }) }, void 0));
};
export default CheckoutAvailableGroups;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
