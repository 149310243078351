var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import H2 from '@shared/atoms/H2';
import { palette } from '@shared/utils/styled';
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  padding: 1rem 2rem;\n  flex: 1;\n  overflow: auto;\n"], ["\n  height: 100%;\n  padding: 1rem 2rem;\n  flex: 1;\n  overflow: auto;\n"])));
export var Headline = styled(H2)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 0rem;\n"], ["\n  margin-top: 0rem;\n"])));
export var ErrorMsg = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: red;\n  margin-top: 0.5rem;\n"], ["\n  color: red;\n  margin-top: 0.5rem;\n"])));
export var ShareOptions = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  height: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  height: 100%;\n"])));
export var QRCodeWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: flex-start;\n  display: flex;\n  margin-top: 0.5rem;\n  padding: 50px;\n  // background-color: ", ";\n  border-radius: 50%;\n"], ["\n  align-items: flex-start;\n  display: flex;\n  margin-top: 0.5rem;\n  padding: 50px;\n  // background-color: ", ";\n  border-radius: 50%;\n"])), palette('black'));
export var EmailForm = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: flex-start;\n  display: flex;\n  width: 80%;\n"], ["\n  align-items: flex-start;\n  display: flex;\n  width: 80%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
