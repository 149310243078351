// @flow
import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    data: List(),
    loading: false,
    loaded: false,
});
var reducer = handleActions({
    GET_FURNITURES: function (state) { return state.set('loading', true); },
    GET_FURNITURES_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .set('data', action.payload);
        },
        throw: function (state) {
            return state.set('loading', false).set('loaded', false);
        },
    },
    RESET_FURNITURE: {
        next: function () {
            return initialState;
        },
        throw: function () {
            return initialState;
        },
    },
}, initialState);
export default reducer;
