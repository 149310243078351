import { createAction } from 'redux-actions';
import { metaCreator } from '@shared/utils/redux';
export var REQUEST_ACTION = 'REQUEST_ACTION';
export var REQUEST_ACTION_COMPLETE = 'REQUEST_ACTION_COMPLETE';
export var ORDER_ACTION = 'ORDER_ACTION';
export var ORDER_ACTION_COMPLETE = 'ORDER_ACTION_COMPLETE';
export var RESET_ORDERNUMBER = 'RESET_ORDERNUMBER';
export var requestActionComplete = createAction(REQUEST_ACTION_COMPLETE);
export var orderActionComplete = createAction(ORDER_ACTION_COMPLETE);
export var resetOrderNumber = createAction(RESET_ORDERNUMBER);
export var requestAction = createAction(REQUEST_ACTION, function (payload) { return payload; }, function (payload, sessionId, ref, resolve, reject) {
    return metaCreator({ sessionId: sessionId, ref: ref }, resolve, reject);
});
export var orderAction = createAction(ORDER_ACTION, function (payload) { return payload; }, function (payload, sessionId, deliveryOption) {
    return metaCreator({ sessionId: sessionId, deliveryOption: deliveryOption });
});
