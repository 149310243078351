var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from "react";
import { connect } from "react-redux";
import { toJS } from "@shared/utils/immutable";
import { createStructuredSelector } from "reselect";
import OpenSessionsWrapper from "pages/OpenSessions/OpenSessionsWrapper";
import { logout } from "@store/authorize/actions";
import { selectTheme } from "@store/theme/selectors";
import { selectWatchlist } from "@store/watchlist/selectors";
import { selectSessionsUnassigned } from "@store/session/selectors";
import { aquireCall, getSessionsUnassigned } from "@store/session/actions";
import { selectCurrentUserId } from "@store/authorize/selectors";
import { deleteWatchlist, deleteWatchlistItem } from "@store/watchlist/actions";
var OpenSessions = /** @class */ (function (_super) {
    __extends(OpenSessions, _super);
    function OpenSessions(props) {
        var _this = _super.call(this, props) || this;
        _this.fetchSessions = function (page) {
            _this.props.getSessionsUnassigned({ page: page });
        };
        _this.onAquireCall = function (session) {
            var currentAssistantId = _this.props.currentAssistantId;
            _this.props.aquireCall({ assistantId: currentAssistantId }, session.uuid, session.watchlistAmount);
        };
        return _this;
    }
    OpenSessions.prototype.render = function () {
        var _this = this;
        var _a = this.props, theme = _a.theme, logout = _a.logout, watchlist = _a.watchlist, session = _a.session, sessionsUnassigned = _a.sessionsUnassigned;
        return (_jsx(OpenSessionsWrapper, { theme: theme, cart: watchlist, onLogoutButtonClicked: logout, sessions: sessionsUnassigned, sessionId: (session === null || session === void 0 ? void 0 : session.id) || session, fetchSessions: function (page) {
                _this.fetchSessions(page);
            }, onAssignRequest: this.onAquireCall, clearCart: function () {
                _this.props.deleteWatchlist(localStorage.getItem("sessionuuid"));
            }, deleteCartItem: function (articleId) {
                _this.props.deleteWatchlistItem(articleId, localStorage.getItem("sessionuuid"));
            } }, void 0));
    };
    return OpenSessions;
}(Component));
var select = createStructuredSelector({
    currentAssistantId: selectCurrentUserId,
    sessionsUnassigned: selectSessionsUnassigned,
    theme: selectTheme,
    watchlist: selectWatchlist
});
export var Container = OpenSessions;
export default connect(select, {
    aquireCall: aquireCall,
    getSessionsUnassigned: getSessionsUnassigned,
    deleteWatchlist: deleteWatchlist,
    deleteWatchlistItem: deleteWatchlistItem,
    logout: logout
})(toJS(Container));
