var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// https://github.com/diegohaz/arc/wiki/Sagas
import { all, fork } from 'redux-saga/effects';
// import sagas by hand
import { categoryGetWatcher, categoriesGetWatcher } from './category/sagas';
import { devicesWatcher, deviceThemeWatcher } from './device/sagas';
import { getArticlesWatcher, getArticleWatcher, scanArticleWatcher, searchArticleWatcher, updateArticleWatcher, } from './article/sagas';
import { getAssistantsWatcher, getAssistantWatcher } from './assistant/sagas';
import { getFiltersWatcher, getFilterWatcher } from './filter/sagas';
import { identityWatcher } from './identity/sagas';
import { loginWatcher, logoutWatcher } from './authorize/sagas';
import { primaryCategoriesGetWatcher } from './primaryCategory/sagas';
import { webWatcher, serviceWatcher } from './connection/sagas';
import { customerCreateWatcher, customerGetWatcher, customerSearchWatcher, customerUpdateWatcher, } from './customer/sagas';
import { filterSelectionsApplyWatcher, filterSelectionsResetWatcher, } from './filterSelection/sagas';
import { getFurnituresWatcher } from './furniture/sagas';
import { sessionAquireCallWatcher, sessionAssignTerminalWatcher, sessionAssignWatcher, sessionCheckAssignedWatcher, sessionCheckoutWatcher, sessionCheckUnassignedWatcher, sessionCreateWatcher, sessionCreateWithScanWatcher, sessionDeleteWatcher, sessionGetWatcher, sessionsAllGetWatcher, sessionsArchiveGetWatcher, sessionsAssignedGetWatcher, sessionsUnassignedGetWatcher, sessionUpdateWatcher, sessionUnleashAssistantWatcher, unleashDeviceWatcher, sessionArchiveWatcher, sessionShareWatcher, } from './session/sagas';
import { storesWatcher } from './store/sagas';
import { themeWatcher } from './theme/sagas';
import { watchlistItemCreateWatcher, watchlistItemDeleteWatcher, watchlistGetWatcher, watchlistDeleteWatcher, watchlistUpdateWatcher, requestWatchlistWatcher, } from './watchlist/sagas';
import { getInspirationsWatcher } from './inspiration/sagas';
import { getDepartmentsWatcher } from './department/sagas';
import { requestActionWatcher, orderActionWatcher } from './action/sagas';
import { sortsWatcher } from './sort/sagas';
// add imported sagas to array
var sagas = [
    categoriesGetWatcher,
    categoryGetWatcher,
    customerCreateWatcher,
    customerGetWatcher,
    customerSearchWatcher,
    customerUpdateWatcher,
    devicesWatcher,
    deviceThemeWatcher,
    filterSelectionsApplyWatcher,
    filterSelectionsResetWatcher,
    getArticlesWatcher,
    getArticleWatcher,
    getAssistantsWatcher,
    getAssistantWatcher,
    getDepartmentsWatcher,
    getFiltersWatcher,
    getFilterWatcher,
    getFurnituresWatcher,
    getInspirationsWatcher,
    identityWatcher,
    loginWatcher,
    logoutWatcher,
    orderActionWatcher,
    primaryCategoriesGetWatcher,
    requestWatchlistWatcher,
    scanArticleWatcher,
    searchArticleWatcher,
    serviceWatcher,
    sessionAquireCallWatcher,
    sessionAssignTerminalWatcher,
    sessionAssignWatcher,
    sessionCheckAssignedWatcher,
    sessionCheckoutWatcher,
    sessionCheckUnassignedWatcher,
    sessionCreateWatcher,
    sessionCreateWithScanWatcher,
    sessionDeleteWatcher,
    sessionGetWatcher,
    sessionShareWatcher,
    sessionArchiveWatcher,
    sessionsAllGetWatcher,
    sessionsArchiveGetWatcher,
    sessionsAssignedGetWatcher,
    sessionsUnassignedGetWatcher,
    sessionUnleashAssistantWatcher,
    sessionUpdateWatcher,
    storesWatcher,
    themeWatcher,
    unleashDeviceWatcher,
    updateArticleWatcher,
    watchlistDeleteWatcher,
    watchlistGetWatcher,
    watchlistItemCreateWatcher,
    watchlistItemDeleteWatcher,
    watchlistUpdateWatcher,
    webWatcher,
    requestActionWatcher,
    sortsWatcher,
];
var getAllSagas = function (services) {
    if (services === void 0) { services = {}; }
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all(sagas.map(function (saga) { return fork(saga, services); }))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
};
export default getAllSagas;
