var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
// utils
import { fontWeight, palette } from '@shared/utils/styled';
// components
import CancelButton from '@shared/molecules/CancelButton';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import SubMenu from 'organisms/SubMenu';
import CheckoutArticleInfo from 'molecules/CheckoutArticleInfo';
// translations
import msg, { SizeSelectMessageHeaders } from './messages';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  height: calc(100% - 3.125rem);\n  left: 0;\n  position: fixed;\n  top: 3.125rem;\n  width: 100%;\n  z-index: 1;\n"], ["\n  background-color: ", ";\n  height: calc(100% - 3.125rem);\n  left: 0;\n  position: fixed;\n  top: 3.125rem;\n  width: 100%;\n  z-index: 1;\n"])), palette('primaryBackground'));
var Spacer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 4.375rem;\n"], ["\n  width: 4.375rem;\n"])));
var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: 0.625rem;\n"], ["\n  background-color: ", ";\n  padding: 0.625rem;\n"])), palette('white'));
var Lights = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 1rem;\n  content: ' ';\n  display: inline-block;\n  height: 0.5rem;\n  width: 0.5rem;\n"], ["\n  background-color: ", ";\n  border-radius: 1rem;\n  content: ' ';\n  display: inline-block;\n  height: 0.5rem;\n  width: 0.5rem;\n"])), function (props) { return palette(props.availability); });
var SizeListItem = styled(HorizontalFlex)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-top: 0.0625rem solid ", ";\n  padding: 0.9375rem 2.5rem 0.9375rem 5rem;\n  width: 100%;\n"], ["\n  border-top: 0.0625rem solid ", ";\n  padding: 0.9375rem 2.5rem 0.9375rem 5rem;\n  width: 100%;\n"])), palette('border'));
var SizeLabel = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex 1;\n  font-weight: ", ";\n"], ["\n  flex 1;\n  font-weight: ", ";\n"])), fontWeight('bold'));
var SizeAvailability = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex 4;\n"], ["\n  flex 4;\n"])));
var CheckoutSizeSelect = function (_a) {
    var item = _a.item, toggleAction = _a.toggleAction, selectSize = _a.selectSize;
    var article = item.article;
    var intl = useIntl();
    return (_jsxs(Wrapper, { children: [_jsx(SubMenu, { title: intl.formatMessage(msg.headline), cancelIcon: _jsx(Spacer, {}, void 0), iconRight: _jsx(CancelButton, { action: toggleAction }, void 0) }, void 0), _jsxs(Content, { children: [_jsx(CheckoutArticleInfo, { article: article }, void 0), article.sizes.map(function (size) { return (_jsxs(SizeListItem, __assign({ onClick: function () { return selectSize(size.sizeId); } }, { children: [_jsx(SizeLabel, { children: size.labelSize }, void 0), _jsxs(SizeAvailability, { children: [_jsx(Lights, { availability: size.stock }, void 0), ' ', size.stock in SizeSelectMessageHeaders &&
                                        intl.formatMessage(msg[size.stock])] }, void 0)] }), "size" + size.labelSize)); })] }, void 0)] }, void 0));
};
export default withRouter(CheckoutSizeSelect);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
