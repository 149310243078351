var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import { palette, fontWeight } from '@shared/utils/styled';
import { getImageUrl } from '@shared/utils/images';
var InnerWrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 0.0625rem solid ", ";\n  cursor: pointer;\n  text-decoration: none;\n  padding: 0.6rem 2rem;\n  font-weight: ", ";\n  align-items: center;\n  color: ", ";\n  display: flex;\n  padding: 0.5rem 2rem;\n  background-color: ", ";\n  ", "\n"], ["\n  border-bottom: 0.0625rem solid ", ";\n  cursor: pointer;\n  text-decoration: none;\n  padding: 0.6rem 2rem;\n  font-weight: ", ";\n  align-items: center;\n  color: ", ";\n  display: flex;\n  padding: 0.5rem 2rem;\n  background-color: ", ";\n  ", "\n"])), palette('border'), fontWeight('bold'), palette('black'), function (props) {
    return props.active ? palette('selectedWhite') : 'transparent';
}, function (props) {
    return props.active &&
        "\n      border-bottom: 0.0625rem solid " + palette('white') + ";\n  ";
});
var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: auto;\n  margin: auto;\n  width: 1.5rem;\n  margin-right: 25%;\n  border-radius: 50%;\n"], ["\n  height: auto;\n  margin: auto;\n  width: 1.5rem;\n  margin-right: 25%;\n  border-radius: 50%;\n"])));
var FilterOptionListItem = function (_a) {
    var filterOption = _a.filterOption, handleClick = _a.handleClick, active = _a.active, filterName = _a.filterName;
    return (_jsxs(InnerWrapper, __assign({ active: active, onClick: handleClick }, { children: [_jsxs("span", { children: ["- ", filterOption.displayName] }, void 0), filterName === 'color' && (_jsx(Image, { src: getImageUrl(filterOption.image) }, void 0))] }), void 0));
};
export default FilterOptionListItem;
var templateObject_1, templateObject_2;
