import { createSelector } from 'reselect';
import { selectArticleId } from '../article/selectors';
var selectState = function (state) { return state.get('watchlist'); };
export var selectAddToCartClicked = createSelector(selectState, function (watchListState) {
    return watchListState.get('addToCartClicked');
});
export var selectLoading = createSelector(selectState, function (watchListState) {
    return watchListState.get('loading');
});
export var selectWatchlist = createSelector(selectState, function (watchListState) {
    return watchListState.get('data');
});
export var selectWatchlistStatus = createSelector(selectState, function (watchListState) { return watchListState.get('status'); });
export var selectWatchListHasDifferentType = createSelector(selectState, function (watchListState) { return watchListState.get('hasDifferentType'); });
export var loadSingleWatchlistItem = function () {
    return createSelector([selectWatchlist, selectArticleId], function (watchlist, articleId) {
        return watchlist.find(function (item) { return item.get('articleId') === articleId; });
    });
};
