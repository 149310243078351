var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ScanForm from 'molecules/ScanForm';
import { Center, Error, Headline, Main } from './styles';
import Button from '@shared/atoms/Button';
import Spinner from '@shared/atoms/Spinner';
import addEanPrefix from '@shared/utils/ean';
var ArticleSrch = function (_a) {
    var sessionId = _a.sessionId, scanArticle = _a.scanArticle, searchArticle = _a.searchArticle, loading = _a.loading, error = _a.error, resetArticleError = _a.resetArticleError;
    var _b = useState(false), scanErrors = _b[0], setScanErrors = _b[1];
    var _c = useState(''), scanResult = _c[0], setScanResult = _c[1];
    var submitHandler = function (data) {
        var code = data.code, type = data.type;
        var articleId = code;
        if (type === 'scan') {
            articleId = addEanPrefix(articleId);
        }
        searchArticle(articleId, "/sessions/" + sessionId + "/", type);
    };
    var showTryAgainMsg = error && scanResult.length > 0;
    var tryAgainMsg = (_jsxs("div", { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { defaultMessage: 'Scan nicht erfolgreich', id: 'scan.fallback.headline' }, void 0) }, void 0), _jsx(Center, { children: _jsx(FormattedMessage, { defaultMessage: 'Suchen Sie erneut oder nutzen Sie die manuelle Eingabe.', id: 'scan.fallback.message' }, void 0) }, void 0), _jsx(Error, { children: scanResult }, void 0), _jsx(Center, { children: _jsx(Button, __assign({ onClick: function () {
                        setScanResult('');
                        setScanErrors(false);
                        setCameraAccess(true);
                        resetArticleError();
                    } }, { children: "Erneut Scannen" }), void 0) }, void 0)] }, void 0));
    var errorMsg = (_jsxs("div", { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { defaultMessage: 'Scan nicht erfolgreich', id: 'scan.fallback.headline' }, void 0) }, void 0), _jsx(Center, { children: _jsx(FormattedMessage, { defaultMessage: 'Suchen Sie erneut oder nutzen Sie die manuelle Eingabe.', id: 'scan.fallback.message' }, void 0) }, void 0)] }, void 0));
    return (_jsxs(Main, { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { defaultMessage: 'Manuelle Eingabe der Artikelnummer', id: 'scanSearch.headline' }, void 0) }, void 0), _jsx("br", {}, void 0), loading && _jsx(Spinner, {}, void 0), scanErrors && errorMsg, showTryAgainMsg && tryAgainMsg, _jsx(ScanForm, { onSubmit: submitHandler, scanResult: scanResult }, void 0)] }, void 0));
};
export default ArticleSrch;
