var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { FormattedMessage } from "react-intl";
// utils
import { palette, fontSize, fontWeight } from "@shared/utils/styled";
import styled from "styled-components";
// components
import CheckoutStoreSelect from "organisms/CheckoutStoreSelect";
import Icon from "@shared/atoms/Icon";
import VerticalFlex from "atoms/VerticalFlex";
import HorizontalFlex from "@shared/atoms/HorizontalFlex";
// types
import { DeliveryOptions } from "@store/session/types";
import { processActionTypes, deliveryModes as possibleDeliveryModes } from "@shared/utils/constants";
import { updateSession } from "@store/session/actions";
import { useDispatch } from "react-redux";
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n   align-items: center;\n   height: auto;\n   width: 100%;\n\n   input[type=\"radio\"] {\n      appearance: none;\n      background-position: 50% 50%;\n      background-repeat: no-repeat;\n      background-size: 1rem;\n      border: 0.0625rem solid\n         ", ";\n      height: 2rem;\n      margin-right: 0.625rem;\n      width: 2rem;\n      outline: none;\n   }\n   input[type=\"radio\"]:checked {\n      background-image: url(/icons/icon_checkmark.svg);\n   }\n"], ["\n   align-items: center;\n   height: auto;\n   width: 100%;\n\n   input[type=\"radio\"] {\n      appearance: none;\n      background-position: 50% 50%;\n      background-repeat: no-repeat;\n      background-size: 1rem;\n      border: 0.0625rem solid\n         ", ";\n      height: 2rem;\n      margin-right: 0.625rem;\n      width: 2rem;\n      outline: none;\n   }\n   input[type=\"radio\"]:checked {\n      background-image: url(/icons/icon_checkmark.svg);\n   }\n"])), function (props) { return (props.disabled ? "transparent" : palette("secondaryBackground")); });
var InputWrapper = styled.form(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n   width: 100%;\n"], ["\n   width: 100%;\n"])));
var CheckboxRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n   border-left: 0.25rem solid transparent;\n   border-right: 0.25rem solid transparent;\n   margin-bottom: 0.25rem;\n   opacity: ", ";\n   position: relative;\n   height: 7.375rem;\n   &:first-child {\n      margin-top: 0.25rem;\n   }\n"], ["\n   border-left: 0.25rem solid transparent;\n   border-right: 0.25rem solid transparent;\n   margin-bottom: 0.25rem;\n   opacity: ", ";\n   position: relative;\n   height: 7.375rem;\n   &:first-child {\n      margin-top: 0.25rem;\n   }\n"])), function (props) { return (!props.selected ? 0.5 : 1); });
var CheckboxWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n   align-items: center;\n   display: flex;\n   border-right: 0.0625rem solid ", ";\n   padding: 0 0.625rem;\n   margin-right: 0.625rem;\n   height: 100%;\n"], ["\n   align-items: center;\n   display: flex;\n   border-right: 0.0625rem solid ", ";\n   padding: 0 0.625rem;\n   margin-right: 0.625rem;\n   height: 100%;\n"])), palette("border"));
var GroupHeader = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n   background-color: ", ";\n   color: ", ";\n   margin-bottom: 0.25rem;\n   padding: 0.5rem 0.6875rem;\n"], ["\n   background-color: ", ";\n   color: ", ";\n   margin-bottom: 0.25rem;\n   padding: 0.5rem 0.6875rem;\n"])), palette("secondaryHighlight"), palette("white"));
var InnerRowWrapper = styled(HorizontalFlex)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n   align-items: center;\n   background-color: ", ";\n   height: 100%;\n   justify-content: flex-start;\n"], ["\n   align-items: center;\n   background-color: ", ";\n   height: 100%;\n   justify-content: flex-start;\n"])), palette("white"));
var InfoWrapper = styled(HorizontalFlex)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n   align-items: center;\n   justify-content: flex-start;\n   height: 100%;\n   width: 100%;\n"], ["\n   align-items: center;\n   justify-content: flex-start;\n   height: 100%;\n   width: 100%;\n"])));
var InfoText = styled(VerticalFlex)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n   height: auto;\n   justify-content: center;\n"], ["\n   height: auto;\n   justify-content: center;\n"])));
var ChangeStore = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n   cursor: pointer;\n   font-size: ", ";\n   position: relative;\n"], ["\n   cursor: pointer;\n   font-size: ", ";\n   position: relative;\n"])), fontSize("default"));
var StoreSelectWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n   max-height: ", ";\n   overflow: hidden;\n   transition: max-height 0.5s linear;\n"], ["\n   max-height: ", ";\n   overflow: hidden;\n   transition: max-height 0.5s linear;\n"])), function (props) { return (props.visible ? "62.5rem" : 0); });
var ChangeStoreLabel = styled.label(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n   font-weight: ", ";\n   margin-right: 0.3125rem;\n"], ["\n   font-weight: ", ";\n   margin-right: 0.3125rem;\n"])), fontWeight("bold"));
var CheckboxRowIcon = styled(Icon)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n   background-color: ", ";\n   border-radius: 50%;\n   flex-shrink: 0;\n   margin-right: 0.625rem;\n   padding: 0.25rem;\n   width: 3.75rem;\n"], ["\n   background-color: ", ";\n   border-radius: 50%;\n   flex-shrink: 0;\n   margin-right: 0.625rem;\n   padding: 0.25rem;\n   width: 3.75rem;\n"])), function (props) {
    return props.selected ? palette("primaryHighlight") : palette("secondaryHighlight");
});
var DropdownIcon = styled(Icon)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n   margin-left: 0.3rem;\n"], ["\n   margin-left: 0.3rem;\n"])));
var DeliveryOptionsComp = function (_a) {
    var deliveryModes = _a.deliveryModes, limitText = _a.limitText, stores = _a.stores, type = _a.type, session = _a.session, currentStoreId = _a.currentStoreId, deliveryMode = _a.deliveryMode, setDeliveryMode = _a.setDeliveryMode;
    var dispatch = useDispatch();
    var _b = useState(false), showStoreSelect = _b[0], setShowStoreSelect = _b[1];
    var _c = useState(currentStoreId), targetStoreId = _c[0], setTargetStoreId = _c[1];
    useEffect(function () {
        dispatch(updateSession({
            deliveryOption: deliveryMode,
        }, session.uuid));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var toggleStoreSelect = function () {
        if (Boolean(stores)) {
            setShowStoreSelect(!showStoreSelect);
        }
    };
    var getFavoriteStore = function () {
        var favStore = stores.find(function (store) { return store.id === targetStoreId; });
        return favStore ? favStore.displayName : "";
    };
    var favoriteStore = getFavoriteStore();
    var isTypeCheckout = type === processActionTypes.CHECKOUT;
    var isTypeReserve = type === processActionTypes.RESERVE;
    var isTypeOrder = type === processActionTypes.ORDER;
    var shouldRenderDevliveryOptionStore = isTypeCheckout ||
        isTypeReserve ||
        (isTypeOrder && (deliveryModes === null || deliveryModes === void 0 ? void 0 : deliveryModes.includes(possibleDeliveryModes.MANUFACTURE_ORDER)));
    var shouldRenderDeliveryOptionHome = isTypeCheckout || (isTypeOrder && (deliveryModes === null || deliveryModes === void 0 ? void 0 : deliveryModes.includes(possibleDeliveryModes.DROPSHIPPING)));
    return (_jsxs(Wrapper, { children: [_jsx(FinalForm, { onSubmit: function () { return console.log(""); }, initialValues: {
                    deliveryOption: deliveryMode,
                    targetStoreId: targetStoreId,
                }, render: function (_a) {
                    var handleSubmit = _a.handleSubmit;
                    return (_jsxs(InputWrapper, __assign({ onSubmit: handleSubmit, id: "delivery-options-form" }, { children: [_jsx(GroupHeader, { children: limitText ? (_jsx(FormattedMessage, { id: "checkout.reserve.availability.form.nonAvailableArticlesWillBeDelivered", defaultMessage: "In anderer Filiale reservieren / Bestellung beim Lieferanten" }, void 0)) : (_jsx(FormattedMessage, { id: "checkout.buy.availability.form.nonAvailableArticlesWillBeDelivered", defaultMessage: "Nicht verf\u00FCgbare Artikel sollen geliefert werden" }, void 0)) }, void 0), shouldRenderDevliveryOptionStore && (_jsx(CheckboxRow, __assign({ selected: deliveryMode === DeliveryOptions.order }, { children: _jsxs(InnerRowWrapper, { children: [_jsx(CheckboxWrapper, { children: _jsx(Field, { component: "input", type: "radio", name: "deliveryOption", value: "store", onClick: function () {
                                                    setDeliveryMode(DeliveryOptions.order);
                                                    dispatch(updateSession({
                                                        deliveryOption: DeliveryOptions.order,
                                                        targetStoreId: targetStoreId,
                                                    }, session.uuid));
                                                } }, void 0) }, void 0), _jsxs(InfoWrapper, __assign({ onClick: function () {
                                                if (!isTypeOrder) {
                                                    toggleStoreSelect();
                                                }
                                            } }, { children: [_jsx(CheckboxRowIcon, { icon: "store", selected: deliveryMode === DeliveryOptions.order, width: 60 }, void 0), _jsxs(InfoText, { children: [_jsx(ChangeStoreLabel, __assign({ htmlFor: "deliveryOption" }, { children: limitText ? (_jsx(FormattedMessage, { id: "checkout.reserve.availability.form.store", defaultMessage: "In Filiale" }, void 0)) : (_jsx(FormattedMessage, { id: "checkout.buy.availability.form.store", defaultMessage: "In Filiale abholen" }, void 0)) }), void 0), Boolean(stores) && !showStoreSelect && !isTypeOrder && (_jsxs(ChangeStore, { children: [_jsx("label", __assign({ htmlFor: "storeId" }, { children: favoriteStore && " " + favoriteStore }), void 0), _jsx(DropdownIcon, { icon: "dropdown", width: 10 }, void 0)] }, void 0))] }, void 0)] }), void 0)] }, void 0) }), void 0)), _jsx(StoreSelectWrapper, __assign({ visible: showStoreSelect }, { children: _jsx(Field, { component: "input", name: "targetStoreId", type: "hidden" }, void 0) }), void 0), shouldRenderDeliveryOptionHome && (_jsx(CheckboxRow, __assign({ selected: deliveryMode === DeliveryOptions.dropshipping }, { children: _jsxs(InnerRowWrapper, { children: [_jsx(CheckboxWrapper, { children: _jsx(Field, { component: "input", name: "deliveryOption", type: "radio", value: "home", onClick: function () {
                                                    setDeliveryMode(DeliveryOptions.dropshipping);
                                                    dispatch(updateSession({
                                                        deliveryOption: DeliveryOptions.dropshipping,
                                                        targetStoreId: targetStoreId,
                                                    }, session.uuid));
                                                } }, void 0) }, void 0), _jsxs(InfoWrapper, { children: [_jsx(CheckboxRowIcon, { icon: "shipping", selected: deliveryMode === DeliveryOptions.dropshipping, width: 60 }, void 0), _jsx(InfoText, { children: _jsx(FormattedMessage, { id: "checkout.buy.availability.form.home", defaultMessage: "Direkt nach Hause liefern" }, void 0) }, void 0)] }, void 0)] }, void 0) }), void 0)), isTypeCheckout && (_jsxs("div", { children: [_jsx(GroupHeader, { children: _jsx(FormattedMessage, { id: "checkout.buy.availability.form.allArticlesWillBeDelivered", defaultMessage: "Alle Artikel sollen geliefert werden" }, void 0) }, void 0), _jsx(CheckboxRow, __assign({ selected: deliveryMode === DeliveryOptions.all }, { children: _jsxs(InnerRowWrapper, { children: [_jsx(CheckboxWrapper, { children: _jsx(Field, { component: "input", name: "deliveryOption", type: "radio", value: "all" }, void 0) }, void 0), _jsxs(InfoWrapper, { children: [_jsx(CheckboxRowIcon, { icon: "shipping", selected: deliveryMode === DeliveryOptions.all, width: 60, onClick: function () {
                                                                setDeliveryMode(DeliveryOptions.all);
                                                                dispatch(updateSession({
                                                                    deliveryOption: DeliveryOptions.all,
                                                                    targetStoreId: targetStoreId,
                                                                }, session.uuid));
                                                            } }, void 0), _jsx(InfoText, { children: _jsx(FormattedMessage, { id: "checkout.buy.availability.form.allHome", defaultMessage: "Alle Artikel liefern lassen" }, void 0) }, void 0)] }, void 0)] }, void 0) }), void 0)] }, void 0))] }), void 0));
                } }, void 0), showStoreSelect && (isTypeCheckout || isTypeReserve) && (_jsx(CheckoutStoreSelect, { currentStore: favoriteStore, select: function (value) {
                    setTargetStoreId(value);
                    dispatch(updateSession({
                        deliveryOption: deliveryMode,
                        targetStoreId: targetStoreId,
                    }, session.uuid));
                }, stores: stores, toggleAction: toggleStoreSelect }, void 0))] }, void 0));
};
export default DeliveryOptionsComp;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
