var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from "styled-components";
import { palette } from "@shared/utils/styled";
import { Button } from "organisms/ArticleView/style";
var Lights = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";\n    border: 1rem;\n    content: \" \";\n    height: 0.25rem;\n    width: 100%;\n    min-width: 2rem;\n"], ["\n    background-color: ", ";\n    border: 1rem;\n    content: \" \";\n    height: 0.25rem;\n    width: 100%;\n    min-width: 2rem;\n"])), function (props) { return palette(props.availability); });
var NeedleSizeSelect = function (_a) {
    var _b;
    var sizes = _a.sizes, stocksForSize = _a.stocksForSize, onRequestButtonClicked = _a.onRequestButtonClicked, onReserveButtonClicked = _a.onReserveButtonClicked, onBuyButtonClicked = _a.onBuyButtonClicked, onSizeSelect = _a.onSizeSelect, branchSelectionEnabled = _a.branchSelectionEnabled;
    var _c = useState(null), selectedSize = _c[0], setSelectedSize = _c[1];
    var _d = useState(null), selectedStore = _d[0], setSelectedStore = _d[1];
    var currentStoreId = localStorage.getItem('storeId');
    var sortedStores = (_b = selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.stores) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) {
        if (a.id === currentStoreId)
            return -1;
        if (b.id === currentStoreId)
            return 1;
        return 0;
    });
    return (_jsxs("div", __assign({ style: { padding: "0 0.25rem" } }, { children: [_jsx("div", __assign({ style: {
                    display: "flex",
                    gap: "0.5rem",
                    overflowX: "auto",
                    overflowY: "hidden",
                    padding: "0.5rem 0"
                } }, { children: sizes === null || sizes === void 0 ? void 0 : sizes.map(function (size) {
                    return (_jsxs("div", __assign({ style: {
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "1.25rem",
                            gap: "0.25rem",
                            padding: "0.5rem",
                            background: (selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.labelSize) === (size === null || size === void 0 ? void 0 : size.labelSize) ? "#ededed" : "none",
                            borderRadius: "1rem",
                        }, onClick: function () {
                            setSelectedStore(null);
                            if ((size === null || size === void 0 ? void 0 : size.labelSize) === (selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.labelSize)) {
                                setSelectedSize(null);
                                onSizeSelect(null);
                                return;
                            }
                            onSizeSelect(size);
                            setSelectedSize(size);
                        } }, { children: [_jsx("div", __assign({ style: { display: "flex", justifyContent: "center" } }, { children: size.labelSize }), void 0), _jsx(Lights, { availability: size.stock }, void 0), _jsx("div", __assign({ style: { display: "flex", justifyContent: "center" } }, { children: stocksForSize[size.labelSize] }), void 0)] }), size.sizeId));
                }) }), void 0), _jsxs("div", __assign({ hidden: !selectedSize }, { children: [_jsx("div", { children: _jsxs("div", __assign({ style: {
                                marginTop: "1rem",
                                display: "flex",
                                flexDirection: "row",
                                gap: "0.5rem",
                            } }, { children: [_jsx(Button, __assign({ onClick: function () {
                                        onBuyButtonClicked(selectedSize, selectedStore);
                                    } }, { children: "Verkaufen" }), void 0), _jsx(Button, __assign({ onClick: function () {
                                        onRequestButtonClicked(selectedSize, selectedStore);
                                    } }, { children: "Anfordern" }), void 0), _jsx(Button, __assign({ onClick: function () {
                                        onReserveButtonClicked(selectedSize, selectedStore);
                                    } }, { children: "Reservieren" }), void 0)] }), void 0) }, void 0), _jsx("div", __assign({ hidden: (sortedStores === null || sortedStores === void 0 ? void 0 : sortedStores.filter(function (store) { return store.id !== currentStoreId; }).length) === 0, style: {
                            marginTop: "1rem"
                        } }, { children: _jsxs("div", { children: [_jsxs("div", __assign({ style: {
                                        display: "grid",
                                        gridTemplateColumns: "70% 30%",
                                        padding: "0.5rem 0",
                                        borderBottom: "1px solid #e0e0e0",
                                    } }, { children: [_jsx("div", { children: "Filiale" }, void 0), _jsx("div", __assign({ style: { display: "flex", justifyContent: "center" } }, { children: "Bestand" }), void 0)] }), void 0), sortedStores === null || sortedStores === void 0 ? void 0 : sortedStores.filter(function (store) { return store.id !== currentStoreId; }).map(function (store) {
                                    return (_jsxs("div", __assign({ onClick: function () {
                                            if (!branchSelectionEnabled)
                                                return;
                                            if (store.id === (selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.id)) {
                                                setSelectedStore(null);
                                                return;
                                            }
                                            setSelectedStore(store);
                                        }, style: {
                                            display: "grid",
                                            gridTemplateColumns: "70% 30%",
                                            padding: "0.75rem 0",
                                            borderBottom: "1px solid #e0e0e0",
                                            background: (selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.id) !== store.id ? "none" : "#e0e0e0",
                                            fontWeight: !selectedStore || (selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.id) !== store.id ? 300 : 400
                                        } }, { children: [_jsxs("div", __assign({ style: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    padding: "0 1rem"
                                                } }, { children: [_jsxs("div", __assign({ style: {
                                                            display: "flex",
                                                            alignItems: "center",
                                                            paddingBottom: !selectedStore || (selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.id) !== store.id ? "1.5rem" : "0",
                                                        } }, { children: [store.id, " - ", store.store] }), void 0), _jsx("div", __assign({ hidden: !selectedStore || (selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.id) !== store.id }, { children: store.address }), void 0)] }), void 0), _jsx("div", __assign({ style: {
                                                    display: "flex",
                                                    justifyContent: "center"
                                                } }, { children: store.amount }), void 0)] }), store.id));
                                })] }, void 0) }), void 0)] }), void 0)] }), void 0));
};
export default NeedleSizeSelect;
var templateObject_1;
