var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { parse } from 'query-string';
// utils
import { toJS } from '@shared/utils/immutable';
// actions
import { getArticle, updateArticle } from '@store/article/actions';
import { createWatchlistItem } from '@store/watchlist/actions';
// selectors
import { loadSingleArticle, makeSelectArticle } from '@store/article/selectors';
// components
import SessionContainer from 'containers/SessionContainer';
import ArticlePage from 'pages/Article';
import ArticleInfoPage from 'pages/ArticleInfo';
import { selectCurrentAssistant, } from '@store/assistant/selectors';
var Article = /** @class */ (function (_super) {
    __extends(Article, _super);
    function Article() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showInfo: false,
        };
        _this.toggleArticleInfo = function () {
            _this.setState({
                showInfo: !_this.state.showInfo,
            });
        };
        return _this;
    }
    Article.prototype.componentDidMount = function () {
        if (Boolean(this.props.match.params.articleId) &&
            this.props.shouldLoadArticle) {
            this.props.getArticle(this.props.match.params.articleId);
        }
    };
    Article.getDerivedStateFromProps = function (nextProps) {
        if (Boolean(nextProps.match.params.articleId) &&
            nextProps.shouldLoadArticle) {
            return nextProps.getArticle(nextProps.match.params.articleId);
        }
        return null;
    };
    Article.prototype.render = function () {
        var _this = this;
        var _a = this.props, article = _a.article, match = _a.match, location = _a.location, currentAssistant = _a.currentAssistant;
        var queryParams = parse(location.search, { arrayFormat: 'bracket' });
        return (_jsx(SessionContainer, { children: function (sessionProps) {
                // TODO Größe zum Erstellen von watchlistItem hier hinzufügen oder Option zum Erstellen von watchlistItem deaktivieren
                return _this.state.showInfo ? (_jsx(_Fragment, { children: _jsx(ArticleInfoPage, __assign({}, sessionProps, { assistantNote: article.assistantNote || '', createWatchlistItem: function (articleId, sizeId, status) {
                            return _this.props.createWatchlistItem({ articleId: articleId, sizeId: sizeId, status: status }, sessionProps.session.uuid);
                        }, deleteWatchlistItem: function (articleId) {
                            sessionProps.deleteWatchlistItem(articleId);
                        }, showInfo: _this.state.showInfo, toggleArticleInfo: _this.toggleArticleInfo, updateArticle: function (assistantNote) {
                            return _this.props.updateArticle(assistantNote, article.id);
                        } }), void 0) }, void 0)) : (_jsx(_Fragment, { children: _jsx(ArticlePage, __assign({}, sessionProps, { currentAssistant: currentAssistant, article: article, createWatchlistItem: function (payload) {
                            _this.props.createWatchlistItem(payload, sessionProps.session.uuid);
                        }, deleteWatchlistItem: function (articleId) {
                            sessionProps.deleteWatchlistItem(articleId);
                        }, params: match.params, showInfo: _this.state.showInfo, toggleArticleInfo: _this.toggleArticleInfo, source: queryParams && queryParams.source ? queryParams.source : null, primaryCategoryId: queryParams && queryParams.primaryCategory
                            ? queryParams.primaryCategory
                            : null }), void 0) }, void 0));
            } }, void 0));
    };
    return Article;
}(Component));
var select = function () { return function (state, props) { return ({
    article: makeSelectArticle()(state, props),
    shouldLoadArticle: loadSingleArticle()(state, props),
    currentAssistant: selectCurrentAssistant(state),
}); }; };
export var ArticleCont = Article;
var mapDispatchToProps = function (dispatch) { return ({
    createWatchlistItem: function (payload, sessionId) {
        return dispatch(createWatchlistItem(payload, sessionId));
    },
    updateArticle: function (payload, articleId) {
        return dispatch(updateArticle(payload, articleId));
    },
    getArticle: function (articleId) { return dispatch(getArticle(articleId)); },
}); };
export default withRouter(connect(select, mapDispatchToProps)(toJS(ArticleCont)));
