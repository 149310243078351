var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
// utils
import { palette } from '@shared/utils/styled';
// components
import { Link } from 'react-router-dom';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: 0.0625rem solid ", ";\n    width: 100%;\n    height: 100%;\n    padding: 0.5rem;\n    border-radius: 1rem;\n"], ["\n    border: 0.0625rem solid ", ";\n    width: 100%;\n    height: 100%;\n    padding: 0.5rem;\n    border-radius: 1rem;\n"])), function (props) { return (props.active ? palette('highlightText') : 'transparent'); });
var ArticleLink = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    cursor: pointer;\n    text-decoration: none;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n\n"], ["\n    cursor: pointer;\n    text-decoration: none;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n\n"])));
var SliderArticle = function (_a) {
    var active = _a.active, article = _a.article, basePath = _a.basePath, params = _a.params;
    return (_jsx(_Fragment, { children: _jsx(Wrapper, __assign({ active: active }, { children: _jsx(ArticleLink, __assign({ to: "" + (basePath || '') + article.id + params }, { children: _jsx("img", { src: article.images.main.medium, alt: "article-variant" }, void 0) }), void 0) }), void 0) }, void 0));
};
export default SliderArticle;
var templateObject_1, templateObject_2;
