var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '@shared/utils/immutable';
// actions
import { updateWatchlistItem } from '@store/watchlist/actions';
import { checkout } from '@store/session/actions';
import { orderAction } from '@store/action/actions';
// selectors
import { selectCheckingOut, selectCheckoutNumber, } from '@store/session/selectors';
import { selectCustomers } from '@store/customer/selectors';
import { selectOrderNumber } from '@store/action/selectors';
// components
import SessionContainer from 'containers/SessionContainer';
import Page from 'pages/CheckoutComplete';
var CheckoutCompleteComp = /** @class */ (function (_super) {
    __extends(CheckoutCompleteComp, _super);
    function CheckoutCompleteComp() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CheckoutCompleteComp.prototype.render = function () {
        var _this = this;
        return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { updateWatchlistItem: function (payload, id) {
                    return _this.props.updateWatchlistItem(payload, id, sessionProps.session.uuid);
                }, checkout: _this.props.checkout, checkoutNumber: _this.props.checkoutNumber, orderAction: _this.props.orderAction, orderNumber: _this.props.orderNumber, checkingOut: _this.props.checkingOut, customer: _this.props.customer }), void 0)); } }, void 0));
    };
    return CheckoutCompleteComp;
}(Component));
export { CheckoutCompleteComp };
var select = function () { return function (state) { return ({
    checkingOut: selectCheckingOut(state),
    checkoutNumber: selectCheckoutNumber(state),
    orderNumber: selectOrderNumber(state),
    customer: selectCustomers(state),
}); }; };
export default connect(select, {
    updateWatchlistItem: updateWatchlistItem,
    checkout: checkout,
    orderAction: orderAction,
})(toJS(CheckoutCompleteComp));
