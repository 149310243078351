var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Icon from "@shared/atoms/Icon";
import WatchlistArea from "organisms/WatchlistArea";
import { palette } from "@shared/utils/styled";
import localStore from "store";
var WatchlistIconWrapper = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    cursor: pointer;\n    padding-right: 16px;\n    font-size: 11.5pt;\n    color: white;\n\n"], ["\n    position: relative;\n    cursor: pointer;\n    padding-right: 16px;\n    font-size: 11.5pt;\n    color: white;\n\n"])));
var ItemsCount = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    top: 15px;\n    left: 23px;\n    background-color: ", ";\n    border-radius: 50%;\n    padding: 0 0.35rem;\n    z-index: 1;\n    font-size: 11.5pt;\n    height: 20px;\n    width: 20px;\n"], ["\n    position: absolute;\n    top: 15px;\n    left: 23px;\n    background-color: ", ";\n    border-radius: 50%;\n    padding: 0 0.35rem;\n    z-index: 1;\n    font-size: 11.5pt;\n    height: 20px;\n    width: 20px;\n"])), palette("black"));
var VSpan = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    color: black;\n    position: absolute;\n    bottom: 6px;\n    left: 12.5px;\n    font-size: 9.5pt;\n    z-index: 1;\n"], ["\n    color: black;\n    position: absolute;\n    bottom: 6px;\n    left: 12.5px;\n    font-size: 9.5pt;\n    z-index: 1;\n"])));
var WatchlistInfo = function (_a) {
    var deleteWatchlist = _a.deleteWatchlist, deleteWatchlistItem = _a.deleteWatchlistItem, requestWatchlist = _a.requestWatchlist, session = _a.session, theme = _a.theme, watchlist = _a.watchlist, _b = _a.isOpen, isOpen = _b === void 0 ? false : _b;
    var _c = useState(false), isWatchlistVisible = _c[0], setIsWatchlistVisible = _c[1];
    useEffect(function () {
        setIsWatchlistVisible(isOpen);
    }, [isOpen]);
    var handleToggleWatchlist = function () {
        return setIsWatchlistVisible(function (prevState) { return !prevState; });
    };
    var itemsCount = watchlist === null || watchlist === void 0 ? void 0 : watchlist.length;
    var addToCartClicked = localStore.get("addToCartClicked");
    var itemsSelected = addToCartClicked === "checkout-button" && itemsCount > 0
        ? "V"
        : addToCartClicked === "request-button" && itemsCount > 0
            ? "A"
            : addToCartClicked === "inDifferentStore" && itemsCount > 0
                ? "A"
                : addToCartClicked === "reserve-button" && itemsCount > 0
                    ? "R"
                    : addToCartClicked === "orderable" && itemsCount > 0
                        ? "B"
                        : "";
    return (_jsxs(_Fragment, { children: [_jsxs(WatchlistIconWrapper, __assign({ onClick: handleToggleWatchlist }, { children: [_jsx(Icon, { icon: "warenkorb", width: "35" }, void 0), _jsx(VSpan, { children: itemsSelected }, void 0), itemsCount && _jsx(ItemsCount, { children: itemsCount }, void 0)] }), void 0), _jsx(WatchlistArea, { deleteWatchlist: deleteWatchlist, deleteWatchlistItem: deleteWatchlistItem, toggleWatchlist: handleToggleWatchlist, sessionId: session.uuid || session, theme: theme, visible: isWatchlistVisible, watchlist: watchlist, requestWatchlist: requestWatchlist }, void 0)] }, void 0));
};
export default WatchlistInfo;
var templateObject_1, templateObject_2, templateObject_3;
