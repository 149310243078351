var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from "react";
import styled from "styled-components";
import orderBy from "lodash/orderBy";
import { FormattedMessage } from "react-intl";
// components
import WatchlistItem from "organisms/WatchlistItem";
import QRCode from "qrcode.react";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  overflow-y: auto;\n"], ["\n  height: 100%;\n  overflow-y: auto;\n"])));
var QrCodeWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 15px;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  overflow-y: auto;\n"], ["\n  padding: 15px;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  overflow-y: auto;\n"])));
var QrCodeDescription = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: bold;\n  font-size: 1.075rem;\n  color: #000;\n  margin-bottom: 10px;\n"], ["\n  font-weight: bold;\n  font-size: 1.075rem;\n  color: #000;\n  margin-bottom: 10px;\n"])));
export var Watchlist = function (_a) {
    var watchlist = _a.watchlist, deleteWatchlistItem = _a.deleteWatchlistItem, sessionId = _a.sessionId, toggleWatchlist = _a.toggleWatchlist, theme = _a.theme, canShare = _a.canShare;
    return (_jsxs(Wrapper, { children: [watchlist &&
                orderBy(watchlist, "createdAt", "desc").map(function (watchlistItem) { return (_jsx(WatchlistItem, { deleteWatchlistItem: deleteWatchlistItem, item: watchlistItem, sessionId: sessionId, toggleWatchlist: toggleWatchlist }, watchlistItem.id)); }), theme.customerAppId && theme.switches.share && (_jsxs(QrCodeWrapper, { children: [_jsx(QrCodeDescription, { children: _jsx(FormattedMessage, { id: "watchlist.qrCode.description", defaultMessage: "\u00DCbertrage den Warenkorb zur mobilen Bezahlung oder zum weiterst\u00F6bern an deinen Kunden." }, void 0) }, void 0), _jsx(QRCode, { value: theme.customerAppUrl + "/" + sessionId + "?" + theme.customerAppId + "&p=1", fgColor: "#000", size: 150 }, void 0)] }, void 0))] }, void 0));
};
var templateObject_1, templateObject_2, templateObject_3;
