var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import FilterOptionList from 'molecules/FilterOptionList';
import FilterAutocomplete from 'molecules/FilterAutocomplete';
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-y: scroll;\n  flex: 1;\n"], ["\n  overflow-y: scroll;\n  flex: 1;\n"])));
var FilterOptionSearch = function (_a) {
    var deselectFilterItem = _a.deselectFilterItem, filterId = _a.filterId, filterItems = _a.filterItems, filterLoading = _a.filterLoading, isOptionActive = _a.isOptionActive, selectFilterItem = _a.selectFilterItem;
    var selectedItems = filterItems.filter(function (item) { return isOptionActive(item.id); });
    return (_jsxs(Wrapper, { children: [_jsx(FilterAutocomplete, { filterId: filterId, filterItems: filterItems, selectItem: selectFilterItem }, void 0), selectedItems && (_jsx(FilterOptionList, { deselectFilterItem: deselectFilterItem, filterId: filterId, filterItems: selectedItems, filterLoading: filterLoading, isOptionActive: isOptionActive, selectFilterItem: selectFilterItem }, void 0))] }, void 0));
};
export default FilterOptionSearch;
var templateObject_1;
