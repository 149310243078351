var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import TilesSmall from 'molecules/TilesSmall';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import TilesBig from 'molecules/TilesBig';
import Banner from 'molecules/Banner';
import { Link } from 'react-router-dom';
import SimpleBanner from 'molecules/SimpleBanner';
import ArticleSlider from 'molecules/ArticleSlider';
import { key } from '@shared/utils/styled';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  overflow-y: auto;\n"], ["\n  flex: 1;\n  overflow-y: auto;\n"])));
var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  margin: 0 auto;\n  max-width: 90%;\n"], ["\n  display: block;\n  margin: 0 auto;\n  max-width: 90%;\n"])));
var Section = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0 auto 1.25rem auto;\n  max-width: 81.25rem;\n  width: 100%;\n"], ["\n  margin: 0 auto 1.25rem auto;\n  max-width: 81.25rem;\n  width: 100%;\n"])));
var SectionBig = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0 auto 1.25rem auto;\n  @media screen and (min-width: ", ") {\n    width: 90%;\n  }\n"], ["\n  margin: 0 auto 1.25rem auto;\n  @media screen and (min-width: ", ") {\n    width: 90%;\n  }\n"])), key('breakpoints.large'));
var InspirationSections = function (_a) {
    var sections = _a.sections, session = _a.session;
    return (_jsx(Wrapper, { children: sortBy(sections, 'sort').map(function (section) {
            if (section.type === 'banner' && Boolean(section.imageUrlMobile)) {
                return (_jsx(SectionBig, { children: _jsx(Banner, { buttonPosition: section.buttonPosition || null, buttonText: section.buttonText, imageUrl: section.imageUrlMobile, targetUrl: "/sessions/" + session.uuid + section.targetUrl }, void 0) }, section.sort));
            }
            if (section.type === 'simpleBanner' &&
                Boolean(section.imageUrlMobile)) {
                return (_jsx(SectionBig, { children: _jsx(SimpleBanner, { imageUrl: section.imageUrlMobile, targetUrl: "/sessions/" + session.uuid + section.targetUrl }, void 0) }, section.sort));
            }
            if (section.type === 'tilesSmall') {
                return (_jsx(Section, { children: _jsx(TilesSmall, { bannerUrl: section.bannerUrl, session: session, tiles: section.items }, void 0) }, section.sort));
            }
            if (section.type === 'tilesBig') {
                return (_jsx(Section, { children: _jsx(TilesBig, { tiles: section.items, session: session }, void 0) }, section.sort));
            }
            if (section.type === 'articleSlider') {
                return (_jsx(Section, { children: _jsx(ArticleSlider, { articles: section.items, headline: section.headline, session: session }, void 0) }, section.sort));
            }
            if (section.type === 'image' && Boolean(section.imageUrlMobile)) {
                if (!section.targetUrl) {
                    return (_jsx(Section, { children: _jsx(Image, { src: section.imageUrlMobile }, void 0) }, section.sort));
                }
                return (_jsx(Section, { children: _jsx(Link, __assign({ to: "/sessions/" + session.uuid + section.targetUrl }, { children: _jsx(Image, { src: section.imageUrl }, void 0) }), void 0) }, section.sort));
            }
            return null;
        }) }, void 0));
};
export default InspirationSections;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
