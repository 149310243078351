var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
// utils
import { palette, fontWeight } from '@shared/utils/styled';
import { getPriceString } from '@shared/utils/currency';
import { getImageUrl } from '@shared/utils/images';
// components
import CheckButton from '@shared/molecules/CheckButton';
import DiscountBadgeCheckout from 'molecules/DiscountBadgeCheckout';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import VerticalFlex from 'atoms/VerticalFlex';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-left: 0.25rem solid transparent;\n  border-right: 0.25rem solid transparent;\n  margin-bottom: 0.25rem;\n  opacity: 1;\n  position: relative;\n  min-height: 8.5rem;\n  &:first-child {\n    margin-top: 0.1875rem;\n  }\n\n  &::before,\n  &::after {\n    content: '';\n    height: 0.25rem;\n    left: -0.25rem;\n    position: absolute;\n    width: calc(100% + 0.5rem);\n  }\n\n  &::before {\n    bottom: 100%;\n  }\n\n  &::after {\n    top: 100%;\n  }\n"], ["\n  border-left: 0.25rem solid transparent;\n  border-right: 0.25rem solid transparent;\n  margin-bottom: 0.25rem;\n  opacity: 1;\n  position: relative;\n  min-height: 8.5rem;\n  &:first-child {\n    margin-top: 0.1875rem;\n  }\n\n  &::before,\n  &::after {\n    content: '';\n    height: 0.25rem;\n    left: -0.25rem;\n    position: absolute;\n    width: calc(100% + 0.5rem);\n  }\n\n  &::before {\n    bottom: 100%;\n  }\n\n  &::after {\n    top: 100%;\n  }\n"])));
var ContentWrapper = styled(VerticalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: flex-start;\n  background-color: ", ";\n  height: 100%;\n  justify-content: flex-start;\n"], ["\n  align-items: flex-start;\n  background-color: ", ";\n  height: 100%;\n  justify-content: flex-start;\n"])), palette('white'));
var InnerWrapper = styled(HorizontalFlex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  height: 100%;\n  justify-content: flex-start;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  height: 100%;\n  justify-content: flex-start;\n"])), palette('white'));
var InfoWrapper = styled(VerticalFlex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-left: 0.0625rem solid ", ";\n  justify-content: flex-start;\n  overflow: hidden;\n  padding: 0 0.625rem;\n  width: 100%;\n"], ["\n  border-left: 0.0625rem solid ", ";\n  justify-content: flex-start;\n  overflow: hidden;\n  padding: 0 0.625rem;\n  width: 100%;\n"])), palette('border'));
var ArticleWrapper = styled(HorizontalFlex)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-top: 0.0625rem solid ", ";\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  padding-top: 0.625rem;\n  width: 100%;\n"], ["\n  border-top: 0.0625rem solid ", ";\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  padding-top: 0.625rem;\n  width: 100%;\n"])), palette('border'));
var ArticleBox = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 0 0.625rem 0.625rem 0;\n"], ["\n  margin: 0 0.625rem 0.625rem 0;\n"])));
var ArticleImageWrapper = styled.object(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: 0.0625rem solid ", ";\n  display: block;\n  height: 40px;\n  width: 40px;\n"], ["\n  border: 0.0625rem solid ", ";\n  display: block;\n  height: 40px;\n  width: 40px;\n"])), palette('border'));
var ArticleImage = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: block;\n  height: 40px;\n  width: 40px;\n"], ["\n  display: block;\n  height: 40px;\n  width: 40px;\n"])));
var CheckButtonWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  padding: 0 0.625rem;\n  height: 100%;\n"], ["\n  align-items: center;\n  display: flex;\n  padding: 0 0.625rem;\n  height: 100%;\n"])));
var InfoWrapper2 = styled(HorizontalFlex)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  align-items: center;\n  justify-content: flex-start;\n"], ["\n  align-items: center;\n  justify-content: flex-start;\n"])));
var ThumbnailImage = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  font-size: 0;\n  justify-content: center;\n  margin-right: 0.625rem;\n  padding: 0.625rem 0;\n  position: relative;\n  width: 4.375rem;\n  img {\n    border: 0.0625rem solid ", ";\n    max-width: 100%;\n    width: 100%;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  font-size: 0;\n  justify-content: center;\n  margin-right: 0.625rem;\n  padding: 0.625rem 0;\n  position: relative;\n  width: 4.375rem;\n  img {\n    border: 0.0625rem solid ", ";\n    max-width: 100%;\n    width: 100%;\n  }\n"])), palette('border'));
var Info = styled(VerticalFlex)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: ", ";\n  flex: 7;\n  line-height: 1.25rem;\n  padding: 0.625rem 0;\n  max-width: 78%; // Needed for inner text-overflow\n"], ["\n  color: ", ";\n  flex: 7;\n  line-height: 1.25rem;\n  padding: 0.625rem 0;\n  max-width: 78%; // Needed for inner text-overflow\n"])), palette('text'));
var Headline = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: ", ";\n  margin: 0;\n  overflow: hidden;\n  padding-right: 2rem;\n  text-overflow: ellipsis;\n  text-transform: uppercase;\n  white-space: nowrap;\n"], ["\n  color: ", ";\n  font-weight: ", ";\n  margin: 0;\n  overflow: hidden;\n  padding-right: 2rem;\n  text-overflow: ellipsis;\n  text-transform: uppercase;\n  white-space: nowrap;\n"])), palette('text'), fontWeight('bold'));
var Description = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"], ["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"])));
var Tag = styled.span(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: ", ";\n"], ["\n  color: ", ";\n  text-decoration: ", ";\n"])), function (props) { return (props.discount ? '#b52025' : 'inherit'); }, function (props) { return (props.crossed ? 'line-through' : 'none'); });
var CheckoutOverviewListItem = function (_a) {
    var item = _a.item, watchlist = _a.watchlist, addAction = _a.addAction, removeAction = _a.removeAction;
    var getWatchlistItem = function () {
        return watchlist.find(function (witem) {
            return witem.articleId === item.id && witem.sizeId === item.size.sizeId;
        });
    };
    var isSelected = function () { return Boolean(getWatchlistItem()); };
    var toggleSelection = function () {
        var _a;
        if ((_a = item === null || item === void 0 ? void 0 : item.size) === null || _a === void 0 ? void 0 : _a.sizeId) {
            var watchlistItem = getWatchlistItem();
            if (watchlistItem && Boolean(watchlistItem)) {
                return removeAction(watchlistItem.id);
            }
            return addAction({ articleId: item.id, sizeId: item.size.sizeId });
        }
    };
    var getCurrentPrice = function () {
        var size = item.size;
        if (size.price === size.targetPrice) {
            return _jsx(Tag, { children: getPriceString(size.price) }, void 0);
        }
        return (_jsxs("span", { children: [_jsx(Tag, __assign({ crossed: true }, { children: getPriceString(size.targetPrice) }), void 0), ' ', _jsx(Tag, __assign({ discount: true }, { children: getPriceString(size.price) }), void 0), ' '] }, void 0));
    };
    var selected = isSelected();
    var price = getCurrentPrice();
    return (_jsx(Wrapper, __assign({ onClick: function () { return toggleSelection(); } }, { children: _jsx(ContentWrapper, { children: _jsxs(InnerWrapper, { children: [_jsx(CheckButtonWrapper, __assign({ onClick: function () { return toggleSelection(); } }, { children: _jsx(CheckButton, { checked: selected, onChange: function () { return toggleSelection(); } }, void 0) }), void 0), _jsxs(InfoWrapper, { children: [_jsxs(InfoWrapper2, { children: [_jsxs(ThumbnailImage, { children: [_jsx("img", { src: getImageUrl(item.images.main), alt: '' }, void 0), item.size.price < item.size.targetPrice && (_jsx(DiscountBadgeCheckout, {}, void 0))] }, void 0), _jsxs(Info, { children: [_jsxs(Headline, { children: [item.brand && item.brand.name && item.brand.name + ", ", item.displayName] }, void 0), _jsx(Description, { children: item.color }, void 0), price && _jsx("span", { children: price }, void 0)] }, void 0)] }, void 0), item.belongsTo && item.belongsTo.length > 0 && (_jsx(ArticleWrapper, { children: item.belongsTo.map(function (article) { return (_jsx(ArticleBox, __assign({ title: article.displayName }, { children: _jsx(ArticleImageWrapper, __assign({ data: 'https://storage.googleapis.com/c91b2bb74b7db747a90a1741add6745f/needle_images/app/fallback.svg', type: 'image/svg+xml' }, { children: _jsx(ArticleImage, { alt: article.displayName, src: article.images.main.thumbnail
                                                ? article.images.main.thumbnail
                                                : 'https://storage.googleapis.com/c91b2bb74b7db747a90a1741add6745f/needle_images/app/fallback.svg' }, void 0) }), void 0) }), "article-" + article.id)); }) }, void 0))] }, void 0)] }, void 0) }, void 0) }), void 0));
};
export default CheckoutOverviewListItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
