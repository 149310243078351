var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
// utils
import { fontWeight, palette } from '@shared/utils/styled';
// components
import CancelButton from '@shared/molecules/CancelButton';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import SubMenu from 'organisms/SubMenu';
// translations
import msg from './messages';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  height: calc(100% - 3.125rem);\n  left: 0;\n  position: fixed;\n  top: 3.125rem;\n  width: 100%;\n  z-index: 1;\n"], ["\n  background-color: ", ";\n  height: calc(100% - 3.125rem);\n  left: 0;\n  position: fixed;\n  top: 3.125rem;\n  width: 100%;\n  z-index: 1;\n"])), palette('primaryBackground'));
var Spacer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 4.375rem;\n"], ["\n  width: 4.375rem;\n"])));
var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100%;\n  padding: 0.625rem;\n  background-color: ", ";\n"], ["\n  height: 100%;\n  padding: 0.625rem;\n  background-color: ", ";\n"])), palette('white'));
var StoreListItem = styled(HorizontalFlex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  border-top: 0.0625rem solid ", ";\n  padding: 0.9375rem 2.5rem 0.9375rem 5rem;\n  width: 100%;\n  &:first-child {\n    border-top: none;\n  }\n"], ["\n  background-color: ", ";\n  border-top: 0.0625rem solid ", ";\n  padding: 0.9375rem 2.5rem 0.9375rem 5rem;\n  width: 100%;\n  &:first-child {\n    border-top: none;\n  }\n"])), function (props) {
    return props.selected ? palette('border') : 'transparent';
}, palette('border'));
var StoreLabel = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex 1;\n  font-weight: ", ";\n"], ["\n  flex 1;\n  font-weight: ", ";\n"])), fontWeight('bold'));
var CheckoutStoreSelect = function (_a) {
    var currentStore = _a.currentStore, stores = _a.stores, toggleAction = _a.toggleAction, select = _a.select;
    var intl = useIntl();
    return (_jsxs(Wrapper, { children: [_jsx(SubMenu, { title: intl.formatMessage(msg.headline), cancelIcon: _jsx(Spacer, {}, void 0), iconRight: _jsx(CancelButton, { action: toggleAction }, void 0) }, void 0), _jsx(Content, { children: stores.map(function (store) { return (_jsx(StoreListItem, __assign({ onClick: function () {
                        select(store.id);
                        toggleAction();
                    }, selected: store.displayName === currentStore }, { children: _jsx(StoreLabel, { children: store.displayName }, void 0) }), "size" + store.id)); }) }, void 0)] }, void 0));
};
export default withRouter(CheckoutStoreSelect);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
