var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import ShareForm from 'molecules/WatchlistShareForm';
import QRCode from 'qrcode.react';
import WatchlistShareSuccess from '@shared/molecules/WatchlistShareSuccess';
import localStore from 'store';
import { Wrapper, Headline, ErrorMsg, ShareOptions, QRCodeWrapper, EmailForm, } from './style';
var WatchlistShare = function (_a) {
    var sessionId = _a.sessionId, theme = _a.theme, shareSession = _a.shareSession;
    var _b = useState(false), shareSuccess = _b[0], setShareSuccess = _b[1];
    var _c = useState(false), shareFailed = _c[0], setShareFailed = _c[1];
    var toggleShareSuccess = function (success) {
        setShareFailed(!success);
        setShareSuccess(success);
    };
    var onShareSessionClick = function (values) {
        return new Promise(function (resolve, reject) {
            return shareSession(values, resolve, reject);
        })
            .then(function () { return toggleShareSuccess(true); })
            .catch(function () { return toggleShareSuccess(false); });
    };
    var watchlistStatus = localStore.get('watchlistStatus');
    return (_jsxs(Wrapper, { children: [!shareSuccess && (_jsxs(ShareOptions, { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { id: 'action.share.headline', defaultMessage: '\u00DCbertragen des Warenkorbs' }, void 0) }, void 0), _jsx(QRCodeWrapper, __assign({ "data-cy-id": 'qr-code-wrapper' }, { children: _jsx(QRCode, { "data-cy-id": 'qr-code', value: theme.customerAppUrl + "/" + sessionId + "?" + theme.customerAppId, fgColor: '#000', size: 150 }, void 0) }), void 0), shareFailed && (_jsx(ErrorMsg, { children: _jsx(FormattedMessage, { id: 'action.shareFailed.message', defaultMessage: 'Teilen des Warenkorbs ist fehlgeschlagen' }, void 0) }, void 0)), 'orderable' !== watchlistStatus && (_jsx(EmailForm, { children: _jsx(ShareForm, { onSubmit: onShareSessionClick }, void 0) }, void 0))] }, void 0)), shareSuccess && _jsx(WatchlistShareSuccess, {}, void 0)] }, void 0));
};
export default withTheme(WatchlistShare);
