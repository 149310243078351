var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import styled, { withTheme } from "styled-components";
import ArticleList from "molecules/ArticleList";
import SessionLayout from "templates/SessionLayout";
import SubMenu from "organisms/SubMenu";
import Icon from "@shared/atoms/Icon";
import { useSelector } from "react-redux";
import { filterSelection } from "@store/filterSelections/filterSelectors";
var MenuIconLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border-radius: 1rem;\n    background-color: #acaaa9;\n    padding: 0.75rem;\n    align-items: center;\n    display: flex;\n    height: 100%;\n    justify-content: center;\n\n    &:active {\n        background-color: #000000;\n        color: #ffffff;\n    }\n"], ["\n    border-radius: 1rem;\n    background-color: #acaaa9;\n    padding: 0.75rem;\n    align-items: center;\n    display: flex;\n    height: 100%;\n    justify-content: center;\n\n    &:active {\n        background-color: #000000;\n        color: #ffffff;\n    }\n"])));
var IconsFix = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    width: fit-content;\n    position: fixed;\n    bottom: 1rem;\n    right: 1.5rem;\n    gap: 1rem;\n"], ["\n    display: flex;\n    flex-direction: column;\n    width: fit-content;\n    position: fixed;\n    bottom: 1rem;\n    right: 1.5rem;\n    gap: 1rem;\n"])));
var Result = function (_a) {
    var articlePage = _a.articlePage, articlePageCount = _a.articlePageCount, articles = _a.articles, articlesLoading = _a.articlesLoading, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, fetch = _a.fetch, filterDescription = _a.filterDescription, primaryCategory = _a.primaryCategory, session = _a.session, terminals = _a.terminals, title = _a.title, theme = _a.theme, watchlist = _a.watchlist, showMenuIcons = _a.showMenuIcons;
    var history = useHistory();
    var location = useLocation();
    var queryParams = queryString.parse(location.search);
    var params = (session === null || session === void 0 ? void 0 : session.searchParams) || "";
    var _b = useState(false), isArticleListScrolled = _b[0], setIsArticleListScrolled = _b[1];
    var getSelectedFilter = useSelector(filterSelection);
    var _c = queryString.parse(window.location.search), category = _c.category, rest = __rest(_c, ["category"]);
    var queryStringExceptCategory = queryString.stringify(rest);
    var scanUrl = "/sessions/" + session.uuid + "/scan";
    var handleArticleListScroll = function (scrolled) {
        setIsArticleListScrolled(scrolled);
    };
    var getSearchPageUrl = function () {
        var _a = queryString.parse(window.location.search), category = _a.category, primaryCategory = _a.primaryCategory, rest = __rest(_a, ["category", "primaryCategory"]);
        var queryStringExceptCategory = queryString.stringify(rest);
        return "/sessions/" + (session === null || session === void 0 ? void 0 : session.uuid) + "/search/" + primaryCategory + "/?" + queryStringExceptCategory;
    };
    var cancelSearch = function () {
        history.push(getSearchPageUrl());
    };
    var createFilterUrl = function () {
        var queryString = Object.entries(queryParams).reduce(function (acc, _a, index, array) {
            var key = _a[0], value = _a[1];
            acc += key + "=" + value;
            if (index < array.length - 1) {
                acc += "&";
            }
            return acc;
        }, "");
        return "/sessions/" + session.uuid + "/filters?" + queryString;
    };
    var hasSorting = function () {
        var baseParams = queryString.parse(params, { arrayFormat: 'bracket' });
        return Boolean(baseParams.sort);
    };
    var filterUrl = createFilterUrl();
    var filterIcon = getSelectedFilter[0] || queryStringExceptCategory
        ? "icon_filter_active"
        : "icon_filter";
    var sortIcon = hasSorting() ? "icon_sort_active" : "icon_sort";
    var allQueryParams = queryString.stringify(__assign(__assign({}, queryParams), { category: category, primaryCategory: primaryCategory === null || primaryCategory === void 0 ? void 0 : primaryCategory.id }));
    return (_jsxs(SessionLayout, __assign({ showMenuIcons: !showMenuIcons, assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, backgroundColor: theme.palette.white, watchlist: watchlist, isArticleListScrolled: isArticleListScrolled, subMenu: _jsx(SubMenu, { cancelIcon: _jsx(Icon, { onClick: cancelSearch, icon: "back" }, void 0), title: title }, void 0), hasFilters: Boolean(filterDescription), currentAssistantId: currentAssistantId }, { children: [_jsx(ArticleList, { params: params, articles: articles, basePath: "/sessions/" + (session === null || session === void 0 ? void 0 : session.uuid) + "/articles/", currentPage: articlePage, filterDescription: filterDescription, fetchArticles: fetch, loading: articlesLoading, pageCount: articlePageCount, primaryCategory: primaryCategory, ScrollingState: handleArticleListScroll }, void 0), _jsxs(IconsFix, { children: [_jsx(MenuIconLink, __assign({ to: "/sessions/" + session.uuid + "/sorts?" + allQueryParams }, { children: _jsx(Icon, { icon: sortIcon, width: "30", fill: "white" }, void 0) }), void 0), _jsx(MenuIconLink, __assign({ to: filterUrl }, { children: _jsx(Icon, { icon: filterIcon, width: "30", fill: "white" }, void 0) }), void 0), _jsx(MenuIconLink, __assign({ to: scanUrl }, { children: _jsx(Icon, { icon: "scan", width: "30", fill: "white" }, void 0) }), void 0)] }, void 0)] }), void 0));
};
export default withTheme(Result);
var templateObject_1, templateObject_2;
