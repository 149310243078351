var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
// singlton
import history from '@store/history';
// components
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
// import ScanSearch from 'organisms/ScanSearch';
import CancelButton from '@shared/molecules/CancelButton';
import { withTheme } from 'styled-components';
import ArticleSrch from 'components/organisms/ArticleSrch';
var ArticleSearch = function (_a) {
    var assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, currentAssistantId = _a.currentAssistantId, error = _a.error, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, loading = _a.loading, searchArticle = _a.searchArticle, scanArticle = _a.scanArticle, session = _a.session, terminals = _a.terminals, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist, resetArticleError = _a.resetArticleError, theme = _a.theme;
    return (_jsx(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, backgroundColor: theme.palette.white, currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, subMenu: _jsx(SubMenu, { title: 'Artikel suchen', cancelIcon: _jsx(CancelButton, { customIcon: 'back', width: '2rem', action: function () { return history.push("/"); } }, void 0) }, void 0), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist }, { children: _jsx(ArticleSrch, { sessionId: session.uuid, searchArticle: searchArticle, scanArticle: scanArticle, resetArticleError: resetArticleError, loading: loading, error: error }, void 0) }), void 0));
};
export default withTheme(ArticleSearch);
