var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { palette, fontSize } from '@shared/utils/styled';
import styled from 'styled-components';
import FlexButton from '@shared/atoms/FlexButton';
var HighlightButton = styled(FlexButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  font-size: ", ";\n  letter-spacing: 0.0125rem;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  font-size: ", ";\n  letter-spacing: 0.0125rem;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('primaryHighlight'), palette('white'), fontSize('extraLarge'), palette('activePrimaryHighlight'));
/** @component */
export default HighlightButton;
var templateObject_1;
