var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import Spinner from '@shared/atoms/Spinner';
import { Wrapper } from './styles';
import { parse, stringify } from 'query-string';
import { injectIntl } from 'react-intl';
import SortListItem from 'molecules/SortListItem';
import { useLocation } from 'react-router';
var SortList = function (props) {
    var _a = useState(null), selectedSortId = _a[0], setSelectedSortId = _a[1];
    var location = useLocation();
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var sortIdFromLocation = queryParams.get('sort');
        if (sortIdFromLocation) {
            var sortId = parseInt(sortIdFromLocation, 10);
            if (!isNaN(sortId)) {
                setSelectedSortId(sortId);
            }
        }
    }, [location]);
    var baseParams = useMemo(function () { return parse(props.params, { arrayFormat: 'bracket' }); }, [props.params]);
    var getSorts = useMemo(function () {
        var sorts = props.sorts, session = props.session, currentSortId = props.currentSortId;
        return sorts.reduce(function (acc, item) {
            baseParams.sort = item.id;
            acc.push({
                title: item.displayName,
                target: "/sessions/" + session.uuid + "/results?" + stringify(baseParams, {
                    arrayFormat: 'bracket',
                }),
                active: item.id === currentSortId,
                id: item.id,
            });
            return acc;
        }, []);
    }, [props.sorts, props.session, props.currentSortId, baseParams]);
    var loading = props.loading;
    var sortItems = getSorts;
    if (loading) {
        return _jsx(Spinner, {}, void 0);
    }
    return (_jsx(Wrapper, { children: sortItems.map(function (sort) { return (_jsx("div", __assign({ style: { borderLeft: (sort === null || sort === void 0 ? void 0 : sort.id) === selectedSortId ? 'black solid 0.4rem' : 'transparent solid 0.4rem' } }, { children: _jsx(SortListItem, { sort: sort }, sort.id) }), sort.id)); }) }, void 0));
};
export default injectIntl(SortList);
