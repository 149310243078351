var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from "react";
import styled, { withTheme } from "styled-components";
import { fontWeight, palette } from "@shared/utils/styled";
import HorizontalFlex from "@shared/atoms/HorizontalFlex";
import HeaderLogo from "../../../assistant/headerLogo/components/HeaderLogo";
import Icon from "@shared/atoms/Icon";
import { Link } from "react-router-dom";
var MenuIconLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    align-items: center;\n    display: flex;\n    height: 100%;\n\n    &:active {\n        background-color: ", ";\n    }\n"], ["\n    align-items: center;\n    display: flex;\n    height: 100%;\n\n    &:active {\n        background-color: ", ";\n    }\n"])), palette("activeSecondaryBackground"));
var Wrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 4.375rem;\n    width: 100%;\n    font-weight: ", ";\n        // border-bottom: 0.14rem solid ", ";\n    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);\n    padding: 1rem;\n\n"], ["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 4.375rem;\n    width: 100%;\n    font-weight: ", ";\n        // border-bottom: 0.14rem solid ", ";\n    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);\n    padding: 1rem;\n\n"])), fontWeight("bold"), palette("border"));
var WrapperFooter = styled(HorizontalFlex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    align-items: center;\n    height: 4.375rem;\n    width: 100%;\n    font-weight: ", ";\n    border-top: 0.14rem solid ", ";\n    padding: 1rem;\n    display: flex;\n    justify-content: space-around;\n"], ["\n    align-items: center;\n    height: 4.375rem;\n    width: 100%;\n    font-weight: ", ";\n    border-top: 0.14rem solid ", ";\n    padding: 1rem;\n    display: flex;\n    justify-content: space-around;\n"])), fontWeight("bold"), palette("border"));
var HomeHeader = function (_a) {
    var theme = _a.theme;
    return (_jsx(Wrapper, { children: _jsx(HeaderLogo, {}, void 0) }, void 0));
};
export var HomeFooter = function (session, theme) {
    var scanUrl = "/sessions/" + localStorage.getItem("sessionuuid") + "/scan";
    var tilePath = function (path) {
        return "/sessions/" + localStorage.getItem("sessionuuid") + "/" + path;
    };
    return (_jsxs(WrapperFooter, { children: [_jsx(MenuIconLink, __assign({ to: scanUrl }, { children: _jsx(Icon, { icon: "scan", width: "35" }, void 0) }), void 0), _jsx(MenuIconLink, __assign({ to: scanUrl }, { children: _jsx(Icon, { icon: "search", width: "35" }, void 0) }), void 0), _jsx(MenuIconLink, __assign({ to: tilePath("search") }, { children: _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", height: "35", viewBox: "0 -960 960 960", width: "35", fill: "#534d4d" }, { children: _jsx("path", { d: "M80-160v-80h800v80H80Zm80-160v-320h80v320h-80Zm160 0v-480h80v480h-80Zm160 0v-480h80v480h-80Zm280 0L600-600l70-40 160 280-70 40Z" }, void 0) }), void 0) }), void 0)] }, void 0));
};
export default withTheme(HomeHeader);
var templateObject_1, templateObject_2, templateObject_3;
