var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { fontSize, palette } from "@shared/utils/styled";
import AssignModal from "molecules/AssignModal";
import styled from "styled-components";
import ProfileIcon from "../../../assistant/profileIcon/components/ProfileIcon";
import HorizontalFlex from "@shared/atoms/HorizontalFlex";
var Wrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n"], ["\n"])));
var Menu = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n"], ["\n"])));
var MenuContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n"], ["\n"])));
var Entry = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n"], ["\n"])));
var LogoutButton = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    cursor: pointer;\n    color: ", ";\n    display: flex;\n    font-size: ", ";\n    font-weight: 500;\n    height: fit-content;\n    position: relative;\n    margin: 0;\n    width: fit-content;\n\n    &:active {\n        background-color: #dcdcdc;\n        width: 80px;\n        border-radius: 5px\n    }\n"], ["\n    cursor: pointer;\n    color: ", ";\n    display: flex;\n    font-size: ", ";\n    font-weight: 500;\n    height: fit-content;\n    position: relative;\n    margin: 0;\n    width: fit-content;\n\n    &:active {\n        background-color: #dcdcdc;\n        width: 80px;\n        border-radius: 5px\n    }\n"])), palette("black"), fontSize("mediume"));
var SessionMenu = function (_a) {
    var unleashAssistant = _a.unleashAssistant, visible = _a.visible, toggleMenu = _a.toggleMenu, history = _a.history, logout = _a.logout;
    var _b = useState(false), showAssignModal = _b[0], setShowAssignModal = _b[1];
    var toggleAssignModal = function () {
        setShowAssignModal(!showAssignModal);
    };
    var goBackToSession = function () {
        toggleAssignModal();
        toggleMenu();
    };
    return (_jsxs(Wrapper, { children: [_jsx(Menu, __assign({ visible: visible }, { children: _jsxs(MenuContent, { children: [_jsx(Entry, { children: _jsx(ProfileIcon, {}, void 0) }, void 0), _jsx(Entry, { children: _jsx(LogoutButton, __assign({ onClick: logout }, { children: "Abmelden" }), void 0) }, void 0)] }, void 0) }), void 0), _jsx(AssignModal, { open: showAssignModal, toggleAction: toggleAssignModal, unleashAssistant: unleashAssistant, goBackToSession: goBackToSession }, void 0)] }, void 0));
};
export default withRouter(SessionMenu);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
