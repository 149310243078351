var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from "react";
import styled from "styled-components";
import { palette } from "@shared/utils/styled";
import CheckboxIcon from "./icon_checkbox.svg";
var Input = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  appearance: none;\n  background-position: 50% 50%;\n  background-repeat: no-repeat;\n  background-size: 1.25rem;\n  border: 0.0625rem solid\n    ", ";\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  height: 1.875rem;\n  justify-content: center;\n  outline: none;\n  width: 1.875rem;\n  &:checked {\n    background-image: url(\"", "\");\n  }\n"], ["\n  align-items: center;\n  appearance: none;\n  background-position: 50% 50%;\n  background-repeat: no-repeat;\n  background-size: 1.25rem;\n  border: 0.0625rem solid\n    ", ";\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  height: 1.875rem;\n  justify-content: center;\n  outline: none;\n  width: 1.875rem;\n  &:checked {\n    background-image: url(\"", "\");\n  }\n"])), function (props) {
    return props.disabled ? "transparent" : palette("secondaryBackground");
}, palette("text"), CheckboxIcon);
var CheckButton = function (_a) {
    var checked = _a.checked, disabled = _a.disabled, id = _a.id, name = _a.name, value = _a.value, onBlur = _a.onBlur, onChange = _a.onChange, onDragStart = _a.onDragStart, onDrop = _a.onDrop, onFocus = _a.onFocus;
    return (_jsx(Input, { disabled: disabled, type: "checkbox", checked: checked, id: id, name: name, value: value, onBlur: onBlur, onChange: onChange, onDragStart: onDragStart, onDrop: onDrop, onFocus: onFocus }, void 0));
};
/** @component */
export default CheckButton;
var templateObject_1;
