var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { useState } from 'react';
// utils
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';
// components
import ArticleInfoForm from 'molecules/ArticleInfoForm';
import CancelButton from '@shared/molecules/CancelButton';
import SessionLayout from 'templates/SessionLayout';
import SubMenu from 'organisms/SubMenu';
var Spacer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 4.375rem;\n"], ["\n  width: 4.375rem;\n"])));
var ArticleInfo = function (_a) {
    var assistantNote = _a.assistantNote, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, session = _a.session, terminals = _a.terminals, toggleArticleInfo = _a.toggleArticleInfo, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, updateArticle = _a.updateArticle, requestWatchlist = _a.requestWatchlist, watchlist = _a.watchlist, currentAssistantId = _a.currentAssistantId, theme = _a.theme;
    var intl = useIntl();
    var editMode = useState(false)[0];
    var msg = defineMessages({
        notes: { defaultMessage: 'Notizen', id: 'article.info.notes' },
    });
    return (_jsx(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, backgroundColor: theme.palette.white, requestWatchlist: requestWatchlist, watchlist: watchlist, currentAssistantId: currentAssistantId, subMenu: _jsx(SubMenu, { cancelIcon: _jsx(Spacer, {}, void 0), iconRight: _jsx(CancelButton, { action: function () { return toggleArticleInfo(); } }, void 0), title: intl.formatMessage(msg.notes) }, void 0), unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal }, { children: _jsx(ArticleInfoForm, { updateArticle: updateArticle, editMode: editMode, currentAssistantNote: assistantNote }, void 0) }), void 0));
};
export var ArticleInfoComponent = ArticleInfo;
export default ArticleInfo;
var templateObject_1;
