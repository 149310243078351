var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toJS } from '@shared/utils/immutable';
import LoginPage from 'pages/Login';
import { login, resetLoginForm } from '@store/authorize/actions';
import { selectAuthErrors } from '@store/authorize/selectors';
var Login = /** @class */ (function (_super) {
    __extends(Login, _super);
    function Login(props) {
        var _this = _super.call(this, props) || this;
        _this.performLogin = function (values) {
            _this.props.login(values);
        };
        return _this;
    }
    Login.prototype.render = function () {
        var errors = this.props.errors;
        return (_jsx(LoginPage, { login: this.performLogin, reset: resetLoginForm, errors: errors }, void 0));
    };
    return Login;
}(Component));
var select = function () { return function (state) { return ({
    errors: selectAuthErrors(state),
}); }; };
export var LoginContainer = Login;
export default withRouter(connect(select, { login: login })(toJS(Login)));
