import { defineMessages } from 'react-intl';
var msg = defineMessages({
    headline: {
        defaultMessage: "Gr\u00F6\u00DFe w\u00E4hlen",
        id: 'reserveSizeSelect.menu.headline',
    },
    available: {
        defaultMessage: 'verfügbar',
        id: 'reserveSizeSelect.sizeLabels.available',
    },
    inDifferentStore: {
        defaultMessage: 'in anderer Filiale',
        id: 'reserveSizeSelect.sizeLabels.inDifferentStore',
    },
    inStock: {
        defaultMessage: 'lieferbar aus Zentrallager',
        id: 'reserveSizeSelect.sizeLabels.inStock',
    },
    orderable: {
        defaultMessage: 'lieferbar über Lieferant',
        id: 'reserveSizeSelect.sizeLabels.orderable',
    },
    scheduled: {
        defaultMessage: 'disponiert',
        id: 'reserveSizeSelect.sizeLabels.scheduled',
    },
});
export default msg;
