var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { useState } from 'react';
import orderBy from 'lodash/orderBy';
import CheckoutOverviewListItem from 'molecules/CheckoutOverviewListItem';
import CheckoutSizeSelect from 'organisms/CheckoutSizeSelect';
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-y: auto;\n  overflow: 1;\n"], ["\n  overflow-y: auto;\n  overflow: 1;\n"])));
var CheckoutOverviewList = function (_a) {
    var updateWatchlistItem = _a.updateWatchlistItem, watchlist = _a.watchlist;
    var _b = useState(null), sizeSelectItem = _b[0], setSizeSelectItem = _b[1];
    var toggleSizeSelect = function (sizeSelectItem) {
        setSizeSelectItem(sizeSelectItem);
    };
    var selectSize = function (watchlistItemId, sizeId) {
        toggleSizeSelect(null);
        updateWatchlistItem({ sizeId: sizeId }, watchlistItemId);
    };
    return (_jsxs(Wrapper, { children: [orderBy(watchlist, [function (item) { return (item.sizeId ? 1 : -1); }, 'createdAt'], ['asc', 'desc']).map(function (item) { return (_jsx(CheckoutOverviewListItem, { item: item, openSizeSelect: function () { return toggleSizeSelect(item); }, updateWatchlistItem: function (payload) {
                    return updateWatchlistItem(payload, item.id);
                } }, item.id)); }), sizeSelectItem && (_jsx(CheckoutSizeSelect, { toggleAction: function () { return toggleSizeSelect(null); }, item: sizeSelectItem, selectSize: function (sizeId) { return selectSize(sizeSelectItem.id, sizeId); } }, void 0))] }, void 0));
};
export default CheckoutOverviewList;
var templateObject_1;
