var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { withRouter } from "react-router-dom";
import { Description } from "./style";
import SearchResultPrice from "../../../assistant/searchResultPrice/components/SearchResultPrice";
import PriceTag from "@shared/molecules/PriceTag";
var ArticleListItem = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var article = _a.article, history = _a.history, basePath = _a.basePath, full = _a.full, params = _a.params;
    var id = article.id;
    var brand = (_b = article === null || article === void 0 ? void 0 : article.v2) === null || _b === void 0 ? void 0 : _b.brand;
    var color = (_c = article === null || article === void 0 ? void 0 : article.v2) === null || _c === void 0 ? void 0 : _c.color;
    var material = (_d = article === null || article === void 0 ? void 0 : article.v2) === null || _d === void 0 ? void 0 : _d.material;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ onClick: function () { return history.push("" + basePath + id + params); }, style: {
                display: "flex",
                flexDirection: "column",
                marginBottom: "1rem",
                justifyContent: "center",
                alignItems: "center"
            } }, { children: [_jsx("div", { children: _jsx("img", { src: ((_e = article === null || article === void 0 ? void 0 : article.images) === null || _e === void 0 ? void 0 : _e.thumbnail) || ((_g = (_f = article === null || article === void 0 ? void 0 : article.images) === null || _f === void 0 ? void 0 : _f.main) === null || _g === void 0 ? void 0 : _g.thumbnail), alt: "thumbnail", style: { maxHeight: "150px", maxWidth: "150px" } }, void 0) }, void 0), _jsx("div", { children: brand || "-" }, void 0), _jsx("div", { children: _jsx(Description, {}, void 0) }, void 0), _jsx("div", { children: color || "-" }, void 0), _jsx("div", { children: material || "-" }, void 0), _jsx("div", { children: (article === null || article === void 0 ? void 0 : article.price) ? _jsx(SearchResultPrice, { price: article === null || article === void 0 ? void 0 : article.price }, void 0) :
                        _jsx(PriceTag, { prices: article === null || article === void 0 ? void 0 : article.prices, targetPrices: article === null || article === void 0 ? void 0 : article.targetPrices }, void 0) }, void 0)] }), void 0) }, void 0));
};
export default withRouter(ArticleListItem);
