var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { fontWeight, palette } from "@shared/utils/styled";
import styled from "styled-components";
export var BottomMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\ncursor:pointer;\n  height: 3.25rem;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  // border-top: 0.14rem solid ", ";\n  box-shadow:0px -1px 13px rgba(0,0,0,0.1), 0px 1px 3px rgba(0,0,0,0.1);\n\n\n"], ["\ncursor:pointer;\n  height: 3.25rem;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  // border-top: 0.14rem solid ", ";\n  box-shadow:0px -1px 13px rgba(0,0,0,0.1), 0px 1px 3px rgba(0,0,0,0.1);\n\n\n"])), palette("border"));
export var BottomMenuArchive = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  height: 100%;\n  justify-content: center;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n  }\n\n  & > span {\n    opacity: ", ";\n  }\n"], ["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  display: flex;\n  font-weight: ", ";\n  height: 100%;\n  justify-content: center;\n  overflow: hidden;\n  text-align: center;\n  text-decoration: none;\n  transition: background-color 0.2s ease, opacity 0.2s ease;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n  }\n\n  & > span {\n    opacity: ", ";\n  }\n"])), palette("secondaryBackground"), palette("white"), fontWeight("bold"), function (props) {
    return props.disabled
        ? props.theme.palette.primaryHighlight
        : props.theme.palette.activePrimaryHighlight;
}, function (props) { return (props.disabled ? "0.3" : "1"); });
var templateObject_1, templateObject_2;
