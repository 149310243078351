// @flow
import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
export var initialState = Map({
    autoLogOff: false,
    authorized: false,
    currentUserId: null,
    errors: [],
});
var reducer = handleActions({
    LOGIN: function (state) { return state; },
    LOGIN_COMPLETE: {
        next: function (state, action) {
            return state
                .set('authorized', true)
                .set('errors', [])
                .set('currentUserId', action.payload);
        },
        throw: function (state, action) {
            return Boolean(action.payload.body)
                ? state.set('errors', action.payload.body || [])
                : state;
        },
    },
    LOGOUT: function (state) { return state; },
    LOGOUT_COMPLETE: {
        next: function (state) {
            return state
                .set('authorized', false)
                .set('errors', [])
                .set('currentUserId', null);
        },
        throw: function (state) {
            return state;
        },
    },
    SET_AUTO_LOG_OFF: {
        next: function (state, action) {
            return state
                .set('autoLogOff', action.payload);
        },
        throw: function (state) {
            return state;
        },
    },
}, initialState);
export default reducer;
