var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @flow
import React, { useEffect, useState } from "react";
import { palette } from "@shared/utils/styled";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import localStorage from "store";
import SessionLayout from "templates/SessionLayout";
import CategoryGrid from "organisms/CategoryGrid";
import styled, { withTheme } from "styled-components";
import VerticalFlex from "components/atoms/VerticalFlex";
import { Link, withRouter } from "react-router-dom";
import Icon from "@shared/atoms/Icon";
import { useDispatch } from "react-redux";
import { filterSelected } from "@store/filterSelections/filterActions";
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    flex: 1;\n    overflow: auto;\n"], ["\n    width: 100%;\n    flex: 1;\n    overflow: auto;\n"])));
var Entry = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);\n    border-bottom: 0.0625rem solid ", ";\n        // border-left: 0.0625rem solid ", ";\n        // border-right: 0.0625rem solid ", ";\n    cursor: pointer;\n    text-decoration: none;\n    padding: 0.8rem 2rem;\n    font-weight: 300;\n    font-size: 1.5rem;\n    align-items: center;\n    color: ", ";\n\n    &:first-child {\n        color: ", ";\n        display: flex;\n        padding: 1.2rem 2rem;\n        justify-content: center;\n        position: relative;\n        font-size: 1.5rem;\n    }\n"], ["\n    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);\n    border-bottom: 0.0625rem solid ", ";\n        // border-left: 0.0625rem solid ", ";\n        // border-right: 0.0625rem solid ", ";\n    cursor: pointer;\n    text-decoration: none;\n    padding: 0.8rem 2rem;\n    font-weight: 300;\n    font-size: 1.5rem;\n    align-items: center;\n    color: ", ";\n\n    &:first-child {\n        color: ", ";\n        display: flex;\n        padding: 1.2rem 2rem;\n        justify-content: center;\n        position: relative;\n        font-size: 1.5rem;\n    }\n"])), palette("border"), palette("border"), palette("border"), palette("black"), palette("black"));
var EntryList = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border: 1px solid #e0e0e0;\n    display: flex;\n    justify-content: center;\n    padding: 2rem;\n    border-radius: 1rem;\n    font-size: 2rem;\n    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);\n    text-decoration: none;\n    color: #000000;\n"], ["\n    border: 1px solid #e0e0e0;\n    display: flex;\n    justify-content: center;\n    padding: 2rem;\n    border-radius: 1rem;\n    font-size: 2rem;\n    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);\n    text-decoration: none;\n    color: #000000;\n"])));
var NeueSessionIcon = styled(Icon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: absolute;\n    left: 1rem;\n"], ["\n    position: absolute;\n    left: 1rem;\n"])));
var CategoryStyle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 2rem;\n    padding: 1rem;\n"], ["\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 2rem;\n    padding: 1rem;\n"])));
var Search = function (_a) {
    var primaryCategories = _a.primaryCategories, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, categories = _a.categories, categoryCurrentPage = _a.categoryCurrentPage, categoryLoading = _a.categoryLoading, categoryPageCount = _a.categoryPageCount, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, getCategories = _a.getCategories, match = _a.match, session = _a.session, terminals = _a.terminals, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, watchlist = _a.watchlist, theme = _a.theme;
    var intl = useIntl();
    var getTabs = function () {
        return primaryCategories.reduce(function (acc, item) {
            acc.push({
                title: item.displayName,
                target: "/sessions/" + session.uuid + "/search/" + item.id,
            });
            return acc;
        }, []);
    };
    var _b = useState(false), isArticleListScrolled = _b[0], setIsArticleListScrolled = _b[1];
    var handleArticleListScroll = function (scrolled) {
        setIsArticleListScrolled(scrolled);
    };
    var dispatch = useDispatch();
    useEffect(function () {
        if (!match.params.primaryCategoryId) {
            dispatch(filterSelected([]));
        }
    }, [match.params]);
    var getBaseTitle = function () {
        if (!Boolean(primaryCategories) || !Boolean(match.params.primaryCategoryId)) {
            return "";
        }
        var current = primaryCategories.find(function (item) { return item.id === parseInt(match.params.primaryCategoryId, 10); });
        return current && Boolean(current.displayName) ? current.displayName : "";
    };
    var mafId = localStorage.get("mafId");
    var tabs = getTabs();
    var isCategoryPage = "" === getBaseTitle();
    var msg = defineMessages({
        search: {
            defaultMessage: "Artikelauswahl",
            id: "dashboard.tiles.searchList",
        },
    });
    var backRoute = isCategoryPage ? "/" : "/sessions/" + session.uuid + "/search";
    return (_jsxs(SessionLayout, __assign({ assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, backgroundColor: theme.palette.white, currentAssistantId: currentAssistantId, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, session: session, terminals: terminals, unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, watchlist: watchlist, subMenu: undefined, isArticleListScrolled: isArticleListScrolled }, { children: [_jsxs(_Fragment, { children: [_jsxs(Entry, __assign({ to: backRoute, "data-cy-id": "HomePage" }, { children: [_jsx(NeueSessionIcon, { icon: "back" }, void 0), getBaseTitle() || intl.formatMessage(msg.search)] }), void 0), isCategoryPage &&
                        tabs.map(function (tab) { return (_jsx(CategoryStyle, { children: _jsx(EntryList, __assign({ "data-cy-id": "range", to: tab.target }, { children: tab.title }), void 0) }, tab.title)); })] }, void 0), _jsxs(Wrapper, { children: [mafId && isCategoryPage && (_jsx("p", __assign({ style: { padding: "0px 5px" } }, { children: _jsx(FormattedMessage, { defaultMessage: "-", id: "matchandfit.notice" }, void 0) }), void 0)), _jsx(CategoryGrid, { ScrollingState: handleArticleListScroll, basePath: "/sessions/" + session.uuid, categories: categories, currentPage: categoryCurrentPage, getCategories: getCategories, loading: categoryLoading, pageCount: categoryPageCount, primaryCategoryId: match.params.primaryCategoryId
                            ? parseInt(match.params.primaryCategoryId, 10)
                            : null }, void 0)] }, void 0)] }), void 0));
};
export default withRouter(withTheme(Search));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
