import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
import localStore from 'store';
/*
- Delete item , if the data is empty then reset status to null
- update item
        -- if one item only exist then update and change status of basket
        -- if more than one item then check the status of the new updated item ,
        -- if it's the same as the basket status then update if no then don't update and show modal with error
- add new item
        -- new item to empty list , then set status of the basket as the item status
        -- new item to list which is not empty, then status of the item should be the same as the basket
           else show modal with error
*/
export var initialState = Map({
    loading: false,
    data: List(),
    status: null,
    hasDifferentType: false,
    addToCartClicked: false,
});
export var updateItem = function (item) { return function (existing) {
    // $FlowIgnoreNextLine
    var index = existing.findIndex(function (exitem) { return exitem.get('id') === item.get('id'); });
    if (index === -1) {
        // $FlowIgnoreNextLine
        return existing.push(item);
    }
    return existing.set(index, item);
}; };
var updateList = function (state, requested) {
    var p = requested.toJS();
    if (p.length < 1)
        return state;
    return state.update('data', function (items) {
        var index = {};
        p.forEach(function (x) {
            index[x.id] = x;
        });
        var list = items.toJS().map(function (x) {
            var tempX = x;
            if (index[tempX.id]) {
                tempX.requestId = index[tempX.id].requestId;
                tempX.requestState = index[tempX.id].requestState;
            }
            return Map(tempX);
        });
        return List(list);
    });
};
var reducer = handleActions({
    CREATE_WATCHLIST_ITEM: function (state) { return state.set('loading', true); },
    CREATE_WATCHLIST_ITEM_COMPLETE: {
        next: function (state, action) {
            return state.set('loading', false).update('data', function (watchlistItems) {
                // $FlowIgnoreNextLine
                return watchlistItems.push(action.payload);
            });
        },
        throw: function (state) {
            return state.set('loading', false);
        },
    },
    DELETE_WATCHLIST_ITEM: function (state) { return state.set('loading', true); },
    DELETE_WATCHLIST_ITEM_COMPLETE: {
        next: function (state, action) {
            localStore.remove("addToCartClicked");
            return state
                .set('loading', false)
                .update('data', function (existing) {
                return existing.filter(function (item) { return item.get('id') !== action.payload.get('id'); });
            });
        },
        throw: function (state) {
            return state.set('loading', false);
        },
    },
    DELETE_WATCHLIST: function (state) { return state.set('deletingAll', true); },
    DELETE_WATCHLIST_COMPLETE: {
        next: function (state) {
            return state.set('deletingAll', false).set('data', List());
        },
        throw: function (state) {
            return state.set('deletingAll', false);
        },
    },
    GET_WATCHLIST: function (state) { return state.set('loading', true); },
    GET_WATCHLIST_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('data', action.payload)
                .set('status', localStore.get('watchlistStatus'));
        },
        throw: function (state) {
            return state.set('loading', false);
        },
    },
    UPDATE_WATCHLIST_ITEM: function (state) { return state; },
    UPDATE_WATCHLIST_ITEM_COMPLETE: {
        next: function (state, action) {
            return state.update('data', updateItem(action.payload));
        },
        throw: function (state) {
            return state;
        },
    },
    REQUEST_WATCHLIST: function (state) { return state.set('waitingRequest', true); },
    REQUEST_WATCHLIST_COMPLETE: {
        next: function (state, action) {
            return updateList(state, action.payload).set('waitingRequest', false);
            // const p = action.payload.toJS();
            // if (p.length < 1)
            //   return state;
            // return state.update("data", items => {
            //   const index = {};
            //   p.forEach(x => index[x.id] = x);
            //   const list = items.toJS().map(x => {
            //     if (index[x.id]) {
            //       x.requestId = index[x.id].requestId;
            //       x.requestState = index[x.id].requestState;
            //     }
            //     return Map(x);
            //   })
            //   return List(list);
            // });
        },
        throw: function (state) {
            return state.set('waitingRequest', false);
        },
    },
    POLL_WATCHLIST_STATE: {
        next: function (state, action) {
            // eslint-disable-next-line no-unused-vars
            return state.update('data', function (items) {
                // (GG)
                // hier gleich wie oben: action.payload in items eintragen
                return updateList(state, action.payload);
            });
        },
        throw: function (state) {
            return state;
        },
    },
    POLL_WATCHLIST_STATE_COMPLETE: function (state) { return state; },
    SET_WATCHLIST_STATUS: {
        next: function (state, action) {
            return state.set('status', action.payload);
        },
        throw: function (state) {
            return state;
        },
    },
    SET_WATCHLIST_HAS_DIFFERENT_TYPE: {
        next: function (state, action) {
            return state
                .set('hasDifferentType', action.payload)
                .set('loading', false);
        },
        throw: function (state) {
            return state;
        },
    },
    ADD_TO_CART_CLICKED: {
        next: function (state, action) {
            return state
                .set('addToCartClicked', action.payload)
                .set('loading', false);
        },
        throw: function (state) {
            return state;
        },
    },
}, initialState);
export default reducer;
