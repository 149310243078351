var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
// utils
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toJS } from '@shared/utils/immutable';
import { parse } from 'query-string';
import { injectIntl, defineMessages } from 'react-intl';
// actions
import { getPrimaryCategories } from '@store/primaryCategory/actions';
import { getInspirations } from '@store/inspiration/actions';
// selectors
import { selectPrimaryCategories, selectShouldLoad, } from '@store/primaryCategory/selectors';
import { selectShouldLoadAll as selectShouldLoadInspirations, selectLoadingAll as selectInspirationsLoading, selectCurrent as selectCurrentInspiration, } from '@store/inspiration/selectors';
import { selectAll as selectDepartments, selectShouldLoadAll as selectShouldLoadDepartments, } from '@store/department/selectors';
// actions
import { getDepartments } from '@store/department/actions';
// components
import SessionContainer from 'containers/SessionContainer';
import Page from 'pages/Inspiration';
var Inspiration = /** @class */ (function (_super) {
    __extends(Inspiration, _super);
    function Inspiration() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Inspiration.prototype.componentDidMount = function () {
        var _a = this.props, shouldLoadInspirations = _a.shouldLoadInspirations, shouldLoad = _a.shouldLoad, shouldLoadDepartments = _a.shouldLoadDepartments;
        if (shouldLoad) {
            this.props.getPrimaryCategories();
        }
        if (shouldLoadInspirations) {
            this.props.getInspirations();
        }
        if (shouldLoadDepartments) {
            this.props.getDepartments();
        }
    };
    Inspiration.prototype.getTitle = function () {
        var _a = this.props, departments = _a.departments, location = _a.location, intl = _a.intl, inspiration = _a.inspiration;
        var params = parse(location.search, { arrayFormat: 'bracket' });
        // When set, show current department name
        if (inspiration && inspiration.departmentId) {
            if (params.department) {
                var currentDep = departments.find(function (dep) { return dep.id === parseInt(params.department, 10); });
                if (currentDep) {
                    return currentDep.displayName;
                }
            }
            return departments[0].displayName;
        }
        // Show default title
        if (inspiration && !inspiration.departmentId) {
            var defaultTitle = defineMessages({
                defaultTitle: {
                    defaultMessage: 'Highlights',
                    id: 'inspiration.menu.headline',
                },
            }).defaultTitle;
            return intl.formatMessage(defaultTitle);
        }
        // Avoid title flicker due to data fetching
        return '';
    };
    Inspiration.prototype.render = function () {
        var _this = this;
        var _a = this.props, departments = _a.departments, inspiration = _a.inspiration, inspirationsLoading = _a.inspirationsLoading, location = _a.location, match = _a.match, primaryCategories = _a.primaryCategories;
        return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { location: location, match: match, primaryCategories: primaryCategories, inspiration: inspiration, inspirationsLoading: inspirationsLoading, title: _this.getTitle(), hasDepartments: departments && departments.length > 0 }), void 0)); } }, void 0));
    };
    return Inspiration;
}(Component));
export var InspirationContainer = Inspiration;
var select = function () { return function (state, props) { return ({
    departments: selectDepartments(state),
    inspiration: selectCurrentInspiration()(state, props),
    inspirationsLoading: selectInspirationsLoading(state),
    primaryCategories: selectPrimaryCategories(state),
    shouldLoad: selectShouldLoad(state),
    shouldLoadDepartments: selectShouldLoadDepartments(state),
    shouldLoadInspirations: selectShouldLoadInspirations(state),
}); }; };
export default withRouter(injectIntl(connect(select, {
    getDepartments: getDepartments,
    getInspirations: getInspirations,
    getPrimaryCategories: getPrimaryCategories,
})(toJS(Inspiration))));
