var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { getImageUrl } from '@shared/utils/images';
import { palette, fontWeight } from '@shared/utils/styled';
import { hasDiscounts } from '@shared/utils/currency';
import PriceTag from '@shared/molecules/PriceTag';
import DiscountBadgeMedium from 'molecules/DiscountBadgeMedium';
import { Link } from 'react-router-dom';
var flash = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n"], ["\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n"])));
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  margin-bottom: 1rem;\n  position: relative;\n"], ["\n  display: block;\n  margin-bottom: 1rem;\n  position: relative;\n"])));
var ThumbnailImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  bottom: 0;\n  left: 0;\n  margin: auto;\n  max-height: 100%;\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 100%;\n"], ["\n  bottom: 0;\n  left: 0;\n  margin: auto;\n  max-height: 100%;\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 100%;\n"])));
var ImageWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: ", ";\n  position: relative;\n  width: 100%;\n  &::before {\n    content: '';\n    display: block;\n    padding-top: 100%;\n  }\n  &:active {\n    animation: ", " 0.25s linear;\n  }\n"], ["\n  background: ", ";\n  position: relative;\n  width: 100%;\n  &::before {\n    content: '';\n    display: block;\n    padding-top: 100%;\n  }\n  &:active {\n    animation: ", " 0.25s linear;\n  }\n"])), palette('white'), flash);
var Bold = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
var InfoWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 0.625rem;\n  line-height: 1.25rem;\n  color: ", ";\n"], ["\n  margin-top: 0.625rem;\n  line-height: 1.25rem;\n  color: ", ";\n"])), palette('text'));
var ActionWrapper = styled(Link)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  text-decoration: none;\n  width: 100%;\n"], ["\n  text-decoration: none;\n  width: 100%;\n"])));
var SliderArticleExtended = function (_a) {
    var article = _a.article, session = _a.session;
    var brand = article.brand, displayName = article.displayName, id = article.id, images = article.images, prices = article.prices, targetPrices = article.targetPrices;
    return (_jsx(Wrapper, { children: _jsxs(ActionWrapper, __assign({ to: "/sessions/" + session.uuid + "/articles/" + id }, { children: [_jsxs(ImageWrapper, { children: [_jsx(ThumbnailImage, { src: getImageUrl(images.main) }, void 0), hasDiscounts(prices, targetPrices) && (_jsx(DiscountBadgeMedium, { alignLeft: true }, void 0))] }, void 0), _jsxs(InfoWrapper, { children: [brand && brand.name, displayName && " \u2013 " + displayName, _jsx(Bold, { children: _jsx(PriceTag, { targetPrices: targetPrices, prices: prices }, void 0) }, void 0)] }, void 0)] }), void 0) }, void 0));
};
export default SliderArticleExtended;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
