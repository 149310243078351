var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from "react";
import { withTheme } from "styled-components";
import BaseLayout from "templates/BaseLayout";
import SessionHeader from "molecules/SessionHeader";
// Actions
import { deleteWatchlist } from "@store/watchlist/actions";
import { logout } from '@store/authorize/actions';
import { connect } from "react-redux";
var SessionLayout = function (_a) {
    var deleteWatchlist = _a.deleteWatchlist, deleteWatchlistItem = _a.deleteWatchlistItem, isWatchlistOpen = _a.isWatchlistOpen, session = _a.session, watchlist = _a.watchlist, requestWatchlist = _a.requestWatchlist, theme = _a.theme, assignSession = _a.assignSession, assignTerminal = _a.assignTerminal, assistants = _a.assistants, backgroundColor = _a.backgroundColor, children = _a.children, currentAssistantId = _a.currentAssistantId, deleteSession = _a.deleteSession, subMenu = _a.subMenu, terminals = _a.terminals, unleashAssistant = _a.unleashAssistant, unleashTerminal = _a.unleashTerminal, hasFilters = _a.hasFilters, hideTopMenu = _a.hideTopMenu, isArticleListScrolled = _a.isArticleListScrolled, showMenuIcons = _a.showMenuIcons, logout = _a.logout;
    var performLogout = function (currentUserId) {
        logout({ assistantId: currentUserId });
        localStorage.removeItem("sessionuuid");
    };
    return (_jsxs(BaseLayout, __assign({ topMenu: hideTopMenu ? (_jsx(_Fragment, {}, void 0)) : (_jsx(SessionHeader
        // istop
        , { 
            // istop
            showMenuIcons: showMenuIcons, assignSession: assignSession, assignTerminal: assignTerminal, assistants: assistants, currentAssistantId: currentAssistantId, deleteSession: deleteSession, session: session, terminals: terminals, unleashAssistant: unleashAssistant, unleashTerminal: unleashTerminal, hasFilters: hasFilters, deleteWatchlist: deleteWatchlist, deleteWatchlistItem: deleteWatchlistItem, isOpen: isWatchlistOpen || false, watchlist: watchlist, requestWatchlist: requestWatchlist, isArticleListScrolled: isArticleListScrolled, theme: theme, logout: function (currentUserId) { return performLogout(currentUserId); } }, void 0)), backgroundColor: backgroundColor }, { children: [subMenu, children] }), void 0));
};
export default withTheme(connect(null, { deleteWatchlist: deleteWatchlist, logout: logout })(SessionLayout));
