import { createSelector } from 'reselect';
var selectActionState = function (state) { return state.get('action'); };
var selectSessionState = function (state) { return state.get('session'); };
var selectState = function (state) { return state; };
var isActionLoading = function (actionState) { return actionState.get('requesting'); };
var isSessionLoading = function (sessionState) {
    return sessionState.get('creating') ||
        sessionState.get('deleting') ||
        sessionState.get('loading');
};
export var selectIsLoading = createSelector(selectState, function (state) {
    return isActionLoading(selectActionState(state)) ||
        isSessionLoading(selectSessionState(state));
});
