var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// utils
import { toJS } from '@shared/utils/immutable';
// actions
import { updateWatchlistItem } from '@store/watchlist/actions';
import { getFurnitures } from '@store/furniture/actions';
// seletors
import { selectLoadingComplete, selectFurnitures, } from '@store/furniture/selectors';
// components
import SessionContainer from 'containers/SessionContainer';
import Page from 'pages/CheckoutAvailability';
var CheckoutAvailabilityComp = /** @class */ (function (_super) {
    __extends(CheckoutAvailabilityComp, _super);
    function CheckoutAvailabilityComp() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CheckoutAvailabilityComp.prototype.render = function () {
        var _this = this;
        var _a = this.props, furnitures = _a.furnitures, furnituresReady = _a.furnituresReady;
        return (_jsx(SessionContainer, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { furnitures: furnitures, furnituresReady: furnituresReady, getFurnitures: function () {
                    return _this.props.getFurnitures(sessionProps.session.uuid);
                }, updateWatchlistItem: function (payload, id) {
                    return _this.props.updateWatchlistItem(payload, id, sessionProps.session.uuid);
                } }), void 0)); } }, void 0));
    };
    return CheckoutAvailabilityComp;
}(Component));
export { CheckoutAvailabilityComp };
var select = function () { return function (state) { return ({
    furnitures: selectFurnitures(state),
    furnituresReady: selectLoadingComplete(state),
}); }; };
export default connect(select, {
    updateWatchlistItem: updateWatchlistItem,
    getFurnitures: getFurnitures,
})(toJS(CheckoutAvailabilityComp));
