var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import styled from 'styled-components';
// components
import CustomerFormFields from 'molecules/CustomerFormFields';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background-color: white;\n  overflow-y: auto;\n  flex: 1;\n  padding:0.4rem 1.25rem;\n  width: 100%;\n"], ["\n  align-items: center;\n  background-color: white;\n  overflow-y: auto;\n  flex: 1;\n  padding:0.4rem 1.25rem;\n  width: 100%;\n"])));
var StyledForm = styled.form(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-height: 100%;\n  // overflow: auto;\n"], ["\n  min-height: 100%;\n  // overflow: auto;\n"])));
var CustomerFormAddComp = function (_a) {
    var session = _a.session, onSubmit = _a.onSubmit, updateSubmitState = _a.updateSubmitState;
    return (_jsx(Wrapper, { children: _jsx(FinalForm, { onSubmit: function (values) { return onSubmit(values); }, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (_jsxs(StyledForm, __assign({ onSubmit: handleSubmit, id: 'customer-add' }, { children: [_jsx(CustomerFormFields, { action: 'add', session: session }, void 0), _jsx(FormSpy, { subscription: {
                                valid: true,
                                submitting: true,
                                pristine: true,
                            }, onChange: function (_a) {
                                var valid = _a.valid, submitting = _a.submitting, pristine = _a.pristine;
                                setTimeout(function () {
                                    if (valid) {
                                        updateSubmitState(false);
                                    }
                                    if (submitting || pristine) {
                                        updateSubmitState(true);
                                    }
                                }, 0);
                            } }, void 0)] }), void 0));
            } }, void 0) }, void 0));
};
export default CustomerFormAddComp;
var templateObject_1, templateObject_2;
